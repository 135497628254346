import { ModelBase } from './model';

class StatusCount extends ModelBase {
  static namespace = 'user_lesson_statuses_counts';

  static path = '/api/v2/user_lesson_statuses/counts/';

  static cache = false;

  static keyname = 'user_lesson_status';
}
export default StatusCount.register();
