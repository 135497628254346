import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  Dropdown, Form, Segment,
} from 'semantic-ui-react';
import _ from 'underscore';
import bind from 'bind-decorator';
import TextareaAutosize from 'react-textarea-autosize';
import { FREE_RESPONSE, MULTIPLE_CHOICE } from '../../constants';
import Upload from '../Shared/Upload';
import AnswerArea from './AnswerArea';

const s = {
  cardTile: {
    width: '600px',
    minHeight: '650px',
  },
};

const QUESTION_TYPES = [
  {
    key: MULTIPLE_CHOICE,
    value: MULTIPLE_CHOICE,
    text: 'Multiple Choice',
  },
  {
    key: FREE_RESPONSE,
    value: FREE_RESPONSE,
    text: 'Free Response',
  },
  // {
  //   key: TRUE_FALSE,
  //   value: TRUE_FALSE,
  //   text: 'True/False',
  // },
];

class QuestionContent extends Component {
  static propTypes = {
    card: PropTypes.object,
    uploadImage: PropTypes.func,
    saveCard: PropTypes.func,
    takeSnapshot: PropTypes.func,
    historyPosition: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      question: props.card.content.question,
    };
  }

  @bind
  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.historyPosition !== prevProps.historyPosition) {
      this.setState({
        question: this.props.card.content.question,
      });
    }
    return null;
  }

  @bind
  async updateImage(files) {
    const { card } = this.props;
    const content = card.content;
    this.props.takeSnapshot();
    if (files) {
      const url = await this.props.uploadImage(files);
      content.image = url;
    } else {
      content.image = null;
    }
    this.props.saveCard({ content });
  }

  @bind
  handleTypeChange(e, { value }) {
    const { card } = this.props;
    const content = card.content;
    this.props.takeSnapshot();
    if (value === MULTIPLE_CHOICE) {
      content.answers = [{ answer: '', correct: true },
        { answer: '', correct: false }];
    } else if (value === FREE_RESPONSE) {
      content.answers = [];
    }
    this.props.takeSnapshot();
    this.props.saveCard({ content, presentation_type: value });
  }

  @bind
  handleChoicesChange(e, { value }) {
    const { card } = this.props;
    const content = card.content;
    this.props.takeSnapshot();
    content.choices = value;
    this.props.saveCard({ content });
  }

  @bind
  updateQuestion(question) {
    this.setState({ question });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.takeSnapshot();
      const { content } = this.props.card;
      content.question = question;
      this.props.saveCard({ content });
    }, 300);
  }

  render() {
    const { card, uploadImage, saveCard } = this.props;
    const { question } = this.state;
    const incorrectAnswerCount = _.filter(card.content.answers, (answer) => {
      return !answer.correct;
    }).length;
    const answerCount = incorrectAnswerCount + 1;
    const image = card.content.image;
    const content = card.content;
    const choices = card.content.choices;
    const countOptions = _.range(2, answerCount + 1)
      .map((num) => ({ key: num, value: num, text: `Show ${num} Choices` }));

    return (
      <div style={s.cardTile}>
        <Segment style={{ padding: '5px' }}>
          <Dropdown
            options={QUESTION_TYPES}
            defaultValue={card ? card.presentation_type : null}
            placeholder="QUESTION TYPE"
            onChange={this.handleTypeChange}
            button
          />
          {card.presentation_type === MULTIPLE_CHOICE
          && (
          <Dropdown
            options={countOptions}
            defaultValue={card ? Math.min(choices, answerCount) : null}
            placeholder="Show ? Choices"
            onChange={this.handleChoicesChange}
            button
          />
          )}
        </Segment>
        <Form size="massive">
          <Form.Field required>
            <label>Question:</label>
            <TextareaAutosize
              rows={1}
              value={question}
              onChange={(e) => this.updateQuestion(e.target.value)}
            />
          </Form.Field>
        </Form>
        <Upload
          maxSize={20}
          source={image}
          accept="image/png, image/jpeg, image/gif, image/webp"
          updateFile={this.updateImage}
          description="an Image"
        />
        <AnswerArea
          content={content}
          uploadImage={uploadImage}
          saveCard={saveCard}
          type={card.presentation_type}
          takeSnapshot={this.props.takeSnapshot}
          historyPosition={this.props.historyPosition}
        />
      </div>
    );
  }
}

export default Radium(QuestionContent);
