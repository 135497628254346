import jwtDecode from 'jwt-decode';
import ls from 'local-storage';
import moment from 'moment';

const refreshToken = async () => {
  const response = await fetch('/api/v2/login/', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${ls('token.access')}`,
    },
  });
  if (response.status === 200) {
    const data = await response.json();
    return data;
  }
  return false;
};

export const checkToken = async (timeout) => {
  const loginPage = window.location.href.endsWith('/login');
  const enrollmentPage = window.location.href.includes('/enroll/')
                         || window.location.href.includes('/signup');
  const resetPasswordPage = window.location.href.endsWith('/reset-password')
                         || window.location.href.includes('/reset/');
  const integrationPage = window.location.href.includes('/authorize/');

  const framablePage = window.location.href.includes('/app/v2/lessons/');

  if (loginPage || enrollmentPage || resetPasswordPage || integrationPage) {
    // if we're on the login, enrollment, integration authorization, or password reset screens, don't do anything.
    return;
  }
  let token = ls('token.access');
  if (!framablePage) {
    // To allow SSO from the mobile app, we need a way of
    // getting our JWT out of the web and back into the
    // mobile app - this accomplishes that. But, we should
    // ONLY do this if we're not on a page that could be framed.
    // This list of frameable pages is driven by nginx.conf
    // - we only allow specific pages to be framed for the purpose
    // of an embeddable lesson player.
    window.postMessage(token);
  }
  let validToken = false;
  if (token) {
    token = jwtDecode(token);
    const expiration = moment.unix(token.exp);
    const now = moment();
    if (expiration.isAfter(now.add(timeout, 'seconds'))) {
      // if their token does NOT expire in the next TIMEOUT seconds
      // then it is still good (e.g. not expired)
      validToken = true;
    } else if (token.partially_authenticated) {
      // if the token is a partial auth token
      // then go ahead and log them out.
      // Partial auth tokens cannot be refreshed.
      window.location.href = '/api/v2/logout/';
      return;
    }
  }
  if (!validToken) {
    const response = await refreshToken();
    if (response) {
      window.localStorage.setItem('token.access', response.access);
      window.localStorage.setItem('token.refresh', response.refresh);
      validToken = true;
    } else {
      console.warn('The current token is invalid, redirecting to login');

      // even if their token is invalid, there may still be an HttpOnly cookie to clear
      window.location.href = '/api/v2/logout/'; // this will redirect to login
    }
  }
};

export const seamlessRedirect = (e) => {
  // HACK: put this function in a better location
  e.preventDefault();
  const url = e.target.href;
  const preloadFrame = document.getElementById('preloadframe');
  preloadFrame.location = url;
  setTimeout(() => {
    window.location.href = url;
  }, 800); // HACK: this shouldn't be hard coded. Find a more intelligent way of doing this
  // TODO: also use fide out/in the UI to also ease the dom transition... maybe
};

export const clone = (obj) => JSON.parse(JSON.stringify(obj));

export const enumerateDaysBetweenDates = (startDate, endDate) => {
  const dates = [];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }

  return dates;
};
