import React, { Component } from 'react';
import Radium from 'radium';
import {
  Input, Button, Loader, Label, Icon,
} from 'semantic-ui-react';
import bind from 'bind-decorator';
import PropTypes from 'prop-types';
import { hickoryGreen } from '../../styles/palette';

let s = {};

class NewTrialSignupContainer extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);

    const query = new URLSearchParams(props.location.search);

    this.state = {
      email: '',
      password: '',
      confirmPassword: '',
      orgName: '',
      firstName: '',
      lastName: '',
      invalidUsername: false,
      passwordError: null,
      referralCode: query.get('ref'),
    };
  }

  @bind
  async createAccount() {
    const email = this.state.email.trim().toLowerCase();
    const password = this.state.password.trim();
    const confirmPassword = this.state.confirmPassword.trim();
    if (password !== confirmPassword) {
      this.setState({
        passwordError: 'Password and Confirm Password do not match. Please verify that they\'re correct and try again.',
      });
      return;
    }
    if (password.length < 8) {
      this.setState({
        passwordError: 'Your password is too short. Please choose a password with at least 8 characters.',
      });
      return;
    }
    const { referralCode } = this.state;
    const orgName = this.state.orgName.trim();
    const firstName = this.state.firstName.trim();
    const lastName = this.state.lastName.trim();

    this.setState({ working: true });
    const response = await fetch('/api/v2/signup/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email,
        password,
        org_name: orgName,
        first_name: firstName,
        last_name: lastName,
        referral_code: referralCode ? referralCode.trim() : null,
      }),
    });

    if (response.status === 200) {
      const tokenContainer = await response.json();
      localStorage.setItem('token.access', tokenContainer.access);
      localStorage.setItem('token.refresh', tokenContainer.refresh);

      window.location.href = '/';
    } else {
      this.setState({ invalidUsername: true });
    }
    this.setState({ working: false });
  }

  render() {
    return (
      <div style={s.contents}>
        <div style={s.backContainer}>
          <div
            style={s.backLink}
            onClick={() => {
              window.location.href = 'https://www.hickorytraining.com';
            }}
          >
            <Label style={s.backLinkText}>
              <Icon name="angle double left" />
              {' '}
              Back to hickorytraining.com
            </Label>
          </div>
        </div>
        <div style={s.signupCard}>
          <div style={s.signupCardTitle}>Sign Up for 30 Day Trial</div>

          <div style={s.cardContents}>
            <div style={s.instructionText}>
              Enjoy a 30-day free trial of Hickory, a modern learning management system built for
              continuous learning.
            </div>
            {this.state.invalidUsername ? (
              <div style={s.error}>
                The email address you provided is already registered with Hickory. Please use
                another email address or contact us at support@hickorytraining.com.
              </div>
            ) : null}

            {this.state.passwordError ? (
              <div style={s.error}>
                {this.state.passwordError}
              </div>
            ) : null}

            <div style={s.inputLabel}>Your Name</div>
            <div style={s.nameBox}>
              <div>
                <Input
                  style={s.input}
                  placeholder="First Name"
                  onChange={(e, { value }) => this.setState({ firstName: value })}
                />
              </div>

              <div>
                <Input
                  style={s.input}
                  placeholder="Last Name"
                  onChange={(e, { value }) => this.setState({ lastName: value })}
                />
              </div>
            </div>

            <div style={s.inputLabel}>Email Address</div>
            <div>
              <Input
                style={s.input}
                placeholder="Enter Your Email Address"
                onChange={(e, { value }) => this.setState({ email: value })}
              />
            </div>

            <div style={s.inputLabel}>Password</div>
            <div>
              <Input
                placeholder="Enter Your Password"
                type="password"
                style={s.input}
                onChange={(e, { value }) => this.setState({ password: value })}
              />
            </div>

            <div style={s.inputLabel}>Confirm Password</div>
            <div>
              <Input
                placeholder="Confirm Your Password"
                type="password"
                style={s.input}
                onChange={(e, { value }) => this.setState({ confirmPassword: value })}
              />
            </div>

            <div style={s.inputLabel}>Company Name</div>
            <div>
              <Input
                placeholder="Enter Company Name"
                style={s.input}
                onChange={(e, { value }) => this.setState({ orgName: value })}
              />
            </div>

            <div style={s.inputLabel}>Referral Code</div>
            <div>
              <Input
                placeholder="Enter Referral Code"
                style={s.input}
                defaultValue={this.state.referralCode}
                onChange={(e, { value }) => this.setState({ referralCode: value })}
              />
            </div>

            <div>
              {this.state.working ? (
                <Loader active={this.state.working} inline="centered" />
              ) : (
                <Button style={s.button} onClick={this.createAccount}>
                  Create Account
                </Button>
              )}
            </div>
          </div>
        </div>
        <div style={s.versionNumber}>
          version&nbsp;
          {window.APP_VERSION}
        </div>
      </div>
    );
  }
}

s = {
  contents: {
    backgroundColor: '#f5f5f5',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signupCard: {
    backgroundColor: 'white',
    width: '408px',
    padding: '20px',
  },
  signupCardTitle: {
    alignItems: 'left',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '26px',
    lineHeight: '125%',
    color: '#27272B',
  },
  cardContents: {},
  input: {
    width: '100%',
    marginBottom: '20px',
    height: '4em',
  },
  button: {
    width: '100%',
    height: '4em',
    backgroundColor: hickoryGreen,
    color: 'white',
  },
  cardFooter: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '1.5rem',
  },
  footerAction: {
    color: '#918f90',
    marginTop: '10px',
    marginLeft: '40px',
    cursor: 'pointer',
  },
  ssoBody: {
    fontSize: '1.4em',
    marginTop: '50px',
    marginBottom: '50px',
  },
  error: {
    color: '#d24e4e',
    backgroundColor: 'rgba(210,78,78,.1)',
    fontSize: '1.1em',
    border: '1px solid #d24e4e',
    padding: '1.5rem 1.5rem 1.5rem 1.5rem',
    borderRadius: '4px',
    marginBottom: '20px',
  },
  versionNumber: {
    position: 'fixed',
    bottom: '5px',
    left: '5px',
  },
  instructionText: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  inputLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '125%',
    color: '#4D4E58',
    marginBottom: '10px',
  },
  backContainer: {
    width: '408px',
    height: '55px',
    marginBottom: '24px',
  },
  backLink: {
    background: '#E7EDFC',
    height: '55px',
    width: '285px',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  backLinkText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '125%',
    textAlign: 'center',
    color: '#4E7BEF',
    background: '#E7EDFC',
    height: '55px',
    width: '285px',
    alignItems: 'center',
    display: 'flex',
  },
  nameBox: {
    display: 'flex',
    flexDirection: 'row',
  },
};

export default Radium(NewTrialSignupContainer);
