import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';
import {
  Button, Dropdown, Header, Icon, Segment, Table,
} from 'semantic-ui-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import CourseSchedule from '@/models/course_schedule';
import User from '../../models/user';
import { Features } from '../../constants';
import CourseScheduler from '../Shared/CourseScheduler';

class GroupCourseCard extends Component {
  static propTypes = {
    schedule: PropTypes.object,
    refresh: PropTypes.func,
    confirmRemoveFromGroup: PropTypes.func,
    confirmToggleReview: PropTypes.func,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      scheduling: false,
    };
  }

  async componentDidMount() {
    const me = await User.me();
    this.setState({
      me,
    });
  }

  @bind
  async scheduleCourse(schedule) {
    const {
      pendingStartDate,
      pendingStartTime,
      scheduledReview,
      allowRetakes,
      scheduledScore,
    } = schedule;
    const dateTimeStr = `${pendingStartDate} ${pendingStartTime}`;
    const dateTimeFormat = 'MM/DD/YYYY hh:mm A';
    const startTime = moment(dateTimeStr, dateTimeFormat);
    const newSchedule = {
      start_date: startTime,
      start_date_tz: moment.tz.guess(),
    };
    newSchedule.review_frequency = scheduledReview;
    newSchedule.review_frequency_type = 2; // HACK
    newSchedule.retake_threshold = allowRetakes ? scheduledScore : 0;
    await CourseSchedule.objects().update(this.props.schedule.id, newSchedule);
    this.props.refresh();
  }

  @bind
  menuOptionsForCourse(schedule) {
    return (
      <Dropdown text="" direction="left">
        <Dropdown.Menu>
          <Dropdown.Item
            text="Edit"
            onClick={() => {
              this.props.history.push(`/app/v2/courses/${schedule.course_id}/`);
            }}
          />
          <Dropdown.Divider />
          { schedule.start_date ? (
            <Dropdown.Item
              text={
                    schedule.reviews_enabled ? 'Turn Off Reviews' : 'Turn On Reviews'
                  }
              onClick={() => {
                this.props.confirmToggleReview(schedule);
              }}
            />
          ) : null }

          <Dropdown.Item
            text="Remove From Group"
            onClick={() => {
              this.props.confirmRemoveFromGroup(schedule, true);
            }}
          />
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  @bind
  showScheduling(schedule) {
    const {
      me,
    } = this.state;

    const showAdvancedScheduling = me && me.waffle_flags.includes(Features.ADVANCED_SCHEDULING);
    return (
      <CourseScheduler
        showAdvancedScheduling={showAdvancedScheduling}
        scheduleCourse={this.scheduleCourse}
        stopScheduling={() => this.setState({ scheduling: false })}
        course={{ retake_option_available: schedule.course__retake_option_available }}
      />
    );
  }

  render() {
    const { scheduling } = this.state;
    const { schedule } = this.props;
    const now = new Date();

    return (
      <Segment className="row">
        <div className="hickory-column">
          <Link to={`/app/v2/courses/${schedule.course_id}`}>
            <Header as="h2" color="blue">{schedule.course__name}</Header>
          </Link>
          <span>Last updated {moment(schedule.course__updated_at).format('MMMM Do [at] h:mm a')}.</span>
        </div>
        {!schedule.start_date && (scheduling
          ? this.showScheduling(schedule)
          : (
            <div>
              <Button positive onClick={() => this.setState({ scheduling: !scheduling })}>Schedule</Button>
              {this.menuOptionsForCourse(schedule)}
            </div>
          )
        )}
        {schedule.start_date && (
        <Table className="hickory-borderless" style={{ maxWidth: '50%' }}>
          <Table.Body>
            <Table.Row>
              <Table.Cell textAlign="center">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    {moment(schedule.start_date).isAfter(now) ? (
                      <p style={{ color: 'gray' }}>STARTING</p>
                    ) : (
                      <p style={{ color: 'green' }}>STARTED</p>
                    )}
                    {schedule.start_date_tz === 'UTC'
                      ? moment(schedule.start_date).format('MMMM Do YYYY, hh:mm a')
                      : moment(schedule.start_date)
                        .tz(schedule.start_date_tz)
                        .format('MMMM Do YYYY, hh:mm a')}
                    {schedule.reviews_enabled ? (
                      <p />
                    ) : (
                      <Icon name="calendar times outline" />
                    )}

                    {!schedule.continuous_reviews && (
                    <div style={{ textAlign: "left" }}>
                      Retake: every {schedule.review_frequency} months
                    </div>
                    )}
                    {schedule.retake_threshold > 0 && (
                    <div style={{ textAlign: "left" }}>
                      Pass: {parseInt(schedule.retake_threshold * 100.0)}%
                    </div>
                    )}
                  </div>
                  {schedule.requirements && 'time_delay' in schedule.requirements && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                          &nbsp;
                    <span style={{ fontSize: 'x-large' }}>&</span>
                    <Header as="h2" style={{ margin: '10px' }}>
                      {schedule.requirements.time_delay}
                    </Header>
                    Days after Learner
                    <br />
                    is added to group
                  </div>
                  )}
                </div>
              </Table.Cell>
              {schedule.start_date && (
              <Table.Cell textAlign="center">
                {schedule.end_date ? (
                  <div>
                    <p style={{ color: 'red' }}>END</p>
                    {moment(schedule.end_date).format('MMMM Do YYYY, hh:mm a')}
                  </div>
                ) : (
                  <p
                    style={{
                      color: moment(schedule.start_date).isAfter(now) ? 'gray' : 'green',
                    }}
                  >
                    {moment(schedule.start_date).isAfter(now) ? 'QUEUED' : 'LIVE'}
                  </p>
                )}
              </Table.Cell>
              )}
              <Table.Cell>
                {this.menuOptionsForCourse(schedule)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        )}
      </Segment>

    );
  }
}

export default GroupCourseCard;
