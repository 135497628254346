import React from 'react';
import { CompositeDecorator } from 'draft-js';
import PropTypes from 'prop-types';

/**
 * The link decorator is used with DraftJS to understand how to render links given the raw data for links.
 * The decorator is applied to EditorState, and from there it will find all the link entities in the data
 * and create a Link component from that data and applied it to the selection.
 *
 * For a deeper understanding of how entities work in DraftJS,
 * read this: https://medium.com/@rajaraodv/how-draft-js-represents-rich-text-data-eeabb5f25cf2#.xppmr3ozb
 */

const Link = function (props) {
  // DraftJS example usage: https://github.com/facebook/draft-js/blob/master/examples/draft-0-9-1/link/link.html#L189
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} rel="noreferrer" target="_blank" style={{ cursor: 'pointer' }}>
      {props.children}
    </a>
  );
};

Link.propTypes = {
  children: PropTypes.array,
  entityKey: PropTypes.string,
  contentState: PropTypes.object,
};

function findLinkEntities(contentBlock, callback, contentState) {
  // This decorator was found in the draftJS documentation repo, used in an example:
  // https://github.com/facebook/draft-js/blob/master/examples/draft-0-9-1/link/link.html#L176
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null
        && contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback,
  );
}

const LinkDecorator = new CompositeDecorator([{
  strategy: findLinkEntities,
  component: Link,
}]);

export default LinkDecorator;
