import ls from 'local-storage';
import { ModelBase } from './model';

class Course extends ModelBase {
  static namespace = 'course';

  async uploadCoverImage(file) {
    const formData = new FormData();
    formData.append('file', file);
    const response = await fetch(`/api/v2/courses/${this.id}/upload/`, {
      method: 'POST',
      credentials: 'same-origin',
      header: {
        Authorization: `Bearer ${ls('token.access')}`,
        Accept: 'application/json',
      },
      body: formData,
    });
    const data = await response.json();
    return data.url;
  }

  async uploadPexelsCoverImage(url) {
    const formData = new FormData();
    formData.append('url', url);
    const response = await fetch(`/api/v2/courses/${this.id}/upload_pexels/`, {
      method: 'POST',
      credentials: 'same-origin',
      header: {
        Authorization: `Bearer ${ls('token.access')}`,
        Accept: 'application/json',
      },
      body: formData,
    });
    const data = await response.json();
    return data.url;
  }
}
export default Course.register();
