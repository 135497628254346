import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'semantic-ui-react';
// eslint-disable-next-line import/no-cycle
import LessonPlayer from '../LessonPlayer/LessonPlayer';

class LessonPreviewModal extends Component {
  static propTypes = {
    lessonId: PropTypes.string,
    close: PropTypes.func,
    show: PropTypes.bool,
    study: PropTypes.bool,
  };

  render() {
    return (

      <Modal
        open={this.props.show}
        onClose={this.props.close}
        closeIcon
      >
        <Modal.Header>Lesson Preview</Modal.Header>
        <Modal.Content style={{ padding: 0, minHeight: '774px' }}>
          <LessonPlayer
            style={{ paddingBottom: '20px' }}
            lessonId={this.props.lessonId}
            hideFinalScreen
            preview
            study={this.props.study}
          />
        </Modal.Content>
      </Modal>

    );
  }
}

export default LessonPreviewModal;
