import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  Button,
  Modal,
  Table,
} from 'semantic-ui-react';

class UserActionModal extends Component {
  static propTypes = {
    item: PropTypes.string,
    toggleModal: PropTypes.func,
    showModal: PropTypes.bool,
    selectedUsers: PropTypes.array,
    handleSave: PropTypes.func,
  };

  render() {
    return (
      <Modal
        onClose={this.props.toggleModal}
        onOpen={this.props.toggleModal}
        closeIcon
        open={this.props.showModal}
      >
        <Modal.Header>
          {this.props.item}
          {' '}
          Users
        </Modal.Header>
        <Modal.Content>
          <div
            style={{
              maxHeight: '500px',
              overflowY: 'auto',
              maxWidth: '80%',
              border: '2px solid gray',
              borderRadius: '5px',
              margin: 'auto',
            }}
          >
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.selectedUsers.map((item) => (
                  <Table.Row key={item.id}>
                    <Table.Cell>{`${item.first_name} ${item.last_name}`}</Table.Cell>
                    <Table.Cell>{item.email}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
          <p>
            You are about to
            {' '}
            {this.props.item}
            {' '}
            the
            {' '}
            {this.props.selectedUsers.length}
            {' '}
            users above. Are you sure?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.toggleModal}>Cancel</Button>
          <Button positive onClick={this.props.handleSave}>
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default Radium(UserActionModal);
