import React, { Component } from 'react';
import Radium from 'radium';
import _ from 'underscore';
import moment from 'moment';
import { Button, Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import SumoLogger from 'sumo-logger';
import Lesson from '@/models/lesson';
import User from '@/models/user';
import UserLessonStatus from '@/models/user_lesson_status';
import SmallCard from '../Shared/SmallCard';
import ModelTable from '../Shared/ModelTable';
import { hickoryGreen, hickoryRed } from '../../styles/palette';
import { SUMO } from '../../constants';

const sumoLogger = new SumoLogger({ endpoint: SUMO });

const s = {
  container: {
    padding: '0 20%',
    marginTop: '20px',
  },
  top: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  score: {
    color: hickoryGreen,
    size: 'large',
  },
  lift: {
    color: hickoryGreen,
  },
  fall: {
    color: hickoryRed,
  },
  startButton: {
    textAlign: 'center',
    color: '#ffffff',
    textShadow: 'none',
    padding: '10px',
  },
};

class LearnerLessonsContainer extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      currentAverage: -1,
      initialAverage: -1,
      completedCount: -1,
      futureCount: -1,
      lateCount: -1,
      baseFilter: {},
    };
  }

  async componentDidMount() {
    const me = await User.me();
    const completedAssignments = await UserLessonStatus.objects()
      .filtered({ user_id: me.id, available_to_take: true, completed: true })
      .sorted('localized_start_date', false)
      .all();
    const futureAssignments = await UserLessonStatus.objects()
      .filtered({ user_id: me.id, available_to_take: true, completed: false })
      .sorted('localized_start_date', false)
      .all();
    const currentAverage = _.reduce(completedAssignments, (memo, assignment) => memo + assignment.current_score, 0)
      / (!completedAssignments.length ? 1 : completedAssignments.length);
    const initialAverage = _.reduce(completedAssignments, (memo, assignment) => memo + assignment.initial_score, 0)
      / (!completedAssignments.length ? 1 : completedAssignments.length);
    const completedCount = completedAssignments.length;
    const futureCount = futureAssignments.length;
    const lateCount = _.reduce(
      futureAssignments,
      (memo, assignment) => (memo + assignment.isLate() ? 1 : 0),
      0,
    );
    const lessons = await Lesson.objects().all();
    this.setState({
      currentAverage,
      initialAverage,
      completedCount,
      futureCount,
      lateCount,
      lessons,
      baseFilter: {
        available_to_take: true,
        user_id: me.id,
      },
    });
  }

  render() {
    const updateData = async ({
      perPage, page, filter, search, join,
    }) => {
      let allData = UserLessonStatus.objects()
        .filtered(this.state.baseFilter)
        .filtered(filter)
        .search(search)
        .sorted('localized_start_date', 'dsc' === 'asc');

      if (join) allData.joinData = join;
      allData = await allData.all();
      // allData = allData.filter((dat) => _.contains(lessonIds, dat.lesson_id));
      allData = _.groupBy(allData, (dat) => {
        const avail = _.some(dat.cards_available_to_review, (c) => moment(c.scheduled_review_date).isBefore(moment()));
        if (dat.completed && dat.cards_available_to_review.length && avail) return 'reviewable';
        if (!dat.completed) return 'takeable';
        return 'other';
      });
      allData = {
        reviewable: [],
        takeable: [],
        other: [],
        ...allData,
      };
      allData = allData.reviewable.concat(allData.takeable).concat(allData.other);
      const data = allData.slice((page - 1) * perPage, page * perPage);

      return [allData, data];
    };
    const columnData = this.state.lessons
      ? [
        {
          name: 'Assigned',
          sort: true,
          key: ['localized_start_date', (date) => moment(date).fromNow()],
        },
        {
          name: 'Lesson Name',
          sort: true,
          search: true,
          key: 'lesson__name',
        },
        {
          name: 'Status',
          sort: true,
          filter: true,
          key: [
            'completed',
            'cards_available_to_review',
            (comp, reviews) => (!comp || reviews.length ? (
              <Label color="green">Active</Label>
            ) : (
              <Label>Complete</Label>
            )),
          ],
        },
        {
          name: 'Score',
          key: [
            'current_score',
            (score) => <span style={s.score}>{Math.round(score * 100)}</span>,
          ],
        },
        {
          name: 'Improvement',
          key: [
            'initial_score',
            'current_score',
            (initial, current) => {
              const change = current - initial;
              return (
                <span style={change >= 0 ? s.lift : s.fall}>
                  {`${change >= 0 ? '+' : '-'} ${Math.abs(
                    Math.round((current - initial) * 100),
                  )}%`}
                </span>
              );
            },
          ],
        },
        {
          name: 'Next Review',
          key: [
            'cards_available_to_review',
            (cards) => (cards.length
              ? moment.min(cards.map((r) => moment(r.scheduled_review_date))).fromNow()
              : ''),
          ],
        },
        {
          name: '',
          key: [
            'id',
            'lesson_id',
            'completed',
            'cards_available_to_review',
            (statusId, id, completed, cards) => {
              if (id === undefined) {
                sumoLogger.log(
                  `ERROR: missing lesson_id for UserLessonStatus ${statusId} in LearnerLessonsContainer.jsx`,
                );
              }
              if (!completed) {
                return (
                  <Link style={s.startButton} to={`/app/v2/lessons/${id}/player`}>
                    <Button color="blue">START</Button>
                  </Link>
                );
              }
              const avail = _.some(cards, (c) => moment(c.scheduled_review_date).isBefore(moment()));
              return !!cards.length && avail ? (
                <Link style={s.startButton} to={`/app/v2/lessons/${id}/player`}>
                  <Button color="green">REVIEW</Button>
                </Link>
              ) : (
                <Link style={s.startButton} to={`/app/v2/lessons/${id}/player/preview`}>
                  <Button color="blue">STUDY</Button>
                </Link>
              );
            },
          ],
        },
      ]
      : [];
    return (
      <div style={s.container}>
        <div style={s.top}>
          <SmallCard
            title="Completed Assignments"
            timeDescription="Lifetime"
            stat={this.state.completedCount}
          />
          <SmallCard
            title="Open Assignments"
            timeDescription="Now"
            stat={this.state.futureCount}
          />
          <SmallCard
            title="Average Score"
            timeDescription="Now"
            stat={Math.round(this.state.currentAverage * 100)}
            prevStat={Math.round(this.state.initialAverage * 100)}
            percent
          />
          <SmallCard
            title="Late Assignments"
            timeDescription="Now"
            stat={this.state.lateCount}
          />
        </div>
        {!!Object.keys(this.state.baseFilter).length && (
          <ModelTable
            title=""
            model={UserLessonStatus}
            join={{ lesson: ['name'] }}
            columns={columnData}
            updateData={updateData}
            baseFilter={this.state.baseFilter}
            defaultSort={['lesson__name', 'asc']}
          />
        )}
      </div>
    );
  }
}

export default Radium(LearnerLessonsContainer);
