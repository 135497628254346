import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';
import * as moment from 'moment';
import {
  Button,
  Dropdown,
  Icon,
  Label,
  Loader,
  Modal,
  Segment,
  Table,
} from 'semantic-ui-react';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import UserLessonStatusSummary from '@/models/user_lesson_status_summary';
import User from '@/models/user';
import Organization from '@/models/organization';
import LearningGroupAssignment from '../../models/learning_group_assignment';
import Group from '../../models/group';
import ModelTable from '../Shared/ModelTable';
import CreateUserModal from '../Shared/CreateUserModal';
import ExistingUserModal from '../Shared/ExistingUserModal';
import { GROUP_TYPE_LEARNING } from '../../constants';

const STATUS = {
  not_invited: 'Not Invited',
  invited: 'Invited',
  invite_expired: 'Invite Expired',
  inactive: 'Inactive',
  active: <Label style={{ whiteSpace: "nowrap" }} className="hgreen">• Active</Label>,
  invite_failed: 'Invite Failed',
  invalid: 'Invalid',
};

class GroupLearners extends Component {
  static propTypes = {
    groupId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      groupId: props.groupId,
      action: '',
      selected: [],
      showUserQuotaErrorModal: false,
    };

    this.table = React.createRef();
  }

  async componentDidMount() {
    const user = await User.me();
    const organization = await Organization.objects().get(user.organization_id);
    const lgs = await LearningGroupAssignment.objects().filtered({
      organizational_group_id: this.state.groupId,
      user__deleted: false,
    }).all();
    const userIds = _.pluck(lgs, 'user_id');
    const group = await Group.objects().get(this.state.groupId);
    const statii = await UserLessonStatusSummary.objects().filtered({ _group_id: group.id }).all();
    this.setState({
      statii,
      userIds,
      group,
      organization,
    });
  }

  @bind
  async refresh() {
    const lgs = await LearningGroupAssignment.objects().filtered({
      organizational_group_id: this.state.groupId,
      user__deleted: false,
    }).all();
    const userIds = _.pluck(lgs, 'user_id');
    const statii = await UserLessonStatusSummary.objects().filtered({ _group_id: this.state.groupId }).all();
    this.setState({
      action: '',
      selected: [],
      userIds,
      statii,
      showExistingUserModal: false,
      showNewUserModal: false,
    }, this.table.current.updateData);
    this.table.current.selectNone();
  }

  @bind
  async performAction() {
    const { action, selected, groupId } = this.state;
    let actions = [];
    switch (action) {
      case 'remove':
        actions = selected.map((user) => user.removeFromGroup(groupId));
        break;
      case 'invite':
        actions = selected.map((user) => user.sendInvite());
        break;
      case 'activate':
        actions = selected.map((obj) => User.objects().update(obj.id, { is_active: true }));
        break;
      case 'deactivate':
        actions = selected.map((obj) => User.objects().update(obj.id, { is_active: false }));
        break;
      case 'delete':
        actions = selected.map((obj) => User.objects().update(obj.id, { deleted: true }));
        break;
      default:
        console.log('Invalid action');
    }
    await Promise.all(actions);
    this.refresh();
  }

  @bind
  userActions(user) {
    const actions = [];
    if (['invited', 'not_invited'].includes(user.status)) {
      actions.push(
        <Dropdown.Item
          text="Send Invite"
          onClick={() => {
            this.setState({
              action: 'invite',
              selected: [user],
            });
          }}
        />,

      );
    } else if (user.is_active) {
      actions.push(
        <Dropdown.Item
          text="Deactivate"
          onClick={() => {
            this.setState({
              action: 'deactivate',
              selected: [user],
            });
          }}
        />,
      );
    } else {
      actions.push(
        <Dropdown.Item
          text="Activate"
          onClick={() => {
            this.setState({
              action: 'activate',
              selected: [user],
            });
          }}
        />,

      );
    }
    if (this.state.group?.group_type === GROUP_TYPE_LEARNING) {
      actions.push(
        <Dropdown.Item
          text="Remove From Group"
          onClick={() => {
            this.setState({
              action: 'remove',
              selected: [user],
            });
          }}
        />,
      );
    }
    actions.push(
      <Dropdown.Item
        text="Delete"
        onClick={() => {
          this.setState({
            action: 'delete',
            selected: [user],
          });
        }}
      />,
    );
    return actions;
  }

  @bind
  async addToGroup(users) {
    const actions = users.map((user) => user.assignToGroup(this.state.groupId));
    await Promise.all(actions);
    this.refresh();
  }

  @bind
  attemptUserCreation() {
    const { organization } = this.state;
    const overQuota = organization.total_active_users >= organization.hard_limit;
    if (overQuota) {
      this.setState({
        showUserQuotaErrorModal: true,
      });
    } else {
      this.setState({
        showNewUserModal: true,
      });
    }
  }

  @bind
  async createUser(firstName, lastName, email, preferredTimezone) {
    const user = await User.objects().create({
      first_name: firstName, last_name: lastName, email, preferred_timezone: preferredTimezone,
    });
    this.addToGroup([user]);
  }

  @bind
  humanizeTime(time) {
    if (!time) {
      return "N/A";
    }
    const now = moment();
    const then = moment(time);
    const delta = now.diff(then, 'seconds');
    const str = moment.duration(delta, 'seconds').humanize();
    if (delta < 0) {
      return `In ${str}`;
    }
    return `${str} ago`;
  }

  render() {
    const {
      // eslint-disable-next-line camelcase
      action, selected, userIds, showExistingUserModal, showNewUserModal, statii, group,
    } = this.state;
    const columnData = [
      {
        name: 'Active',
        key: [
          'last_login',
          (lastLogin) => <span className="grey800">{ lastLogin ? moment(lastLogin).fromNow() : ""}</span>,
        ],
      },
      {
        name: 'Learner',
        key: [
          'email',
          'first_name',
          'last_name',
          (email, first, last) => <Link to={`/app/v2/people/${email}`}>{`${first} ${last}`}</Link>,
        ],
        search: true,
      },
      {
        name: 'Status',
        key: [
          'status',
          (status) => STATUS[status],
        ],
      },
      {
        name: 'Assignments',
        key: [
          'id',
          (id) => {
            const status = _.findWhere(statii, { user_id: id });
            return (
              <div style={{ whiteSpace: 'nowrap' }}>
                <Label className="hblue">{status?.open_assignments || 0} Assignments</Label>
                <Label className="hred">{status?.late_assignments || 0} Late</Label>
              </div>
            );
          },
        ],
      },
      {
        name: 'Last Review',
        key: [
          'id',
          (id) => (
            <span style={{ color: "#7E7F8F" }}>
              {this.humanizeTime(_.findWhere(statii, { user_id: id })?.last_review)}
            </span>
          ),
        ],
      },
      {
        name: 'Next Review',
        key: [
          'id',
          (id) => (
            <span style={{ color: "#7E7F8F" }}>
              {this.humanizeTime(_.findWhere(statii, { user_id: id })?.next_review)}
            </span>
          ),
        ],
      },
      {
        name: 'Actions',
        key: [
          'this',
          (user) => (
            <Dropdown
              direction="left"
              floating
              icon={null}
              trigger={(
                <Segment compact>
                  <Icon style={{ margin: 0 }} name="ellipsis vertical" color="blue" />
                </Segment>
              )}
            >
              <Dropdown.Menu>
                {this.userActions(user)}
              </Dropdown.Menu>
            </Dropdown>
          ),
        ],
      },
    ];
    if (!userIds) return <Loader active />;
    return (
      <div>
        <ModelTable
          ref={this.table}
          title="Learners in Group"
          model={User}
          columns={columnData}
          defaultSort={['name', 'asc']}
          baseFilter={{
            id: userIds || [],
          }}
          select
        >
          <ModelTable.Actions>
            {(group?.group_type === GROUP_TYPE_LEARNING) && (
              <ModelTable.Action
                name="Remove From Group"
                method={(selection) => this.setState({ action: 'remove', selected: selection })}
                requireSelected
              />
            )}
            <ModelTable.Action
              name="Send Invite"
              method={(selection) => this.setState({ action: 'invite', selected: selection })}
              requireSelected
            />
            <ModelTable.Action
              name="Activate"
              method={(selection) => this.setState({ action: 'activate', selected: selection })}
              requireSelected
            />
            <ModelTable.Action
              name="Deactivate"
              method={(selection) => this.setState({ action: 'deactivate', selected: selection })}
              requireSelected
            />
            <ModelTable.Action
              name="Delete"
              method={(selection) => this.setState({ action: 'delete', selected: selection })}
              requireSelected
            />
          </ModelTable.Actions>
          {(group?.group_type === GROUP_TYPE_LEARNING) && (
            <ModelTable.Actions title="Add Learner..." color="green">
              <ModelTable.Action name="Create New User" method={this.attemptUserCreation} />
              <ModelTable.Action
                name="Add Existing User"
                method={() => this.setState({ showExistingUserModal: true })}
              />
            </ModelTable.Actions>
          )}
        </ModelTable>
        <Modal open={!!action}>
          <Modal.Header>{`${action} users`.toUpperCase()}</Modal.Header>
          <Modal.Content>
            <div>Are you sure you want to {action.toUpperCase()} the following users?</div>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Last login</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {selected.map((user) => (
                  <Table.Row>
                    <Table.Cell>{`${user.first_name} ${user.last_name}`}</Table.Cell>
                    <Table.Cell>{user.email}</Table.Cell>
                    <Table.Cell>{user.last_login ? moment(user.last_login).fromNow() : ""}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setState({ action: '' })}>Cancel</Button>
            <Button onClick={this.performAction} positive>Confirm</Button>
          </Modal.Actions>
        </Modal>
        <CreateUserModal
          show={showNewUserModal}
          close={() => this.setState({ showNewUserModal: false })}
          create={this.createUser}
        />
        <ExistingUserModal
          show={showExistingUserModal}
          close={() => this.setState({ showExistingUserModal: false })}
          actionText="Add to Group"
          action={this.addToGroup}
          filter={{ id__not: userIds }}
        />
        <Modal
          open={this.state.showUserQuotaErrorModal}
          onClose={() => {
            this.setState({ showUserQuotaErrorModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Quota Exceeded</Modal.Header>
          <Modal.Content>
            We&apos;re sorry, but you are currently over your quota for users and cannot add any more at this time.
            Please delete any unneeded users or contact us for more details on upgrading your plan.
          </Modal.Content>
          <Modal.Actions>
            <Button type="submit" className="green" onClick={() => this.setState({ showUserQuotaErrorModal: false })}>
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default GroupLearners;
