import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card, Header, Icon, Image, Progress,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import pluralize from 'pluralize';

class CourseLibraryCard extends Component {
  static propTypes = {
    course: PropTypes.object,
  };

  render() {
    const { course } = this.props;
    const completed = course.lesson_path.reduce((acc, cur) => (cur.completed ? acc + 1 : acc), 0);
    return (
      <Card className="course-card">
        <Image src={course.header_image_path} />
        <Card.Header><Header as="h2">{course.name}</Header></Card.Header>
        {/* <Card.Meta>by Alex Tirrell</Card.Meta> */}
        <Card.Description>
          <div style={{ display: 'flex' }}>
            {!!course.median_time_spent && (
              <React.Fragment>
                <Icon name="clock outline" />
                {moment.duration(course.median_time_spent, 'seconds').humanize()}
              </React.Fragment>
            )}
            <Icon name="tasks" style={{ marginLeft: '10px' }} />
            {`${course.lesson_path.length} ${pluralize('lesson', course.lesson_path.length)}`}
          </div>
        </Card.Description>
        <div style={{ flex: '1 1 auto' }} />
        <Card.Description>
          <Progress
            value={completed}
            total={course.lesson_path.length}
            progress="ratio"
            success
          />
        </Card.Description>
        <Card.Content style={{ padding: 0, marginTop: '10px' }} textAlign="center" extra>
          <Link to={`/app/v2/courses/${course.id}/player`}>
            <div style={{ padding: '10px' }}>
              <Header as="h3" color="blue">{completed ? 'Resume Course' : 'Start Course'}</Header>
            </div>
          </Link>
        </Card.Content>
      </Card>
    );
  }
}

export default CourseLibraryCard;
