import _ from 'underscore';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import {
  Button, Dropdown, Header, Modal, Checkbox, Accordion, Icon,
  List,
} from 'semantic-ui-react';
import pluralize from 'pluralize';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { subMonths } from 'date-fns';
import Report from '@/models/report';
import Group from '@/models/group';
import Lesson from '@/models/lesson';
import User from '@/models/user';
import Course from '@/models/course';
import CardContainer from '../Shared/CardContainer';
import { GROUP_TYPE_COMPLIANCE } from '../../constants';
import ModeToggle from '../Shared/ModeToggle';

const s = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  dimension: {
    marginBottom: '20px',
    minHeight: '80px',
  },
};

const MODELS = {
  lesson: Lesson,
  user: User,
  group: Group,
  course: Course,
};

const MODEL_FILTERS = {
  lesson: {},
  user: {},
  group: { group_type__not: GROUP_TYPE_COMPLIANCE },
  course: {},
};

class ReportGenCard extends Component {
  static propTypes = {
    report: PropTypes.object,
    addReport: PropTypes.func,
    height: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      filter: {},
    };
  }

  async componentDidMount() {
    this.props.report.dimensions.forEach(async (dimension) => {
      if (dimension.type === 'id') {
        const filter = MODEL_FILTERS[dimension.name];
        const data = await MODELS[dimension.name].objects().filtered(filter).all();
        const newState = {};
        newState[dimension.name] = data;
        this.setState(newState);
      }
    });
  }

  @bind
  async generateReport() {
    let missing = false;
    this.props.report.dimensions.forEach((dimension) => {
      if (dimension.required && !this.state.filter[dimension.name]) {
        this.setState(
          {
            required: dimension.display_name,
          },
          this.toggleRequiredModal,
        );
        missing = true;
      }
    });
    if (!missing) {
      const report = await Report.create(this.props.report.name, this.state.filter);
      this.props.addReport(report.id);
    }
  }

  @bind
  toggleRequiredModal() {
    this.setState({
      showRequiredModal: !this.state.showRequiredModal,
    });
  }

  mapModelsToOption(modelName) {
    const models = this.state[modelName];
    let result = null;
    if (modelName === 'user') {
      result = models.map((elm) => ({
        value: elm.id,
        text: `${elm.first_name} ${elm.last_name}`,
      }));
    }
    if (modelName === 'group') {
      result = models.map((elm) => ({
        value: elm.id,
        text: elm.name,
      }));
    }
    if (modelName === 'lesson') {
      result = models.map((elm) => ({
        value: elm.id,
        text: elm.name,
        published: elm.published,
        revision_number: elm.revision_number,
      }));
    }
    if (modelName === 'course') {
      result = models.map((elm) => ({
        value: elm.id,
        text: elm.name,
      }));
    }
    if (result) {
      result = result.sort((a, b) => {
        return a.text.localeCompare(b.text, 'en', { sensitivity: 'base' });
      });
    }
    // unknown/unimplemented model
    return result;
  }

  updateFilter(name, value) {
    const { filter } = this.state;
    if (value === null || value.length === 1) delete filter[name];
    else filter[name] = value;
    this.setState({
      filter,
    });
  }

  @bind
  inputForDimension(report, dimension, options) {
    const { filter } = this.state;
    if (dimension.type === 'id') {
      const key = `${report.name}-${dimension.name}-historical`;
      let opts = options;
      if (dimension.name === "lesson") {
        opts = !this.state[key] ? _.where(options, { published: true })
          : _.map(options, (record) => {
            const text = record.original_text ? record.original_text : record.text;
            record.content = (
              <Header size="tiny" content={text} subheader={`Version ${record.revision_number}`} />
            );
            record.text = `${text} (ver. ${record.revision_number})`;
            return record;
          });
      }

      return (
        <>
          <Header as="h4">{dimension.display_name}</Header>
          { dimension.name === "lesson" ? (
            <div style={{ paddingBottom: '10px' }}>
              <Checkbox
                label="Show all versions of lessons"
                onChange={(e, { checked }) => {
                  const state = {
                    filter: this.state.filter,
                  };
                  state.filter[key] = checked;
                  state[key] = checked;
                  this.setState(state);
                }}
              />
            </div>
          ) : null }
          <Dropdown
            value={dimension.name in this.state.filter ? this.state.filter[dimension.name] : ['']}
            placeholder={`All ${pluralize.plural(dimension.display_name)}`}
            loading={!this.state[dimension.name]}
            onChange={(e, { value }) => this.updateFilter(dimension.name, value)}
            error={dimension.required && !Object.keys(filter).includes(dimension.name)}
            options={opts}
            search
            multiple
            selection
          />
        </>
      );
    }
    if (dimension.type === 'date') {
      return (
        <>
          <Header as="h4">{dimension.display_name}</Header>
          <SemanticDatepicker
            format="YYYY/MM/DD"
            type="range"
            maxDate={new Date()}
            minDate={Date.parse(dimension.earliest)}
            date={subMonths(new Date(), 1)}
            showToday={false}
            monthsToDisplay={1}
            onChange={(e, { value }) => this.updateFilter(dimension.name, value)}
            value={dimension.name in this.state.filter ? this.state.filter[dimension.name] : ''}
            error={dimension.required && !Object.keys(filter).includes(dimension.name)}
          />
        </>
      );
    }
    if (dimension.type === 'toggle') {
      const key = `${report.name}-${dimension.name}-toggle`;
      return (
        <div style={{ paddingTop: '40px' }}>
          <Checkbox
            label={dimension.display_name}
            onChange={(e, { checked }) => {
              const state = {
                filter: this.state.filter,
              };
              state.filter[key] = checked;
              state[key] = checked;
              this.setState(state);
            }}
          />
        </div>
      );
    }
    return null;
  }

  @bind
  handleClick(e, titleProps) {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  }

  @bind
  renderAdvancedOption(option) {
    switch (option.type) {
      case 'toggle':
        return (
          <List.Item>
            <Checkbox
              label={option.display_name}
              style={{ marginLeft: '5px' }}
              onChange={(e, { checked }) => {
                const state = {
                  filter: this.state.filter,
                };
                state.filter[option.name] = checked;
                state[option.name] = checked;
                this.setState(state);
              }}
              toggle
            />
          </List.Item>
        );
      case 'mode_toggle':
        return (
          <List.Item>
            <ModeToggle
              off={option.display_left}
              on={option.display_right}
              onToggle={(e, { checked }) => {
                const state = {
                  filter: this.state.filter,
                };
                state.filter[option.name] = checked;
                state[option.name] = checked;
                this.setState(state);
              }}
              control={this.state.filter[option.name]}
            />
          </List.Item>
        );
      default:
        return null;
    }
  }

  render() {
    const { report } = this.props;
    const { activeIndex } = this.state;
    return (
      <CardContainer>
        <div style={s.container}>
          <Header as="h2">{report.display_name}</Header>
          <span>{report.description || ''}</span>
          <div style={{ minHeight: `${this.props.height * 100}px` }}>
            {report.dimensions.map((dimension) => {
              let options = [];
              if (dimension.type === 'id') {
                options = dimension.name in this.state ? this.mapModelsToOption(dimension.name) : [];
              }
              return (
                <div key={report.name + dimension.name} style={s.dimension}>
                  {this.inputForDimension(report, dimension, options)}
                </div>
              );
            })}
            {report.advanced && (
              <Accordion>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Advanced Options
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <List>
                    {report.advanced.map((option) => this.renderAdvancedOption(option))}
                  </List>
                </Accordion.Content>

              </Accordion>
            )}
          </div>
          <Button onClick={this.generateReport} positive>
            Generate Report
          </Button>
        </div>
        <Modal
          open={this.state.showRequiredModal}
          onClose={this.toggleRequiredModal}
          closeIcon
        >
          <Modal.Header>Required Filter</Modal.Header>
          <Modal.Content>
            {`The ${this.state.required} filter is required for this report.`}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.toggleRequiredModal}>OK</Button>
          </Modal.Actions>
        </Modal>
      </CardContainer>
    );
  }
}

export default Radium(ReportGenCard);
