import React, { Component } from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Label } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'underscore';
import UserLessonStatus from '@/models/user_lesson_status';
import Lesson from '@/models/lesson';
import ModelTable from '../Shared/ModelTable';

class UserLessons extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  async componentDidMount() {
    const lessons = await Lesson.objects().all();

    this.setState({
      lessons,
    });
  }

  render() {
    const { user } = this.props;
    const lateCutoff = moment().subtract(3, 'days');

    let name = `${user.first_name} ${user.last_name}`;
    if (name.endsWith('s')) {
      name += "'";
    } else {
      name += "'s";
    }

    const lessonColumns = this.state.lessons
      ? [
        { name: 'Active', key: ['localized_start_date', (date) => moment(date).fromNow()] },
        {
          name: 'Lesson Name',
          key: [
            'lesson_id',
            (id) => {
              const lesson = _.findWhere(this.state.lessons, { id });
              if (lesson) {
                return (
                  <Link to={`/app/v2/lessons/${lesson.id}`}>
                    { lesson.name }
                  </Link>
                );
              }
              return <span>Missing Lesson</span>;
            },
          ],
        },
        {
          name: 'Status',
          key: [
            'localized_start_date',
            'completed',
            (startDate, completed) => {
              let description = <Label>Active</Label>;
              if (!completed) {
                if (moment(startDate).isBefore(lateCutoff)) {
                  description = <Label color="yellow">Late</Label>;
                } else {
                  description = <Label color="green">In Progress</Label>;
                }
              }
              return description;
            },
          ],
        },
        {
          name: 'Initial Score',
          key: ['initial_score', (score) => `${(score * 100.0).toFixed(2)}%`],
        },
        {
          name: 'Current Score',
          key: ['current_score', (score) => `${(score * 100.0).toFixed(2)}%`],
        },
      ]
      : [];

    return (
      <div>
        <ModelTable
          title={`${name} Lessons`}
          model={UserLessonStatus}
          columns={lessonColumns}
          defaultSort={['localized_start_date', 'dsc']}
          baseFilter={{ completed: true, user_id: this.props.user.id }}
        />
      </div>
    );
  }
}

export default Radium(UserLessons);
