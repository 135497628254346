import React, { Component } from 'react';
import Radium from 'radium';
import { Input, Button, Loader } from 'semantic-ui-react';
import bind from 'bind-decorator';
import PropTypes from 'prop-types';
import User from '@/models/user';
import { colorGreyVeryDark, hickoryGreen } from '../../styles/palette';

let s = {};

class CompleteProfile extends Component {
  static propTypes = {
    userId: PropTypes.string,
    updateUser: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  @bind
  async updateProfile() {
    this.setState({ working: true });
    const user = await User.objects().update(
      this.props.userId,
      {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
      },
    );
    this.props.updateUser(user);
    this.setState({ working: false });
  }

  render() {
    return (
      <div style={s.contents}>
        <div style={s.loginCard}>
          <div style={s.loginCardTitle}>Welcome!</div>
          <div style={s.loginCardSubTitle}>Please complete your profile to continue to Hickory.</div>
          <div style={s.cardContents}>
            <div>First Name</div>
            <div>
              <Input
                style={s.input}
                onChange={(e, { value }) => this.setState({ firstName: value })}
                error={!this.state.firstName}
              />
            </div>
            <div>Last Name</div>
            <div>
              <Input
                style={s.input}
                onChange={(e, { value }) => this.setState({ lastName: value })}
                error={!this.state.lastName}
              />
            </div>
            <div>
              {this.state.working ? (
                <Loader active={this.state.working} inline="centered" />
              ) : (
                <Button
                  style={s.button}
                  onClick={this.updateProfile}
                  disabled={!this.state.firstName || !this.state.lastName || this.state.working}
                >
                  Continue
                </Button>
              )}
            </div>
          </div>
        </div>
        <div style={s.versionNumber}>
          version&nbsp;
          {window.APP_VERSION}
        </div>
      </div>
    );
  }
}

s = {
  contents: {
    backgroundColor: '#f5f5f5',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginCard: {
    backgroundColor: 'white',
    width: '36rem',
  },
  loginCardTitle: {
    fontSize: '2.2em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '20px',
  },
  loginCardSubTitle: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '20px',
    color: colorGreyVeryDark,
  },
  cardContents: {
    margin: '20px',
  },
  input: {
    width: '100%',
    marginBottom: '20px',
    height: '4em',
  },
  button: {
    width: '100%',
    height: '4em',
    backgroundColor: hickoryGreen,
    color: 'white',
  },
  versionNumber: {
    position: 'fixed',
    bottom: '5px',
    left: '5px',
  },
};

export default Radium(CompleteProfile);
