import _ from 'underscore';
import React, { Component } from 'react';
import Radium from 'radium';
import bind from 'bind-decorator';
import {
  Button, Modal, Input, Dropdown, Loader, Checkbox,
  Popup,
  Label,
  Progress,
  Message,
  List,
  ListItem,
} from 'semantic-ui-react';
import SumoLogger from 'sumo-logger';
import { Link } from 'react-router-dom';
import Lesson from '@/models/lesson';
// Edge case, because this model is only accessed through a join, it doesn't get registered
// We import it here to register it
// eslint-disable-next-line no-unused-vars
import LessonStats from '@/models/lesson_stats';
import Organization from '@/models/organization';
import User from '@/models/user';
import {
  SUMO,
  Features,
  ARTICLE,
  VIDEO,
  CARD,
  SCORM,
} from '../../constants';
import ModelTable from '../Shared/ModelTable';
import LessonPreviewModal from '../Shared/LessonPreviewModal';

const sumoLogger = new SumoLogger({ endpoint: SUMO });

let s = {
  printFrame: {
    border: 'none',
    width: '100%',
    height: '500px',
  },
};

class LessonListContainer extends Component {
  constructor(props) {
    super(props);

    const popupPreview = JSON.parse(localStorage.getItem('popupPreview'));

    this.state = {
      scorm_enabled: false,
      articles_enabled: false,
      video_lessons_enabled: false,
      uploading_scorm: false,
      popupPreview: popupPreview === null ? true : popupPreview,
      completedLessons: 0,

    };
    this.fileInput = React.createRef();
  }

  async componentDidMount() {
    const user = await User.me();
    const organization = await Organization.objects().get(user.organization_id);
    this.setState({
      user,
      scorm_enabled: organization.scorm_enabled,
      articles_enabled: user.waffle_flags.includes(Features.ARTICLES),
      video_lessons_enabled: user.waffle_flags.includes(Features.VIDEO_LESSONS),
    });
  }

  @bind
  async createLesson(lessonType) {
    const lessonName = this.state.newLessonName.trim();
    const newLesson = await Lesson.objects().create({ name: lessonName, lesson_type: lessonType });
    if (newLesson.id === undefined) {
      sumoLogger.log(`ERROR: missing lesson_id for new Lesson ${this.state.newLessonName} in LessonListContainer.jsx`);
    }
    window.location.href = `/app/v2/lessons/${newLesson.id}/editor`;
  }

  @bind
  async createSCORMLesson() {
    const lessonName = this.state.newLessonName.trim();
    const newLesson = await Lesson.objects().create({ name: lessonName, lesson_type: 2 });

    const file = this.fileInput.current.files[0];
    this.setState({ uploading_scorm: true }, async () => {
      const response = await newLesson.uploadSCORMContent(file);
      if (response.error) {
        await Lesson.objects().update(newLesson.id, { deleted: true });
        const error = response.error;
        this.setState({
          uploading_scorm: false,
          scorm_error: error,
        });
      } else {
        this.setState({
          uploading_scorm: false,
          showSCORMUploadModal: false,
        });
      }
    });
  }

  @bind
  togglePopup(e) {
    const { popupPreview } = this.state;
    this.setState({ popupPreview: !popupPreview });
    localStorage.setItem('popupPreview', !popupPreview);
    e.preventDefault();
  }

  @bind
  showEditModal(record) {
    const rawTags = record.tags.join(', ');
    this.setState({
      showLessonEditor: true,
      selectedRecord: record,
      editLessonName: record.name,
      editLessonRawTags: rawTags,
    });
  }

  @bind
  async tagLessons() {
    const { selectedLessons } = this.state;
    this.setState({ totalLessons: selectedLessons.length, completedLessons: 0 });
    const actions = selectedLessons.map(async (lesson) => {
      let tags = (lesson.tags || []).concat(this.state.editLessonRawTags.split(','));
      tags = _.compact(
        _.map(tags, (tag) => {
          const t = tag.trim();
          if (!t.length) {
            return null;
          }
          return t;
        }),
      );
      await Lesson.objects().update(lesson.id, { tags });
      this.setState((prevState) => ({ completedLessons: prevState.completedLessons + 1 }));
    });
    await Promise.all(actions);
    this.setState({ totalLessons: 0 });
    this.setState({ showAddTagsModal: false, editLessonRawTags: null });
  }

  @bind
  async removeLessonTags() {
    const { selectedLessons } = this.state;
    this.setState({ totalLessons: selectedLessons.length, completedLessons: 0 });
    const actions = selectedLessons.map(async (lesson) => {
      await Lesson.objects().update(lesson.id, { tags: [] });
      this.setState((prevState) => ({ completedLessons: prevState.completedLessons + 1 }));
    });
    await Promise.all(actions);
    this.setState({ totalLessons: 0 });
    this.setState({ showDeleteTagsModal: false });
  }

  @bind
  async updateLesson() {
    let tags = this.state.editLessonRawTags.split(',');
    tags = _.compact(
      _.map(tags, (tag) => {
        const t = tag.trim();
        if (!t.length) {
          return null;
        }
        return t;
      }),
    );
    const name = this.state.editLessonName.trim();
    await Lesson.objects().update(this.state.selectedRecord.id, { name, tags });
    this.setState({ showLessonEditor: false });
  }

  @bind
  menuOptionsForLesson(record) {
    if (record.lesson_type === SCORM) return null;
    const { user } = this.state;
    const { popupPreview } = this.state;
    const options = [
      <Dropdown.Item
        text="Clone"
        onClick={() => {
          this.cloneLesson(record);
        }}
      />,
      <Dropdown.Item
        text="Edit"
        onClick={() => {
          const editable = !record.published;
          if (editable) window.location.href = `/app/v2/lessons/${record.id}/editor`;
          else this.setState({ showEditWarningModal: record, draftCreating: false });
        }}
      />,
      popupPreview ? (
        <Dropdown.Item
          text="Preview"
          onClick={() => {
            this.setState({ previewLessonId: record.id });
          }}
        />
      ) : (
        <Dropdown.Item
          as={Link}
          to={`/app/v2/lessons/${record.id}/player/preview`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Preview
        </Dropdown.Item>
      ),
      <Dropdown.Item
        onClick={() => this.setState({ showDisableReviewModal: true, disableReviewLesson: record })}
      >
        Disable Reviews
      </Dropdown.Item>,
    ];
    if (record.lesson_type === CARD) {
      options.push(
        <Dropdown.Item
          text="Print..."
          onClick={() => {
            this.setState({
              showPrintModal: true,
              printLessonId: record.id,
            });
          }}
        />,

      );
      if (user.waffle_flags.includes(Features.CMI5_EXPORT)) {
        options.push(
          <Dropdown.Item
            text="Export"
            onClick={() => {
              this.exportLesson(record);
            }}
          />,
        );
      }
    }
    return options;
  }

  @bind
  menuForLesson(lesson) {
    const options = this.menuOptionsForLesson(lesson);
    if (!options) {
      return null;
    }
    return (
      <Dropdown icon="ellipsis vertical" floating labeled className="icon">
        <Dropdown.Menu>
          {options}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  @bind
  exportLesson(record) {
    const exportLessonAck = localStorage.getItem('export_lesson_ack');
    if (exportLessonAck) {
      window.location.href = `/api/v2/cmi5/${record.id}`;
    } else {
      this.setState({
        export_lesson_id: record.id,
        showExportModal: true,
      });
    }
  }

  @bind
  doExportLesson() {
    this.setState({
      showExportModal: false,
    });
    const lessonId = this.state.export_lesson_id;
    window.location.href = `/api/v2/cmi5/${lessonId}`;
  }

  @bind
  setExportLessonAckCookie(checked) {
    if (checked) {
      localStorage.setItem('export_lesson_ack', true);
    } else {
      localStorage.removeItem('export_lesson_ack');
    }
  }

  @bind
  cloneLesson(lesson) {
    this.setState({
      showEditWarningModal: null,
      showCloneModal: true,
      clonedLessonName: `CLONE  ${lesson.name}`,
      clone: lesson,
    });
  }

  @bind
  async doCloneLesson() {
    this.setState({
      showCloneModal: false,
      showCloneProgress: true,
      cloning_progress: 0,
    });
    const cloneFrom = this.state.clone;
    const dolly = await Lesson.objects().create({
      name: this.state.clonedLessonName,
      cloned_from_id: cloneFrom.id,
    });
    if (dolly.id === undefined) {
      sumoLogger.log(
        `ERROR: missing lesson_id when cloning lesson ${cloneFrom.id} in LessonsList.jsx`,
      );
    }
    this.waitForClone(dolly);
  }

  @bind
  waitForClone(dolly) {
    this.setState({ cloning_progress: this.state.cloning_progress + 1 });
    setTimeout(() => {
      if (this.state.cloning_progress >= 100) {
        this.setState({ showCloneProgress: false });
        window.location.href = `/app/v2/lessons/${dolly.id}/editor`;
      } else {
        this.waitForClone(dolly);
      }
    }, 100);
  }

  @bind
  async doCreateNewDraft(record) {
    this.setState({ draftCreating: true });
    const lesson = await Lesson.objects().filtered({ id: record.id }).first();
    const newRevision = await lesson.new_revision();

    window.location = `/app/v2/lessons/${newRevision.id}/editor`;
  }

  @bind
  async disableReviews() {
    const { disableReviewLesson, selectedLessons } = this.state;
    if (disableReviewLesson) disableReviewLesson.disableReviews();
    else {
      this.setState({ totalLessons: selectedLessons.length, completedLessons: 0 });
      const actions = selectedLessons.map(async (lesson) => {
        await lesson.disableReviews();
        this.setState((prevState) => ({ completedLessons: prevState.completedLessons + 1 }));
      });
      await Promise.all(actions);
      this.setState({ totalLessons: 0 });
    }
    this.setState({ showDisableReviewModal: false, disableReviewLesson: null });
  }

  render() {
    const { popupPreview } = this.state;

    const style = {
      color: '#27A167',
      fontSize: '28px',
    };

    const scorify = (score) => {
      if (!score) {
        return score;
      }
      return `${(score * 100.0).toFixed(0)}`;
    };
    const columns = [
      {
        name: 'Lesson Name',
        key: [
          'name',
          'this',
          (name, lesson) => (
            <>
              <Link to={`/app/v2/lessons/${lesson.id}`}>{name}</Link>
                    &nbsp;&nbsp;
              <Label
                style={{ cursor: 'pointer' }}
                onClick={() => this.showEditModal(lesson)}
              >
                {' '}
                {lesson.tags.length}
                {' '}
                tags
                {' '}
              </Label>
              {lesson.lesson_type === SCORM ? <Label color="blue">SCORM</Label> : null}
            </>
          ),
        ],
        search: true,
        sort: true,
      },
      {
        name: 'Status',
        key: [
          'published',
          (published) => (published ? 'Active' : 'Draft'),
        ],
        filter: { false: 'Draft', true: 'Active' },
      },
      {
        name: 'Avg. Initial Score',
        key: [
          'stats__average_initial_score',
          (averageInitialScore) => <span style={style}>{scorify(averageInitialScore) || ''}</span>,
        ],
        sort: true,
      },
      {
        name: 'Avg. Current Score',
        key: [
          'stats__average_current_score',
          (averageCurrentScore) => <span style={style}>{scorify(averageCurrentScore) || ''}</span>,
        ],
        sort: true,
      },
      {
        name: 'Completion Rate',
        key: [
          'stats__total_assigned',
          'stats__total_completed',
          (totalAssigned, totalCompleted) => (
            <Popup
              content={`${totalCompleted}/${totalAssigned}`}
              trigger={<span style={style}>{scorify(totalCompleted / totalAssigned) || ''}</span>}
            />
          ),
        ],
        sort: (lesson) => lesson.stats__total_completed / lesson.stats__total_assigned,
      },
      {
        name: 'Actions',
        key: [
          'this',
          (lesson) => this.menuForLesson(lesson),
        ],
      },
      {
        name: 'Tag',
        key: ['tags',
          () => null],
        arrayFilter: 'true',
        display: false,
      },
    ];

    return (
      <div style={s.body}>
        <div style={s.container}>
          <div style={{ width: '100%' }}>
            <ModelTable
              title="Lessons"
              model={Lesson}
              columns={columns}
              defaultSort={['name', 'asc']}
              baseFilter={{ deleted: false, content_head: true }}
              join={[
                'stats__average_initial_score',
                'stats__average_current_score',
                'stats__total_assigned',
                'stats__total_completed',
              ]}
              select
              selectionChanged={(selectedLessons) => this.setState({ selectedLessons })}
            >
              <ModelTable.Actions color="green" title="Create New...">
                {this.state.articles_enabled && (
                <ModelTable.Action
                  name="Article-based Lesson"
                  method={() => this.setState({ showCreateLessonModal: true, createLessonType: ARTICLE })}
                />
                )}
                <ModelTable.Action
                  name="Card-based Lesson"
                  method={() => this.setState({ showCreateLessonModal: true, createLessonType: CARD })}
                />
                {this.state.scorm_enabled && (
                <ModelTable.Action
                  name="SCORM Lesson"
                  method={() => this.setState({ showSCORMUploadModal: true })}
                />
                )}
                {this.state.video_lessons_enabled && (
                <ModelTable.Action
                  name="Video-based Lesson"
                  method={() => this.setState({ showCreateLessonModal: true, createLessonType: VIDEO })}
                />
                )}
              </ModelTable.Actions>
              <ModelTable.Actions color="blue">
                <ModelTable.Action
                  name="Disable Reviews"
                  method={() => this.setState({ showDisableReviewModal: true })}
                  requireSelected
                />
                <ModelTable.Action
                  name="Tag Lessons"
                  method={() => this.setState({ showAddTagsModal: true })}
                  requireSelected
                />
                <ModelTable.Action
                  name="Remove Tags"
                  method={() => this.setState({ showDeleteTagsModal: true })}
                  requireSelected
                />
              </ModelTable.Actions>
              <ModelTable.Actions title="Options" closeOnChange={false}>
                <ModelTable.Action
                  name={
                    <Checkbox label="Pop-up Preview" toggle checked={popupPreview} onMouseDown={this.togglePopup} />
                }
                  method={() => {}}
                />
              </ModelTable.Actions>
            </ModelTable>
          </div>
        </div>

        <Modal
          open={this.state.showCreateLessonModal}
          onClose={() => {
            this.setState({ showCreateLessonModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Create Lesson</Modal.Header>
          <Modal.Content>
            Name:
            {' '}
            <Input onChange={(e, { value }) => this.setState({ newLessonName: value })} />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showCreateLessonModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                this.createLesson(this.state.createLessonType);
              }}
            >
              Create
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showSCORMUploadModal}
          onClose={() => {
            this.setState({ showSCORMUploadModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Create Lesson</Modal.Header>
          {this.state.uploading_scorm ? (
            <Modal.Content>
              <div>
                Uploading...
                <Loader active inline />
              </div>
            </Modal.Content>
          ) : (
            <Modal.Content>
              {this.state.scorm_error ? <div style={s.error}>{this.state.scorm_error}</div> : null}
              <div>
                Name:
                {' '}
                <Input
                  error={!this.state.newLessonName}
                  onChange={(e, { value }) => this.setState({ newLessonName: value })}
                />
              </div>
              <div />
              <div>
                Choose a SCORM file to upload:
                <div />
                <input id="file-input" type="file" accept="application/zip" ref={this.fileInput} />
              </div>
              <div>
                <br />
                <em>NOTE: SCORM files must be in SCORM 2004 format</em>
              </div>
            </Modal.Content>
          )}
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              disabled={this.state.uploading_scorm}
              onClick={() => {
                this.setState({ showCreateLessonModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              disabled={this.state.uploading_scorm || !this.state.newLessonName}
              onClick={() => {
                this.createSCORMLesson();
              }}
            >
              Create
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.showLessonEditor}
          onClose={() => {
            this.setState({ showLessonEditor: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Update Lesson</Modal.Header>
          <Modal.Content>
            Name:
            <br />
            <Input
              value={this.state.editLessonName}
              onChange={(e, { value }) => this.setState({ editLessonName: value })}
            />
            <br />
            <br />
            Tags (comma separated):
            <br />
            {' '}
            <Input
              value={this.state.editLessonRawTags}
              onChange={(e, { value }) => this.setState({ editLessonRawTags: value })}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showLessonEditor: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                this.updateLesson();
              }}
            >
              Update
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.showEditWarningModal}
          onClose={() => this.setState({ showEditWarningModal: false })}
          style={{ width: '500px' }}
        >
          <Modal.Header>Lesson already published</Modal.Header>
          <Modal.Content>
            You are attempting to edit an already published lesson. To ensure the best experience possible for
            your learners we instead recommend that you create a
            { ' ' }
            <strong>new draft</strong>
            { ' ' }
            (unpublished) version of this lesson.
            You will be able to edit this draft version as much as you need, and then
            { ' ' }
            <strong>publish</strong>
            { ' ' }
            a replacement of this lesson for your learners.
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="grey"
              onClick={() => {
                this.setState({
                  showEditWarningModal: false,
                });
              }}
            >
              Cancel
            </Button>
            <Button
              positive
              onClick={() => {
                this.doCreateNewDraft(this.state.showEditWarningModal);
              }}
              disabled={this.state.draftCreating}
              loading={this.state.draftCreating}
            >
              New Draft
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showCloneModal}
          onClose={() => {
            this.setState({ showCloneModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Clone Lesson</Modal.Header>
          <Modal.Content>
            Name:
            <br />
            <Input
              value={this.state.clonedLessonName}
              style={{ width: '100%' }}
              onChange={(e, { value }) => this.setState({ clonedLessonName: value })}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showCloneModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                this.doCloneLesson();
              }}
            >
              Clone
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.showExportModal}
          onClose={() => {
            this.setState({ showExportModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Export Lesson</Modal.Header>
          <Modal.Content>
            <p>
              You can export a Hickory lesson to cmi5 format, compatable with xAPI enabled systems.
            </p>
            <p>NOTE: Lessons exported in this way require:</p>
            <ul>
              <li>An external system compatible with xAPI & cmi5</li>
              <li>Actors with mbox ids that match email addresses in Hickory</li>
              <li>An active license of Hickory</li>
            </ul>
            <br />
            <Checkbox
              label="Do not show me this again"
              onClick={(e, { checked }) => {
                this.setExportLessonAckCookie(checked);
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showExportModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                this.doExportLesson();
              }}
            >
              Export
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={this.state.showCloneProgress} style={{ width: '500px' }}>
          <Modal.Header>Cloning Lesson</Modal.Header>
          <Modal.Content>
            <Progress percent={this.state.cloning_progress} indicating />
          </Modal.Content>
          <Modal.Actions />
        </Modal>

        <Modal
          open={this.state.showPrintModal}
          onClose={() => {
            this.setState({ showPrintModal: false });
          }}
          closeIcon
        >
          <Modal.Header>Print Lesson</Modal.Header>
          <Modal.Content>
            <iframe
              id="printFrame"
              src={`/app/v2/lessons/${this.state.printLessonId}/print?embedded=true`}
              style={{
                border: 'none',
                width: '100%',
                height: '500px',
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showPrintModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                document.getElementById('printFrame').contentWindow.print();
              }}
            >
              Print
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.showDisableReviewModal}
          onClose={() => {
            this.setState({ showDisableReviewModal: false, disableReviewLesson: null });
          }}
          closeIcon
        >
          <Modal.Header>Disable Reviews</Modal.Header>
          <Modal.Content>
            {this.state.disableReviewLesson ? (
              <div>
                Disable reviews for {this.state.disableReviewLesson.name}?<br />
                <Message warning>
                  Doing so will prevent any further reviews from being generated for this lesson,
                  as well as unassigning any existing reviews for this lesson.<br />
                  THIS ACTION CANNOT BE REVERSED.
                </Message>
              </div>
            ) : (
              <>
                <div>
                  Disable reviews for the following lessons?<br />
                </div>
                {this.state.selectedLessons && (
                <List divided style={{ paddingInline: '20px' }}>
                  {this.state.selectedLessons.map((lesson) => <ListItem>{lesson.name}</ListItem>)}
                </List>
                )}
                <br />
                <Message warning>
                  Doing so will prevent any further reviews from being generated for these lessons,
                  as well as unassigning any existing reviews for these lessons.<br />
                  THIS ACTION CANNOT BE REVERSED.
                </Message>
              </>
            )}
          </Modal.Content>
          <Modal.Actions>
            {this.state.totalLessons ? (
              <Progress
                value={this.state.completedLessons}
                total={this.state.totalLessons}
                progress="ratio"
                success
              />
            ) : (
              <>
                <Button
                  type="submit"
                  className="gray"
                  onClick={() => {
                    this.setState({ showDisableReviewModal: false, disableReviewLesson: null });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="green"
                  onClick={() => {
                    this.disableReviews();
                  }}
                >
                  Confirm
                </Button>
              </>
            )}
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.showAddTagsModal}
          onClose={() => {
            this.setState({ showAddTagsModal: false });
          }}
          closeIcon
        >
          <Modal.Header>Add Tags</Modal.Header>
          <Modal.Content>
            <>
              <div>
                Enter the tags you would like to add, separated by commas.<br />
                <Input
                  style={{ paddingInline: '20px' }}
                  value={this.state.editLessonRawTags}
                  onChange={(e, { value }) => this.setState({ editLessonRawTags: value })}
                />
                <br />These tags will be added to the following lessons:
              </div>
              {this.state.selectedLessons && (
                <List divided style={{ paddingInline: '20px' }}>
                  {this.state.selectedLessons.map((lesson) => <ListItem>{lesson.name}</ListItem>)}
                </List>
              )}
            </>
          </Modal.Content>
          <Modal.Actions>
            {this.state.totalLessons ? (
              <Progress
                value={this.state.completedLessons}
                total={this.state.totalLessons}
                progress="ratio"
                success
              />
            ) : (
              <>
                <Button
                  type="submit"
                  className="gray"
                  onClick={() => {
                    this.setState({ showAddTagsModal: false });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="green"
                  onClick={() => {
                    this.tagLessons();
                  }}
                >
                  Confirm
                </Button>
              </>
            )}
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.showDeleteTagsModal}
          onClose={() => {
            this.setState({ showDeleteTagsModal: false });
          }}
          closeIcon
        >
          <Modal.Header>Add Tags</Modal.Header>
          <Modal.Content>
            <>
              <div>
                All tags will be removed from the following lessons:
              </div>
              {this.state.selectedLessons && (
                <>
                  <List divided style={{ paddingInline: '20px' }}>
                    {this.state.selectedLessons.map((lesson) => <ListItem>{lesson.name}</ListItem>)}
                  </List>
                  <br />
                  <Message warning>
                    THIS ACTION CANNOT BE REVERSED.
                  </Message>
                </>
              )}
            </>
          </Modal.Content>
          <Modal.Actions>
            {this.state.totalLessons ? (
              <Progress
                value={this.state.completedLessons}
                total={this.state.totalLessons}
                progress="ratio"
                success
              />
            ) : (
              <>
                <Button
                  type="submit"
                  className="gray"
                  onClick={() => {
                    this.setState({ showDeleteTagsModal: false });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  className="green"
                  onClick={() => {
                    this.removeLessonTags();
                  }}
                >
                  Confirm
                </Button>
              </>
            )}
          </Modal.Actions>
        </Modal>
        <LessonPreviewModal
          lessonId={this.state.previewLessonId}
          show={!!this.state.previewLessonId}
          close={() => this.setState({ previewLessonId: null })}
        />
      </div>
    );
  }
}

s = {
  body: {},
  container: {
    marginLeft: '20%',
    marginRight: '20%',
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  error: {
    color: 'red',
    paddingBottom: '20px',
  },
};

export default Radium(LessonListContainer);
