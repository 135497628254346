import React, { Component } from 'react';
import Radium from 'radium';
import bind from 'bind-decorator';
import {
  Button, Header, Segment,
} from 'semantic-ui-react';
import { parse } from '@vanillaes/csv';
import { Link } from 'react-router-dom';
import Organization from '@/models/organization';
import ColumnSelector from './ColumnSelector';
import Upload from '../../Shared/Upload';
import StatusDisplay from './StatusDisplay';
import Group from '../../../models/group';
import User from '../../../models/user';

const s = {
  container: {
    marginLeft: '20%',
    marginRight: '20%',
    marginTop: '40px',
  },
};

const TITLE = [
  'Bulk Upload Learners',
  'Map Spreadsheet Columns',
  'Review & Confirm Import',
  'Import Successfully Completed',
];
const INSTRUCTIONS = [
  'Upload a CSV containing a First Name, Last Name, and Email Address for each user.',
  'Select the proper header for each column, and then select any groups to assign the uploaded users to.',
  'Download the duplicate and error CSVs, fix any issues, and return to the start to upload the corrected data.',
];
class UploaderContainer extends Component {
  static propTypes = {
  };

  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      complete: 0,
      errors: [],
      uploadError: false,
    };
  }

  async componentDidMount() {
    let groups = await Group.objects().filtered({ group_type: 2 }).all();
    groups = groups.map((group) => ({ text: group.name, key: group.id, value: group.id }));
    const me = await User.me();
    const organization = await Organization.objects().get(me.organization_id);
    this.setState({
      groups,
      me,
      organization,
    });
  }

  @bind
  restart() {
    this.setState({
      step: 0,
      data: null,
      complete: 0,
      total: 0,
      duplicates: [],
      errors: [],
      success: [],

    });
  }

  @bind
  async getFile(files) {
    const result = await files[0].text();
    const data = parse(result);
    this.setState({
      data,
      step: this.state.step + 1,
    });
  }

  @bind
  async uploadData(excludeFirst, fields, addGroups = []) {
    const duplicates = [];
    const errors = [];
    const success = [];
    let { data } = this.state;
    if (excludeFirst) data = data.slice(1);
    this.setState({ total: data.length });
    await Promise.all(data.map((dat) => {
      const newUser = {};
      const newUserGroups = [];
      const newUserTags = new Set();
      Object.entries(fields).forEach(([key, value]) => {
        if (value === 'group' && dat[key]) {
          newUserGroups.push(dat[key]);
        } else if (value === 'tag' && dat[key]) {
          newUserTags.add(dat[key]);
        } else {
          newUser[value] = dat[key];
        }
        newUser.tags = [...newUserTags];
      });
      return User.objects().create(newUser).then((result) => {
        addGroups.forEach((group) => result.assignToGroup(group));
        newUserGroups.forEach((group) => result.assignToGroup(null, group));
        success.push(dat);
        this.setState({
          complete: this.state.complete + 1,
        });
      }).catch((result) => {
        if (result.data.email && result.data.email[0].endsWith('already exists.')) {
          duplicates.push(dat);
        } else errors.push(dat);
        this.setState({
          complete: this.state.complete + 1,
          uploadError: true,
        });
      });
    }));
    this.setState({
      duplicates,
      errors,
      success,
      fields,
      addGroups,
      step: this.state.step + 1,
    });
  }

  render() {
    const {
      step,
      data,
      groups,
      addGroups,
      total,
      complete,
      duplicates,
      errors,
      success,
      me,
      uploadError,
      organization,
      fields,
    } = this.state;
    if (organization && organization.total_active_users >= organization.hard_limit) {
      return (
        <div style={s.container}>
          <Header as="h1">{TITLE[step]}</Header>
          <Segment>
            We&apos;re sorry, but you are currently over your quota for users and cannot add any more at this time.
            Please delete any unneeded users or contact us for more details on upgrading your plan.
          </Segment>
        </div>
      );
    }
    return (
      <div style={s.container}>
        <Header as="h1">{TITLE[step]}</Header>
        <Header as="h3">Step Instructions</Header>
        <div style={{ marginBottom: '20px' }}>{INSTRUCTIONS[step]}</div>
        {[
          <div>
            <Upload maxSize={20} description="Your Spreadsheet File" accept=".csv" updateFile={this.getFile} />
            <Segment>
              <Link to="/app/v2/people"><Button>Cancel</Button></Link>
              Please upload your spreadsheet to continue
            </Segment>
          </div>,
          <ColumnSelector
            data={data}
            groups={groups}
            restart={this.restart}
            uploadData={this.uploadData}
            error={uploadError}
            complete={complete}
            total={total}
            me={me}
          />,
          <StatusDisplay
            duplicates={duplicates}
            errors={errors}
            success={success}
            fields={fields}
            restart={this.restart}
            groups={addGroups}
          />,
        ][step]}
      </div>
    );
  }
}

export default Radium(UploaderContainer);
