import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';
import _ from 'underscore';
import {
  Button,
  Header, Icon, Label, Loader, Segment,
} from 'semantic-ui-react';
import moment from 'moment';
import UserCourseStatus from '../../models/user_course_status';
import LessonPlayer from './LessonPlayer';

const LESSON_TYPES = ['Card-Based', 'SCORM', 'Article', 'Video'];

class CoursePlayer extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      courseId: props.match.params.course_id,
      loading: true,
    };
  }

  async componentDidMount() {
    this.initializePlayer();
  }

  async initializePlayer() {
    this.setState({ loading: true });
    const status = await UserCourseStatus.objects().filtered({ id: this.state.courseId }).first();
    const selectedIndex = _.findIndex(status.lesson_path, (l) => !l.completed);
    this.setState({
      status,
      loading: false,
      selectedIndex,
      latest: selectedIndex,
      selected: status.lesson_path[selectedIndex].lesson_id,
    });
  }

  @bind
  componentDidUpdate(prevProps) {
    if (this.props.match?.params.course_id !== prevProps.match?.params.course_id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        courseId: this.props.match.params.course_id,
      }, this.initializePlayer);
    }
  }

  @bind
  nextLesson() {
    const { status, selectedIndex } = this.state;
    status.lesson_path[selectedIndex + 1].available_to_take = true;
    this.setState({
      status,
      selectedIndex: selectedIndex + 1,
      latest: selectedIndex + 1,
      selected: status.lesson_path[selectedIndex + 1].lesson_id,
    });
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  observer = ({ id }) => {
    useEffect(() => {
      document.getElementById(id).scrollIntoView({ block: 'center', behavior: 'smooth' });
    });
    return null; // component does not render anything
  };

  render() {
    const {
      status, loading, selected, selectedIndex, courseId, latest,
    } = this.state;
    if (loading) return <Loader active />;
    const counts = _.countBy(status.lesson_path, (lesson) => lesson.type);
    const canTakeNextLesson = selectedIndex < status.lesson_path.length - 1
      && !status.lesson_path[selectedIndex + 1].time_delay;
    const displayCards = status.lesson_path.map((info, index) => {
      return (
        <Segment
          disabled={!info.available_to_take}
          className="title-card"
          color={info.lesson_id === selected ? 'blue' : null}
          key={info.lesson_id}
          id={info.lesson_id}
          onClick={info.available_to_take ? (() => this.setState({
            selected: info.lesson_id,
            selectedIndex: index,
          })) : undefined}
        >
          <this.observer id={this.state.selected} />
          <div className="hickory-column">
            <div style={{ display: 'flex', width: '100%' }}>
              <Label color="grey" basic>
                {index + 1}
              </Label>
              <Label color={info.type === 1 ? 'grey' : 'blue'}>
                {LESSON_TYPES[info.type - 1]}
              </Label>
            </div>
            <Header as="h4" className="lesson-name">
              {info.name}
            </Header>
          </div>
        </Segment>
      );
    });
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        <div className="sidebar">
          <Button onClick={this.props.history.goBack} className="back-link">
            Back
          </Button>
          <Header as="h1">{status.name}</Header>
          <span style={{ color: '#7E7F8F' }}>
            Time Estimate: {moment.duration(status.median_time_spent, 'seconds').humanize()}
          </span>
          <Segment className="types">
            <div>
              <Icon name="window restore outline" color="grey" size="large" />
              {counts['1'] || 0} Card-Based
            </div>
            <div>
              <Icon name="sign-in" color="grey" size="large" />
              {counts['2'] || 0} SCORM
            </div>
            <div>
              <Icon name="file text" color="grey" size="large" />
              {counts['3'] || 0} Article
            </div>
            <div>
              <Icon name="video play" color="grey" size="large" />
              {counts['4'] || 0} Video
            </div>
          </Segment>
          <div className="title-card-container">
            {displayCards}
          </div>
        </div>
        <LessonPlayer
          lessonId={selected}
          nextInCourse={canTakeNextLesson
            ? [this.nextLesson, status.lesson_path[selectedIndex + 1].lesson_id]
            : undefined}
          courseId={courseId}
          preview={latest > selectedIndex}
        />
      </div>
    );
  }
}

export default CoursePlayer;
