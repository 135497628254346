import React, { Component } from 'react';
import Radium from 'radium';
import moment from 'moment';
import PropTypes from 'prop-types';
import StatusCount from '../../models/status_count';
import LineChartCard from '../Shared/LineChartCard';
import AssignmentList from '../Shared/AssignmentList';

const s = {
  dashboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '15%',
    paddingRight: '15%',
    paddingTop: '40px',
    backgroundColor: '#FAFBFD',
  },
};

class AssignmentsContainer extends Component {
  static propTypes = {
    baseFilter: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    limit: PropTypes.number,
  };

  static defaultProps = {
    baseFilter: {},
    limit: null,
  };

  componentDidMount() {
    const now = moment();
    const startDate = moment()
      .add(-29, 'days')
      .format('YYYY-MM-DD');

    StatusCount.objects()
      .filtered({ _start_date: startDate, _end_date: now.format('YYYY-MM-DD'), _type: 'completed' })
      .all()
      .then((completedAssignments) => {
        const assignmentsList = completedAssignments.map((a) => Object.values(a));
        this.setState({
          completedAssignments: assignmentsList,
          startDate,
        });
      });
  }

  render() {
    return (
      <div style={s.dashboardContainer}>
        <h1>Assignment Activity</h1>
        <LineChartCard
          chartData={[
            {
              name: 'Completed',
              data: this.state.completedAssignments,
              color: '#2BB674',
              fillColor: '#2BB6740D',
            },
          ]}
          startDate={this.state.startDate}
        />
        <AssignmentList baseFilter={this.props.baseFilter} limit={this.props.limit} />
      </div>
    );
  }
}

export default Radium(AssignmentsContainer);
