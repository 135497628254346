import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import bind from 'bind-decorator';
import FancyDropdown from '../Shared/FancyDropdown';
import {
  CARD,
  SCORM,
  CONTINUOUS_REVIEW_TYPE,
  INTERVAL_REVIEW_TYPE,
  NO_REVIEW_TYPE,
} from '../../constants';

const ReviewTypeMeta = {
  CONTINUOUS: "1",
  MONTHLY: "2",
  QUARTERLY: "3",
  YEARLY: "4",
  NEVER: "5",
};

class CourseLessonRow extends Component {
  static propTypes = {
    lesson: PropTypes.object,
    onCriteriaChange: PropTypes.func,
    criteria: PropTypes.object,
    allowEdits: PropTypes.bool,
    index: PropTypes.number,
  };

  constructor(props) {
    super(props);

    const { lesson, criteria } = props;

    let assigned = '1';
    let reviewTypeMeta = lesson.lesson_type === CARD ? ReviewTypeMeta.CONTINUOUS : null;
    const reviewTypeEnabled = lesson.lesson_type === CARD;
    const retakeThresholdEnabled = lesson.lesson_type !== SCORM;
    let previousLessonScore = null;
    let timeDelay = null;

    if (criteria && (criteria.time_delay > 0 || criteria.previous_lesson_score > 0)) {
      assigned = '2';
      previousLessonScore = parseInt(parseFloat(criteria.previous_lesson_score) * 100);
      timeDelay = criteria.time_delay;
    }
    if (criteria && criteria.review_type) {
      if (criteria.review_type === INTERVAL_REVIEW_TYPE) {
        const freq = criteria.review_frequency;
        if (freq === 365) {
          reviewTypeMeta = ReviewTypeMeta.YEARLY;
        } else if (freq === 90) {
          reviewTypeMeta = ReviewTypeMeta.QUARTERLY;
        } else if (freq === 30) {
          reviewTypeMeta = ReviewTypeMeta.MONTHLY;
        }
      } else if (criteria.review_type === NO_REVIEW_TYPE) {
        reviewTypeMeta = ReviewTypeMeta.NEVER;
      }
    }
    if (reviewTypeMeta === null) {
      reviewTypeMeta = ReviewTypeMeta.NEVER;
    }

    this.state = {
      assigned,
      previousLessonScore,
      timeDelay,
      reviewTypeMeta,
      reviewTypeEnabled,
      retakeThresholdEnabled,
      retakeThreshold: criteria.retake_threshold ? `${criteria.retake_threshold}` : "0",
    };
  }

  @bind
  updateParent() {
    if (this.props.onCriteriaChange) {
      const {
        assigned,
        previousLessonScore,
        timeDelay,
        reviewTypeMeta,
        retakeThreshold,
      } = this.state;
      const hasCriteria = assigned === '2';

      const previousLessonScoreInt = parseInt(previousLessonScore);
      const timeDelayInt = parseInt(timeDelay);

      let reviewType = CONTINUOUS_REVIEW_TYPE;
      let reviewFrequency = null;
      if (reviewTypeMeta === ReviewTypeMeta.NEVER) {
        reviewType = NO_REVIEW_TYPE;
      } else if (reviewTypeMeta !== ReviewTypeMeta.CONTINUOUS) {
        reviewType = INTERVAL_REVIEW_TYPE;
        if (reviewTypeMeta === ReviewTypeMeta.MONTHLY) {
          reviewFrequency = 30;
        } else if (reviewTypeMeta === ReviewTypeMeta.QUARTERLY) {
          reviewFrequency = 90;
        } else {
          reviewFrequency = 365;
        }
      }

      const criteria = {
        retake_threshold: parseFloat(retakeThreshold),
        review_type: reviewType,
        review_frequency: reviewFrequency,
        previous_lesson_score: hasCriteria && previousLessonScoreInt > 0 ? (previousLessonScoreInt / 100.0) : 0,
        time_delay: hasCriteria && timeDelayInt > 0 ? timeDelayInt : 0,
      };
      if (hasCriteria && criteria.time_delay === 0 && criteria.previous_lesson_score === 0) {
        return; // STOP!
      }
      this.props.onCriteriaChange(criteria);
    }
  }

  render() {
    const { lesson } = this.props;
    return (
      <div className="hickory-course-lesson-row">
        <div className="hickory-course-lesson-name">{lesson.name}</div>
        <div>
          <div className="hickory-course-lesson-options">
            <FancyDropdown
              text="Assigned"
              disabled={this.props.index === 0 || !this.props.allowEdits}
              onChange={(assigned) => {
                this.setState({
                  assigned,
                  timeDelay: this.state.timeDelay || 0,
                  previousLessonScore: this.state.previousLessonScore || 0,
                }, this.updateParent);
              }}
              value={this.state.assigned}
            >
              <FancyDropdown.Item key="1" text="Immediately" value="1" />
              <FancyDropdown.Item key="2" text="After Criteria Meet" value="2" />
            </FancyDropdown>

            <FancyDropdown
              text="Reviews"
              disabled={!this.state.reviewTypeEnabled || !this.props.allowEdits}
              onChange={(reviewTypeMeta) => {
                this.setState({
                  reviewTypeMeta,
                }, this.updateParent);
              }}
              value={this.state.reviewTypeMeta}
            >
              <FancyDropdown.Item text="Continuous" value={ReviewTypeMeta.CONTINUOUS} />
              <FancyDropdown.Item text="Every Month" value={ReviewTypeMeta.MONTHLY} />
              <FancyDropdown.Item text="Every Quarter" value={ReviewTypeMeta.QUARTERLY} />
              <FancyDropdown.Item text="Every Year" value={ReviewTypeMeta.YEARLY} />
              <FancyDropdown.Item text="Never" value={ReviewTypeMeta.NEVER} />
            </FancyDropdown>

            <FancyDropdown
              text="Pass"
              disabled={!this.state.retakeThresholdEnabled || !this.props.allowEdits}
              onChange={(retakeThreshold) => {
                this.setState({
                  retakeThreshold,
                }, this.updateParent);
              }}
              value={this.state.retakeThreshold}
            >
              <FancyDropdown.Item key="0" text="Any Score" value="0" />
              <FancyDropdown.Item key="90" text="90%" value={`${0.9}`} />
              <FancyDropdown.Item key="80" text="80%" value={`${0.8}`} />
              <FancyDropdown.Item key="70" text="70%" value={`${0.7}`} />
              <FancyDropdown.Item key="60" text="60%" value={`${0.6}`} />
              <FancyDropdown.Item key="50" text="50%" value={`${0.5}`} />
              <FancyDropdown.Item key="40" text="40%" value={`${0.4}`} />
              <FancyDropdown.Item key="30" text="30%" value={`${0.3}`} />
              <FancyDropdown.Item key="20" text="20%" value={`${0.2}`} />
              <FancyDropdown.Item key="10" text="10%" value={`${0.1}`} />
            </FancyDropdown>
          </div>

          {this.state.assigned === '2' ? (
            <div className="hickory-course-lesson-row-requirements">
              Assigned:{' '}
              <Input
                value={this.state.timeDelay === 0 ? "" : this.state.timeDelay}
                disabled={this.props.index === 0 || !this.props.allowEdits}
                onChange={(e, { value }) => {
                  this.setState({
                    timeDelay: value,
                  }, this.updateParent);
                }}
              />{' '}
              Days After Learner Reaches{' '}
              <Input
                value={this.state.previousLessonScore === 0 ? "" : this.state.previousLessonScore}
                disabled={this.props.index === 0 || !this.props.allowEdits}
                onChange={(e, { value }) => {
                  this.setState({
                    previousLessonScore: value,
                  }, this.updateParent);
                }}
              />
              % In Preceeding Lesson
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default CourseLessonRow;
