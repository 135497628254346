import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';
import { Button, Form, Modal } from 'semantic-ui-react';
import User from '@/models/user';
import Organization from '../../models/organization';

class CreateUserModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    create: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      email: '',
    };
  }

  async componentDidMount() {
    const me = await User.me();
    const org = await Organization.objects().get(me.organization_id);
    this.setState({
      preferredTimezone: org.default_timezone,
    });
  }

  @bind
  createUser() {
    const {
      firstName, lastName, email, preferredTimezone,
    } = this.state;
    this.props.create(firstName, lastName, email, preferredTimezone);
    this.setState({
      firstName: '',
      lastName: '',
      email: '',
    });
  }

  @bind
  handleChange(e, { name, value }) { this.setState({ [name]: value }); }

  render() {
    const {
      firstName, lastName, email, preferredTimezone,
    } = this.state;
    return (
      <Modal open={this.props.show}>
        <Modal.Header>Create New User</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Input
              label="First Name"
              placeholder="First Name"
              value={firstName}
              name="firstName"
              onChange={this.handleChange}
            />
            <Form.Input
              label="Last Name"
              placeholder="Last Name"
              value={lastName}
              name="lastName"
              onChange={this.handleChange}
            />
            <Form.Input
              label="Email Address"
              placeholder="Email Address"
              value={email}
              name="email"
              onChange={this.handleChange}
            />
            <Form.Select
              label="Timezone"
              options={User.SUPPORTED_TIMEZONES.map((tz) => ({ key: tz, value: tz, text: tz }))}
              value={preferredTimezone}
              name="preferredTimezone"
              onChange={this.handleChange}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.close}>Cancel</Button>
          <Button
            onClick={this.createUser}
            positive
          >Create
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default CreateUserModal;
