import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dropdown, Header, Input, Label, Modal, Tab,
} from 'semantic-ui-react';
import bind from 'bind-decorator';
import GroupLearners from './GroupLearners';
import Group from '../../models/group';
import GroupCourses from './GroupCourses';
import GroupLessons from './GroupLessons';
import GroupManagers from './GroupManagers';
import Breadcrumb from '../Shared/Breadcrumb';
import { GROUP_TYPE_COMPLIANCE, GROUP_TYPE_LEARNING, GROUP_TYPE_SMART } from '../../constants';
import GroupCriteria from './GroupCriteria';
import { colorForGroupNameLength } from '../utils/DisplayHelpers';
// import GroupOverview from './GroupOverview';

class GroupContainer extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      groupId: props.match.params.group_id,
      groupName: '',
    };
  }

  async componentDidMount() {
    const group = await Group.objects().get(this.state.groupId);
    this.setState({
      group,
      groupName: group.name,
    });
  }

  @bind
  async convertGroup() {
    const group = await Group.objects().update(this.state.groupId, {
      group_type: GROUP_TYPE_SMART,
    });
    this.setState({
      group,
      showConvertModal: false,
    });
  }

  @bind
  async renameGroup() {
    const group = await Group.objects().update(this.state.groupId, { name: this.state.groupName });
    this.setState({
      group,
      groupName: group.name,
      showRenameModal: false,
    });
  }

  render() {
    const { groupId, group } = this.state;
    const panes = [
      // {
      //   menuItem: 'Overview',
      //   render: () => <Tab.Pane attached={false}><GroupOverview groupId={groupId} /></Tab.Pane>,
      // },
      {
        menuItem: 'Learners',
        render: () => <Tab.Pane attached={false}><GroupLearners groupId={groupId} /></Tab.Pane>,
      },
      {
        menuItem: 'Courses',
        render: () => (
          <Tab.Pane attached={false}>
            <GroupCourses groupId={groupId} history={this.props.history} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Lessons',
        render: () => (
          <Tab.Pane attached={false}>
            <GroupLessons groupId={groupId} history={this.props.history} />
          </Tab.Pane>
        ),
      },
      // {
      //   menuItem: 'Assignments',
      //   render: () => <Tab.Pane attached={false}>Assigments</Tab.Pane>,
      // },
      // {
      //   menuItem: 'Activity',
      //   render: () => <Tab.Pane attached={false}>Activity</Tab.Pane>,
      // },
    ];
    if (group?.group_type !== GROUP_TYPE_COMPLIANCE) {
      panes.push(
        {
          menuItem: 'Managers',
          render: () => <Tab.Pane attached={false}><GroupManagers groupId={groupId} /></Tab.Pane>,
        },
      );
    }
    if (group?.group_type === GROUP_TYPE_SMART) {
      panes.push(
        {
          menuItem: 'Criteria',
          render: () => <Tab.Pane attached={false}><GroupCriteria groupId={groupId} /></Tab.Pane>,
        },
      );
    }
    return (
      <div className="hickory-container">
        <Breadcrumb parentName="Groups" parentUrl="/app/v2/groups/" name={group ? group.name : ''}>
          <Dropdown text="Group Actions" button className="green">
            <Dropdown.Menu>
              <Dropdown.Item text="Rename" onClick={() => this.setState({ showRenameModal: true })} />
              {(group?.group_type === GROUP_TYPE_LEARNING) && (
                <Dropdown.Item
                  text="Convert to Smart Group"
                  onClick={() => this.setState({ showConvertModal: true })}
                />
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Breadcrumb>
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
        />
        <Modal
          open={this.state.showRenameModal}
          onClose={() => {
            this.setState({ showRenameModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Create a New Group</Modal.Header>
          <Modal.Content>
            <Header as="h3">Group Name:</Header>
            <div>
              <Input
                placeholder="Enter Group Name (required)"
                style={{ padding: '10px' }}
                value={this.state.groupName}
                onChange={(e, { value }) => {
                  const length = value.trim().length;
                  if (length <= 80) {
                    this.setState({
                      groupName: value,
                    });
                  }
                }}
              />
            </div>
            <Label
              content={`${this.state.groupName.length}/50 characters`}
              color={colorForGroupNameLength(this.state.groupName.length)}
              size="small"
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showRenameModal: false });
              }}
            >
              Cancel
            </Button>
            <Button type="submit" className="green" onClick={this.renameGroup} disabled={!this.state.groupName}>
              Update
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.state.showConvertModal}
          onClose={() => {
            this.setState({ showConvertModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Convert to Smart Group</Modal.Header>
          <Modal.Content>
            Would you like to convert this group to be a Smart Group?<br />
            Smart Groups are a special type of group whose membership is automatically set based
            on various (customizable) criteria.
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showConvertModal: false });
              }}
            >
              No
            </Button>
            <Button type="submit" className="green" onClick={this.convertGroup}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default GroupContainer;
