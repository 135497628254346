import React, { Component } from 'react';
import Radium from 'radium';
import {
  Tab, Dropdown, Modal, Button,
} from 'semantic-ui-react';
import bind from 'bind-decorator';
import _ from 'underscore';
import PropTypes from 'prop-types';
import User from '@/models/user';
import UserSummary from './UserSummary';
import UserAssignments from './UserAssignments';
import UserLessons from './UserLessons';
import UserModal from '../PeoplePanel/UserModal';
import Breadcrumb from '../Shared/Breadcrumb';

const s = {
  body: {},
  container: {
    marginLeft: '20%',
    marginRight: '20%',
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'row',
  },
  breadcrumb: {
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '38px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  menuContainer: {
    width: '100%',
  },
  contentPane: {
    border: 'none',
  },
  slash: {
    color: '#E6E8ED',
  },
  dropdownGreen: {
    height: '36px',
    marginLeft: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#2BB674',
    backgroundColor: '#C8F5DA',
  },
  dropdownGray: {
    height: '36px',
    marginLeft: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#4A4A4A',
    backgroundColor: '#D4D4D4',
  },
  dropdownBlue: {
    height: '36px',
    marginLeft: '20px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#4575F1',
    backgroundColor: '#E0E9FF',
  },
};

const TABS = [
  ['Overview', UserSummary],
  ['Assignments', UserAssignments],
  ['Lessons', UserLessons],
];
class UserDetailsContainer extends Component {
  static propTypes = {
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      email: props.match.params.email,
      loading: true,
      showEditModal: false,
    };
  }

  async componentDidMount() {
    this.refreshUser();
  }

  @bind
  async refreshUser() {
    const me = await User.me();
    const users = await User.objects()
      .filtered({ email: this.state.email })
      .all();
    if (users.length > 0) {
      const user = users[0];
      this.setState({
        me,
        user,
        loading: false,
      });
    } else {
      console.log('user not found...');
    }
  }

  @bind
  async reactivateUser() {
    this.setState({ loading: true });
    const { user } = this.state;
    user.is_active = true;
    await User.objects().update(user.id, user);
    this.refreshUser();
  }

  @bind
  async deactivateUser() {
    this.setState({ loading: true });
    const { user } = this.state;
    user.is_active = false;
    await User.objects().update(user.id, user);
    this.refreshUser();
  }

  @bind
  async deleteUser() {
    this.setState({ loading: true });
    const { user } = this.state;
    user.deleted = true;
    await User.objects().update(user.id, user);
    this.refreshUser();
  }

  @bind
  async undeleteUser() {
    this.setState({ loading: true });
    const { user } = this.state;
    user.deleted = false;
    await User.objects().update(user.id, user);
    this.refreshUser();
  }

  @bind
  async inviteUser() {
    const { user } = this.state;
    user.sendInvite();
    this.setState({ confirmAction: true, confirmationMessage: 'Invite Successfully Sent' });
  }

  renderActionMenu() {
    const { user } = this.state;
    if (user.deleted) {
      return (
        <Dropdown text="Deleted" button style={s.dropdownGray}>
          <Dropdown.Menu>
            <Dropdown.Item text="Undelete" onClick={this.deleteUser} />
          </Dropdown.Menu>
        </Dropdown>
      );
    }
    if (!user.is_active) {
      return (
        <Dropdown text="Inactive" button style={s.dropdownGray}>
          <Dropdown.Menu>
            <Dropdown.Item text="Re-Activate" onClick={this.reactivateUser} />
            <Dropdown.Item text="Delete" onClick={this.undeleteUser} />
          </Dropdown.Menu>
        </Dropdown>
      );
    }
    return (
      <Dropdown text="Active" button style={s.dropdownGreen}>
        <Dropdown.Menu>
          <Dropdown.Item text="Deactivate" onClick={this.deactivateUser} />
          <Dropdown.Item text="Delete" onClick={this.deleteUser} />
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  @bind
  switchTab(name) {
    const tab = _.findIndex(TABS, (pane) => pane[0] === name);
    this.setState({ activeIndex: tab });
  }

  @bind
  handleTabChange(e, { activeIndex }) {
    this.setState({ activeIndex });
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    const { user, me } = this.state;
    let canEdit = true;
    const isOwner = me.roles.indexOf('Owner') >= 0;

    if (!isOwner) {
      canEdit = user.roles.indexOf('Owner') === -1;
    }

    const panes = TABS.map(([title, component]) => ({
      menuItem: title,
      render: () => (
        <Tab.Pane key={title} style={s.contentPane} attached="bottom">
          {React.createElement(component, { user, switchTab: this.switchTab })}
        </Tab.Pane>
      ),
    }));
    return (
      <div style={s.body}>
        <div style={s.container}>
          <div style={s.menuContainer}>
            <div style={{ display: 'flex' }}>
              <Breadcrumb
                parentName="People"
                parentUrl="/app/v2/people/"
                name={`${user.first_name} ${user.last_name}`}
              />
              {canEdit ? this.renderActionMenu() : null}
              {canEdit ? (
                <div style={{ display: 'flex', flexGrow: '1', justifyContent: 'flex-end' }}>
                  <Dropdown text="Actions" button style={s.dropdownBlue}>
                    <Dropdown.Menu>
                      <Dropdown.Item text="Edit" onClick={() => this.setState({ showEditModal: true })} />
                      {user.is_active === false ? (
                        <Dropdown.Item text="Resend Invite" onClick={this.inviteUser} />
                      ) : null}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : null }
            </div>
            <Tab
              menu={{ attached: 'top', pointing: true, secondary: true }}
              activeIndex={this.state.activeIndex}
              onTabChange={this.handleTabChange}
              renderActiveOnly
              panes={panes}
            />
          </div>
        </div>

        <Modal open={this.state.confirmAction} style={{ width: '500px' }} closeIcon>
          <Modal.Header>Success</Modal.Header>
          <Modal.Content>
            <div style={s.column}>{this.state.confirmationMessage}</div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                this.setState({ confirmAction: false });
              }}
            >
              OK
            </Button>
          </Modal.Actions>
        </Modal>

        <UserModal
          user={user}
          userId={user.id.toString()}
          open={this.state.showEditModal}
          hideModal={() => this.setState({ showEditModal: false })}
          updateUsers={this.refreshUser}
        />
      </div>
    );
  }
}

export default Radium(UserDetailsContainer);
