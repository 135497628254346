import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  Button, Dropdown, Form, Progress, Segment, Table,
} from 'semantic-ui-react';
import bind from 'bind-decorator';
import { v4 as uuid } from 'uuid';
import _ from 'underscore';
import { Features } from '../../../constants';
import Group from '@/models/group';

const HEADERS = [
  {
    key: 'first_name', value: 'first_name', text: 'First Name', limit: 1, required: true,
  },
  {
    key: 'last_name', value: 'last_name', text: 'Last Name', limit: 1, required: true,
  },
  {
    key: 'email', value: 'email', text: 'Email', limit: 1, required: true,
  },
  { key: 'group', value: 'group', text: 'Group' },
  { key: 'tag', value: 'tag', text: 'Tag' },
  { key: 'nope', value: undefined, text: 'Do Not Import' },
];

class ColumnSelector extends Component {
  static propTypes = {
    groups: PropTypes.array,
    data: PropTypes.array,
    uploadData: PropTypes.func,
    restart: PropTypes.func,
    total: PropTypes.number,
    complete: PropTypes.number,
    error: PropTypes.bool,
    me: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      fields: {},
      addGroups: [],
      groups: props.groups || [],
    };
  }

  @bind
  async createNewGroup(e, { value }) {
    const newGroup = await Group.objects().create({ name: value });
    this.setState((prevState) => {
      const addGroups = prevState.addGroups;
      addGroups.pop();
      return {
        groups: [{ text: newGroup.name, value: newGroup.id, key: newGroup.id }, ...prevState.groups],
        addGroups: [...addGroups, newGroup.id],
      };
    });
  }

  render() {
    const {
      fields, excludeFirst, addGroups, groups,
    } = this.state;
    const {
      restart, uploadData, total, complete, me, error,
    } = this.props;
    const data = this.props.data.slice(excludeFirst ? 1 : 0, excludeFirst ? 6 : 5) || [];
    return (
      <div>
        <Form style={{ width: '100%' }}>
          <Form.Group widths="equal">
            {me.waffle_flags.includes(Features.ADVANCED_USER_UPLOAD) && (
            <Form.Select
              fluid
              label="Add imported users to groups:"
              options={groups}
              placeholder="No Groups"
              onChange={(e, { value }) => this.setState({ addGroups: value })}
              value={addGroups}
              allowAdditions
              selection
              multiple
              search
              onAddItem={this.createNewGroup}
            />
            )}
            <Form.Select
              fluid
              label="Ignore first row of spreadsheet?"
              options={[
                { key: 'yes', value: 'yes', text: 'Yes, Ignore First Row' },
                { key: 'no', value: 'no', text: 'No, Include First Row' },
              ]}
              onChange={(e, { value }) => this.setState({ excludeFirst: value === 'yes' })}
              defaultValue="no"
            />
          </Form.Group>
        </Form>
        <Table celled>
          <Table.Header>
            <Table.Row>
              {data[0].map((d, index) => (
                <Table.HeaderCell key={uuid()} singleLine>
                  <Dropdown
                    options={HEADERS.filter(
                      (h) => !h.limit || Object.values(fields).filter(
                        (x) => x === h.key,
                      ).length < h.limit || fields[index] === h.key,
                    )}
                    placeholder="Do Not Import"
                    onChange={(e, { value }) => {
                      if (!value) delete fields[index];
                      else fields[index] = value;
                      this.setState({
                        fields,
                      });
                    }}
                    value={fields[index]}
                    error={!fields[index] && !!_.where(HEADERS, { required: true }).filter(
                      (h) => !Object.values(fields).includes(h.key),
                    ).length}
                  />
                </Table.HeaderCell>
              ))}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((dat) => (
              <Table.Row>
                {dat.map((d) => (
                  <Table.Cell>
                    {d}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {total ? (
          <Progress
            value={complete}
            total={total}
            precision={0}
            progress="percent"
            color={error ? 'yellow' : 'green'}
            size="big"
          />
        ) : (
          <Segment>
            <Button onClick={restart}>Cancel</Button>
            Please map all required columns.
            <Button
              positive
              style={{ float: 'right' }}
              onClick={() => uploadData(excludeFirst, fields, addGroups)}
              disabled={!!_.where(HEADERS, { required: true }).filter(
                (h) => !Object.values(fields).includes(h.key),
              ).length}
            >
              Import Data
            </Button>
          </Segment>
        )}
      </div>
    );
  }
}

export default Radium(ColumnSelector);
