import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import _ from 'underscore';
import {
  Button, Dropdown, Icon, Loader,
} from 'semantic-ui-react';

const s = {
  cardContainer: {
    background: '#FFFFFF',
    boxShadow:
    // eslint-disable-next-line max-len
      '0px 11.28px 23.3955px rgba(0, 0, 0, 0.0215656), 0px 6.0308px 12.5083px rgba(0, 0, 0, 0.0178832), 0px 3.38082px 7.01207px rgba(0, 0, 0, 0.015), 0px 1.79553px 3.72406px rgba(0, 0, 0, 0.0121168), 0px 0.747159px 1.54966px rgba(0, 0, 0, 0.00843437)',
    borderRadius: '4px',
    padding: '10px',
    margin: '0px 10px',
    position: 'relative',
  },
  cardStack: {
    background: '#FFFFFF',
    boxShadow:
    // eslint-disable-next-line max-len
      '0px 11.28px 23.3955px rgba(0, 0, 0, 0.0215656), 0px 6.0308px 12.5083px rgba(0, 0, 0, 0.0178832), 0px 3.38082px 7.01207px rgba(0, 0, 0, 0.015), 0px 1.79553px 3.72406px rgba(0, 0, 0, 0.0121168), 0px 0.747159px 1.54966px rgba(0, 0, 0, 0.00843437)',
    borderRadius: '4px',
    padding: '10px',
    position: 'relative',
  },
  options: {
    padding: '10px',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  cardTitle: {
    position: 'absolute',
    width: '198px',
    height: '21px',
    left: '17px',
    top: '12px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#7E7F8F',
    zIndex: '999',
    whiteSpace: 'nowrap',
  },
  cardBody: {
    minWidth: '200px',
    minHeight: '90px',
  },
  spinner: {
    position: 'absolute',
    marginLeft: '100px',
    marginTop: '30px',
  },
};

class CardContainer extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    title: PropTypes.string,
    options: PropTypes.object,
    loaded: PropTypes.bool,
    compact: PropTypes.bool,
    stack: PropTypes.number,
    height: PropTypes.string,
    width: PropTypes.string,
  };

  static defaultProps = {
    loaded: true,
    stack: 0,
  };

  constructor(props) {
    super(props);

    this.state = {
      width: 0,
    };
    this.container = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const width = this.container.current ? this.container.current.offsetWidth : 0;
    if (prevState.width !== width) {
    // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        width,
      });
    }
  }

  stack() {
    return _.range(1, this.props.stack).map((depth) => (
      <div
        key={depth}
        style={({
          ...s.cardStack,
          width: `${this.state.width - 30 * depth}px`,
          zIndex: this.props.stack - depth,
          margin: `-10px ${20 + ((30 * depth) / 2)}px 0`,
        })}
      />
    ));
  }

  render() {
    const cardContainer = _.clone(s.cardContainer);
    cardContainer.zIndex = this.props.stack || 'auto';
    if (this.props.compact) cardContainer.width = 'fit-content';
    if (this.props.width) {
      cardContainer.width = this.props.width;
    }
    if (this.props.height) {
      cardContainer.height = this.props.height;
    }
    return (
      <div style={{ position: 'relative' }}>
        <div style={cardContainer}>
          {this.props.title && (
          <div style={s.titleContainer}>
            <h2 style={s.cardTitle}>{this.props.title}</h2>
            {!!this.props.options && (
            <Dropdown
              trigger={(
                <Button style={s.options} basic>
                  <Icon name="ellipsis vertical" color="blue" style={{ margin: '0px' }} />
                </Button>
              )}
              icon=""
              className="icon"
              labeled
            >
              <Dropdown.Menu>
                {Object.keys(this.props.options).map((key) => (
                  <Dropdown.Item key={key} text={key} onClick={this.props.options[key]} />
                ))}
              </Dropdown.Menu>
            </Dropdown>
            )}
          </div>
          )}
          <div style={s.cardBody} ref={this.container}>
            {this.props.loaded ? (
              this.props.children
            ) : (
              <div style={s.spinner}>
                <Loader active={!this.props.loaded} style={{ position: 'relative', marginTop: '30px' }} />
              </div>
            )}
          </div>
        </div>
        <div style={{ height: '40px', marginTop: '-10px' }}>
          {!!this.props.stack && this.stack()}
        </div>
      </div>
    );
  }
}

export default Radium(CardContainer);
