import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Link } from 'react-router-dom';
import {
  Button, Header, Loader, Progress,
} from 'semantic-ui-react';
import bind from 'bind-decorator';
import { formatDistanceToNow, parseISO } from 'date-fns';
import UserLessonStatus from '@/models/user_lesson_status';
import CardContainer from '../Shared/CardContainer';

const s = {
  container: {
    backgroundColor: '#D6D7DC',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTile: {
    width: '600px',
    height: '650px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    color: '#918f90',
    fontSize: '1.5em',
    justifyContent: 'space-between',
    borderTop: '1px solid #d1d1d1',
    marginTop: '20px',
    width: '100%',
  },
  startButton: {
    display: 'block',
    backgroundColor: '#2185D0',
    textAlign: 'center',
    color: '#ffffff',
    textShadow: 'none',
    padding: '10px',
    margin: '10px',
    borderRadius: '5px',
  },
  checkmarkContainer: {
    height: '130px',
    width: '130px',
    border: '5px solid #e3e3e3',
    borderRadius: '50%',
    overflow: 'hidden',
  },
  scoreContainer: {
    border: '1px solid #BABEC7',
    borderRadius: '4px',
    // eslint-disable-next-line max-len
    boxShadow: '0px 0.7471592426300049px 1.5496636629104614px 0px #00000002, 0px 1.7955275774002075px 3.724057197570801px 0px #00000003, 0px 3.380819082260132px 7.012069225311279px 0px #00000004, 0px 6.030803203582764px 12.508333206176758px 0px #00000005, 0px 11.27995491027832px 23.395462036132812px 0px #00000005',
    width: '60%',
    height: '15%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
  },
};

class LessonCompleteCard extends Component {
  static propTypes = {
    next: PropTypes.string,
    nextInCourse: PropTypes.array,
    activityCount: PropTypes.number,
    status: PropTypes.object,
    showScore: PropTypes.bool,
    nextIsCourse: PropTypes.bool,
    lesson: PropTypes.object,
    initializePlayer: PropTypes.func,
    preview: PropTypes.bool,
  };

  static defaultProps = {
    showScore: true,
  };

  constructor(props) {
    super(props);

    const params = new URLSearchParams(window.location.search);
    this.state = {
      review: props.status ? props.status.completed : false,
      scoringProgress: 0,
      embedded: params.get('embedded'),
      scored: false,
    };
  }

  componentDidMount() {
    this.waitForScore();
  }

  @bind
  async waitForScore() {
    if (this.props.preview) {
      return;
    }
    this.setState({ scoringProgress: this.state.scoringProgress + 1 });
    setTimeout(async () => {
      if (this.state.scoringProgress >= 100 || this.props.lesson) {
        const { lesson, nextInCourse } = this.props;
        let nextId;
        if (nextInCourse) nextId = nextInCourse[1];
        const data = await lesson.scoreLesson(nextId);
        const score = data?.score || 0;
        const canProgress = data?.can_progress;
        const status = this.props.status && await UserLessonStatus.objects().get(this.props.status.id);
        this.setState({
          score,
          canProgress,
          status,
          scored: !!data,
        });
      } else {
        this.waitForScore();
      }
    }, 50);
  }

  @bind
  nextButton() {
    const {
      next, nextIsCourse, nextInCourse, initializePlayer, preview,
    } = this.props;
    const { scored, canProgress } = this.state;
    if ((!next && !nextInCourse) || preview) {
      return (
        <Link style={s.startButton} to="/app/v2/learning/home/">
          All Done
        </Link>
      );
    }
    if (nextInCourse) {
      if (!scored || canProgress) {
        return (
          <div className="hickory-column">
            <Button style={s.startButton} onClick={nextInCourse[0]} disabled={!scored}>
              {scored ? 'Continue Course' : 'Scoring Lesson...'}
            </Button>
            <span>
              You can always&nbsp;
              <Link to={`/app/v2/${nextIsCourse ? 'courses' : 'lessons'}/${next}/player`}>
                Take Another Lesson
              </Link>
            &nbsp;instead of continuing this course.
            </span>
          </div>
        );
      }
      return (
        <div className="row">
          <Button style={s.startButton} onClick={initializePlayer}>
            Retake Lesson
          </Button>
          <Link
            style={s.startButton}
            to={`/app/v2/${nextIsCourse ? 'courses' : 'lessons'}/${next}/player`}
          >
            Next Activity
          </Link>

        </div>
      );
    }
    if (!scored || canProgress) {
      return (
        <Link
          style={s.startButton}
          to={`/app/v2/${nextIsCourse ? 'courses' : 'lessons'}/${next}/player`}
        >
          Next Activity
        </Link>
      );
    }
    return (
      <div>
        <Link
          style={s.startButton}
          onClick={() => window.location.reload()}
        >
          Try Again
        </Link>
        <Link
          style={s.startButton}
          to={`/app/v2/${nextIsCourse ? 'courses' : 'lessons'}/${next}/player`}
        >
          Next Activity
        </Link>

      </div>
    );
  }

  get completionText() {
    const { canProgress, scored } = this.state;
    if (scored) {
      if (canProgress) {
        return 'Great Job!';
      }
      return 'Try Again!';
    }
    return 'Scoring...';
  }

  get completionIcon() {
    const { canProgress, scored } = this.state;
    if (scored) {
      if (canProgress) {
        return (
          <div className="checkmark-icon">
            <svg width="100%" height="100%" viewBox="0 0 256 256">
              <polyline stroke="#0dd07b" points="206.97 70.79 94.26 204.28 55.03 155.06" />
            </svg>
          </div>
        );
      }
      return (
        <div>
          <svg width="100%" height="100%" viewBox="0 0 256 256">
            <line x1="16" y1="240" x2="240" y2="16" stroke="red" strokeWidth="5%" />
            <line x1="16" y1="16" x2="240" y2="240" stroke="red" strokeWidth="5%" />
          </svg>
        </div>
      );
    }
    return (
      <div className="checkmark-icon" />
    );
  }

  render() {
    const {
      status, scoringProgress, embedded, scored, canProgress, score,
    } = this.state;
    const { next, nextInCourse, preview } = this.props;
    if (embedded && !status) {
      return (
        <CardContainer compact>
          <div style={s.cardTile}>
            <Loader active inline="centered" />
          </div>
        </CardContainer>
      );
    }
    let diff = 0;
    if (status) diff = parseInt((status.current_score - status.initial_score) * 100);
    return (
      <CardContainer compact>
        <div style={s.cardTile}>
          <div style={s.checkmarkContainer}>
            {this.completionIcon}
          </div>
          <Header as="h1" textAlign="center">
            {this.completionText}
          </Header>
          { this.props.showScore && !preview ? (
            <div style={s.scoreContainer}>
              {scored ? (
                <div style={{ textAlign: 'center' }}>
                  <Header as="h3" style={{ marginBottom: 0 }}>
                    {`Your current score on this lesson is ${Math.round(score * 100)}%. `}
                    {(diff !== 0) && `That's ${Math.round(Math.abs(diff))} points ${diff > 0 ? 'higher' : 'lower'}
                    than your initial score!`}
                  </Header>
                  <span style={{ fontSize: '.7em' }}>
                    (as of
                    {' '}
                    {formatDistanceToNow(parseISO(status.updated_at), { addSuffix: true })}
                    )
                  </span>
                </div>
              ) : (
                <div>
                  <Header as="h3" textAlign="center">Scoring your lesson...</Header>
                  <Progress percent={scoringProgress} indicating style={{ margin: '10px' }} />
                  <span>(You can still go on to the next lesson)</span>
                </div>
              )}
            </div>
          ) : null }
          <div style={{ textAlign: 'center', marginLeft: '40px', marginRight: '40px' }}>
            {!this.state.review && (
              (scored && !canProgress) ? (
                <span>You did not score high enough on this lesson
                  {nextInCourse && ' to move on to the next lesson in the course' }.{' '}
                  You can retake this lesson immediately to try for a better score, or you can move on to the{' '}
                  next activity and come back to this one later.
                </span>
              ) : (
                <div>
                  <span>
                    You have finished this
                    {this.state.review ? ' review' : ' lesson'}
                    .
                  </span>
                  <br />
                  <span>We will schedule a review for you soon to help you remember!</span>
                  <br />
                  <span>
                    You can check your scores, and times of your upcoming reviews, by visiting your
                    {' '}
                    <a href="/app/v2/learning/library/">lessons</a>
                    {' '}
                    pages.
                  </span>
                </div>

              )
            )}
          </div>
          <div className="hickory-column center">
            {!embedded && (
              <React.Fragment>
                {this.nextButton()}
                {next ? (
                  <span>
                    (
                    {this.props.activityCount - 1}
                    {' '}
                    more to go)
                  </span>
                ) : <span /> }
              </React.Fragment>
            )}
          </div>
        </div>
      </CardContainer>
    );
  }
}

export default Radium(LessonCompleteCard);
