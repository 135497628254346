import _ from 'underscore';
import ModelQuery from '@/models/model_query';
import { OPERATORS } from './model_query';

const models = {};

export const get_model = (namespace) => {
      const model = _.findWhere(models, {namespace});
      if(!model){
        console.error("Unable to find '" + namespace +"'");
      }
      return model;
}

class ModelQueryBuilder {
  constructor(klass) {
    this.klass = klass;
    this.filters = {};
    this.searchBy = null;
    this.sortBy = null;
    this.sortAscending = false;
    this.prefetchKlasses = [];
    this.joinData = {};
  }

  filtered(conditions) {
    _.extend(this.filters, conditions);
    Object.keys(conditions).forEach((condition) => {if (condition.includes('__')) this.join(condition)})
    return this;
  }

  prefetch(...klasses) {
    this.prefetchKlasses = this.prefetchKlasses.concat(klasses);
    return this;
  }

  join() {
    [...arguments].forEach((join) => {
      const [model_name, field] = join.split('__');
      if (Object.keys(OPERATORS).includes(field)) return;
      if (this.joinData[model_name] && !this.joinData[model_name].includes(field)) this.joinData[model_name].push(field)
      else this.joinData[model_name] = [field]
    })
    return this
  }


  search(value) {
    this.searchBy = value;
    for (const key in Object.keys(value || {})) {
      if (key.includes('__')) this.join(key)
    }
    return this;
  }

  sorted(column, ascending, metafunction) {
    this.sortBy = column;
    this.sortAscending = ascending;
    this.metafunction = metafunction;
    if (column.includes('__')) this.join(column)
    return this;
  }

  // the below are terminal methods
  // invoking them executes the query you've just constructed
  async get(id) {
    const result = await this._getInitialQueryRunner().get(id);
    return result;
  }

  async all() {
    // this method can/should be refactored if/when we support passing in
    // filters directly to the API - right now it preloads everything
    await this._executePrefetch();
    let cacheQueryRunner = await this._getInitialQueryRunner().init(this.filters);
    if (Object.keys(this.joinData).length) cacheQueryRunner = await cacheQueryRunner.join(this.joinData, models)
    const queryChain = this._buildChain(cacheQueryRunner);
    const result = queryChain.all();
    return result;
  }

  async first(count = 1) {
    let t = await this.page(1, count);
    if (count == 1) {
      t = t[0];
    }
    return t;
  }

  async page(page, perPage = 100) {
    await this._executePrefetch();
    let cacheQueryRunner = await this._getInitialQueryRunner().init(this.filters);
    if (Object.keys(this.joinData).length) cacheQueryRunner = await cacheQueryRunner.join(this.joinData, models)
    const queryChain = this._buildChain(cacheQueryRunner);
    return queryChain.page(page, perPage);
  }

  async create(props, urlPlus = null, urlParams = null) {
    return await this._getInitialQueryRunner().create(props, urlPlus, urlParams);
  }

  async update(id, props) {
    return await this._getInitialQueryRunner().update(id, props);
  }

  clearCache() {
    this._getInitialQueryRunner().cache.clear();
  }

  _getInitialQueryRunner() {
    return new ModelQuery(this.klass);
  }

  _buildChain(cacheQuery) {
    let chain = cacheQuery.filtered(this.filters);
    if (this.searchBy) {
      chain = chain.search(this.searchBy);
    }
    if (this.sortBy) {
      chain = chain.sorted(this.sortBy, this.sortAscending, this.metafunction);
    }
    return chain;
  }

  async _executePrefetch() {
    await Promise.all(
      _.map(this.prefetchKlasses, (klass) => klass.objects().all()),
    );
  }
}

export class ModelBase {
  static objects() {
    return new ModelQueryBuilder(this);
  }

  static register() {
    models[this.namespace] = this;
    // cache(this).clear()
    return this;
  }
}

export var Model = function (namespace) {
  return models[namespace];
};
