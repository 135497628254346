import { ModelBase } from '@/models/model';

class GroupSummary extends ModelBase {
  static namespace = 'organizational_group_summary';

  static path = '/api/v2/groups/summary/';

  static keyname = 'organizational_group';
}

export default GroupSummary.register();
