/* eslint-disable react/no-unused-state */
import { Component } from 'react';
import bind from 'bind-decorator';
import _ from 'underscore';

import User from '@/models/user';
import RoleAssignment from '@/models/role_assignment';
import LearningGroupAssignment from '@/models/learning_group_assignment';
import Group from '@/models/group';

import paginate from './pagination';
import PeopleFilter from '../PeoplePanel/PeopleFilter';
import { GROUP_TYPE_COMPLIANCE, GROUP_TYPE_LEARNING, GROUP_TYPE_SMART } from '../../constants';

const transformUser = (user, roleAssignments, groupAssignments) => {
  let roles = _.where(roleAssignments, { user_id: user.id });
  roles = _.unique(
    _.map(roles, (assignment) => assignment.role_name),
  );

  const learningAssignments = _.where(groupAssignments, { user_id: user.id });
  const learningGroups = _.without(
    _.map(learningAssignments, (assignment) => {
      if (assignment.group__group_type === GROUP_TYPE_LEARNING || assignment.group__group_type === GROUP_TYPE_SMART) {
        return assignment.group__name;
      }
      return null;
    }),
    null,
  );

  user.roles = roles;
  user.role_assignments = roles;
  user.learning_groups = learningGroups;
  return user;
};

class UserPaginationComponent extends Component {
  @bind
  // eslint-disable-next-line react/no-unused-class-component-methods
  async updateUsers() {
    let filters = {};
    if (this.state.filters) {
      filters = _.clone(this.state.filters);
    }
    this.setState({ loading: true });

    if ('roles' in filters) {
      const role = filters.roles;
      if (role.length > 0) {
        const roleAssignments = await RoleAssignment.objects().filtered({ role_name: role, deleted: false }).all();
        filters.id = roleAssignments.map((a) => a.user_id);
      }
      delete filters.roles;
    }

    // HACK: stupid hack for "no group"
    const noGroupsFilter = ('learning_groups.name' in filters && filters['learning_groups.name'] === 'NO GROUPS');

    if (('learning_groups.name' in filters || 'learning_groups.id' in filters) && !noGroupsFilter) {
      const groups = ('learning_groups.name' in filters)
        ? (await Group.objects().filtered({ name: filters['learning_groups.name'] }).all())
        : [{ id: filters['learning_groups.id'] }];

      if (groups.length > 0) {
        const groupId = groups[0].id;
        const groupAssignments = await LearningGroupAssignment
          .objects()
          .filtered({ organizational_group_id: groupId })
          .all();
        filters.id = _.map(groupAssignments, (g) => g.user_id);
      }
    }

    delete filters['learning_groups.name'];
    delete filters['learning_groups.id'];

    if ('tags' in filters && filters.tags === PeopleFilter.untaggedTag.name) {
      filters.tags = (user) => _.isEmpty(user.tags);
    }

    let allUsers;
    let roleAssignments;
    let groupAssignments;
    await Promise.all([
      User.objects()
        .filtered({ deleted: false })
        .filtered(filters)
        .search(this.state.search)
        .sorted(this.state.sort.column, this.state.sort.ascending)
        .all(),
      RoleAssignment.objects().filtered({ deleted: false }).all(),
      LearningGroupAssignment.objects().filtered({
        deleted: false,
      }).join('group__name', 'group__group_type').all(),
    ]).then((results) => {
      [allUsers, roleAssignments, groupAssignments] = results;
    });

    if (noGroupsFilter) {
      const excludedUserIds = _.uniq(
        _.without(
          _.map(
            groupAssignments,
            (assignment) => (assignment.group__group_type === GROUP_TYPE_COMPLIANCE ? null : assignment.user_id),
          ),
        ),
      );

      allUsers = _.reject(allUsers, (user) => excludedUserIds.includes(user.id));
    }
    const users = paginate(
      allUsers,
      this.state.page,
      this.state.perPage,
      (user) => transformUser(user, roleAssignments, groupAssignments),
    );

    this.setState({
      users,
      userCount: allUsers.length,
      allUsers,
      loading: false,
    });
  }
}

export default UserPaginationComponent;
