export const EASY = 1;
export const MEDIUM = 2;
export const HARD = 3;
export const MULTIPLE_CHOICE = 1;
export const FLASH_CARD = 2;
export const FREE_RESPONSE = 3;
export const INSTRUCTION = 1;
export const QUESTION = 2;
export const SCENARIO = 3;
export const KNOWLEDGE = 4;
// enrollment status codes:
export const INVALID_ENROLLMENT_CODE = 1;
export const ALREADY_ENROLLED = 2;
export const EXPIRED_ENROLLMENT_CODE = 3;
export const ENROLLMENT_OK = 4;

export const Features = {
  WHITE_LABELING: 'white_labeling',
  CMI5_EXPORT: 'cmi5_export',
  SCORM: 'scorm',
  ADVANCED_SCHEDULING: 'advanced_scheduling',
  ADVANCED_USER_UPLOAD: 'advanced_user_upload',
  LESSON_PATHS: 'lesson_paths',
  COMPLIANCE_TRAINING: 'compliance_training',
  WEEKLY_SUMMARY_EMAILS: 'weekly_summary_emails',
  ARTICLES: 'articles',
  VIDEO_LESSONS: 'video_lessons',
  GROUP_CLONING: 'group_cloning',
};

export const CARD = 1;
export const SCORM = 2;
export const ARTICLE = 3;
export const VIDEO = 4;

export const GROUP_TYPE_LEARNING = 2;
export const GROUP_TYPE_COMPLIANCE = 3;
export const GROUP_TYPE_SMART = 4;

export const MANUAL_INVOICE = 1;
export const CREDIT_CARD = 2;
export const INVOICE = 3;

export const CONTINUOUS_REVIEW_TYPE = 1;
export const INTERVAL_REVIEW_TYPE = 2;
export const NO_REVIEW_TYPE = 3;

// eslint-disable-next-line max-len
export const SUMO = 'https://endpoint5.collection.us2.sumologic.com/receiver/v1/http/ZaVnC4dhaV12Suu8nGNytD3XyQfFmakxxGZTxyjb4s59SichMl-B7O-04WGl3gwqIJZTZurPtO5dlx8-x4eoGQ8oDtEG-NhigE0QLVyWGsW1Cyw9F9RYrQ==';

// DRAFTJS STYLING
// PREFIX COLOR STYLES
export const PREFIX_COLOR = 'COLOR_';
export const PREFIX_BACKGROUND = 'BACKGROUND_';
export const PREFIX_FONT = 'FONT_';
export const PREFIX_SIZE = 'SIZE_';

export const KEY_ENTER = 13;
export const KEY_ESC = 27;

export const DEFAULT_SCENARIO_CARD_CONTENT = {
  persona: {
    left: 'Customer',
    right: 'You',
  },
  scenario: [
    {
      type: 'chat',
      position: 'left',
      content: '',
    },
  ],
  question: 'How would you respond?',
  image: null,
  explanation: [{
    type: 'text',
    string: '',
  }, {
    type: 'image',
    image: null,
  }],
  answers: [{
    answer: '',
    correct: true,
  }, {
    answer: '',
    correct: false,
  }],
  choices: 3,
};

export const DEFAULT_QUESTION_CARD_CONTENT = {
  question: '',
  image: null,
  explanation: [{
    type: 'text',
    string: '',
  }, {
    type: 'image',
    image: null,
  }],
  answers: [{
    answer: '',
    correct: true,
  }, {
    answer: '',
    correct: false,
  }],
  choices: 3,
};

export const styleMap = {
  'FONT_Trebuchet MS': {
    fontFamily: 'Trebuchet MS',
  },
  FONT_Monospace: {
    fontFamily: 'Monospace',
  },
  'FONT_Hoefler Text': {
    fontFamily: 'Hoefler Text',
  },
  FONT_Georgia: {
    fontFamily: 'Georgia',
  },
  FONT_Verdana: {
    fontFamily: 'Verdana',
  },
  FONT_Tahoma: {
    fontFamily: 'Tahoma',
  },
  FONT_Serif: {
    fontFamily: 'Serif',
  },
  'FONT_Sans Serif': {
    fontFamily: 'Sans-serif',
  },
  SIZE_12px: {
    fontSize: '1rem',
  },
  SIZE_16px: {
    fontSize: '1.6rem',
  },
  SIZE_20px: {
    fontSize: '2rem',
  },
  SIZE_24px: {
    fontSize: '2.5rem',
  },
  'BACKGROUND_#4d4d4d': {
    backgroundColor: '#4D4D4D',
    padding: '1px 3px',
  },
  'BACKGROUND_#333333': {
    backgroundColor: '#333333',
    padding: '1px 3px',
  },
  'BACKGROUND_#000000': {
    backgroundColor: '#000000',
    padding: '1px 3px',
  },
  'BACKGROUND_#999999': {
    backgroundColor: '#999999',
    padding: '1px 3px',
  },
  'BACKGROUND_#808080': {
    backgroundColor: '#808080',
    padding: '1px 3px',
  },
  'BACKGROUND_#666666': {
    backgroundColor: '#666666',
    padding: '1px 3px',
  },
  'BACKGROUND_#ffffff': {
    backgroundColor: '#FFFFFF',
    padding: '1px 3px',
  },
  'BACKGROUND_#cccccc': {
    backgroundColor: '#CCCCCC',
    padding: '1px 3px',
  },
  'BACKGROUND_#b3b3b3': {
    backgroundColor: '#B3B3B3',
    padding: '1px 3px',
  },
  'BACKGROUND_#f44e3b': {
    backgroundColor: '#F44E3B',
    padding: '1px 3px',
  },
  'BACKGROUND_#d33115': {
    backgroundColor: '#D33115',
    padding: '1px 3px',
  },
  'BACKGROUND_#9f0500': {
    backgroundColor: '#9F0500',
    padding: '1px 3px',
  },
  'BACKGROUND_#fe9200': {
    backgroundColor: '#FE9200',
    padding: '1px 3px',
  },
  'BACKGROUND_#e27300': {
    backgroundColor: '#E27300',
    padding: '1px 3px',
  },
  'BACKGROUND_#c45100': {
    backgroundColor: '#C45100',
    padding: '1px 3px',
  },
  'BACKGROUND_#fcdc00': {
    backgroundColor: '#FCDC00',
    padding: '1px 3px',
  },
  'BACKGROUND_#fcc400': {
    backgroundColor: '#FCC400',
    padding: '1px 3px',
  },
  'BACKGROUND_#fb9e00': {
    backgroundColor: '#FB9E00',
    padding: '1px 3px',
  },
  'BACKGROUND_#dbdf00': {
    backgroundColor: '#DBDF00',
    padding: '1px 3px',
  },
  'BACKGROUND_#b0bc00': {
    backgroundColor: '#B0BC00',
    padding: '1px 3px',
  },
  'BACKGROUND_#808900': {
    backgroundColor: '#808900',
    padding: '1px 3px',
  },
  'BACKGROUND_#a4dd00': {
    backgroundColor: '#A4DD00',
    padding: '1px 3px',
  },
  'BACKGROUND_#68bc00': {
    backgroundColor: '#68BC00',
    padding: '1px 3px',
  },
  'BACKGROUND_#194d33': {
    backgroundColor: '#194D33',
    padding: '1px 3px',
  },
  'BACKGROUND_#68ccca': {
    backgroundColor: '#68CCCA',
    padding: '1px 3px',
  },
  'BACKGROUND_#16a5a5': {
    backgroundColor: '#16A5A5',
    padding: '1px 3px',
  },
  'BACKGROUND_#0c797d': {
    backgroundColor: '#0C797D',
    padding: '1px 3px',
  },
  'BACKGROUND_#73d8ff': {
    backgroundColor: '#73D8FF',
    padding: '1px 3px',
  },
  'BACKGROUND_#009ce0': {
    backgroundColor: '#009CE0',
    padding: '1px 3px',
  },
  'BACKGROUND_#0062b1': {
    backgroundColor: '#0062B1',
    padding: '1px 3px',
  },
  'BACKGROUND_#aea1ff': {
    backgroundColor: '#AEA1FF',
    padding: '1px 3px',
  },
  'BACKGROUND_#7b64ff': {
    backgroundColor: '#7B64FF',
    padding: '1px 3px',
  },
  'BACKGROUND_#653294': {
    backgroundColor: '#653294',
    padding: '1px 3px',
  },
  'BACKGROUND_#fda1ff': {
    backgroundColor: '#FDA1FF',
    padding: '1px 3px',
  },
  'BACKGROUND_#fa28ff': {
    backgroundColor: '#FA28FF',
    padding: '1px 3px',
  },
  'BACKGROUND_#ab149e': {
    backgroundColor: '#AB149E',
    padding: '1px 3px',
  },
  'COLOR_#4d4d4d': {
    color: '#4D4D4D',
  },
  'COLOR_#333333': {
    color: '#333333',
  },
  'COLOR_#000000': {
    color: '#000000',
  },
  'COLOR_#999999': {
    color: '#999999',
  },
  'COLOR_#808080': {
    color: '#808080',
  },
  'COLOR_#666666': {
    color: '#666666',
  },
  'COLOR_#ffffff': {
    color: '#FFFFFF',
  },
  'COLOR_#cccccc': {
    color: '#CCCCCC',
  },
  'COLOR_#b3b3b3': {
    color: '#B3B3B3',
  },
  'COLOR_#f44e3b': {
    color: '#F44E3B',
  },
  'COLOR_#d33115': {
    color: '#D33115',
  },
  'COLOR_#9f0500': {
    color: '#9F0500',
  },
  'COLOR_#fe9200': {
    color: '#FE9200',
  },
  'COLOR_#e27300': {
    color: '#E27300',
  },
  'COLOR_#c45100': {
    color: '#C45100',
  },
  'COLOR_#fcdc00': {
    color: '#FCDC00',
  },
  'COLOR_#fcc400': {
    color: '#FCC400',
  },
  'COLOR_#fb9e00': {
    color: '#FB9E00',
  },
  'COLOR_#dbdf00': {
    color: '#DBDF00',
  },
  'COLOR_#b0bc00': {
    color: '#B0BC00',
  },
  'COLOR_#808900': {
    color: '#808900',
  },
  'COLOR_#a4dd00': {
    color: '#A4DD00',
  },
  'COLOR_#68bc00': {
    color: '#68BC00',
  },
  'COLOR_#194d33': {
    color: '#194D33',
  },
  'COLOR_#68ccca': {
    color: '#68CCCA',
  },
  'COLOR_#16a5a5': {
    color: '#16A5A5',
  },
  'COLOR_#0c797d': {
    color: '#0C797D',
  },
  'COLOR_#73d8ff': {
    color: '#73D8FF',
  },
  'COLOR_#009ce0': {
    color: '#009CE0',
  },
  'COLOR_#0062b1': {
    color: '#0062B1',
  },
  'COLOR_#aea1ff': {
    color: '#AEA1FF',
  },
  'COLOR_#7b64ff': {
    color: '#7B64FF',
  },
  'COLOR_#653294': {
    color: '#653294',
  },
  'COLOR_#fda1ff': {
    color: '#FDA1FF',
  },
  'COLOR_#fa28ff': {
    color: '#FA28FF',
  },
  'COLOR_#ab149e': {
    color: '#AB149E',
  },
};
