import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';
import _ from 'underscore';
import {
  Button,
  Modal,
  Header,
  Dropdown,
} from 'semantic-ui-react';
import moment from 'moment';
import CourseSchedule from '@/models/course_schedule';
import ModelTable from '../Shared/ModelTable';
import Course from '../../models/course';
import GroupCourseCard from './GroupCourseCard';
import CourseCreationModal from '../Shared/CourseCreationModal';

class GroupCourses extends Component {
  static propTypes = {
    groupId: PropTypes.string,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      groupId: props.groupId,
      selectedSchedule: { reviews_enabled: false },
      showCreateCourseModal: false,
    };

    this.table = React.createRef();
    this.addCourse = React.createRef();
  }

  componentDidMount() {
    this.setCourseIds();
  }

  @bind
  async setCourseIds() {
    const schedules = await CourseSchedule.objects().filtered({
      deleted: false,
      organizational_group_id: this.state.groupId,
    }).all();
    this.setState({
      courseIds: _.pluck(schedules, 'course_id'),
    });
  }

  @bind
  refreshTable() {
    this.table.current.updateData();
  }

  @bind
  confirmRemoveFromGroup(schedule) {
    this.setState({
      showRemovalConfirmation: true,
      selectedSchedule: schedule,
    });
  }

  @bind
  async removeFromGroup() {
    await CourseSchedule.objects().update(this.state.selectedSchedule.id, { deleted: true });
    this.table.current.updateData();
    this.setCourseIds();
    this.setState({
      showRemovalConfirmation: false,
    });
  }

  @bind
  confirmToggleReview(schedule) {
    this.setState({
      showConfirmToggleReview: true,
      selectedSchedule: schedule,
    });
  }

  @bind
  async toggleReviewStatus() {
    const schedule = this.state.selectedSchedule;
    await CourseSchedule.objects().update(schedule.id, {
      reviews_enabled: !schedule.reviews_enabled,
    });
    this.setState({ showConfirmToggleReview: false });
    this.table.current.updateData();
  }

  @bind
  async addToGroup() {
    const courses = this.addCourse.current.state.selectedModels;
    const actions = courses.map(((course) => {
      return CourseSchedule.objects().create({
        course_id: course.id,
        organizational_group_id: this.state.groupId,
      });
    }));
    await Promise.all(actions);
    this.addCourse.current.selectNone();
    this.addCourse.current.updateData();
    this.table.current.updateData();
    this.setCourseIds();
    this.setState({
      showAddCourseModal: false,
    });
  }

  render() {
    const {
      groupId, selectedSchedule, showAddCourseModal, showConfirmToggleReview, showRemovalConfirmation, courseIds,
    } = this.state;
    const columnData = [
      {
        key: ['this',
          (schedule) => {
            return (
              <GroupCourseCard
                schedule={schedule}
                refresh={this.refreshTable}
                confirmRemoveFromGroup={this.confirmRemoveFromGroup}
                confirmToggleReview={this.confirmToggleReview}
                history={this.props.history}
              />
            );
          },
        ],
      },
      {
        key: ['course__name',
          () => null],
        search: true,
      },
    ];
    return (
      <div>
        <div className="row">
          <Header as="h2">Group Courses:</Header>
          <Dropdown text="Add Course..." button className="green">
            <Dropdown.Menu>
              <Dropdown.Item
                text="Existing Course"
                onClick={() => this.setState({ showAddCourseModal: true })}
              />
              <Dropdown.Item
                text="New Course"
                onClick={() => this.setState({ showCreateCourseModal: true })}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <ModelTable
          ref={this.table}
          model={CourseSchedule}
          baseFilter={{ organizational_group_id: groupId, deleted: false }}
          join={[
            'course__name',
            'course__updated_at',
            'course__retake_option_available']}
          defaultSort={['course__name', 'asc']}
          columns={columnData}
        >
          <ModelTable.EmptyContainer>
            <div className="hickory-empty-state-container">
              <div>
                It does not look like you have any courses right now. Let&apos;s fix that!
                Courses are a great and powerful way to manage lessons, and creating them is simple.
                Click the &quot;Add Course...&quot; button above to get started.
              </div>
            </div>
          </ModelTable.EmptyContainer>
        </ModelTable>
        <CourseCreationModal
          open={this.state.showCreateCourseModal}
          onClose={() => {
            this.setState({ showCreateCourseModal: false });
          }}
          history={this.props.history}
          groupId={this.props.groupId}
        />

        <Modal open={showConfirmToggleReview}>
          <Modal.Header>Turn Reviews {selectedSchedule.reviews_enabled ? ' Off' : ' On'}?</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <p>
                Are you sure you wish to turn
                {selectedSchedule.reviews_enabled ? ' off' : ' on'}
                {' '}
                reviews for the
                course
                {' '}
                <b>{selectedSchedule.course__name || ''}</b>
                {' '}
                for this group?
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.setState({ showConfirmToggleReview: false });
              }}
            >
              Cancel
            </Button>
            <Button
              content={selectedSchedule.reviews_enabled ? 'Disable' : 'Enable'}
              color={selectedSchedule.reviews_enabled ? 'red' : 'green'}
              onClick={this.toggleReviewStatus}
            />
          </Modal.Actions>
        </Modal>
        <Modal open={showRemovalConfirmation}>
          <Modal.Header>Remove Course From Group?</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <p>
                Are you sure you wish to remove the course
                {' '}
                <b>{this.state.selectedName || ''}</b>
                {' '}
                from this group?
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.setState({ showRemovalConfirmation: false });
              }}
            >
              Cancel
            </Button>
            <Button
              content="Remove"
              color="red"
              onClick={() => {
                this.removeFromGroup();
              }}
            />
          </Modal.Actions>
        </Modal>
        <Modal open={showAddCourseModal}>
          <Modal.Header>Add Course to Group</Modal.Header>
          <Modal.Content>
            <ModelTable
              ref={this.addCourse}
              model={Course}
              defaultSort={['name', 'asc']}
              baseFilter={{ deleted: false, id__not: courseIds }}
              select
              columns={[
                {
                  name: 'Name',
                  key: 'name',
                  search: true,
                },
                {
                  name: 'Description',
                  key: 'description',
                },
                {
                  name: 'Last Edited',
                  key: ['updated_at', (c) => moment(c).format('MM/DD/YYYY')],
                },
              ]}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setState({ showAddCourseModal: false })}>Cancel</Button>
            <Button positive onClick={this.addToGroup}>Assign to Group</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
export default GroupCourses;
