import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  Header, Icon, Input, Label, Checkbox, Button, Modal,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import bind from 'bind-decorator';
import Lesson from '@/models/lesson';
import Upload from '../Shared/Upload';
import PDFViewer from '../Shared/PDFViewer';
import LessonPublishComponent from './LessonPublishComponent';

const s = {
  container: {
    display: 'flex',
    height: '100%',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    width: '440px',
    background: 'white',
    // eslint-disable-next-line max-len
    boxShadow: '0px 1.1899203062057495px 1.743371605873108px 0px #03060F03, 0px 2.859543800354004px 4.189564228057861px 0px #03060F04, 0px 5.384267330169678px 7.888577461242676px 0px #03060F05, 0px 9.604613304138184px 14.071874618530273px 0px #03060F06, 0px 17.964372634887695px 26.319894790649414px 0px #03060F07, 0px 43px 63px 0px #03060F0A',
    height: '100%',
    padding: '40px',
  },
  lesson: {
    background: '#E6E8ED',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'calc(100% - 440px)',
    overflow: 'auto',
  },
  backLink: {
    color: 'blue',
    fontSize: '15px',
    marginBottom: '20px',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  titleEdit: {
    width: '340px',
  },
  fileInfoSection: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
    marginBottom: '20px',
  },
  fileNameLabel: {
    paddingRight: '10px',
  },
  articleFooter: {
    backgroundColor: 'white',
    width: '1000px',
    height: '40px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  acknowledgementCheckbox: {
    paddingTop: '5px',
  },
};

class ArticleEditorContainer extends Component {
  static propTypes = {
    lesson: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      editingName: false,
      reUpload: false,
      lesson: this.props.lesson,
      displayCannotPublishError: false,
    };
  }

  @bind
  async updateDocument(files) {
    const { lesson } = this.state;
    if (!files) {
      // TODO: ???
    } else {
      const file = files[0];
      const url = await lesson.uploadPDF(file);
      const l = await Lesson.objects().update(this.state.lesson.id, {
        content_path: url,
        original_file_name: file.name,
      });
      this.setState({ lesson: l, reUpload: false });
    }
  }

  @bind
  async toggleRequireAcknowledgement() {
    const { lesson } = this.state;
    const l = await Lesson.objects().update(lesson.id, {
      require_acknowledgement: !lesson.require_acknowledgement,
    });
    this.setState({ lesson: l });
  }

  @bind
  async saveLessonName() {
    const { newLessonName, lesson } = this.state;
    if (newLessonName !== lesson.name) {
      const l = await Lesson.objects().update(this.state.lesson.id, {
        name: newLessonName,
      });
      this.setState({ lesson: l });
    }
    this.setState({ editingName: false });
  }

  render() {
    const { editingName, lesson } = this.state;

    return (
      <div style={s.container}>
        <div style={s.sidebar}>
          <Link to="/app/v2/lessons/" style={s.backLink}>
            <Label style={s.backLink}>Back to Lesson Overview</Label>
          </Link>
          {editingName ? (
            <div style={s.titleRow}>
              <Input
                defaultValue={lesson.name}
                size="big"
                style={s.titleEdit}
                onChange={(e, { value }) => this.setState({ newLessonName: value })}
              />
              <div style={s.column}>
                <Icon name="check" size="large" color="green" onClick={this.saveLessonName} link />
                <Icon
                  name="close"
                  size="large"
                  color="red"
                  onClick={() => this.setState({ editingName: false, newLessonName: null })}
                  link
                />
              </div>
            </div>
          ) : (
            <div style={s.titleRow}>
              <Header as="h1">{lesson.name}</Header>
              <Icon
                name="edit"
                size="large"
                color="grey"
                onClick={() => this.setState({ editingName: true })}
                link
              />
            </div>
          )}
          <LessonPublishComponent
            lesson={lesson}
            history={this.props.history}
            beforePublish={() => {
              if (lesson.content_path && !this.state.reUpload) {
                return true; // we're good - they've uploaded a file
              }
              this.setState({
                displayCannotPublishError: true,
              });
              return false;
            }}
          />
          { lesson.content_path && !this.state.reUpload ? (
            <React.Fragment>
              <div>
                File Information:
              </div>
              <div style={s.fileInfoSection}>
                <Icon name="file" size="large" />
                <span style={s.fileNameLabel}>
                  {lesson.original_file_name}
                </span>
                <Icon
                  name="trash alternate outline"
                  size="large"
                  color="grey"
                  onClick={() => this.setState({ reUpload: true })}
                  link
                />
              </div>
              <div>
                <Checkbox
                  label="Require positive affirmation for lesson completion"
                  onChange={this.toggleRequireAcknowledgement}
                  checked={lesson.require_acknowledgement}
                />
              </div>
            </React.Fragment>
          ) : (
            <Upload source="" maxSize={20} updateFile={this.updateDocument} description="a PDF" accept=".pdf" />
          )}
        </div>
        <div style={s.lesson}>
          <Header as="h2" color="grey" style={{ margin: '20px' }}>
            Learner Preview:
          </Header>
          { lesson.content_path ? (
            <React.Fragment>
              <PDFViewer url={lesson.content_path} height="600px" width="1000px" />
              <div style={s.articleFooter}>
                {lesson.require_acknowledgement ? (
                  <Checkbox
                    label="I acknowledge that I have read and understand the above document"
                    style={s.acknowledgementCheckbox}
                  />
                ) : (<div> </div>) }
                <Button color="green">
                  Complete Lesson
                </Button>
              </div>
            </React.Fragment>
          ) : null }
        </div>

        <Modal
          open={this.state.displayCannotPublishError}
          onClose={() => {
            this.setState({ displayCannotPublishError: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Cannot Publish</Modal.Header>
          <Modal.Content>
            This lesson cannot be published yet. Please first upload an article to complete this lesson.
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ displayCannotPublishError: false });
              }}
            >
              Okay
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default Radium(ArticleEditorContainer);
