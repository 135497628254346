import React, { Component } from 'react';
import Radium from 'radium';
import { Input, Button, Loader } from 'semantic-ui-react';
import bind from 'bind-decorator';
import { hickoryGreen } from '../../styles/palette';

let s = {};

class ResetPasswordContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: null,
      working: false,
      invalidUsernameOrPassword: false,
      done: false,
    };
  }

  @bind
  async doReset() {
    this.setState({ working: true });
    const response = await fetch('/api/v2/reset-password', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: this.state.username.toLowerCase(),
      }),
    });

    if (response.status === 200) {
      this.setState({ working: false, done: true });
    }
  }

  render() {
    if (this.state.done) {
      return (
        <div style={s.contents}>
          <div style={s.resetCard}>
            <div style={s.resetCardTitle}>Forgot your Password?</div>
            <p style={s.paragraph}>
              Thank you. <strong>If this email exists</strong> in our system, we will email you instructions for how to
              reset your password. Please allow a minute or two for it to arrive, and be sure to check your junk mail
              folder if necessary.
            </p>
            <p style={s.paragraph}>
              If the email does not arrive within 10 minutes, please verify that you typed your email address correctly.
            </p>

          </div>
          <div style={s.versionNumber}>
            version&nbsp;
            {window.APP_VERSION}
          </div>
        </div>
      );
    }
    return (
      <div style={s.contents}>
        <div style={s.resetCard}>
          <div style={s.resetCardTitle}>Forgot your Password?</div>
          <p style={s.paragraph}>
            We&apos;re sorry to hear that you&apos;re having trouble logging in - it happens to all
            of us sometimes.
          </p>
          <p style={s.paragraph}>
            To request a password reset, please start by telling us the email address associated
            with your Hickory account.
          </p>

          <div style={s.cardContents}>
            {this.state.invalidUsernameOrPassword ? (
              <div style={s.error}>
                Invalid email address or password, please try again or click &quot;Forgot
                Password&quot; below.
              </div>
            ) : null}
            <div>Email Address</div>
            <div>
              <Input
                style={s.input}
                onChange={(e, { value }) => this.setState({ username: value })}
              />
            </div>
            <div>
              {this.state.working ? (
                <Loader active={this.state.working} inline="centered" />
              ) : (
                <Button style={s.button} onClick={this.doReset}>
                  Request Reset
                </Button>
              )}
            </div>
          </div>
        </div>
        <div style={s.versionNumber}>
          version&nbsp;
          {window.APP_VERSION}
        </div>
      </div>
    );
  }
}

s = {
  contents: {
    backgroundColor: '#f5f5f5',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  resetCard: {
    backgroundColor: 'white',
    width: '36rem',
  },
  resetCardTitle: {
    fontSize: '2.2em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '20px',
  },
  cardContents: {
    margin: '20px',
  },
  input: {
    width: '100%',
    marginBottom: '20px',
    height: '4em',
  },
  button: {
    width: '100%',
    height: '4em',
    backgroundColor: hickoryGreen,
    color: 'white',
  },
  cardFooter: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '1.5rem',
  },
  footerAction: {
    color: '#918f90',
    marginTop: '10px',
    marginLeft: '40px',
    cursor: 'pointer',
  },
  error: {
    color: '#d24e4e',
    backgroundColor: 'rgba(210,78,78,.1)',
    fontSize: '1.1em',
    border: '1px solid #d24e4e',
    padding: '1.5rem 1.5rem 1.5rem 5rem',
    borderRadius: '4px',
    marginBottom: '20px',
  },
  versionNumber: {
    position: 'fixed',
    bottom: '5px',
    left: '5px',
  },
  paragraph: {
    margin: '20px',
  },
};

export default Radium(ResetPasswordContainer);
