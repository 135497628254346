const apiQueues = {};

class ApiCache {
  constructor() {
    this.apiQueue = [];

    setTimeout(() => this.makeApiCalls(), 100);
  }

  async makeApiCalls() {
    if (this.apiQueue.length > 0) {
      const nextRequest = this.apiQueue.shift();
      await nextRequest();
      setTimeout(() => this.makeApiCalls(), this.apiQueue.length > 0 ? 0 : 100);
    } else {
      setTimeout(() => this.makeApiCalls(), 100);
    }
  }

  async requestData(canHitCacheFunc, hitCacheFunc, hitApiFunc) {
    let done = false;
    let result = [];
    let error = null;
    this.apiQueue.push(async () => {
      result = null;
      try {
        if (canHitCacheFunc && canHitCacheFunc()) {
          result = await hitCacheFunc();
        } else {
          result = await hitApiFunc();
        }
      } catch (err) {
        error = err;
      }
      done = true;
    });

    const wait = new Promise((resolve) => {
      (async () => {
        while (!done) {
          // eslint-disable-next-line no-await-in-loop
          await new Promise((r) => { setTimeout(r, 100); });
        }
        resolve();
      })();
    });
    return wait.then(() => {
      if (error) {
        console.error(error);
        throw error;
      }
      return result;
    });
  }
}

const apiRequest = (namespace, hitApiFunc, canHitCacheFunc = null, hitCacheFunc = null) => {
  let queue = apiQueues[namespace];
  if (!queue) {
    apiQueues[namespace] = new ApiCache();
    queue = apiQueues[namespace];
  }
  return queue.requestData(canHitCacheFunc, hitCacheFunc, hitApiFunc);
};

export default apiRequest;
