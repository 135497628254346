import React, { Component } from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import {
  Loader, Input, Button, Label,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import bind from 'bind-decorator';
import {
  INVALID_ENROLLMENT_CODE,
  EXPIRED_ENROLLMENT_CODE,
} from '../../constants';
import { hickoryGreen } from '../../styles/palette';

const s = {
  container: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f5f5f5',
  },
  enrollmentCardTitle: {
    fontSize: '2.2em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '20px',
  },
  enrollmentCard: {
    backgroundColor: 'white',
    width: '36rem',
  },
  cardContents: {
    margin: '20px',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  button: {
    width: '100%',
    height: '4em',
    backgroundColor: hickoryGreen,
    color: 'white',
  },
  loginLink: {
    margin: '20px',
  },
  input: {
    width: '100%',
    marginBottom: '20px',
    height: '4em',
  },
  inputBox: {
    width: '100%',
  },
  labelBox: {
    width: '100%',
    fontWeight: 'bold',
    marginTop: '20px',
  },
};

class ResetContainer extends Component {
  static propTypes = {
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      verification_code: props.match.params.verification_code,
      loading: true,
    };
  }

  @bind
  async componentDidMount() {
    const response = await fetch(`/api/v2/reset-password/${this.state.verification_code}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });

    if (response.status === 200) {
      const status = await response.json();

      this.setState({ unexpected_error: false, code: status.code });
    } else {
      this.setState({ unexpected_error: true });
    }
    this.setState({ loading: false });
  }

  @bind
  async doResetPassword() {
    let error = false;
    let errorMessage = '';

    const { password } = this.state;
    const verifyPassword = this.state.verify_password;
    if (password !== verifyPassword) {
      error = true;
      errorMessage = 'The password and password confirmation do not match.';
    } else if (password === null || password.length < 8) {
      error = true;
      errorMessage = 'The password must be at least 8 characters in length.';
    }
    if (error) {
      this.setState({ error, error_message: errorMessage });
      return;
    }

    const response = await fetch('/api/v2/reset-password/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        verification_code: this.state.verification_code,
        password,
        do_reset: true,
      }),
    });

    if (response.status === 200) {
      this.setState({ success: true });
    } else {
      this.setState({ unexpected_error: true });
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={s.container}>
          <Loader active inline="centered" />
        </div>
      );
    }

    if (this.state.success) {
      return (
        <div style={s.container}>
          <div style={s.enrollmentCard}>
            <div style={s.enrollmentCardTitle}>Congratulations!</div>
            <div style={s.cardContents}>
              Congrats - you&apos;ve successfully reset your password! Use the &quot;login&quot;
              link below to log into Hickory using your new password.
              <Link style={s.loginLink} to="/app/v2/login">
                Login
              </Link>
            </div>
          </div>
        </div>
      );
    }

    let errorMessage = null;
    if (this.state.unexpected_error) {
      errorMessage = "We're sorry, but we're unable to process this request at this time. Please try again in a ";
      errorMessage += 'couple of minutes.';
    } else if (this.state.code === INVALID_ENROLLMENT_CODE) {
      errorMessage = 'There was a problem with your password reset link. Please submit a new password reset request.';
    } else if (this.state.code === EXPIRED_ENROLLMENT_CODE) {
      errorMessage = 'The password reset link you clicked has expired. Password reset links are only good for 24';
      errorMessage += ' hours from receipt.';
    }

    if (errorMessage) {
      return (
        <div style={s.container}>
          <div style={s.enrollmentCard}>
            <div style={s.enrollmentCardTitle}>There was a problem...</div>
            <div style={s.cardContents}>{errorMessage}</div>
          </div>
        </div>
      );
    }
    return (
      <div style={s.container}>
        <div style={s.enrollmentCard}>
          <div style={s.enrollmentCardTitle}>Forgot your Password?</div>
          <div style={s.cardContents}>
            Thank you for verifying your email address. All that remains is for you to choose a new
            password.
            {this.state.error ? (
              <Label color="red" style={s.labelBox}>
                {this.state.error_message}
              </Label>
            ) : null}
            <div style={s.labelBox}>Password</div>
            <div style={s.inputBox}>
              <Input
                type="password"
                style={s.input}
                onChange={(e, { value }) => this.setState({ password: value })}
              />
            </div>
            <div style={s.labelBox}>Confirm Password</div>
            <div style={s.inputBox}>
              <Input
                type="password"
                style={s.input}
                onChange={(e, { value }) => this.setState({ verify_password: value })}
              />
            </div>
            <Button style={s.button} onClick={this.doResetPassword}>
              Reset Password
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Radium(ResetContainer);
