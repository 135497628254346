import _ from 'underscore';
import React, { Component } from 'react';
import Radium from 'radium';
import bind from 'bind-decorator';
import moment from 'moment';
import ls from 'local-storage';
import {
  Loader,
  Dropdown,
  Input,
  Divider,
  Modal,
  Button,
  Checkbox,
  Grid,
  Icon,
} from 'semantic-ui-react';
import md5 from 'md5';
import User from '@/models/user';
import InvoiceHistory from '@/models/invoice_history';
import Organization from '@/models/organization';
import RoleAssignment from '@/models/role_assignment';
import {
  MANUAL_INVOICE, INVOICE, CREDIT_CARD, Features,
} from '../../constants';
import ModelTable from '../Shared/ModelTable';
import FancyCheckbox from '../Shared/FancyCheckbox';
import Upload from '../Shared/Upload';
import Header from '../Header/Header';

let s = {};

class OrganizationSettingsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      owners: [],
      showSCORMModal: false,
    };
    this.saveHandlers = {};
  }

  @bind
  async componentDidMount() {
    const assignments = await RoleAssignment.objects()
      .filtered({ role_name: 'Owner', deleted: false })
      .all();
    const userIds = _.pluck(assignments, 'user_id');
    const users = await User.objects()
      .filtered({ deleted: false, id: userIds })
      .all();

    const me = await User.me();
    const organization = await Organization.objects().get(me.organization_id);

    this.setState({
      loading: false,
      owners: users,
      organization,
      user: me,
      primaryColor: organization ? organization.primary_color || '' : '',
      secondaryColor: organization ? organization.secondary_color || '' : '',
    });
  }

  @bind
  async uploadImage(files) {
    let { organization } = this.state;
    if (!files) {
      organization = await Organization.objects().update(organization.id, { logo: null });
    } else {
      const url = await organization.uploadImage(files[0]);
      organization = await Organization.objects().update(organization.id, { logo: url });
    }
    this.setState({
      organization,
    });
  }

  validateHexCode(value) {
    return /^#[0-9A-F]{6}$|^$/i.test(value);
  }

  @bind
  async handleSettingChange(key, value) {
    const props = {};
    props[key] = value;
    const org = this.state.organization;

    this.setState({
      [`saving_${key}`]: true,
      [`error_${key}`]: false,
      organization: _.extend(org, props),
    });

    if (!this.saveHandlers[key]) {
      this.saveHandlers[key] = _.debounce(this.doSave, 500);
    }

    this.saveHandlers[key](key, props);
  }

  @bind
  doSave(key, props) {
    const org = this.state.organization;
    const state = {};

    Organization.objects()
      .update(org.id, props)
      .then(() => {
        state[`saving_${key}`] = false;
        state[`error_${key}`] = false;
        this.setState({
          [`saving_${key}`]: false,
          [`error_${key}`]: false,
        });
      })
      .catch(() => {
        this.setState({
          [`saving_${key}`]: false,
          [`error_${key}`]: true,
        });
      });
  }

  @bind
  async setupPayment(tier) {
    const response = await fetch(
      `/api/v2/organizations/${this.state.organization.id}/setup_billing/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ls('token.access')}`,
        },
        body: JSON.stringify({
          tier,
          host: `${window.location.protocol}//${window.location.host}`,
        }),
      },
    );

    if (response.status === 200) {
      const res = await response.json();
      window.location.href = res.redirect_url;
    } else {
      // TODO
      console.log('ERROR!');
    }
    // TODO
  }

  render() {
    const {
      organization, primaryColor, secondaryColor, user,
    } = this.state;
    if (this.state.loading) {
      return <Loader active={this.state.loading} inline="centered" />;
    }

    const slackEnabled = !!organization.slack_team_id;
    // eslint-disable-next-line max-len
    const slackUrl = `https://slack.com/oauth/v2/authorize?client_id=${window.SLACK_CLIENT_ID}&scope=incoming-webhook,chat:write,app_mentions:read`;

    const owners = _.map(this.state.owners, (owner) => {
      const hash = md5(owner.email.toLowerCase());
      return {
        key: owner.id,
        text: `${owner.first_name} ${owner.last_name}`,
        value: owner.id,
        image: { avatar: true, src: `https://www.gravatar.com/avatar/${hash}.jpg?d=identicon` },
      };
    });

    let days = null;
    const isTrial = this.state.organization.subscription_tier === 1 || this.state.organization.subscription_tier === 5;
    const tierNames = {
      1: 'Trial',
      2: 'Essentials',
      3: 'Premium',
      4: 'Enterprise',
      5: 'For Startups',
    };

    if (isTrial) {
      const trialExpires = moment(this.state.organization.trial_expires);
      const now = moment();
      days = Math.round(moment.duration(trialExpires - now).asDays());
    }

    const nextInvoiceDate = moment()
      .add(1, 'months')
      .startOf('month')
      .format('MMMM D, YYYY');

    const invoiceColumnData = [
      {
        name: 'Sent',
        key: ['invoiced_at', (invoicedAt) => moment(invoicedAt).format('MMM D, YY')],
      },
      {
        name: '',
        key: 'description',
      },
      {
        name: 'Users',
        key: [
          'user_count',
          (userCount) => (
            <p>
              {userCount}
              &nbsp;
              <Icon name="users" />
            </p>
          ),
        ],
      },
      {
        name: 'Total',
        key: ['amount_due', (amountDue) => `$${amountDue.toLocaleString()}`],
      },
      {
        name: '',
        key: [
          'pdf_link',
          (pdfLink) => (
            <a target="_blank" rel="noreferrer" href={pdfLink}>
              PDF&nbsp;
              <Icon name="cloud download" />
            </a>
          ),
        ],
      },
    ];

    return (
      <div style={s.body}>
        <div style={s.container}>
          <div style={{ width: '100%' }}>
            <div style={s.header}>
              <h2 style={s.title}>Company Settings</h2>
            </div>
            <div style={s.contents}>
              <div style={s.row}>
                <div style={s.leftBox}>
                  <h1 style={s.h1}>Company Details</h1>
                  <div style={s.details}>
                    Global company wide settings for support and branding.
                  </div>
                </div>

                <div style={s.rightBox}>
                  <h2 style={s.h2}>Company Name</h2>
                  <Input
                    style={s.input}
                    value={organization.name}
                    loading={this.state.saving_name}
                    error={this.state.error_name}
                    onChange={(e) => {
                      this.handleSettingChange('name', e.target.value);
                    }}
                  />
                  <div style={s.details}>
                    This is displayed in the primary navigation and other places throughout the app.
                  </div>

                  <h2 style={s.h2}>Primary Contact</h2>
                  <Dropdown
                    style={s.input}
                    placeholder="Select primary contact"
                    fluid
                    selection
                    value={organization.primary_contact}
                    options={owners}
                    onChange={(e, { value }) => {
                      this.handleSettingChange('primary_contact', value);
                    }}
                  />
                  <div style={s.details}>
                    In case of support we may reach out directly to the primary contact.
                  </div>

                  <h2 style={s.h2}>Timezone</h2>
                  <Dropdown
                    style={s.input}
                    placeholder="Timezone"
                    fluid
                    selection
                    search
                    value={organization.default_timezone}
                    options={User.SUPPORTED_TIMEZONES.map((tz) => ({
                      key: tz,
                      value: tz,
                      text: tz,
                    }))}
                    onChange={(e, { value }) => {
                      this.handleSettingChange('default_timezone', value);
                    }}
                  />
                  <div style={s.details}>
                    This is the default timezone setting for your organization. Newly created users
                    will default to this timezone setting.
                  </div>
                </div>
              </div>

              {user.waffle_flags.includes(Features.WHITE_LABELING) ? (
                <React.Fragment>
                  <Divider />

                  <div style={s.row}>
                    <div style={s.leftBox}>
                      <h1 style={s.h1}>App Branding</h1>
                      <div style={s.details}>
                        Add your company logo and colors here to replace Hickory branding in your
                        app.
                      </div>
                    </div>

                    <div style={s.rightBox}>
                      <Upload
                        maxSize={20}
                        source={organization.logo}
                        updateFile={this.uploadImage}
                        description="an Image"
                      />
                      <div style={s.details}>
                        PNG, JPG, and GIF supported. Recommended dimensions: 400px wide by 160px
                        tall.
                      </div>
                      <Grid columns="equal" style={{ margin: '10px 0' }}>
                        <Grid.Row stretched>
                          <Grid.Column>
                            <h2 style={s.h2}>Primary Brand Color HEX</h2>
                            <Input
                              placeholder="#FFFFFF"
                              value={primaryColor}
                              icon={(
                                <Icon
                                  name="delete"
                                  link
                                  onClick={() => {
                                    this.setState({ primaryColor: '' });
                                    this.handleSettingChange('primary_color', '');
                                  }}
                                />
                              )}
                              error={!this.validateHexCode(primaryColor)}
                              onChange={(e, { value }) => {
                                this.setState({
                                  primaryColor: value,
                                });
                                if (this.validateHexCode(value)) {
                                  this.handleSettingChange('primary_color', value);
                                }
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <h2 style={s.h2}>Text Brand Color HEX</h2>
                            <Input
                              placeholder="#FFFFFF"
                              value={secondaryColor}
                              icon={(
                                <Icon
                                  name="delete"
                                  link
                                  onClick={() => {
                                    this.setState({ secondaryColor: '' });
                                    this.handleSettingChange('secondary_color', '');
                                  }}
                                />
                              )}
                              error={!this.validateHexCode(secondaryColor)}
                              onChange={(e, { value }) => {
                                this.setState({
                                  secondaryColor: value,
                                });
                                if (this.validateHexCode(value)) {
                                  this.handleSettingChange('secondary_color', value);
                                }
                              }}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                      <div style={s.headerPreviewContainer}>
                        <h2 style={s.h2}>Navigation Preview</h2>
                        <div style={s.headerPreview}>
                          <Header organization={this.state.organization} dataLoaded preview />
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}

              <Divider />

              <div style={s.row}>
                <div style={s.leftBox}>
                  <h1 style={s.h1}>Billing Details</h1>
                  <div style={s.details}>Review and manage how your Hickory invoice is paid.</div>
                </div>

                <div style={s.rightBox}>
                  <div style={s.checkboxContainer}>
                    <FancyCheckbox
                      small
                      header="Payment Method:"
                      text="Credit Card"
                      subtext="Your credit card on file will be automatically billed each month."
                      checked={organization.payment_method === CREDIT_CARD}
                      style={{ width: '50%', height: '110px', borderRight: 'solid 1px #D8DBE2' }}
                    />
                    <FancyCheckbox
                      small
                      header="Payment Method:"
                      text="Invoice Based"
                      checked={
                        organization.payment_method === INVOICE
                        || organization.payment_method === MANUAL_INVOICE
                      }
                      subtext="An invoice will be issued directly to you for payment."
                      width="50%"
                      height="193px"
                      style={{ width: '50%', height: '110px', borderLeft: 'solid 1px #D8DBE2' }}
                    />
                  </div>

                  <div style={s.contactUsRow}>
                    Email us at&nbsp;
                    <a href="mailto:support@hickorytraining.com">support@hickorytraining.com</a>
                    &nbsp;to change your billing method
                  </div>

                  {organization.payment_method === CREDIT_CARD ? (
                    <div>
                      {isTrial ? (
                        <div>
                          Your trial ends in {days} days. Please choose a tier below to ensure
                          uninterrupted service.
                        </div>
                      ) : (
                        <div>
                          Your current tier:{' '}
                          <strong>{tierNames[this.state.organization.subscription_tier]}</strong>
                        </div>
                      )}
                      <br />
                      {isTrial ? (
                        <div>
                          <Button
                            disabled={this.state.organization.subscription_tier === 2}
                            onClick={() => {
                              this.setupPayment(2);
                            }}
                          >
                            Choose Essentials
                          </Button>
                          <Button
                            disabled={this.state.organization.subscription_tier === 3}
                            onClick={() => {
                              this.setupPayment(3);
                            }}
                          >
                            Choose Premium
                          </Button>
                          <Button
                            disabled={this.state.organization.subscription_tier === 4}
                            onClick={() => {
                              this.setState({
                                showContactUsForEnterpriseModal: true,
                              });
                            }}
                          >
                            Choose Enterprise
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Button
                            onClick={() => {
                              this.setupPayment();
                            }}
                          >
                            Manage Payment
                          </Button>
                        </div>
                      )}
                      <br />
                    </div>
                  ) : null}
                  {organization.payment_method === INVOICE ? (
                    <div>
                      <div>
                        <p>
                          Your next invoice will be issued to you on{' '}
                          <strong> {nextInvoiceDate} </strong>.
                        </p>
                      </div>
                      <div style={{ marginTop: '20px' }}>
                        Email Invoice to:
                        <Input
                          style={s.input}
                          value={organization.invoice_email_addresses.join('; ')}
                          onChange={(e) => {
                            const rawValue = e.target.value
                              .replaceAll(';', ' ')
                              .replaceAll('  ', ' ');
                            this.handleSettingChange(
                              'invoice_email_addresses',
                              rawValue.split(' '),
                            );
                          }}
                        />
                        <div>
                          <em>
                            NOTE: The primary account owner will receive a copy of the invoice
                            directly from Stripe &nbsp;(our payment processor provider).
                          </em>
                        </div>
                        <div />
                        <div style={{ marginTop: '20px' }}>
                          <h1 style={s.h1}>Invoice History</h1>
                          <ModelTable
                            title=""
                            model={InvoiceHistory}
                            columns={invoiceColumnData}
                            defaultSort={['created_at', 'dsc']}
                            limit={12}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {organization.payment_method === MANUAL_INVOICE ? (
                    <div>
                      <div>
                        <p>
                          Invoices for your organization are currently processed outside of our web
                          app. If you&apos;d like to upgrade to our advanced, web-based, invoicing
                          system please contact support.
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>

              <Divider />

              <div style={s.row}>
                <div style={s.leftBox}>
                  <h1 style={s.h1}>Integrations</h1>
                  <div style={s.details}>Manage integrations for your organization.</div>
                </div>

                <div style={s.rightBox}>
                  <div style={{ display: 'flex' }}>
                    <a
                      href="https://chrome.google.com/webstore/detail/hickory/fhmmnaiblknlojkbkdhhcoialcibgedp"
                      target="_blank"
                      rel="noreferrer"
                      style={s.integrationIcon}
                    >
                      <img src="/static/v2/frontend/Google-Chrome-icon.png" style={s.appIcon} />
                      <strong style={s.integrationName}>Chrome</strong>
                    </a>

                    { slackEnabled ? (
                      <a
                        href={`https://www.slack.com/apps/${window.SLACK_APP_ID}`}
                        target="_blank"
                        rel="noreferrer"
                        style={s.integrationIcon}
                      >
                        <img src="/static/v2/frontend/Slack_Mark_Web.png" style={s.appIcon} />
                        <strong style={s.integrationName}>Slack</strong>
                      </a>
                    ) : (
                      <a
                        href={slackUrl}
                        target="_blank"
                        rel="noreferrer"
                        style={s.integrationIcon}
                      >
                        <img src="/static/v2/frontend/Slack_Mark_Web.png" style={s.appIcon} />
                        <strong style={s.integrationName}>Slack</strong>
                      </a>
                    ) }
                  </div>

                  {user.waffle_flags.includes(Features.SCORM) ? (
                    <div style={s.checkboxContainer}>
                      <FancyCheckbox
                        text="SCORM Disabled"
                        small
                        checked={!organization.scorm_enabled}
                        subtext="SCORM lessons cannot be imported or used within Hickory (most secure)."
                        style={{
                          width: '50%',
                          height: '110px',
                          borderRight: 'solid 1px #D8DBE2',
                        }}
                        onClick={() => {
                          this.handleSettingChange('scorm_enabled', false);
                        }}
                      />
                      <FancyCheckbox
                        text="SCORM Enabled"
                        warning
                        small
                        checked={organization.scorm_enabled}
                        subtext="Allow SCORM lessons to be uploaded into Hickory."
                        width="50%"
                        height="193px"
                        style={{ width: '50%', height: '110px', borderLeft: 'solid 1px #D8DBE2' }}
                        onClick={() => {
                          this.setState({ showSCORMModal: true, riskAccepted: false });
                        }}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          open={this.state.showSCORMModal}
          onClose={() => {
            this.setState({ showSCORMModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Security Warning</Modal.Header>
          <Modal.Content>
            <p>
              By enabling SCORM support you are allowing anyone with Writer, Admin, or Owner
              permission to upload SCORM content into Hickory. SCORM lessons, by design, contain
              JavaScript that has not been written or reviewed by Hickory.
            </p>
            <p>
              This permits <strong>malicious code</strong> to be ran, putting your organization at
              risk.
            </p>
            <p>
              <strong>Only</strong> enable SCORM support if you trust all content authors and
              understand this risk.
            </p>
            <br />
            <Checkbox
              label="I understand this risk"
              checked={this.state.riskAccepted}
              onClick={(e, { checked }) => {
                this.setState({
                  riskAccepted: checked,
                });
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showSCORMModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={!this.state.riskAccepted}
              className="red"
              onClick={() => {
                this.handleSettingChange('scorm_enabled', true);
                this.setState({ showSCORMModal: false });
              }}
            >
              Proceed
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showContactUsForEnterpriseModal}
          onClose={() => {
            this.setState({ showContactUsForEnterpriseModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Contact Us</Modal.Header>
          <Modal.Content>
            <p>
              Thank you for your interest in our Enterprise package. To better help tailor our
              fullest offering of Hickory to your unique needs we request that you reach out to your
              account manager (or email us at&nbsp;
              <a href="mailto:sales@hickorytraining.com">sales@hickorytraining.com</a>) to discuss
              how we can best help your organization.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                this.setState({ showContactUsForEnterpriseModal: false });
              }}
            >
              OK
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

s = {
  body: {},
  container: {
    marginLeft: '20%',
    marginRight: '20%',
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'row',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
    marginBottom: '20px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '37px',
    color: '#27272B;',
  },
  contents: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    height: '100%',
    marginTop: '40px',
    borderRadius: '5px',
    paddingTop: '40px',
    paddingLeft: '20px',
    paddingRight: '20px',
    // eslint-disable-next-line max-len
    boxShadow: '0px 11.28px 23.3955px rgba(0, 0, 0, 0.0215656), 0px 6.0308px 12.5083px rgba(0, 0, 0, 0.0178832), 0px 3.38082px 7.01207px rgba(0, 0, 0, 0.015), 0px 1.79553px 3.72406px rgba(0, 0, 0, 0.0121168), 0px 0.747159px 1.54966px rgba(0, 0, 0, 0.00843437)',
  },
  h1: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '15px',
  },
  h2: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '15px',
    color: '#4D4E58',
  },
  leftBox: {
    width: '25%',
    paddingRight: '5px',
  },
  rightBox: {
    paddingLeft: '5px',
    width: '75%',
  },
  details: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '15px',
    color: '#7E7F8F',
    marginTop: '15px',
  },
  input: {
    width: '100%',
    background: '#FFFFFF',
    border: '1px solid #BABEC7',
    boxSizing: 'border-box',
    borderRadius: '4px',
  },
  checkboxContainer: {
    border: 'solid 2px #D8DBE2',
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
    marginBottom: '20px',
  },
  contactUsRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
    marginBottom: '20px',
    background: '#F2F3F4',
    padding: '10px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '15px',
    lineHeight: '18px',
  },
  headerPreviewContainer: {
    background: '#FAFBFD',
    border: '1px solid #BABEC7',
    boxSizing: 'border-box',
    borderRadius: '2px',
  },
  headerPreview: {
    // eslint-disable-next-line max-len
    boxShadow: '0px 11.28px 23.3955px rgba(0, 0, 0, 0.0215656), 0px 6.0308px 12.5083px rgba(0, 0, 0, 0.0178832), 0px 3.38082px 7.01207px rgba(0, 0, 0, 0.015), 0px 1.79553px 3.72406px rgba(0, 0, 0, 0.0121168), 0px 0.747159px 1.54966px rgba(0, 0, 0, 0.00843437)',
    margin: '30px 0 40px 40px',
    overflow: 'hidden',
  },
  appIcon: {
    width: '96px',
    height: '96px',
  },
  integrationIcon: {
    display: 'flex',
    flexDirection: 'column',
    width: '96px',
    cursor: 'pointer',
  },
  integrationName: {
    textAlign: 'center',
  },
};

export default Radium(OrganizationSettingsContainer);
