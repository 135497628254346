/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import {
  Dropdown, Form, Header, Icon, Input, Segment,
} from 'semantic-ui-react';
import bind from 'bind-decorator';
import _ from 'underscore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { v4 as uuid } from 'uuid';
import TextareaAutosize from 'react-textarea-autosize';
import { FREE_RESPONSE, MULTIPLE_CHOICE } from '../../constants';
import AnswerArea from './AnswerArea';

const s = {
  cardTile: {
    width: '600px',
    minHeight: '650px',
  },
  bubble: {
    padding: '8px 12px',
    color: 'white',
    marginTop: '10px',
    outline: '0px solid transparent',
    minHeight: '37px',
    minWidth: '40px',
    maxWidth: '65%',
  },
  editBubble: {
    padding: '8px 12px',
    color: 'white',
    marginTop: '10px',
    outline: '0px solid transparent',
    minWidth: '40px',
    maxWidth: '65%',
    resize: 'none',
  },
  left: {
    backgroundColor: '#4D4E58',
    borderRadius: '20px 20px 20px 0',
    marginRight: 'auto',
  },
  right: {
    backgroundColor: '#4575f1',
    borderRadius: '20px 20px 0',
    marginLeft: 'auto',
  },
  chatContainer: {
    backgroundColor: '#F2F3F4',
    borderRadius: '4px',
    paddingBottom: '10px',
  },
  chatRow: {
    display: 'flex',
  },
  persona: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  instructionsContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '10px',
  },
  instructions: {
    margin: '0',
  },
  addContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
  },
  addButton: {
    cursor: 'pointer',
    color: '#767676',
  },
};

const QUESTION_TYPES = [
  {
    key: MULTIPLE_CHOICE,
    value: MULTIPLE_CHOICE,
    text: 'Multiple Choice',
  },
  {
    key: FREE_RESPONSE,
    value: FREE_RESPONSE,
    text: 'Free Response',
  },
  // {
  //   key: TRUE_FALSE,
  //   value: TRUE_FALSE,
  //   text: 'True/False',
  // },
];

class ScenarioContent extends Component {
  static propTypes = {
    card: PropTypes.object,
    uploadImage: PropTypes.func,
    saveCard: PropTypes.func,
    takeSnapshot: PropTypes.func,
    historyPosition: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      adding: null,
      right: props.card.content.persona.right,
      left: props.card.content.persona.left,
      question: props.card.content.question,
      scenario: props.card.content.scenario,
      editing: null,
    };
  }

  @bind
  componentDidUpdate() {
    // const { editing } = this.state;
    // if (editing) document.getElementById(editing).focus();
  }

  @bind
  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.historyPosition !== this.props.historyPosition) {
      this.setState({
        question: this.props.card.content.question,
        left: this.props.card.content.persona.left,
        right: this.props.card.content.persona.right,
        scenario: this.props.card.content.scenario,
      });
    } return null;
  }

  @bind
  // eslint-disable-next-line react/no-unused-class-component-methods
  SpeechBubble(props) {
    const { side, index, text } = props;
    const { editing } = this.state;
    return (
      <Draggable draggableId={uuid()} index={index}>
        {(provided, snapshot) => {
          const iconStyle = { margin: 'auto 10px' };
          if (snapshot.isDragging) {
            Object.assign(iconStyle, { visibility: 'hidden' });
          }
          return (
            <div ref={provided.innerRef} {...provided.draggableProps}>
              <div style={s.chatRow}>
                {side === 'left' && (
                <Icon
                  name="trash alternate outline"
                  color="grey"
                  style={iconStyle}
                  onClick={() => this.updateChat(index, null, null)}
                  link
                />
                )}
                {editing === index ? (
                  <TextareaAutosize
                    style={{ ...s.editBubble, ...{ left: s.left, right: s.right }[side] }}
                    {...provided.dragHandleProps}
                    autoFocus
                    rows={1}
                    value={text}
                    onChange={(e) => this.updateChat(index, e.target.value, side)}
                    onBlur={() => {
                      this.setState({
                        editing: null,
                      });
                    }}
                  />
                ) : (
                  <div
                    id={index}
                    style={{ ...s.bubble, ...{ left: s.left, right: s.right }[side] }}
                    {...provided.dragHandleProps}
                    onDoubleClick={() => {
                      this.setState({
                        editing: index,
                      });
                    }}
                  >
                    {text}
                  </div>
                )}
                {side === 'right' && (
                <Icon
                  name="trash alternate outline"
                  color="grey"
                  style={iconStyle}
                  onClick={() => this.updateChat(index, null, null)}
                  link
                />
                )}

              </div>
            </div>
          );
        }}
      </Draggable>
    );
  }

  @bind
  updateChat(index, text, position) {
    const data = this.state.scenario;
    const newChat = { type: 'chat', content: text, position };
    if (text === null) data.splice(index, 1);
    else data[index] = newChat;
    this.setState({ scenario: data, adding: null });

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      const { content } = this.props.card;
      this.props.takeSnapshot();

      if (text === null) {
        content.scenario.splice(index, 1);
      } else if (index >= content.scenario.length) {
        content.scenario.push(newChat);
      } else {
        content.scenario = content.scenario.map((c, i) => (i === index ? newChat : c));
      }
      this.props.saveCard({ content });
    }, 300);
  }

  @bind
  updateQuestion(question) {
    this.setState({ question });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.takeSnapshot();
      const { content } = this.props.card;
      content.question = question;
      this.props.saveCard({ content });
    }, 300);
  }

  @bind
  async handleOnDragEnd(result) {
    if (result.source.index !== result.destination.index) {
      const { scenario } = this.state;
      const { card } = this.props;
      const { content } = card;
      this.props.takeSnapshot();
      const order = scenario;
      let chat = order[result.source.index];
      if (!chat) {
        chat = { type: 'chat', content: '', position: this.state.adding };
        this.setState({ adding: null });
      }
      order.splice(result.source.index, 1);
      order.splice(result.destination.index, 0, chat);
      content.scenario = order;
      this.props.saveCard({ content });
    }
  }

  @bind
  saveName(side, name) {
    this.setState({ [side]: name });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      const { content } = this.props.card;
      this.props.takeSnapshot();
      content.persona[side] = name;
      this.props.saveCard({ content });
    }, 300);
  }

  @bind
  handleChoicesChange(e, { value }) {
    const { card } = this.props;
    const content = card.content;
    this.props.takeSnapshot();
    content.choices = value;
    this.props.saveCard({ content });
  }

  render() {
    const { card, uploadImage, saveCard } = this.props;
    const { adding, question, scenario } = this.state;
    const content = card.content;
    const answerCount = content.answers.length;
    const { choices } = content;
    const countOptions = _.range(2, answerCount + 1)
      .map((num) => ({ key: num, value: num, text: `Show ${num} Choices` }));
    const displayScenario = scenario.map((chat, index) => {
      const side = chat.position;
      // eslint-disable-next-line react/no-array-index-key
      return <this.SpeechBubble key={index} side={side} index={index} text={chat.content} />;
    });
    if (adding) {
      displayScenario.push(<this.SpeechBubble
        key={displayScenario.length}
        side={adding}
        index={displayScenario.length}
        text=""
      />);
    }
    return (
      <div style={s.cardTile}>
        <Segment style={{ padding: '5px' }}>
          <Dropdown
            options={QUESTION_TYPES}
            defaultValue={card ? card.presentation_type : null}
            placeholder="QUESTION TYPE"
            onChange={this.handleTypeChange}
            button
          />
          {card.presentation_type === MULTIPLE_CHOICE
          && (
          <Dropdown
            options={countOptions}
            defaultValue={card ? Math.min(choices, answerCount) : null}
            placeholder="Show ? Choices"
            onChange={this.handleChoicesChange}
            button
          />
          )}
        </Segment>
        <div style={s.persona}>
          <Input
            icon="pencil"
            iconPosition="left"
            transparent
            value={this.state.left}
            placeholder="Person 1"
            size="huge"
            onChange={(e, { value }) => this.saveName('left', value)}
          />
          <div className="ui transparent icon input huge">
            <input
              placeholder="Person 2"
              type="text"
              value={this.state.right}
              style={{ textAlign: 'right' }}
              onChange={(e) => this.saveName('right', e.target.value)}
            />
            <Icon name="pencil" />
          </div>
        </div>
        <div style={s.chatContainer}>
          <div style={s.instructionsContainer}>
            <Header as="h3" color="grey" style={s.instructions}>DRAG TO REORDER</Header>
            <Header as="h3" color="grey" style={s.instructions}>DOUBLE-CLICK TO EDIT</Header>
          </div>
          <DragDropContext onDragEnd={this.handleOnDragEnd}>
            <Droppable droppableId="cards">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {displayScenario}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <div style={s.addContainer}>
            <div style={s.addButton} onClick={() => this.setState({ adding: 'left', editing: scenario.length })}>
              <Icon name="circle add" />
              ADD TEXT BUBBLE
            </div>
            <div style={s.addButton} onClick={() => this.setState({ adding: 'right', editing: scenario.length })}>
              ADD TEXT BUBBLE&nbsp;
              <Icon name="circle add" />
            </div>
          </div>
        </div>
        <Form size="massive" style={{ margin: '20px 0' }}>
          <Form.Field required>
            <label>Question:</label>
            <TextareaAutosize
              rows={1}
              value={question || 'How would you respond?'}
              onChange={(e) => this.updateQuestion(e.target.value)}
            />
          </Form.Field>
        </Form>
        <AnswerArea
          content={content}
          uploadImage={uploadImage}
          saveCard={saveCard}
          type={card.presentation_type}
          takeSnapshot={this.props.takeSnapshot}
        />
      </div>
    );
  }
}

export default Radium(ScenarioContent);
