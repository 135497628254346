/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import Radium from 'radium';
import { CompactPicker } from 'react-color';

import bind from 'bind-decorator';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { fontMediumColor } from '../../styles/palette';

const s = {
  colorContainer: {
    position: 'absolute',
    top: '100%',
    width: '100%',
  },
  colorPicker: {
    display: 'flex',
  },
  colorPositioning: {
    top: '6px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    backgroundColor: 'white',
    border: '2px solid #eee',
    marginLeft: '-8rem',
    zIndex: '2',
    maxWidth: '250px',
    minWidth: '250px',
  },
  arrow: {
    display: 'inline-block',
    top: '-8px',
    left: '50%',
    height: '0',
    width: '0',
    position: 'absolute',
    pointerEvents: 'none',
    borderWidth: '8px',
    borderStyle: 'solid',
    borderColor: 'transparent transparent #fff',
    WebkitTransition: 'border-color 0.2s ease-in-out',
    transition: 'border-color 0.2s ease-in-out',
    marginLeft: '-1rem',
  },
  colorHeader: {
    color: 'black',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  colorTitle: {
    fontSize: '1.5em',
    margin: '.2em',
  },
  colorCancel: {
    cursor: 'pointer',
    margin: '.2em',
    fontSize: '.8em',
    ':hover': {
      color: fontMediumColor,
    },
  },
};
class ComposerColorPicker extends Component {
  static propTypes = {
    toggleStyleFn: PropTypes.func,
    toggleStyle: PropTypes.string,
    toggleModal: PropTypes.func,
    title: PropTypes.string,
    color: PropTypes.string,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.colorPicker = React.createRef();
  }

  @bind
  handleChangeComplete(color) {
    // function passed to color picker component and fired when a color in the color picker is clicked on
    const colorHex = color.hex;
    this.props.toggleStyleFn(colorHex, this.props.toggleStyle);
    // close modal after color selection. This can be taken out to allow user to click through colors before choosing
    this.props.toggleModal();
  }

  render() {
    return (
      <div style={s.colorContainer} ref={this.colorPicker}>
        <span style={s.arrow} />
        <div style={s.colorPositioning}>
          <div style={s.colorHeader}>
            <h1 style={s.colorHeader}>{this.props.title}</h1>
            <span style={s.colorCancel} onClick={this.props.toggleModal}>
              <Icon name="close" size="large" />
            </span>
          </div>
          <div style={s.colorPicker}>
            <CompactPicker color={this.props.color} onChangeComplete={this.handleChangeComplete} />
          </div>
        </div>
      </div>
    );
  }
}

export default Radium(ComposerColorPicker);
