import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import CardContainer from './CardContainer';

const s = {
  cardContainer: {
    flexShrink: '1',
    backgroundColor: '#FFFFFF',
    minHeight: '120px',
  },
  cardTitle: {
    color: '#7E7F8F',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '21px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardStat: {
    position: 'static',
    left: '0%',
    right: '50%',
    top: '0%',
    bottom: '0%',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '50px',
    lineHeight: '59px',
    fontFeatureSettings: "'pnum' on, 'lnum' on",
    color: '#4D4E58',
    flex: 'none',
    order: '0',
    flexGrow: '0',
    margin: '20px 6px',
  },
  cardStatPosDelta: {
    borderRadius: '4px',
    backgroundColor: '#C8F5DA',
    flexShrink: '1',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '21px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#2BB674',
    alignItems: 'center',
    padding: '10px',
    marginLeft: '5px',
  },
  cardStatPosDeltaArrow: {
    backgroundImage:
    // eslint-disable-next-line max-len
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAANCAYAAACdKY9CAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACNSURBVHgBlY8xDoJQEER3VvAc1FRcwdrKsxhr7S04g4egNt7Aytoj0EKAgYWEBj5ZXvPzJ292s5AAaXF9rOVRqADgvq/Q8mQvD8xENJ9z2SAtbpmie3P6loOeqEcmddiGv+XqkX/n5zd4Q0gm8RLpPvBOXhztkeeCVx4Le2RDAeZe2YjiqrnUxzjxyEYPsFlbwa5vf0IAAAAASUVORK5CYII=)',
    height: '13px',
    width: '12px',
  },
  cardStatNegDelta: {
    borderRadius: '4px',
    backgroundColor: '#FFDBDB',
    flexShrink: '1',
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '21px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#FF4D4D',
    alignItems: 'center',
    padding: '10px',
    marginLeft: '5px',
  },
  cardStatFlatDelta: {
    display: 'none',
  },
  cardStatNegDeltaArrow: {
    backgroundImage:
    // eslint-disable-next-line max-len
      'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAANCAYAAACdKY9CAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACbSURBVHgBlZCxDYQwDEVt3xVXMgIj3AhciyiywbECEyAmYBQ6WlagZAwGQAk/oUGERCGSre+v/2LJZJTKTFl+KfEJbdtAIlMqJKR1c6g0iG1zQQDO0frH4zhHgScQn4cUiK9GCIJfw8/F+8EGEHTD+RAif/TW2xDaBN1DFUHAg5hXMiZ/BcNVVSD0gVxQCpVZ/x1ZMN2ZMaC7M3cg1kMoHRJsdAAAAABJRU5ErkJggg==)',
    height: '13px',
    width: '12px',
  },
  cardTimeDescription: {
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#A6A9B4',
    margin: '-20px 6px',
  },
  cardFooter: {
    display: 'none',
  },
  actionButtonContainer: {
    position: 'absolute',
    height: '36px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    background: '#FAFBFD',
    borderRadius: '0px 0px 4px 4px',
    borderTop: 'solid 1px #E6E8ED',
  },
  actionButton: {
    position: 'absolute',
    height: '16px',
    left: '17px',
    right: '36px',
    bottom: '10px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#4E7BEF',
    cursor: 'pointer',
  },
};

class SmallCard extends Component {
  static propTypes = {
    title: PropTypes.string,
    stat: PropTypes.number,
    prevStat: PropTypes.number,
    timeDescription: PropTypes.string,
    percent: PropTypes.bool,
    actionText: PropTypes.string,
    onActionClick: PropTypes.func,
  };

  static defaultProps = {
    percent: false,
  };

  trendStyle(expression, delta) {
    let trend = 'Flat';
    if (delta > 0) {
      trend = 'Pos';
    } else if (delta < 0) {
      trend = 'Neg';
    }
    return s[expression.replace('*', trend)];
  }

  render() {
    const {
      title,
      stat,
      prevStat,
      timeDescription,
      actionText,
      onActionClick,
    } = this.props;

    let statDelta = 0;
    if (prevStat > 0 && stat > 0) {
      statDelta = stat / prevStat - 1.0;
    }

    return (
      <CardContainer title={title} loaded={stat >= 0} height="170px" width="240px">
        <div style={s.cardContainer}>
          <div style={s.cardContent}>
            <p style={s.cardStat}>{`${stat.toLocaleString()}${this.props.percent ? '%' : ''}`}</p>

            <div style={this.trendStyle('cardStat*Delta', statDelta)}>
              <p style={this.trendStyle('cardStat*DeltaArrow', statDelta)} />
              &nbsp;
              {Math.ceil(Math.abs(statDelta) * 100.0)}
              %
            </div>
          </div>
          <p style={s.cardTimeDescription}>{timeDescription}</p>
        </div>
        { actionText ? (
          <div style={s.actionButtonContainer}>
            <a style={s.actionButton} onClick={onActionClick}>
              { actionText }
            </a>
          </div>
        ) : null }
      </CardContainer>
    );
  }
}

export default Radium(SmallCard);
