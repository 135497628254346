import React, { Component } from 'react';
import Radium from 'radium';
import moment from 'moment';
import PropTypes from 'prop-types';
import UserLessonStatusSummary from '@/models/user_lesson_status_summary';
import User from '@/models/user';
import SmallCard from '../Shared/SmallCard';
import PieChartCard from '../Shared/PieChartCard';
import LineChartCard from '../Shared/LineChartCard';
import CardContainer from '../Shared/CardContainer';
import StatusCount from '../../models/status_count';

let s = {};

class DashboardContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      summary: {
        week_completed_count: -1,
        prevweek_completed_count: -1,
        yest_completed_count: -1,
        open_assignments: -1,
        late_assignments: -1,
        yest_late_assignments: -1,
        user_count: -1,
        yest_user_count: -1,
      },
    };
  }

  async componentDidMount() {
    const now = moment();
    const startDate = moment()
      .add(-29, 'days')
      .format('YYYY-MM-DD');
    const me = await User.me();
    this.setState({ me });

    Promise.all([
      UserLessonStatusSummary.objects().all(),
      User.objects()
        .filtered({ is_active: true, deleted: false })
        .all(),
    ]).then(([summaries, users]) => {
      const yestUserCount = users.filter((user) => moment(user.date_joined).diff(now, 'hours') <= 24).length;
      const summary = summaries.reduce(
        (m, record) => {
          const memo = m;
          memo.week_completed_count += record.week_completed_count;
          memo.prevweek_completed_count += record.prevweek_completed_count;
          memo.yest_completed_count += record.yest_completed_count;
          memo.open_assignments += record.open_assignments;
          memo.late_assignments += record.late_assignments;
          memo.yest_late_assignments += record.yest_late_assignments;
          return memo;
        },
        {
          week_completed_count: 0,
          prevweek_completed_count: 0,
          yest_completed_count: 0,
          open_assignments: 0,
          late_assignments: 0,
          yest_late_assignments: 0,
          user_count: users.length,
          yest_user_count: yestUserCount,
        },
      );

      const userSummaries = summaries;
      const userSummary = userSummaries.reduce(
        (m, record) => {
          const memo = m;
          if (record.late_assignments > 0) {
            memo.late += 1;
          } else if (record.open_assignments > 0) {
            memo.inprogress += 1;
          } else {
            memo.complete += 1;
          }
          return memo;
        },
        { complete: 0, inprogress: 0, late: 0 },
      );

      if (userSummaries.length < users.length) {
        // there might be newly added users who have not yet
        // received assignments... they are still caught up
        userSummary.complete += users.length - userSummaries.length;
      }

      const isManager = me.roles.indexOf('Manager') >= 0;

      this.setState({
        summary,
        userSummary,
        isManager,
      });
    });
    StatusCount.objects()
      .filtered({ _start_date: startDate, _end_date: now.format('YYYY-MM-DD'), _type: 'completed' })
      .all()
      .then((completedAssignments) => {
        const assignmentsList = completedAssignments.map((a) => Object.values(a));
        this.setState({
          completedAssignments: assignmentsList,
          startDate,
        });
      });
  }

  render() {
    const learnerBreakdownChartData = this.state.userSummary
      ? [
        {
          name: 'All Assignments Complete',
          total: this.state.userSummary.complete,
          color: '#2BB674',
        },
        {
          name: 'In Progress',
          total: this.state.userSummary.inprogress,
          color: '#FF9416',
        },
        {
          name: 'Late',
          total: this.state.userSummary.late,
          color: '#FF4D4D',
        },
      ]
      : null;

    return (
      <div style={s.dashboardContainer}>
        <div>
          <h1 style={s.dashboardTitle}>Your Dashboard</h1>
          <div style={s.dashboardTop}>
            <SmallCard
              title="Completed Assignments"
              stat={this.state.summary.week_completed_count}
              prevStat={this.state.summary.prevweek_completed_count}
              timeDescription="Last 7 Days"
              actionText="View Assignments"
              onActionClick={() => {
                this.props.history.push(`/app/v2/assignments/`);
              }}
            />
            <SmallCard
              title="Open Assignments"
              stat={this.state.summary.open_assignments}
              prevStat={
                this.state.summary.open_assignments - this.state.summary.yest_completed_count
              }
              timeDescription="Now"
              actionText="View Assignments"
              onActionClick={() => {
                this.props.history.push(`/app/v2/assignments/`);
              }}
            />
            <SmallCard
              title="Late Assignments"
              stat={this.state.summary.late_assignments}
              prevStat={this.state.summary.yest_late_assignments}
              timeDescription="Now"
              actionText="View Assignments"
              onActionClick={() => {
                this.props.history.push(`/app/v2/assignments/`);
              }}
            />
            <SmallCard
              title="Learners"
              stat={this.state.summary.user_count}
              prevStat={this.state.summary.yest_user_count}
              timeDescription="Now"
              actionText={this.state.me?.roles.includes('Admin') && "View Learners"}
              onActionClick={() => {
                this.props.history.push(`/app/v2/people/`);
              }}
            />
          </div>
          <div>
            <h1 style={s.subTitle}>Assignment Activity</h1>
            <LineChartCard
              chartData={[
                {
                  name: 'Completed',
                  data: this.state.completedAssignments,
                  color: '#2BB674',
                  fillColor: '#2BB6740D',
                },
              ]}
              startDate={this.state.startDate}
            />
          </div>
          {this.state.isManager ? null : (
            <div style={s.dashboardBottom}>
              <CardContainer title="Learner Breakdown" loaded={!!learnerBreakdownChartData}>
                <PieChartCard
                  chartData={learnerBreakdownChartData}
                  subjectDescription="Learners"
                  timeDescription="Now"
                />
              </CardContainer>
            </div>
          )}
        </div>
      </div>
    );
  }
}

s = {
  dashboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '15%',
    paddingRight: '15%',
    paddingTop: '40px',
    backgroundColor: '#FAFBFD',
  },
  dashboardTitle: {
    fontFamily: 'Roboto',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '38px',
    letterSpacing: '0em',
    textAlign: 'left',
    color: '#27272B',
    marginLeft: '10px',
    marginBottom: '50px',
  },
  dashboardTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
  dashboardBottom: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  subTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '125%',
    color: '#27272B',
    marginLeft: '10px',
  },
};

export default Radium(DashboardContainer);
