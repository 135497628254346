import React, { Component } from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import {
  Loader, Input, Button,
} from 'semantic-ui-react';
import bind from 'bind-decorator';
import User from '@/models/user';
import { hickoryGreen } from '../../styles/palette';

const s = {
  container: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f5f5f5',
    flexDirection: 'column',
  },
  appLogo: {
    height: '90px',
    backgroundColor: 'white',
  },
  syncIcon: {
    height: '60px',
    paddingLeft: '20p',
    paddingRight: '20px',
    paddingBottom: '20px',
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '50px',
  },
  authorizationBody: {
    backgroundColor: 'white',
    width: '100%',
    height: '80%',
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },

  loginOuter: {},
  loginInner: {},

  instructions: {
    marginTop: '20px',
  },
  input: {
    width: '100%',
    marginBottom: '20px',
    height: '4em',
  },
  button: {
    width: '100%',
    height: '4em',
    backgroundColor: hickoryGreen,
    color: 'white',
  },
  error: {
    color: '#d24e4e',
    backgroundColor: 'rgba(210,78,78,.1)',
    fontSize: '1.1em',
    border: '1px solid #d24e4e',
    padding: '1.5rem 1.5rem 1.5rem 5rem',
    borderRadius: '4px',
    marginBottom: '20px',
  },
  authorizationFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: '40px',
  },
  cardFooter: {
    marginTop: '20px',
  },
};

class SlackRegistrationContainer extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      success: false,
      error: false,
    };
  }

  @bind
  async componentDidMount() {
    let user;
    try {
      user = await User.me();
    } catch {
      // do nothing
      user = null;
    }

    this.setState({ loading: false, user });
  }

  @bind
  async doLogin() {
    let user = null;
    this.setState({ loading: true });
    const response = await fetch('/api/v2/login/?force=true', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: this.state.username.toLowerCase(),
        password: this.state.password,
      }),
    });

    if (response.status === 200) {
      const tokenContainer = await response.json();
      localStorage.setItem('token.access', tokenContainer.access);
      localStorage.setItem('token.refresh', tokenContainer.refresh);

      user = await User.me();
    } else {
      this.setState({ invalidUsernameOrPassword: true });
    }
    this.setState({ loading: false, user });
  }

  @bind
  async doAuthorize() {
    const token = new URLSearchParams(this.props.location.search).get('t');
    const { user } = this.state;

    const result = user.authorizeSlack(token);
    if (result) {
      this.setState({ error: false, success: true });
    } else {
      this.setState({ error: true, success: false });
    }
  }

  @bind
  renderHeader() {
    return (
      <div style={s.header}>
        <div>
          <img
            src="/static/v2/frontend/Slack_Mark_Web.png"
            style={s.appLogo}
          />
          <img src="/static/v2/frontend/sync.png" style={s.syncItem} />
          <img src="/static/v2/frontend/Hickory_H_webclip.png" style={s.appLogo} />
        </div>
        <p>
          <strong>Slack</strong> would like to access your <strong>Hickory</strong> account.
        </p>
      </div>
    );
  }

  render() {
    const {
      user, loading, success, error,
    } = this.state;

    if (loading) {
      return (
        <div style={s.container}>
          {this.renderHeader()}
          <Loader active inline="centered" />
        </div>
      );
    }

    if (success) {
      return (
        <div style={s.container}>
          {this.renderHeader()}
          <div style={s.authorizationBody}>
            <div>
              <p style={s.instructions}>
                You have successfully authorized <strong>Slack</strong> to access your{' '}
                <strong>Hickory</strong> account. You may now close this window.
              </p>
            </div>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div style={s.container}>
          {this.renderHeader()}
          <div style={s.authorizationBody}>
            <div>
              <p style={s.instructions}>
                There was an error processing your request. Your authorization link may be expired.
              </p>
            </div>
          </div>
        </div>
      );
    }

    if (!user) {
      return (
        <div style={s.container}>
          {this.renderHeader()}
          <div style={s.authorizationBody}>
            <div>
              <p style={s.instructions}>
                To get started, first login and then we can request your authorization for{' '}
                <strong>Slack</strong>
              </p>
              <div style={s.loginOuter}>
                {this.state.invalidUsernameOrPassword ? (
                  <div style={s.error}>
                    Invalid email address or password, please try again or click &quot;Forgot
                    Password&quot; below.
                  </div>
                ) : null}
                <div>Email Address</div>
                <div>
                  <Input
                    style={s.input}
                    onChange={(e, { value }) => this.setState({ username: value })}
                  />
                </div>
                <div>Password</div>
                <div>
                  <Input
                    type="password"
                    style={s.input}
                    onChange={(e, { value }) => this.setState({ password: value })}
                  />
                </div>
                <div>
                  {this.state.working ? (
                    <Loader active={this.state.working} inline="centered" />
                  ) : (
                    <Button style={s.button} onClick={this.doLogin}>
                      Continue
                    </Button>
                  )}
                </div>
                <div style={s.cardFooter}>
                  Using SSO? Login using your company&apos;s SSO link first, and then refresh this page.
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div style={s.container}>
        {this.renderHeader()}
        <div style={s.authorizationBody}>
          <div>
            <p style={s.instructions}>
              This will allow <strong>Slack</strong> to:
            </p>
            <div style={s.authorizationOuter}>
              <ul>
                <li>Notify you of Hickory reviews.</li>
                <li>Inform you when a new Hickory lesson is scheduled for you.</li>
                <li>Remind you when a lesson or review is late or about to be late.</li>
              </ul>

              <div style={s.authorizationFooter}>
                <Button onClick={this.doNotAuthorize}>Cancel</Button>

                <Button positive onClick={this.doAuthorize}>
                  Authorize
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Radium(SlackRegistrationContainer);
