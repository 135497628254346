import { v4 as uuid } from 'uuid';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Header, Button, Modal,
} from 'semantic-ui-react';
import bind from 'bind-decorator';
import _ from 'underscore';
import LessonSchedule from '@/models/lesson_schedule';
import Lesson from '@/models/lesson';
import ModelTable from '../Shared/ModelTable';
import GroupLessonCard from './GroupLessonCard';
import {
  ARTICLE, VIDEO, CARD, SCORM,
} from '../../constants';

class GroupLessons extends Component {
  static propTypes = {
    groupId: PropTypes.string,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      modalTableKey: uuid(),
      groupId: props.groupId,
      showAddLessonModal: false,
      lessonIds: [],
      selectedLessons: [],
      showConfirmToggleReview: false,
      showRemovalConfirmation: false,
      selectedSchedule: {},
    };
  }

  async componentDidMount() {
    const schedules = await LessonSchedule.objects().filtered({
      organizational_group_id: this.state.groupId, deleted: false,
    }).all();
    const lessonIds = _.pluck(schedules, 'lesson_id');
    this.setState({ lessonIds });
  }

  @bind
  async addToGroup() {
    const { selectedLessons, lessonIds } = this.state;
    const newIds = [];
    const actions = selectedLessons.map(((lesson) => {
      newIds.push(lesson.id);
      return LessonSchedule.objects().create({
        lesson_id: lesson.id,
        organizational_group_id: this.state.groupId,
      });
    }));
    await Promise.all(actions);
    this.setState({
      showAddLessonModal: false,
      modalTableKey: uuid(),
      lessonIds: lessonIds.concat(newIds),
    });
  }

  @bind
  confirmToggleReview(schedule) {
    this.setState({
      showConfirmToggleReview: true,
      selectedSchedule: schedule,
    });
  }

  @bind
  confirmRemoveFromGroup(schedule) {
    this.setState({
      showRemovalConfirmation: true,
      selectedSchedule: schedule,
    });
  }

  @bind
  async removeFromGroup() {
    await LessonSchedule.objects().update(this.state.selectedSchedule.id, { deleted: true });
    this.setState({
      showRemovalConfirmation: false,
      modalTableKey: uuid(),
    });
  }

  @bind
  async toggleReviewStatus() {
    const schedule = this.state.selectedSchedule;
    await LessonSchedule.objects().update(schedule.id, {
      reviews_enabled: !schedule.reviews_enabled,
    });
    this.setState({
      showConfirmToggleReview: false,
      modalTableKey: uuid(),
    });
  }

  render() {
    const { groupId, lessonIds } = this.state;
    const columnData = [
      {
        key: [
          'this',
          (schedule) => {
            let continuousReviewsAvailable = true;
            let retakeOptionAvailable = true;
            const lessonType = schedule.lesson__lesson_type;
            if (lessonType !== CARD) {
              continuousReviewsAvailable = false;
            }
            if (lessonType === ARTICLE || lessonType === VIDEO) {
              retakeOptionAvailable = false;
            }
            schedule.lesson__continuous_reviews_available = continuousReviewsAvailable;
            schedule.lesson__retake_option_available = retakeOptionAvailable;
            return (
              <GroupLessonCard
                schedule={schedule}
                refresh={() => this.setState({ modalTableKey: uuid() })}
                confirmRemoveFromGroup={this.confirmRemoveFromGroup}
                confirmToggleReview={this.confirmToggleReview}
                history={this.props.history}
              />
            );
          },
        ],
      },
      {
        key: ['lesson__name', () => null],
        search: true,
      },
    ];
    return (
      <div>
        <div className="row">
          <Header as="h2">Group Lessons:</Header>
          <Button positive onClick={() => this.setState({ showAddLessonModal: true })}>
            Add Lesson
          </Button>
        </div>
        <ModelTable
          key={this.state.modalTableKey}
          model={LessonSchedule}
          baseFilter={{ organizational_group_id: groupId, deleted: false }}
          join={[
            'lesson__name',
            'lesson__updated_at',
            'lesson__lesson_type',
          ]}
          defaultSort={['lesson__name', 'asc']}
          columns={columnData}
        />

        <Modal
          open={this.state.showRemovalConfirmation}
          onClose={() => {
            this.setState({ showRemovalConfirmation: false });
          }}
        >
          <Modal.Header>Remove Lesson From Group?</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <p>
                Are you sure you wish to remove the lesson
                {' '}
                <b>{this.state.selectedName || ''}</b>
                {' '}
                from this group?
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.setState({ showRemovalConfirmation: false });
              }}
            >
              Cancel
            </Button>
            <Button
              content="Remove"
              color="red"
              onClick={() => {
                this.removeFromGroup();
              }}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showConfirmToggleReview}
          onClose={() => {
            this.setState({ showConfirmToggleReview: false });
          }}
        >
          <Modal.Header>Turn Reviews {this.state.selectedSchedule.reviews_enabled ? ' Off' : ' On'}?</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <p>
                Are you sure you wish to turn
                {this.state.selectedSchedule.reviews_enabled ? ' off' : ' on'}
                {' '}
                reviews for the
                lesson
                {' '}
                <b>{this.state.selectedSchedule.lesson__name || ''}</b>
                {' '}
                for this group?
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.setState({ showConfirmToggleReview: false });
              }}
            >
              Cancel
            </Button>
            <Button
              content={this.state.selectedSchedule.reviews_enabled ? 'Disable' : 'Enable'}
              color={this.state.selectedSchedule.reviews_enabled ? 'red' : 'green'}
              onClick={this.toggleReviewStatus}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showAddLessonModal}
          onClose={() => {
            this.setState({ showAddLessonModal: false });
          }}
        >
          <Modal.Header>Add Lesson to Group</Modal.Header>
          <Modal.Content>
            <ModelTable
              model={Lesson}
              defaultSort={['name', 'asc']}
              baseFilter={{ deleted: false, id__not: lessonIds, published: true }}
              selectionChanged={(selectedLessons) => this.setState({ selectedLessons })}
              select
              columns={[
                {
                  name: 'Lesson Name',
                  search: true,
                  key: 'name',
                },
                {
                  name: 'Type',
                  key: [
                    'lesson_type',
                    (lessonType) => {
                      if (lessonType === CARD) {
                        return 'Card';
                      }
                      if (lessonType === SCORM) {
                        return 'Scorm';
                      }
                      if (lessonType === ARTICLE) {
                        return 'Article';
                      }
                      if (lessonType === VIDEO) {
                        return 'Video';
                      }
                      return 'Other';
                    },
                  ],
                },
                {
                  name: 'Activities',
                  key: [
                    'lesson_type',
                    'card_count',
                    (lessonType, cardCount) => {
                      return lessonType === CARD ? cardCount : 1;
                    },
                  ],
                },
              ]}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setState({ showAddLessonModal: false })}>Cancel</Button>
            <Button positive onClick={this.addToGroup}>
              Assign to Group
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
export default GroupLessons;
