import color from 'color';

/* Updated Colors */
export const hickoryGreen = '#2BB674';
export const grey100 = '#FAFBFD';
export const grey200 = '#F1F3F5';
export const grey700 = '#7E7F8F';
export const blue = '#4E7BEF';
export const blue700 = '#1E48B4';
export const green = '#2FA76E';

/* Global Colors */
export const hickoryBlue = '#1f7ce8';
export const hickoryBlueLighten = color(hickoryBlue).lighten(0.2);
export const hickoryRed = '#Ee4136';
export const hickoryLightBlue = '#E3f0ff';
export const hickoryLighterBlue = '#F0f7ff';

export const colorBluishGreyLight = '#676f79';
export const colorBluishGreyMain = '#414c58';
export const colorBluishGreyDark = '#363e48';
export const colorBluishGreyDarkest = '#1a2533';

export const colorGreyExtremelyLight = '#Fbfbfb';
export const colorGreyVeryLight = '#F6f6f6';
export const colorGreyLight = '#F5f5f5';
export const colorGreyMediumLight = '#F1f1f1';
export const colorGreyMedium = '#E6e5e5';
export const colorGreyMediumDark = '#D8d7d7';
export const colorGreyDark = '#918f90';
export const colorGreyVeryDark = '#858485';
export const colorGreyExtremelyDark = '#4a4a4a';

export const colorOrange = '#Fa8e4a';

export const formSubmitColor = hickoryBlue;
export const formErrorColor = hickoryRed;

export const btnBackgroundColor = '#D8e9fd';
export const btnBackgroundColorHover = '#C0dafa';

/* Generic Colors */
export const appLightestBackgroundBodyColor = colorGreyExtremelyDark;
export const appLightBackgroundBodyColor = colorGreyLight;
export const appMediumBackgroundBodyColor = color(colorGreyVeryDark).lighten(0.3);
export const appDarkBackgroundBodyColor = colorGreyVeryDark;
export const fontDarkColor = colorGreyExtremelyDark;
export const fontMediumColor = '#858c94'; /* Dark Grey */
export const fontLightColor = colorGreyDark;
export const fontLightestColor = colorGreyMediumDark;

export const primaryLessonColor = hickoryBlue;
export const primaryReviewColor = hickoryGreen;
export const primaryKnowledgebaseColor = '#9c00ff'; /* Purple */
export const primaryCompletedColor = colorGreyMediumDark;

export const primaryNavColor = hickoryGreen;
export const secondayNavColor = '#Fff'; /* White */

/* Dashboard Specific Colors */
export const colorDashboardBase = '#Ffffff';
export const colorDashboardDarkGray = '#2d313b';
export const colorDashboardGray = '#Adb3c5';
export const colorDashboardLightGray = '#E8eaef';
export const colorDashboardLightestGray = '#F5f6fa';
export const colorDashboardGreen = hickoryGreen;
export const colorDashboardBlue = hickoryBlue;

export const activityNavColor = hickoryBlue;
export const activityBackgroundColor = colorBluishGreyMain;
export const activityBackgroundColorLight = colorBluishGreyLight;

export const difficultyEasyColor = hickoryGreen;
export const difficultyMediumColor = '#Ffd552'; /* Yellow */
export const difficultyHardColor = hickoryRed;

export const answerCorrectColor = hickoryGreen;
export const answerIncorrectColor = hickoryRed;
export const lightBorderColor = colorGreyMedium;

/* Content Builder Specific Colors */
export const itemSelectedColor = hickoryBlue;
export const itemDeleteColor = hickoryRed;
export const linkedSetColor = '#Ff5900'; /* Orange */
export const instructionColor = hickoryGreen;
export const questionColor = colorOrange;
export const scenarioColor = hickoryBlue;
