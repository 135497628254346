import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'semantic-ui-react';

class ModeToggle extends Component {
  static propTypes = {
    off: PropTypes.string,
    on: PropTypes.string,
    onToggle: PropTypes.func,
    control: PropTypes.bool,
  };

  render() {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {this.props.off}
        <Checkbox
          onClick={this.props.onToggle}
          checked={this.props.control}
          toggle
          style={{ margin: '5px' }}
        />
        {this.props.on}
      </div>
    );
  }
}

export default ModeToggle;
