import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import {
  Input, Divider, Header, Dropdown, Pagination,
} from 'semantic-ui-react';
import pluralize from 'pluralize';

let s = {};

class LessonControlBar extends Component {
  static propTypes = {
    lessonCount: PropTypes.number,
    setSearch: PropTypes.func,
    setPage: PropTypes.func,
    page: PropTypes.number,
    perPage: PropTypes.number,
    setPerPage: PropTypes.func,
  };

  @bind
  search(e, { value }) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.setSearch(value);
    }, 300);
  }

  @bind
  handlePageChange(e, { activePage }) {
    this.props.setPage(activePage);
  }

  @bind
  handlePerPageChange(e, { value }) {
    this.props.setPerPage(value);
  }

  render() {
    const totalPages = Math.ceil(
      this.props.lessonCount / (this.props.perPage || this.props.lessonCount),
    );
    const perPageOptions = [
      { text: '50', value: 50 },
      { text: '100', value: 100 },
      { text: '200', value: 200 },
      { text: '500', value: 500 },
    ];
    const containerStyle = [s.container];

    return (
      <div>
        <Input
          style={s.search}
          icon="search"
          iconPosition="left"
          placeholder="Search Lessons..."
          onChange={this.search}
          size="huge"
          transparent
          fluid
        />
        <Divider fitted style={{ margin: '0em 1em' }} />
        <div style={containerStyle}>
          <div style={[s.container, { minWidth: '200px', justifyContent: 'start', padding: 0 }]}>
            <Dropdown
              options={perPageOptions}
              compact
              inline
              value={this.props.perPage}
              onChange={this.handlePerPageChange}
            />
            per page
          </div>
          <Header as="h3" style={{ marginTop: 0, marginBottom: 10 }}>
            {`${this.props.lessonCount} ${pluralize('LESSON', this.props.lessonCount)} FOUND`}
          </Header>
          <Pagination
            activePage={this.props.page}
            totalPages={totalPages}
            onPageChange={this.handlePageChange}
            size="small"
          />
        </div>
      </div>
    );
  }
}

s = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  search: {
    margin: '0em 1em .5em 1em',
  },
};
export default Radium(LessonControlBar);
