import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';
import _ from 'underscore';
import moment from 'moment';
import {
  Button,
  Dropdown, Modal, Table,
} from 'semantic-ui-react';
import User from '@/models/user';
import ModelTable from '../Shared/ModelTable';
import RoleAssignment from '../../models/role_assignment';
import CreateUserModal from '../Shared/CreateUserModal';
import ExistingUserModal from '../Shared/ExistingUserModal';

const STATUS = {
  not_invited: 'Not Invited',
  invited: 'Invited',
  invite_expired: 'Invite Expired',
  inactive: 'Inactive',
  active: 'Active',
  invite_failed: 'Invite Failed',
  invalid: 'Invalid',
};

class GroupManagers extends Component {
  static propTypes = {
    groupId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      groupId: props.groupId,
      action: '',
      selected: [],
    };

    this.table = React.createRef();
  }

  async componentDidMount() {
    const managers = await RoleAssignment.objects().filtered({
      organizational_group_id: this.state.groupId,
      role_name: 'Manager',
      deleted: false,
    }).all();
    this.setState({
      managerIds: _.pluck(managers, 'user_id'),
    });
  }

  @bind
  async refresh() {
    const managers = await RoleAssignment.objects().filtered({
      organizational_group_id: this.state.groupId,
      role_name: 'Manager',
      deleted: false,
    }).all();
    this.setState({
      showExistingUserModal: false,
      showNewUserModal: false,
      managerIds: _.pluck(managers, 'user_id'),
      action: '',
      selected: [],
    }, this.table.current.updateData);
    this.table.current.selectNone();
  }

  @bind
  async addAsManager(users) {
    const actions = users.map((user) => user.updateRole('Manager', this.state.groupId));
    await Promise.all(actions);
    this.refresh();
  }

  @bind
  async createManager(firstName, lastName, email, preferredTimezone) {
    const user = await User.objects().create({
      first_name: firstName, last_name: lastName, email, preferred_timezone: preferredTimezone,
    });
    this.addAsManager([user]);
  }

  @bind
  async performAction() {
    const { action, selected, groupId } = this.state;
    let users = [];
    let assignments = [];
    let actions = [];
    switch (action) {
      case 'remove':
        assignments = await RoleAssignment.objects().filtered({
          user_id: _.pluck(selected, 'user_id'),
          organizational_group_id: groupId,
          deleted: false,
          role_name: 'Manager',
        }).all();
        actions = assignments.map((assignment) => RoleAssignment.objects().update(assignment.id, { deleted: true }));
        break;
      case 'invite':
        users = await User.objects().filtered({ id: _.pluck(selected, 'user_id') }).all();
        actions = users.map((user) => user.sendInvite());
        break;
      case 'activate':
        actions = selected.map((obj) => User.objects().update(obj.user_id, { is_active: true }));
        break;
      case 'deactivate':
        actions = selected.map((obj) => User.objects().update(obj.user_id, { is_active: false }));
        break;
      case 'delete':
        actions = selected.map((obj) => User.objects().update(obj.user_id, { deleted: true }));
        break;
      default:
        console.log('Invalid action');
    }
    await Promise.all(actions);
    this.refresh();
  }

  render() {
    const {
      groupId, showNewUserModal, showExistingUserModal, managerIds, action, selected,
    } = this.state;
    const columnData = [
      {
        name: 'Active',
        key: [
          'user__last_login',
          (lastLogin) => moment(lastLogin).fromNow(),
        ],
      },
      {
        name: 'Manager',
        key: [
          'user__first_name',
          'user__last_name',
          (first, last) => `${first} ${last}`,
        ],
        search: true,
      },
      {
        name: 'Email',
        key: 'user__email',
      },
      {
        name: 'Status',
        key: [
          'user__status',
          (status) => STATUS[status],
        ],
      },
      {
        name: 'Actions',
        key: [
          'this',
          (user) => (
            <Dropdown direction="left" icon="ellipsis vertical" floating labeled className="icon">
              <Dropdown.Menu>
                {/* <Dropdown.Item
                  text="Remove Manager"
                  onClick={() => {
                    this.setState({
                      action: 'remove',
                      selected: [user],
                    });
                  }}
                /> */}
                <Dropdown.Item
                  text="Send Invite"
                  onClick={() => {
                    this.setState({
                      action: 'invite',
                      selected: [user],
                    });
                  }}
                />
                <Dropdown.Item
                  text="Activate"
                  onClick={() => {
                    this.setState({
                      action: 'activate',
                      selected: [user],
                    });
                  }}
                />
                <Dropdown.Item
                  text="Deactivate"
                  onClick={() => {
                    this.setState({
                      action: 'deactivate',
                      selected: [user],
                    });
                  }}
                />
                <Dropdown.Item
                  text="Delete"
                  onClick={() => {
                    this.setState({
                      action: 'delete',
                      selected: [user],
                    });
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          ),
        ],
      },
    ];
    return (
      <div>
        <div className="row">
          {/* <Dropdown text="Add Manager..." button className="green" style={{ float: 'right' }}>
            <Dropdown.Menu>
              <Dropdown.Item
                text="Create New User as Manager"
                onClick={() => this.setState({ showNewUserModal: true })}
              />
              <Dropdown.Item
                text="Add Existing User as Manager"
                onClick={() => this.setState({ showExistingUserModal: true })}
              />
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
        <ModelTable
          title="Group Managers"
          ref={this.table}
          model={RoleAssignment}
          baseFilter={{
            organizational_group_id: groupId,
            role_name: 'Manager',
            deleted: false,
          }}
          join={[
            'user__first_name',
            'user__last_name',
            'user__email',
            'user__status',
            'user__last_login',
          ]}
          defaultSort={['name', 'asc']}
          columns={columnData}
          select
        >
          <ModelTable.Actions>
            {/* <ModelTable.Action
              name="Remove As Manager"
              method={(selection) => this.setState({ action: 'remove', selected: selection })}
              requireSelected
            /> */}
            <ModelTable.Action
              name="Send Invite"
              method={(selection) => this.setState({ action: 'invite', selected: selection })}
              requireSelected
            />
            <ModelTable.Action
              name="Activate"
              method={(selection) => this.setState({ action: 'activate', selected: selection })}
              requireSelected
            />
            <ModelTable.Action
              name="Deactivate"
              method={(selection) => this.setState({ action: 'deactivate', selected: selection })}
              requireSelected
            />
            <ModelTable.Action
              name="Delete"
              method={(selection) => this.setState({ action: 'delete', selected: selection })}
              requireSelected
            />
          </ModelTable.Actions>
        </ModelTable>
        <CreateUserModal
          show={showNewUserModal}
          close={() => this.setState({ showNewUserModal: false })}
          create={this.createManager}
        />
        <ExistingUserModal
          show={showExistingUserModal}
          close={() => this.setState({ showExistingUserModal: false })}
          actionText="Add as Manager"
          action={this.addAsManager}
          filter={{ id__not: managerIds }}
        />
        <Modal open={!!action}>
          <Modal.Header>{`${action} users`.toUpperCase()}</Modal.Header>
          <Modal.Content>
            <div>Are you sure you want to {action.toUpperCase()} the following users?</div>
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                  <Table.HeaderCell>Email</Table.HeaderCell>
                  <Table.HeaderCell>Last login</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {selected.map((user) => (
                  <Table.Row>
                    <Table.Cell>{`${user.user__first_name} ${user.user__last_name}`}</Table.Cell>
                    <Table.Cell>{user.user__email}</Table.Cell>
                    <Table.Cell>{moment(user.user__last_login).fromNow()}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => this.setState({ action: '' })}>Cancel</Button>
            <Button onClick={this.performAction} positive>Confirm</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

export default GroupManagers;
