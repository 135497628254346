import ls from 'local-storage';
import jwtDecode from 'jwt-decode';
import _ from 'underscore';
import moment from 'moment-timezone';
import { ModelBase } from '@/models/model';
import RoleAssignment from '@/models/role_assignment';
import LearningGroupAssignment from '@/models/learning_group_assignment';
import OrganizationalGroup from '@/models/group';

function timezones() {
  const zones = new Set();
  const countries = moment.tz.countries();

  // eslint-disable-next-line no-restricted-syntax
  for (const country of countries) {
    moment.tz.zonesForCountry(country).reduce((set, zone) => set.add(zone), zones);
  }

  return [...zones].sort();
}

class User extends ModelBase {
  static SUPPORTED_TIMEZONES = timezones();

  static namespace = 'user';

  static related = {
    [LearningGroupAssignment.namespace]: { user_id: 'id' },
    [RoleAssignment.namespace]: { user_id: 'id' },
  };

  static getCurrentUserId() {
    return jwtDecode(ls('token.access')).user_id;
  }

  static async me() {
    return this.objects().get(User.getCurrentUserId());
  }

  async updateRole(newRole, groupId = null) {
    const data = {
      user_id: this.id,
      group_id: groupId, // if group_id is blank the API will assume the apex group
      role_name: newRole,
    };
    await User.objects().create(data, 'role_assignment/assign');
    return true;
  }

  async assignToGroup(groupId = null, groupName = null) {
    const data = {
      user_id: this.id,
      group_id: groupId,
      group_name: groupName,
    };
    await LearningGroupAssignment.objects().create(data, 'assign');
    await User.objects().get(this.id); // HACK to invalidate & reload the cache
    return true;
  }

  async removeFromGroup(groupId) {
    const data = {
      user_id: this.id,
      group_id: groupId,
    };
    await LearningGroupAssignment.objects().create(data, 'unassign');
    await User.objects().get(this.id); // HACK to invalidate & reload the cache
    return true;
  }

  async removeFromAllGroups() {
    const data = {
      user_id: this.id,
    };
    await LearningGroupAssignment.objects().create(data, 'unassign_all');
    await User.objects().get(this.id); // HACK to invalidate & reload the cache
    return true;
  }

  async sendInvite() {
    const data = {
      user_id: this.id,
    };
    if (!this.is_active) {
      const result = await User.objects().create(data, 'send_invite/');
      // now force reload ourselves...
      await User.objects().get(this.id); // HACK to invalidate & reload the cache
      return result;
    }
    return true;
  }

  async authorizeSlack(token) {
    const formData = new FormData();
    formData.append('auth_token', token);
    const response = await fetch(`/api/v2/users/${this.id}/authorize_slack/`, {
      method: 'POST',
      header: {
        Authorization: `Bearer ${ls('token.access')}`,
        Accept: 'application/json',
      },
      body: formData,
    });
    if (response.status === 200) {
      return true;
    }
    return false;
  }

  async permissionGroups() {
    const assignments = await RoleAssignment.objects()
      .filtered({ user_id: this.id })
      .all();
    const groupIds = _.map(assignments, (assignment) => assignment.organizational_group_id);
    return OrganizationalGroup.objects()
      .filtered({ id: groupIds })
      .all();
  }

  async didLoad() {
    if (!this.waffle_flags) {
      this.waffle_flags = '';
    }
    if (this.roles && this.roles.length > 1) {
      this.roles = _.reject(this.roles, (role) => role === 'None');
    } else if (!this.roles) {
      this.roles = [];
    }
  }

  getMainRole() {
    switch (true) {
      case this.roles.includes('Owner'):
        return 'Owner';
      case this.roles.includes('Admin'):
        return 'Admin';
      case this.roles.includes('Writer'):
        return 'Writer';
      case this.roles.includes('Manager'):
        return 'Manager';
      default:
        return 'Learner';
    }
  }
}

export default User.register();
