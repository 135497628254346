import _ from 'underscore';
import bind from 'bind-decorator';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

const s = {
  header: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '15px',
    color: '#7E7F8F',
  },
  subtext: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    color: '#7E7F8F',
    paddingTop: '5px',
  },
  checkbox: {
    width: '19px',
    height: '19px',
    marginRight: '5px',
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '125%',
    color: '#27272B',
    display: 'inline',
  },
  smallText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '15px',
    color: '#27272B',
    display: 'inline',
  },
  checked_container: {},
  unchecked_container: {
    background: '#FAFBFD',
  },
  warning: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '14px',
    lineHeight: '15px',
    display: 'inline',
    color: 'red',
  },
};

class FancyCheckbox extends Component {
  static propTypes = {
    header: PropTypes.string,
    text: PropTypes.string,
    checked: PropTypes.bool,
    subtext: PropTypes.string,
    style: PropTypes.object,
    warning: PropTypes.bool,
    small: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    style: {},
    warning: false,
    small: false,
  };

  @bind
  onClick() {
    if (this.props.onClick) {
      this.props.onClick(this);
    }
  }

  render() {
    let containerStyle = {
      background: this.props.checked ? '#FFFFFF' : '#FAFBFD',
      // eslint-disable-next-line max-len
      boxShadow: '0px 11.28px 23.3955px rgba(0, 0, 0, 0.0215656), 0px 6.0308px 12.5083px rgba(0, 0, 0, 0.0178832), 0px 3.38082px 7.01207px rgba(0, 0, 0, 0.015), 0px 1.79553px 3.72406px rgba(0, 0, 0, 0.0121168), 0px 0.747159px 1.54966px rgba(0, 0, 0, 0.00843437)',
      borderRadius: '1px',
      padding: '20px',
    };
    containerStyle = _.extend(containerStyle, this.props.style);
    return (
      <div style={containerStyle}>
        <div style={s.header}>{this.props.header}</div>
        <div>
          <input
            style={s.checkbox}
            type="checkbox"
            checked={this.props.checked}
            onClick={this.onClick}
          />
          <p style={this.props.small ? s.smallText : s.text}>
            {this.props.text}
            {this.props.warning ? <span style={s.warning}>&nbsp;(Warning)</span> : null}
          </p>
        </div>
        <div style={s.subtext}>{this.props.subtext}</div>
      </div>
    );
  }
}

export default Radium(FancyCheckbox);
