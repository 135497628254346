import _ from 'underscore';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';

class FancyDropdown extends Component {
  static propTypes = {
    text: PropTypes.string,
    value: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.dropdown = React.createRef();
    this.state = {
      text: "",
    };
  }

  static Item = (props) => {
    return (
      <Dropdown.Item
        key={props.key}
        text={props.text}
        value={props.value}
      />
    );
  };

  render() {
    const options = _.map(this.props.children, (child) => {
      return _.pick(child.props, 'key', 'text', 'value');
    });
    const { text, onChange, disabled } = this.props;
    let selectedText = this.state.text;
    if (!selectedText && this.props.value) {
      const t = _.findWhere(options, { value: this.props.value });
      selectedText = t.text;
    }
    return (
      <div className="hickory-fancydropdown">
        <Dropdown
          inline
          ref={this.dropdown}
          disabled={disabled}
          icon={null}
          options={options}
          trigger={(
            <div>
              {`${text}:`}
              { ' ' }
              <span className="hickory-fancydropdown-value">
                {selectedText}
                { ' ' }
                <Icon name="angle down" />
              </span>
            </div>
          )}
          onChange={(event, data) => {
            if (onChange) {
              onChange(data.value, this);
            }

            const t = _.findWhere(data.options, { value: data.value });

            this.setState({
              text: t.text,
            });
            this.dropdown.current.close(); // HACK: the dropdown was NOT closing on the first click for some reason
          }}
        />
      </div>
    );
  }
}

export default FancyDropdown;
