import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import { DateInput, TimeInput } from 'semantic-ui-calendar-react';
import { Button, Checkbox, Input } from 'semantic-ui-react';
import bind from 'bind-decorator';

class CourseScheduler extends Component {
  static propTypes = {
    scheduleCourse: PropTypes.func,
    stopScheduling: PropTypes.func,
    showAdvancedScheduling: PropTypes.bool,
    inModal: PropTypes.bool,
    onScheduleChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      drip: false,
      delay: 3,
      pendingStartTime: '12:00 AM',
      pendingStartDate: moment().format('MM/DD/YYYY'),
      scheduledReview: 12,
      scheduledScore: 60,
      allowRetakes: false,
    };
  }

  componentDidMount() {
    this.updateParent();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      pendingStartTime, pendingStartDate, scheduledReview, allowRetakes, scheduledScore,
    } = this.state;
    if (
      prevState.pendingStartDate !== pendingStartDate
      || prevState.pendingStartTime !== pendingStartTime
      || prevState.scheduledReview !== scheduledReview
      || prevState.allowRetakes !== allowRetakes
      || prevState.scheduledScore !== scheduledScore
    ) this.updateParent();
  }

  @bind
  scheduleCourse() {
    const {
      pendingStartDate,
      pendingStartTime,
      scheduledReview,
      allowRetakes,
      scheduledScore,
    } = this.state;
    this.props.scheduleCourse({
      pendingStartDate,
      pendingStartTime,
      scheduledReview,
      allowRetakes,
      scheduledScore,
    });
  }

  @bind
  updateParent() {
    if (this.props.onScheduleChange) {
      const dateTimeStr = `${this.state.pendingStartDate} ${this.state.pendingStartTime}`;
      const dateTimeFormat = 'MM/DD/YYYY hh:mm A';
      const startTime = moment(dateTimeStr, dateTimeFormat);
      let retakeThreshold = parseInt(this.state.scheduled_score) / 100.0;
      if (retakeThreshold > 1) {
        retakeThreshold = 1;
      } else if (retakeThreshold < 0) {
        retakeThreshold = 0;
      }

      const schedule = {
        start_date: startTime,
        start_date_tz: moment.tz.guess(),
        review_frequency: this.state.scheduledReview,
        review_frequency_type: 2,
        retake_threshold: this.state.allowRetakes ? retakeThreshold : 0,
      };
      this.props.onScheduleChange(schedule);
    }
  }

  render() {
    const {
      pendingStartDate,
      pendingStartTime,
      drip,
      delay,
    } = this.state;
    const {
      showAdvancedScheduling, inModal,
    } = this.props;
    return (
      <div>
        <DateInput
          onChange={(e, { value }) => {
            this.setState({
              pendingStartDate: value,
            });
          }}
          value={pendingStartDate}
          closable
          dateFormat="MM/DD/YYYY"
          minDate={moment().format('MM/DD/YYYY')}
          inlineLabel
          label="Start Date: "
        />
        <TimeInput
          onChange={(e, { value }) => {
            this.setState({
              pendingStartTime: value,
            });
          }}
          value={pendingStartTime}
          inlineLabel
          label="Start Time: "
          timeFormat="AMPM"
        />
        <p>
          <em>NOTE: Scheduled lesson start times are localized on a per-learner basis.</em>
        </p>
        <p>
          <em>
            For example, a lesson scheduled to start at 10am will roll out to learners at 10am
            {' '}
            <strong>Eastern</strong>
            {' '}
            for users in Eastern time zone, and at 10am
            {' '}
            <strong>Central</strong>
            {' '}
            to users in Central time zone.
          </em>
        </p>
        {showAdvancedScheduling && (

        <div style={{ maxWidth: '50%' }}>
          <table style={{ width: '80%' }}>
            <tbody>
              <tr>
                <td>
                  <Checkbox label="Drip Schedule" onChange={() => this.setState({ drip: !drip })} toggle />
                </td>
                <td />
              </tr>
              {drip && (
              <tr>
                <td>Delay:&nbsp;</td>
                <td>
                  <Input
                    value={delay}
                    onChange={(e, { value }) => {
                      this.setState({ delay: value.match(/\d*/)[0] });
                    }}
                  />
                  {' '}
                  days after joining group
                </td>
              </tr>
              )}
              {drip && (
              <tr>
                <td colSpan="2">
                  <em>
                    NOTE: Lessons will
                    {' '}
                    <strong>not</strong>
                    {' '}
                    be available to each individual
                    learner until
                    {' '}
                    <strong>after</strong>
                    {' '}
                    the lesson&apos;s schedule start date
                    has passed,
                    {' '}
                    <strong>as well as</strong>
                    {' '}
                    the specified number of days has
                    elapsed since they&apos;ve joined this group.
                  </em>
                </td>
              </tr>
              )}
            </tbody>
          </table>
        </div>
        )}
        {!inModal && (
        <>
          <Button onClick={this.props.stopScheduling}>Cancel</Button>
          <Button positive onClick={this.scheduleCourse}>Schedule</Button>
        </>
        )}
      </div>
    );
  }
}

export default CourseScheduler;
