import React, { Component } from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';
import { Loader } from 'semantic-ui-react';
import _ from 'underscore';
import Lesson from '@/models/lesson';
import User from '@/models/user';
import Card from '@/models/card';
import LessonCard from './LessonCard';

const s = {
  container: {
    height: '100%',
    alignItems: 'center',
    paddingTop: '20px',
  },
  card: {
    pageBreakBefore: 'always',
  },
};

class PrintLesson extends Component {
  static propTypes = {
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      lesson_id: props.match.params.lesson_id,
      loading: true,
    };
  }

  componentDidMount() {
    this.initializePlayer();
  }

  @bind
  componentDidUpdate(prevProps) {
    if (this.props.match.params.lesson_id !== prevProps.match.params.lesson_id) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          lesson_id: this.props.match.params.lesson_id,
        },
        this.initializePlayer,
      );
    }
  }

  async initializePlayer() {
    const me = await User.me();
    const lesson = await Lesson.objects().get(this.state.lesson_id);

    if (me.roles.includes('None')) {
      window.location = '/app/v2/learning/home';
    }

    let cards = await Card.objects()
      .filtered({
        id: lesson.card_order,
      })
      .all();
    cards = _.compact(lesson.card_order.map((card) => _.findWhere(cards, { id: card })));
    this.setState({
      loading: false,
      lesson,
      cards,
    });
  }

  @bind
  async nextCard() {
    // nope!
  }

  @bind
  async submitAnswer() {
    // nope!
  }

  render() {
    const { lesson, cards } = this.state;
    if (this.state.loading) {
      return (
        <div style={s.container}>
          <Loader active inline="centered" />
        </div>
      );
    }
    return (
      <div style={s.container}>
        <h1>{lesson.name}</h1>
        {cards.map((card) => (
          <div>
            <div style={s.card}>&nbsp;</div>
            <div>&nbsp;</div>
            <LessonCard
              card={card}
              stack={1}
              nextCard={this.nextCard}
              response={null}
              submit={this.submitAnswer}
              print
            />
          </div>
        ))}
      </div>
    );
  }
}

export default Radium(PrintLesson);
