import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import bind from 'bind-decorator';
import Radium from 'radium';
import { Dimmer } from 'semantic-ui-react';
import PeopleList from './PeopleList';
import PeopleFilter from './PeopleFilter';
import PeopleControlBar from './PeopleControlBar';
import { colorGreyVeryLight } from '../../styles/palette';
import UserPaginationComponent from '../utils/UserPaginationComponent';

const FILTER_WIDTH = '250px';
const CONTROL_HEIGHT = '170px';
let s = {};

class PeopleContainer extends UserPaginationComponent {
  static propTypes = {
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      users: [],
      selectedUsers: [],
      filters: {},
      search: '',
      page: 1,
      perPage: 100,
      userCount: 0,
      sort: { column: 'last_name', ascending: true },
    };

    this.filterComp = React.createRef();
  }

  async componentDidMount() {
    await this.updateUsers();
  }

  @bind
  setPage(page) {
    this.setState(
      {
        page,
      },
      this.updateUsers,
    );
  }

  @bind
  setPerPage(perPage) {
    this.setState(
      {
        perPage,
        page: 1,
      },
      this.updateUsers,
    );
  }

  @bind
  setSort(key) {
    this.setState(
      {
        sort: {
          column: key,
          ascending: this.state.sort.column === key ? !this.state.sort.ascending : true,
        },
      },
      this.updateUsers,
    );
  }

  @bind
  setFilter(filter, value = null) {
    this.setState((state) => {
      const temp = state.filters;
      if (!value || value === temp[filter]) {
        delete temp[filter];
      } else {
        temp[filter] = value;
      }
      return { filters: temp, page: 1 };
    }, this.updateUsers);
  }

  @bind
  setSearch(value) {
    let tokens = _.map(value.split(' '), (token) => token.trim());
    tokens = _.filter(tokens, (token) => token !== ''); // remove empty tokens

    const query = {
      first_name: tokens,
      last_name: tokens,
      email: tokens,
    };

    this.setState(
      {
        search: tokens.length >= 1 ? query : {},
        page: 1,
      },
      this.updateUsers,
    );
  }

  @bind
  refreshTags() {
    this.filterComp.current.refreshTags();
  }

  @bind
  selectNone() {
    this.setState({
      selectedUsers: [],
    });
  }

  @bind
  selectAll() {
    let selectedUsers = [];
    if (this.state.selectedUsers.length !== this.state.userCount) {
      selectedUsers = this.state.allUsers;
    }
    this.setState({
      selectedUsers,
    });
  }

  @bind
  selectUser(user) {
    let selectedUsers = [];
    if (
      this.state.selectedUsers.find((item) => item.id === user.id)
    ) {
      selectedUsers = this.state.selectedUsers.filter((item) => item.id !== user.id);
    } else {
      selectedUsers = this.state.selectedUsers.concat([user]);
    }
    this.setState({
      selectedUsers,
    });
  }

  render() {
    const constraints = !(_.isEmpty(this.state.filters) && !this.state.search.length);
    return (
      <div>
        {this.state.loading ? <Dimmer active inverted /> : null}
        <div>
          <PeopleFilter
            filters={this.state.filters}
            setFilter={this.setFilter}
            style={s.filter}
            ref={this.filterComp}
          />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ marginLeft: FILTER_WIDTH }} />
            <div style={s.list}>
              <PeopleControlBar
                history={this.props.history}
                userCount={this.state.userCount}
                style={s.controls}
                setSearch={this.setSearch}
                page={this.state.page}
                perPage={this.state.perPage}
                setPage={this.setPage}
                setPerPage={this.setPerPage}
                selectedUserCount={this.state.selectedUsers.length}
                selectedUsers={this.state.selectedUsers}
                updateUsers={this.updateUsers}
                refreshTags={this.refreshTags}
                selectNone={this.selectNone}
              />
              <div style={{ marginTop: CONTROL_HEIGHT }} />
              <PeopleList
                users={this.state.users}
                constraints={constraints}
                setSort={this.setSort}
                sort={this.state.sort}
                selectUser={this.selectUser}
                selectAll={this.selectAll}
                selectedUsers={this.state.selectedUsers}
                updateUsers={this.updateUsers}
                loading={this.state.loading}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

s = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  filter: {
    position: 'fixed',
    height: 'calc(100% - 70px)',
    width: FILTER_WIDTH,
    borderRadius: 0,
    border: 'none',
    zIndex: 2,
  },
  list: {
    // paddingLeft: FILTER_WIDTH,
    minWidth: `calc(100% - ${FILTER_WIDTH})`,
    marginTop: '-20px',
  },
  controls: {
    background: colorGreyVeryLight,
    paddingTop: '20px',
    position: 'fixed',
    width: `calc(100% - ${FILTER_WIDTH})`,
    height: CONTROL_HEIGHT,
    zIndex: 2,
  },
};
export default Radium(PeopleContainer);
