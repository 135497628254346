import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';
import {
  Header, Icon,
} from 'semantic-ui-react';

class SideScroller extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    title: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      scrollPos: 0,
    };

    this.main = React.createRef();
  }

  componentDidMount() {
    const container = this.main.current.querySelector('.card-container');
    const maxScroll = container.scrollWidth - container.offsetWidth;
    let childWidth = 0;
    if (this.props.children.length) {
      const style = window.getComputedStyle(this.main.current.querySelector('.card-scroll').firstElementChild);
      childWidth = parseFloat(style.marginLeft) + parseFloat(style.marginRight) + parseFloat(style.width);
    }
    this.setState({
      maxScroll,
      childWidth,
    });
  }

  @bind
  scroll(pos) {
    const container = this.main.current.querySelector('.card-container');
    container.scroll(pos, 0);
  }

  render() {
    const { title, children } = this.props;
    const { scrollPos, maxScroll, childWidth } = this.state;
    return (
      <div ref={this.main}>
        <Header as="h1">{title}</Header>
        <div style={{ display: 'flex' }}>
          <div className="overlay left">
            {!!scrollPos && (
            <Icon
              name="arrow alternate circle left outline"
              size="huge"
              color="grey"
              onClick={() => this.scroll(scrollPos - childWidth)}
            />
            )}
          </div>
          <div
            className="card-container"
            onScroll={(e) => {
              this.setState({
                scrollPos: e.currentTarget.scrollLeft,
              });
            }}
          >
            <div className="card-scroll">
              {children}
            </div>
          </div>
          <div className="overlay right">
            {scrollPos !== maxScroll && (
            <Icon
              name="arrow alternate circle right outline"
              size="huge"
              color="grey"
              onClick={() => this.scroll(scrollPos + childWidth)}
            />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SideScroller;
