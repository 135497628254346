import React, { Component } from 'react';
import Radium from 'radium';
import {
  Dropdown, Icon, Image, Label, Message,
} from 'semantic-ui-react';
import { Link, withRouter, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import md5 from 'md5';
import moment from 'moment';
import UserModal from '../PeoplePanel/UserModal';
import {
  blue, blue700, green, grey100, grey200, grey700, hickoryGreen,
} from '../../styles/palette';
import LogoWhite from '@/images/hickory-white.svg';
import LogoGreen from '@/images/hickory-green.svg';

const ROLE_DESCRIPTION = {
  Admin: 'Invite users, edit lessons.',
  Manager: 'Check assignments, generate reports.',
  Writer: 'Edit lessons.',
};
const ROLES = {
  Superuser: [
    'course-planner',
    'insights',
    'reports',
    'courses',
    'lessons',
    'users',
    'help',
    'profile',
    'logout',
  ],
  Admin: [
    'dashboard',
    'course-planner',
    'courses',
    'lessons',
    'users',
    'assignments',
    'reports',
    'help',
    'profile',
    'logout',
  ],
  Owner: [
    'onboarding',
    'dashboard',
    'course-planner',
    'courses',
    'lessons',
    'users',
    'assignments',
    'reports',
    'help',
    'profile',
    'logout',
    'settings',
  ],
  Manager: ['dashboard', 'assignments', 'reports', 'help', 'profile', 'logout'],
  Writer: ['courses', 'lessons', 'help', 'profile', 'logout'],
  None: ['home', 'library', 'my-stats', 'help', 'profile', 'logout'],
};

let s = {};

class Header extends Component {
  static propTypes = {
    user: PropTypes.object,
    organization: PropTypes.object,
    location: PropTypes.object,
    preview: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const anonymous = typeof props.user === 'undefined' || props.user == null;
    this.state = {
      anonymous,
      showProfileModal: false,
    };
    if (this.inLearnerMode()) {
      this.state.foreColor = blue;
      this.state.backColor = '#FFFFFF';
    } else {
      this.state.foreColor = grey100;
      this.state.backColor = hickoryGreen;
    }
    this.NAV = [
      {
        key: 'onboarding',
        label: 'Onboarding',
        baseRoute: '/app/v2/onboarding',
        shouldShow: () => {
          const { organization } = props;

          if (!organization) {
            return false;
          }

          return !organization.onboarding_completed;
        },
      },
      {
        key: 'dashboard',
        label: 'Dashboard',
        baseRoute: '/app/v2',
      },
      {
        key: 'course-planner',
        label: 'Groups',
        baseRoute: '/app/v2/groups',
      },
      {
        key: 'courses',
        label: 'Courses',
        baseRoute: '/app/v2/courses',
      },
      {
        key: 'lessons',
        label: 'Lessons',
        baseRoute: '/app/v2/lessons',
      },
      {
        key: 'users',
        label: 'Learners',
        baseRoute: '/app/v2/people',
      },
      {
        key: 'assignments',
        label: 'Assignments',
        baseRoute: '/app/v2/assignments',
      },
      {
        key: 'home',
        label: 'Dashboard',
        baseRoute: '/app/v2/learning/home',
      },
      {
        key: 'library',
        label: 'Lessons',
        baseRoute: '/app/v2/learning/library',
      },
      {
        key: 'reports',
        label: 'Reports',
        baseRoute: '/app/v2/reports',
        routeParams: 1,
      },
    ];
  }

  async componentDidMount() {
    if (this.state.anonymous) {
      // this.setState({ organization: null });
    } else {
      // const organization = await Organization.objects().get(this.props.user.organization_id);
      // this.setState({
      //   organization,
      // });
    }
  }

  static getDerivedStateFromProps(props) {
    const currentPath = props.location.pathname || '';
    if (props.organization) {
      const newState = {};
      if (currentPath.endsWith('/player') || currentPath.includes('learning')) {
        newState.foreColor = props.organization.secondary_color || blue;
        newState.backColor = props.organization.primary_color || '#FFFFFF';
      } else {
        newState.foreColor = props.organization.primary_color || grey100;
        newState.backColor = props.organization.secondary_color || hickoryGreen;
      }
      return newState;
    } return null;
  }

  get warning() {
    if (
      !this.state.anonymous
      && (this.props.user.roles.includes('Admin') || this.props.user.roles.includes('Owner'))
      && this.props.organization
    ) {
      const org = this.props.organization;
      const overflow = org.total_active_users - org.soft_limit;
      if (overflow > 0) {
        return (
          <Message warning>
            <Message.Header>Warning!</Message.Header>
            <p>
              Your organization currently exceeds their active user quota of
              {' '}
              {org.soft_limit}
              {' '}
              by
              {' '}
              {overflow}
              {' '}
              users. Please deactivate or delete any currently-active users that no
              longer need access to Hickory to bring your user count down to ensure no interruption
              of services.
            </p>
          </Message>
        );
      }
      const isTrial = this.props.organization.subscription_tier === 1;
      if (isTrial && this.props.user.roles.includes('Owner')) {
        const trialExpires = moment(this.props.organization.trial_expires);
        const now = moment();
        const days = Math.round(moment.duration(trialExpires - now).asDays());

        if (trialExpires <= now) {
          return (
            <Message warning>
              <Message.Header />
              <p>
                Your Hickory trial has expired.
                {' '}
                Please choose a subscription plan, or contact us at
                {' '}
                <a href="mailto:support@hickorytraining.com">support@hickorytraining.com</a>
                {' '}
                to re-enable access to Hickory.
              </p>
            </Message>
          );
        }

        return (
          <Message info>
            <Message.Header />
            <p>
              Your Hickory trial will expire in
              {' '}
              {days}
              {' '}
              days.
              {' '}
              <Link to="/app/v2/settings">Click here</Link>
              {' '}
              to choose a subscription plan.
            </p>
          </Message>
        );
      }
    }
    return null;
  }

  get organization() {
    if (this.props.organization) {
      return (
        <span
          className="header-org"
          style={{ color: (this.inLearnerMode() && !this.props.organization.primary_color) && grey700 }}
        >
          for&nbsp;
          {this.props.organization.name}
        </span>
      );
    }
    return null;
  }

  inLearnerMode() {
    const { location } = this.props;
    const currentPath = location.pathname || '';
    return currentPath.endsWith('/player') || currentPath.includes('learning');
  }

  roleNav(navList) {
    const { foreColor, backColor } = this.state;
    const accessList = this.inLearnerMode() || this.props.preview ? ROLES.None : ROLES[this.props.user.roles];
    if (!this.props.preview) {
      switch (this.props.user.roles[0]) {
        case 'Manager':
          accessList.push(this.inLearnerMode() ? 'manage-manager' : 'learn');
          break;
        case 'Admin':
          accessList.push(this.inLearnerMode() ? 'manage-admin' : 'learn');
          break;
        case 'Owner':
          accessList.push(this.inLearnerMode() ? 'manage-admin' : 'learn');
          break;
        case 'Writer':
          accessList.push(this.inLearnerMode() ? 'manage-writer' : 'learn');
          break;
        default:
          break;
      }
    }
    const accessNav = navList.filter((item) => accessList.includes(item.key));
    return accessNav.map((item) => {
      if (item.shouldShow && item.shouldShow() === false) {
        return null;
      }
      const routeParams = item.routeParams || '';
      let route = item.baseRoute.concat('/', routeParams);
      const basePath = window.location.href;
      let selected = basePath.includes(item.baseRoute) && (basePath.endsWith('v2/') === (item.key === 'dashboard'));
      if ('waffle' in item && this.props.user.waffle_flags.includes(item.waffle)) {
        route = item.waffleRoute.concat('/', routeParams);
        selected = basePath.includes(item.waffleRoute);
      }
      return (
        <NavLink
          key={item.key}
          style={{ color: selected ? backColor : foreColor }}
          to={route}
        >
          <div
            className="header-link"
            style={{ backgroundColor: selected ? foreColor : backColor, zIndex: 10, borderRadius: '2px' }}
          >
            {item.label}
          </div>
        </NavLink>
      );
    });
  }

  get menu() {
    const hash = md5(this.props.user.email.toLowerCase());
    const trigger = (
      <div>
        {this.inLearnerMode() ? (
          <Label style={{ backgroundColor: green }}>Learn</Label>
        ) : (
          <Label style={{ backgroundColor: blue700 }}>Manage</Label>
        )}
        {`${this.props.user.first_name} ${this.props.user.last_name}`}
        {' '}
        <Image
          src={`https://www.gravatar.com/avatar/${hash}.jpg?d=identicon&s=24`}
          avatar
          style={{
            // eslint-disable-next-line max-len
            boxShadow: '0px 11.28px 23.3955px rgba(0, 0, 0, 0.0215656), 0px 6.0308px 12.5083px rgba(0, 0, 0, 0.0178832), 0px 3.38082px 7.01207px rgba(0, 0, 0, 0.015), 0px 1.79553px 3.72406px rgba(0, 0, 0, 0.0121168), 0px 0.747159px 1.54966px rgba(0, 0, 0, 0.00843437)',
          }}
        />
      </div>
    );
    let role = this.props.user.getMainRole();
    if (role === 'Owner') role = 'Admin';
    return (
      <div>
        <Dropdown
          style={{ display: 'flex', alignItems: 'center' }}
          trigger={trigger}
          direction="left"
          icon={(
            <Icon
              name="chevron down"
              size="small"
              style={{
                // eslint-disable-next-line max-len
                textShadow: '0px 11.28px 23.3955px rgba(0, 0, 0, 0.0215656), 0px 6.0308px 12.5083px rgba(0, 0, 0, 0.0178832), 0px 3.38082px 7.01207px rgba(0, 0, 0, 0.015), 0px 1.79553px 3.72406px rgba(0, 0, 0, 0.0121168), 0px 0.747159px 1.54966px rgba(0, 0, 0, 0.00843437)',
              }}
            />
)}
        >
          <Dropdown.Menu style={{ zIndex: 99 }}>
            <Dropdown.Item onClick={() => this.setState({ showProfileModal: true })}>
              My Profile
            </Dropdown.Item>
            {this.props.user.getMainRole() === 'Owner' && (
              <Dropdown.Item>
                <Link to="/app/v2/settings/">
                  Company Settings
                </Link>
              </Dropdown.Item>
            )}
            {role !== 'Learner' && (
              <React.Fragment>
                <Dropdown.Divider />
                <Dropdown.Header content="Interface View:" />
                <Link to="/app/v2/">
                  <Dropdown.Item className={`hickory-column${!this.inLearnerMode() ? ' selected' : ''}`}>
                    <div>
                      {`${role} Console`}
                      {!this.inLearnerMode() && <Icon name="check" />}
                    </div>
                    <span>{ROLE_DESCRIPTION[role]}</span>
                  </Dropdown.Item>
                </Link>
                <Link to="/app/v2/learning/home/">
                  <Dropdown.Item className={`hickory-column${this.inLearnerMode() ? ' selected' : ''}`}>
                    <div>
                      Your Learning Dashboard
                      {this.inLearnerMode() && <Icon name="check" />}
                    </div>
                    <span>Take your personal assignments.</span>
                  </Dropdown.Item>
                </Link>
                <div style={{ height: '16px' }} />
              </React.Fragment>
            )}
            <Dropdown.Divider />
            <Link to="/app/v2/logout"><Dropdown.Item style={{ color: blue }}>Logout</Dropdown.Item></Link>
          </Dropdown.Menu>
        </Dropdown>
        <UserModal
          open={this.state.showProfileModal}
          userId="me"
          hideModal={() => this.setState({ showProfileModal: false })}
        />
      </div>
    );
  }

  render() {
    const { anonymous, foreColor, backColor } = this.state;
    const { preview } = this.props;
    if (new URLSearchParams(this.props.location.search).get('embedded')) return null;

    if (this.props.organization) {
      const isTrial = this.props.organization.subscription_tier === 1;
      if (!preview && isTrial && this.props.user.roles.includes('Owner')) {
        const trialExpires = moment(this.props.organization.trial_expires);
        const now = moment();
        // const days = Math.round(moment.duration(trialExpires - now).asDays());

        if (trialExpires <= now) {
          return (<div>{this.warning}</div>);
        }
      }
    }

    const homeLink = this.inLearnerMode() ? '/app/v2/learning/home' : '/app/v2';
    const style = {};
    if (this.inLearnerMode() || preview) {
      style.backgroundColor = this.props.organization.primary_color ? foreColor : '#FFFFFF';
      style.color = this.props.organization.primary_color ? backColor : blue;
    } else {
      style.backgroundColor = backColor;
      style.color = foreColor;
    }
    return (
      <div style={{ zIndex: 99 }}>
        {this.warning}
        <nav className="site-header" style={style}>
          <div className="row" style={{ position: 'absolute' }}>
            <a
              href={homeLink}
              style={{
                color: this.inLearnerMode() || preview ? backColor : foreColor,
                marginLeft: '20px',
              }}
            >
              {this.props.organization && this.props.organization.logo ? (
                <img
                  style={s.logo}
                  src={this.props.organization.logo}
                />

              ) : (
                <img src={this.inLearnerMode() ? LogoGreen : LogoWhite} alt="Hickory Logo" className="header-logo" />
              )}
            </a>
            {anonymous && !preview ? null : <div style={s.leftNav}>{this.organization}</div>}
          </div>
          {anonymous && !preview ? null : (
            <div style={s.centerNav}>
              <div style={s.headerNav}>
                {this.roleNav(this.NAV)}
              </div>
            </div>
          )}
          {anonymous ? null : (
            <div
              className="header-profile"
              style={{ backgroundColor: (this.inLearnerMode() && !this.props.organization.primary_color) && grey200 }}
            >{this.menu}
            </div>
          )}
        </nav>
      </div>
    );
  }
}

s = {
  versionText: {
    position: 'absolute',
    bottom: '0',
    fontSize: '0.8rem',
  },
  leftNav: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: '2',
  },
  centerNav: {
    height: '100%',
    left: '0',
    top: '0',
    flexGrow: '1',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  headerNav: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    zIndex: 20,
  },
  rightNav: {
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    padding: '6px',
  },
};
export default withRouter(Radium(Header));
