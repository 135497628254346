import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import CardContainer from '../Shared/CardContainer';
import { INSTRUCTION, QUESTION, SCENARIO } from '../../constants';
import InstructionContent from './InstructionContent';
import QuestionContent from './QuestionContent';
import ScenarioContent from './ScenarioContent';

class EditorCard extends Component {
  static propTypes = {
    card: PropTypes.object,
    lesson: PropTypes.object,
    setSelected: PropTypes.func,
    uploadImage: PropTypes.func,
    saveCard: PropTypes.func,
    takeSnapshot: PropTypes.func,
    historyPosition: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {

    };
    this.container = React.createRef();
  }

  componentDidMount() {
    const observer = new IntersectionObserver(this.selectCard, { root: null, rootMargin: '0px', threshold: 1 });
    observer.observe(this.container.current.container.current);
  }

  @bind
  selectCard(entries) {
    const [entry] = entries;
    if (entry.isIntersecting) this.props.setSelected(this.props.card.id);
  }

  @bind
  saveCard(data) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.saveCard(this.props.card.id, data);
    }, 300);
  }

  get content() {
    const {
      card, lesson, uploadImage, takeSnapshot, historyPosition,
    } = this.props;
    let type = null;
    switch (card.card_type) {
      case INSTRUCTION:
        type = <InstructionContent />;
        break;
      case QUESTION:
        type = <QuestionContent />;
        break;
      case SCENARIO:
        type = <ScenarioContent />;
        break;
      default:
    }
    return React.cloneElement(
      type,
      {
        key: card.id,
        card,
        lesson,
        uploadImage,
        saveCard: this.saveCard,
        takeSnapshot,
        historyPosition,
      },
    );
  }

  render() {
    return (
      <CardContainer ref={this.container}>
        <div style={{ display: 'flex' }} id={this.props.card.id}>
          {this.content}
        </div>
      </CardContainer>
    );
  }
}

export default Radium(EditorCard);
