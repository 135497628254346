import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Button, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import bind from 'bind-decorator';
import moment from 'moment';
import SumoLogger from 'sumo-logger';
import CardContainer from '../Shared/CardContainer';
import { SUMO, CARD } from '../../constants';

const sumoLogger = new SumoLogger({ endpoint: SUMO });

const s = {
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    margin: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  cardTile: {
    width: '253px',
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  cardTitle: {
    fontWeight: '400',
    lineHeight: '1.3',
    fontSize: '1.6em',
  },
  footer: {
    display: 'flex',
    color: '#918f90',
    fontSize: '0.75em',
    justifyContent: 'space-between',
    borderTop: '1px solid #d1d1d1',
    marginTop: '10px',
    width: '100%',
  },
  startButton: {
    textAlign: 'center',
    color: '#ffffff',
    textShadow: 'none',
    padding: '10px',
  },
  lessonIcon: {
    width: '5em',
    height: '6em',
    margin: '0 auto',
  },
};

class LessonLibraryCard extends Component {
  static propTypes = {
    lessonStatus: PropTypes.object,
  };

  @bind
  renderTakeButton() {
    const { lessonStatus } = this.props;
    const isReview = lessonStatus.completed && lessonStatus.cards_available_to_review.length > 0;
    const { retake } = lessonStatus;
    const isInProgress = !!lessonStatus.started_at;

    let labelText = 'START';
    if (retake) {
      labelText = 'RETAKE';
    } else if (isInProgress) {
      labelText = 'RESUME';
    }
    if (lessonStatus.lesson_id === undefined) {
      sumoLogger.log(
        `ERROR: missing lesson_id for UserLessonStatus ${this.props.lessonStatus.id} in LearnerLibraryCard.jsx`,
      );
    }
    if (isReview) {
      return (
        <Link style={s.startButton} to={`/app/v2/lessons/${lessonStatus.lesson__id}/player`}>
          <Button color="green">
            REVIEW
          </Button>
        </Link>
      );
    }
    return (
      <Link style={s.startButton} to={`/app/v2/lessons/${lessonStatus.lesson__id}/player`}>
        <Button color="blue">
          {labelText}
        </Button>
      </Link>
    );
  }

  render() {
    const { lessonStatus } = this.props;
    const isCardBased = lessonStatus.lesson__lesson_type === CARD;
    const isReview = this.props.lessonStatus.completed && this.props.lessonStatus.cards_available_to_review.length > 0;
    const cardCount = isReview
      ? lessonStatus.cards_available_to_review.filter(
        (card) => moment(card.scheduled_review_date).isBefore(moment()),
      ).length
      : lessonStatus.lesson__card_count;
    return (
      <div>
        <CardContainer compact loaded={!!lessonStatus} stack={3}>
          <div style={s.cardTile}>
            <div style={s.lessonIcon}>
              <svg width="100%" height="100%" viewBox="535 439 96 96" version="1.1">
                {/* <!-- circle outline element --> */}
                <ellipse
                  stroke="#A6A9B4"
                  strokeWidth="4"
                  fill="none"
                  cx="582.808"
                  cy="486.731"
                  rx="45.808"
                  ry="45.731"
                />
                {/* <!-- Card/Paper with bookmark vector Lines --> */}
                <g
                  opacity="1"
                  fill="none"
                  fillRule="evenodd"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#A6A9B4"
                >
                  {/* <!-- little bookmark lineset */}
                  <path
                    strokeWidth="2"
                    d="M592.473 478.332v13.654l4.294-4.714 4.308 4.722V478.07"
                  />
                  <path d="M559.13 525.966V478.07h48v44.923" />
                </g>
              </svg>
            </div>
            <Header as="h3" textAlign="center" style={s.cardTitle}>
              {lessonStatus.lesson__name}
            </Header>
            {this.renderTakeButton()}
            <div style={s.footer}>
              { isCardBased ? (
                <div>
                  {cardCount}
                  {' '}
                  ACTIVITIES
                </div>
              ) : (
                <div />
              ) }
              <div>{moment.duration(lessonStatus.lesson__median_time_spent, 'seconds').humanize()}</div>
            </div>
          </div>
        </CardContainer>
      </div>
    );
  }
}

export default Radium(LessonLibraryCard);
