import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const s = {
  cardContainer: {
    background: '#FFFFFF',
    boxShadow:
    // eslint-disable-next-line max-len
      '0px 11.28px 23.3955px rgba(0, 0, 0, 0.0215656), 0px 6.0308px 12.5083px rgba(0, 0, 0, 0.0178832), 0px 3.38082px 7.01207px rgba(0, 0, 0, 0.015), 0px 1.79553px 3.72406px rgba(0, 0, 0, 0.0121168), 0px 0.747159px 1.54966px rgba(0, 0, 0, 0.00843437)',
    borderRadius: '4px',
    width: '496px',
    height: '216px',
    passing: '10px',
    margin: '10px',
  },
  cardBody: {
    display: 'flex',
  },
  chartContainer: {
    width: '180px',
    height: '160px',
    marginLeft: '10px',
    marginRight: '30px',
    marginTop: '20px',
  },
  cardTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '21px',
    color: '#7E7F8F',
    marginLeft: '10px',
    marginTop: '10px',
    marginBottom: '0px',
  },
  subHeader: {
    position: 'absolute',
    width: '164px',
    height: '33px',
    left: '186px',
    top: '55px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    // fontWeight: '700',
    fontSize: '28px',
    lineHeight: '33px',
    color: '#4D4E58',
    whiteSpace: 'nowrap',
  },
  timeDescription: {
    position: 'absolute',
    width: '90px',
    height: '16px',
    left: '186px',
    top: '89px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: '#7E7F8F',
  },
  chartDetails: {
    marginTop: '10px',
    width: '100%',
  },
  legendRow: {
    display: 'flex',
    alignContent: 'space-between',
  },
  legendPercent: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: '15px',
    color: '#4D4E58',
    width: '35px',
    display: 'flex',
    alignItems: 'center',
  },
  legendCount: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '13px',
    lineHeight: '15px',
    color: '#4D4E58',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '10px',
    paddingRight: '10px',
    width: '60px',
  },
  testStyle: {
    width: '6px',
    height: '6px',
  },
  recordName: {
    position: 'static',
    width: '153px',
    height: '15px',
    left: '84px',
    top: '0px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '15px',
    color: '#4E7BEF',
    flex: 'none',
    order: '2',
    flexGrow: '0',
    margin: '0px 8px',
  },
};

class PieChartCard extends Component {
  static propTypes = {
    subjectDescription: PropTypes.string,
    chartData: PropTypes.array,
    timeDescription: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.chartComponent = React.createRef();
  }

  componentDidMount() {
    const container = this.chartComponent.current.container.current;

    container.style.height = '100%';
    container.style.width = '100%';
    this.chartComponent.current.chart.reflow();
  }

  render() {
    const options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      title: {
        text: '',
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false,
          },
          showInLegend: false,
          size: 100,
        },
      },
      colors: [],
      series: [
        {
          name: this.props.subjectDescription,
          colorByPoint: true,
          innerSize: '40%',
          data: [],
        },
      ],
    };
    let total = 0;
    const data = [];
    this.props.chartData.forEach((record) => {
      options.colors.push(record.color);
      total += record.total;
      data.push({ name: record.name, y: record.total });
    });
    for (let i = 0; i !== data.length; i += 1) {
      data[i].y /= total;
    }

    options.series[0].data = data;

    return (
      <div style={{ display: 'flex', width: '476px', height: '186px' }}>
        <div style={s.chartContainer}>
          <HighchartsReact ref={this.chartComponent} highcharts={Highcharts} options={options} />
        </div>
        <div style={s.chartDetails}>
          <div style={s.subHeader}>
            <strong>{total}</strong>
            {' '}
            {this.props.subjectDescription}
          </div>
          <div style={s.timeDescription}>{this.props.timeDescription}</div>
          <div style={{ marginTop: '100px', marginLeft: '16px' }}>
            {this.props.chartData.map((record) => {
              const percent = (record.total / total) ? ((record.total / total) * 100.0) : 0;
              const style = {
                width: '6px',
                height: '6px',
                background: record.color,
                borderRadius: '2px',
                marginRight: '2px',
              };
              return (
                <div key={`${record.name.replace(' ', '-')}-legend`} style={s.legendRow}>
                  <div style={s.legendPercent}>
                    {percent.toFixed(0)}
                    %
                  </div>
                  <div style={s.legendCount}>
                    <div style={style}>&nbsp;</div>
                    {record.total}
                  </div>
                  <div style={s.recordName}>{record.name}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Radium(PieChartCard);
