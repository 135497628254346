import ls from 'local-storage';
import { ModelBase } from './model';

class Organization extends ModelBase {
  static namespace = 'organization';

  async uploadImage(file) {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('type', 'images');
    const response = await fetch(`/api/v2/organizations/${this.id}/upload/`, {
      method: 'POST',
      credentials: 'same-origin',
      header: {
        Authorization: `Bearer ${ls('token.access')}`,
        Accept: 'application/json',
      },
      body: formData,
    });
    const data = await response.json();
    return data.url;
  }
}
export default Organization.register();
