import React, { Component } from 'react';
import Radium from 'radium';
import bind from 'bind-decorator';
import PropTypes from 'prop-types';
import { PDFReader } from 'reactjs-pdf-reader';
import { Loader } from 'semantic-ui-react';

class PDFViewer extends Component {
  static propTypes = {
    url: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    onScroll: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  @bind
  handleScroll(e) {
    const scrollPercent = e.target.clientHeight / (e.target.scrollHeight - e.target.scrollTop);
    if (this.props.onScroll) {
      this.props.onScroll(this, scrollPercent);
    }
  }

  render() {
    const { url } = this.props;

    const style = {
      height: this.props.height,
      width: this.props.width,
      overflow: 'scroll',
      backgroundColor: 'white',
    };

    const pdfStyle = {};
    if (this.state.loading) {
      pdfStyle.display = 'none';
    }

    return (
      <div style={style} onScroll={this.handleScroll}>
        <PDFReader
          style={pdfStyle}
          url={url}
          showAllPage
          key={url}
          onDocumentComplete={() => {
            this.setState({ loading: false });
          }}
        />
        { this.state.loading ? (
          <Loader active />
        ) : null }
      </div>
    );
  }
}

export default Radium(PDFViewer);
