import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Table, Button, Icon } from 'semantic-ui-react';
import moment from 'moment';
import _ from 'underscore';

class ReportList extends Component {
  static propTypes = {
    reportTasks: PropTypes.array,
    reports: PropTypes.array,
  };

  render() {
    const statusColors = ['blue', 'blue', 'yellow', 'red'];
    const statusText = ['Download', 'Download', 'No Data', 'Error'];
    const statusIcon = ['download', 'download', 'exclamation triangle', 'exclamation triangle'];
    const reportExecs = this.props.reportTasks.map((item) => {
      const reportName = _.findWhere(this.props.reports, { name: item.type }).display_name;
      return (
        <Table.Row key={item.id}>
          <Table.Cell singleLine>{moment(item.created_at).format('MMMM Do YYYY, hh:mm a')}</Table.Cell>
          <Table.Cell singleLine>{reportName}</Table.Cell>
          <Table.Cell singleLine>
            <Button
              href={item.download_url}
              disabled={!item.status}
              color={statusColors[item.status - 1]}
              icon
              labelPosition="left"
            >
              <Icon name={statusIcon[item.status - 1]} />
              {item.status ? statusText[item.status - 1] : 'Running'}
            </Button>
          </Table.Cell>
          <Table.Cell>{item.message}</Table.Cell>
        </Table.Row>
      );
    });
    return (
      <div>
        <Table celled striped selectable collapsing>
          <Table.Header>
            <Table.Row>
              <Table.Cell>Time Run</Table.Cell>
              <Table.Cell>Report Type</Table.Cell>
              <Table.Cell>Status</Table.Cell>
              <Table.Cell>Message</Table.Cell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{reportExecs}</Table.Body>
        </Table>
      </div>
    );
  }
}

export default Radium(ReportList);
