import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import moment from 'moment';
import { Button, Header, Icon } from 'semantic-ui-react';
import CardContainer from '../Shared/CardContainer';

const s = {
  container: {
    backgroundColor: '#D6D7DC',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTile: {
    width: '600px',
    height: '650px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    color: '#918f90',
    fontSize: '1.5em',
    justifyContent: 'space-between',
    borderTop: '1px solid #d1d1d1',
    marginTop: '20px',
    width: '100%',
  },
  cardTitle: {
    fontWeight: '400',
    lineHeight: '1.3',
    fontSize: '1.6em',
  },
};

class LessonTitleCard extends Component {
  static propTypes = {
    startLesson: PropTypes.func,
    lesson: PropTypes.object,
    preview: PropTypes.bool,
    status: PropTypes.object,
    empty: PropTypes.bool,
  };

  render() {
    const { lesson, status, empty } = this.props;
    let isReview = false;
    let dueString = '';
    let btnLabel = 'START';
    if (!this.props.preview) {
      const { retake } = status;
      isReview = !!status.cards_available_to_review.length;
      dueString = `Due ${isReview
        ? moment.min(status.cards_available_to_review.map((r) => moment(r.scheduled_review_date))).fromNow()
        : moment(status.localized_start_date).fromNow()}`;
      if (isReview || retake) {
        btnLabel = retake ? 'RETAKE' : 'REVIEW';
      }
    }
    const cardCount = isReview
      ? status.cards_available_to_review.filter(
        (card) => moment(card.scheduled_review_date).isBefore(moment()),
      ).length
      : lesson.card_count;
    return (
      <CardContainer stack={Math.min(5, lesson.card_count)} loaded={!!lesson} compact>
        { lesson ? (
          <div style={s.cardTile}>
            <Icon name="book" size="huge" color="blue" />
            <Header as="h3" textAlign="center" style={s.cardTitle}>{lesson.name}</Header>
            {!empty
            && (
            <Button color="blue" onClick={this.props.startLesson}>
              {btnLabel}
            </Button>
            )}
            <div style={s.footer}>
              <div>
                {cardCount}
                {' '}
                ACTIVITIES
              </div>
              <div>{dueString}</div>
            </div>
          </div>
        ) : null }
      </CardContainer>
    );
  }
}

export default Radium(LessonTitleCard);
