import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import _ from 'underscore';
import pluralize from 'pluralize';
import {
  Button,
  Checkbox, Dimmer, Dropdown, Header, Icon, Input, Loader, Pagination, Segment, Table,
} from 'semantic-ui-react';

const s = {
  container: {
    margin: '10px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  filterRow: {
    padding: '10px',
  },
};

class ModelTable extends Component {
  static propTypes = {
    title: PropTypes.string,
    model: PropTypes.func.isRequired, // the model which the table will be based on
    baseFilter: PropTypes.object, // the base filter which will be applied to the entire table
    select: PropTypes.bool, // whether to show selection checkboxes
    selectionFilter: PropTypes.func, // determines whether to show a checkbox on a specific row
    defaultPerPage: PropTypes.number,
    defaultSort: PropTypes.arrayOf(PropTypes.string),
    limit: PropTypes.number,
    join: PropTypes.array,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    // columns = [{name: 'ID', sort: true, search: true, filter: true, key: 'id'},
    //              Fetches the property named 'id' as is, column is sortable, searchable, and filterable
    //            {name: 'Uppercase Name', key: ['name', String.prototype.toUpperCase]}]
    //              Fetches the property named 'name' and runs the specified function on it
    //            {name: 'Uppercase Name', key: ['name', String.prototype.toUpperCase]}]
    //              Fetches the property named 'name' and runs the specified function on it
    // actions = {
    //   "dropdown of activity": activity function that accepts a list of objects to operate on
    // }
    setSearch: PropTypes.func,
    selectionChanged: PropTypes.func,
    updateData: PropTypes.func,
    updateHandler: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    gridLayout: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    select: false,
    baseFilter: {},
    defaultSort: null,
    defaultPerPage: 10,
    setSearch: null,
    updateData: null,
    updateHandler: null,
    selectionChanged: null,
    gridLayout: false,
  };

  static Actions = ({ closeOnChange = true, ...props }) => {
    const childs = React.Children.toArray(props.children);
    const disabled = !childs.filter((child) => !child.props.requireSelected).length && !props.selectedModels.length;
    return (
      <Dropdown
        className={props.color}
        text={props.title || 'Actions'}
        button
        disabled={disabled}
        direction="left"
        closeOnChange={closeOnChange}
      >
        <Dropdown.Menu>
          {React.Children.map(props.children, (child) => (
            React.cloneElement(child, { selectedModels: props.selectedModels, selectNone: props.selectNone })))}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  static ActionButton = (props) => {
    return (
      <Button
        key={props.name}
        onClick={() => props.method(props.selectedModels)}
        color={props.color}
        className={props.className}
      >
        {props.name}
      </Button>
    );
  };

  static Action = (props) => {
    if (props.requireSelected && !props.selectedModels.length) return null;
    return (
      <Dropdown.Item
        key={props.name}
        text={props.name}
        onClick={() => {
          props.method(props.selectedModels);
        }}
      />
    );
  };

  static EmptyContainer = (props) => {
    return props.children;
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedModels: [],
      data: [],
      page: 1,
      perPage: parseInt(localStorage.getItem(`${props.model.namespace}PerPage`)) || props.defaultPerPage,
      searchText: '',
      filter: {},
      search: {},
      sort: [props.defaultSort[0], props.defaultSort[1] === 'asc', props.defaultSort[2]],
      uniqueField: 'id',
    };
  }

  @bind
  async componentDidMount() {
    if (this.props.setSearch) {
      this.setSearch = this.props.setSearch;
    } else {
      this.setSearch = this.defaultSetSearch;
    }

    if (this.props.updateData) {
      this.updateData = async () => {
        this.setState({ loading: true });
        const props = _.extend({}, this.props, this.state);

        const result = await this.props.updateData(props);

        const allData = result[0];
        let uniqueField = 'id';
        if (allData.length && !Object.keys(allData[0]).includes('id')) {
          uniqueField = _.find(Object.keys(allData[0]), (key) => key.includes('id'));
          this.setState({ uniqueField });
        }
        const data = result[1];
        this.setState({
          data,
          allData,
          dataCount: allData.length,
          page: 1,
          loading: false,
        });
      };
    } else {
      this.updateData = this.defaultUpdateData;
    }
    await this.updateData(this);
    const { allData } = this.state;
    const filterOptions = {};
    this.props.columns.filter((col) => !!col.filter).forEach((col) => {
      filterOptions[typeof col.key === 'string' ? col.key : col.key[0]] = [{
        key: -1,
        text: `All ${pluralize(col.name)}`,
        value: -1,
      }].concat([...new Set(_.pluck(allData, typeof col.key === 'string' ? col.key : col.key[0]))].map(
        (opt) => ({
          key: opt,
          text: typeof col.filter === 'object' ? col.filter[opt] : opt,
          value: opt,
        }),
      ).filter((opt) => opt.text));
    });
    this.props.columns.filter((col) => !!col.arrayFilter).forEach((col) => {
      filterOptions[typeof col.key === 'string' ? col.key : col.key[0]] = [{
        key: -1,
        text: `All ${pluralize(col.name)}`,
        value: -1,
      }].concat([...new Set(_.pluck(allData, typeof col.key === 'string' ? col.key : col.key[0]).flat())].map(
        (opt) => ({
          key: opt,
          text: typeof col.filter === 'object' ? col.filter[opt] : opt,
          value: opt,
        }),
      ).filter((opt) => opt.text));
    });
    this.setState({ filterOptions });
    if (this.props.updateHandler) {
      this.props.updateHandler(() => {
        this.updateData(this);
      });
    }
  }

  @bind
  componentDidUpdate(prevProps) {
    if (prevProps.model !== this.props.model) {
      this.updateData(this);
    }
  }

  @bind
  handlePageChange(e, { activePage }) {
    const { allData, perPage } = this.state;
    // this.setState({ page: activePage }, () => this.updateData(this));
    this.setState({ page: activePage });
    const data = allData.slice(
      (activePage - 1) * perPage,
      Math.min(activePage * perPage, allData.length),
    );
    this.setState({ data });
  }

  @bind
  selectNone() {
    this.setState({
      selectedModels: [],
    });
    if (this.props.selectionChanged) {
      this.props.selectionChanged([]);
    }
  }

  @bind
  selectAll() {
    const { selectionFilter } = this.props;
    const selectableData = selectionFilter ? this.state.allData.filter(selectionFilter) : this.state.allData;
    let selectedModels = [];
    if (this.state.selectedModels.length !== selectableData.length) {
      selectedModels = selectableData;
    }
    this.setState({
      selectedModels,
    });
    if (this.props.selectionChanged) {
      this.props.selectionChanged(selectedModels);
    }
  }

  @bind
  selectModel(mod) {
    const { uniqueField } = this.state;
    let selectedModels = [];
    if (this.state.selectedModels.some((item) => item[uniqueField] === mod[uniqueField])) {
      selectedModels = this.state.selectedModels.filter((item) => item[uniqueField] !== mod[uniqueField]);
    } else {
      selectedModels = this.state.selectedModels.concat([mod]);
    }
    this.setState({
      selectedModels,
    });
    if (this.props.selectionChanged) {
      this.props.selectionChanged(selectedModels);
    }
  }

  @bind
  updateFilter(key, value) {
    const { filter } = this.state;
    if (value === -1) delete filter[key];
    else filter[key] = value;
    this.setState({ filter }, () => this.updateData(this));
  }

  @bind
  search(e, { value }) {
    this.setState({ searchText: value });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setSearch(value, this);
    }, 300);
  }

  @bind
  defaultSetSearch(value) {
    if (!value) {
      this.setState({ search: null }, () => this.updateData(this));
    } else {
      let keys = [];
      const splitValue = value.split(' ');
      this.props.columns
        .filter((col) => col.search)
        .forEach((col) => {
          if (Array.isArray(col.search)) keys = keys.concat(col.search);
          else if (Array.isArray(col.key)) keys = keys.concat(col.key.slice(0, col.key.length - 1));
          else keys.push(col.key);
        });
      const query = {};
      keys.forEach((key) => {
        query[key] = splitValue;
      });
      this.setState({ search: query }, () => this.updateData(this));
    }
  }

  @bind
  async defaultUpdateData() {
    this.setState({ loading: true });
    let allData = null;
    let { perPage } = this.state;
    const { join } = this.props;
    let call = this.props.model
      .objects()
      .filtered(this.props.baseFilter)
      .filtered(this.state.filter)
      .search(this.state.search)
      .sorted(...this.state.sort);
    if (join) {
      join.forEach((j) => {
        if (typeof j === 'string') call = call.join(j);
        if (typeof j === 'object') call.joinData = _.extend(call.joinData, j);
      });
    }
    if (this.props.limit) {
      allData = await call.page(1, this.props.limit);
      perPage = this.props.limit;
    } else {
      allData = await call.all();
    }
    const data = await allData.slice(0, perPage);
    let uniqueField = 'id';
    if (allData.length && !Object.keys(allData[0]).includes('id')) {
      uniqueField = _.find(Object.keys(allData[0]), (key) => key.includes('id'));
      this.setState({ uniqueField });
    }
    this.selectNone();
    this.setState({
      data,
      allData,
      dataCount: allData.length,
      loading: false,
    });
  }

  @bind
  setPerPage(perPage) {
    localStorage.setItem(`${this.props.model.namespace}PerPage`, perPage);
    this.setState({ perPage }, this.updateData);
  }

  @bind
  handleSort(column) {
    const { sort } = this.state;
    const key = typeof column.key === 'string' ? column.key : column.key[0];
    if (sort[0] === key && sort[1]) {
      this.setState(
        { sort: [key, false, typeof column.sort === 'function' ? column.sort : null] },
        () => this.updateData(this),
      );
    } else {
      this.setState(
        { sort: [key, true, typeof column.sort === 'function' ? column.sort : null] },
        () => this.updateData(this),
      );
    }
  }

  @bind
  getSortIcon(key) {
    const { sort } = this.state;
    if (key === sort[0]) {
      if (sort[1]) {
        return <Icon name="sort ascending" />;
      }
      return <Icon name="sort descending" />;
    }
    return <Icon name="sort" style={{ marginTop: "-3px" }} disabled />;
  }

  @bind
  renderTableRows() {
    const {
      columns, select, gridLayout, selectionFilter,
    } = this.props;
    const { selectedModels, uniqueField } = this.state;

    if (gridLayout) {
      const data = [];
      for (let i = 0; i !== this.state.data.length; i++) {
        const mod = this.state.data[i];
        const column = columns[0];
        const cellKey = mod[uniqueField] + (column.name || i.toString());
        data.push((
          <div className="hickory-model-table-gridview-cell" key={cellKey}>
            {column.key[column.key.length - 1](
              ...column.key.slice(0, column.key.length).map((key) => (key === 'this' ? mod : mod[key])),
            )}
          </div>
        ));
      }
      return data;
    }
    // no grid layout...
    const data = this.state.data.map((mod) => {
      const selected = !!selectedModels.find((sel) => sel[uniqueField] === mod[uniqueField]);
      const cells = columns.filter((col) => !(col.display === false)).map((column, i) => {
        const cellKey = mod[uniqueField] + (column.name || i.toString());
        if (typeof column.key === 'string') {
          return <Table.Cell key={cellKey}>{mod[column.key]}</Table.Cell>;
        }
        return (
          <Table.Cell key={cellKey}>
            {column.key[column.key.length - 1](
              ...column.key.slice(0, column.key.length).map((key) => (key === 'this' ? mod : mod[key])),
            )}
          </Table.Cell>
        );
      });
      return (
        <Table.Row key={mod[uniqueField]}>
          {select && (
            <Table.Cell>
              {(!selectionFilter || selectionFilter(mod)) && (
                <Checkbox onChange={() => this.selectModel(mod)} checked={selected} />
              )}
            </Table.Cell>
          )}
          {cells}
        </Table.Row>
      );
    });
    return data;
  }

  render() {
    const {
      columns, gridLayout, defaultPerPage,
    } = this.props;
    const {
      selectedModels, dataCount, perPage, page, search, filterOptions,
    } = this.state;
    const showSearch = _.some(columns, (col) => col.search);
    const totalPages = Math.ceil(dataCount / perPage);
    const children = _.groupBy(React.Children.toArray(this.props.children), (leech) => {
      if (leech.type === ModelTable.EmptyContainer) {
        return "EmptyContainer";
      }
      if (leech.type === ModelTable.Actions) {
        return "Actions";
      }
      if (leech.type === ModelTable.ActionButton) {
        return "Actions";
      }
      return "";
    });
    if (dataCount === 0 && !search && children.EmptyContainer && !this.state.loading) {
      return children.EmptyContainer[0];
    }
    return (
      <div style={s.container}>
        <div style={s.header}>
          <Header as="h2" style={{ marginBottom: 0 }}>{this.props.title}</Header>
          <div>
            {React.Children.map(children.Actions || [], (child) => React.cloneElement(child, {
              selectedModels,
              selectNone: this.selectNone,
            }))}
          </div>
        </div>
        {showSearch && (
        <Input
          key="search"
          style={s.search}
          value={this.state.searchText}
          icon={{ name: 'search', color: 'grey' }}
          placeholder={`Filter ${this.props.title}`}
          onChange={this.search}
          fluid
          autoFocus
        />
        )}
        <div style={s.filterRow}>
          {columns.filter(
            (col) => !!col.filter || !!col.arrayFilter,
          ).map(
            (col) => {
              const key = typeof col.key === 'string' ? col.key : col.key[0];
              return (
                <Dropdown
                  button
                  search
                  style={{ zIndex: 101 }}
                  loading={!filterOptions}
                  options={filterOptions ? filterOptions[key] : null}
                  defaultValue={-1}
                  onChange={
                  (e, { value }) => (this.updateFilter(typeof col.key === 'string' ? col.key : col.key[0], value))
                }
                />
              );
            },
          )}
        </div>
        {this.state.loading ? (
          <div>
            <Segment>
              <Dimmer active inverted style={{ position: 'relative', zIndex: 100 }}>
                <Loader style={{ position: 'relative', marginTop: '30px' }} />
              </Dimmer>
            </Segment>
          </div>
        ) : (
          <div>
            {gridLayout ? (
              <div
                className={
                  this.state.data.length === 2
                    ? 'hickory-model-table-gridview-small'
                    : 'hickory-model-table-gridview'
                 }
              >
                {this.renderTableRows()}
              </div>
            ) : (
              <Table basic="very" className="hickory-model-table" celled sortable>
                {_.some(columns, 'name')
                && (
                <Table.Header>
                  <Table.Row>
                    {this.props.select && (
                    <Table.HeaderCell>
                      <Checkbox
                        onChange={this.selectAll}
                        checked={!!selectedModels.length && selectedModels.length === dataCount}
                        indeterminate={selectedModels.length > 0 && selectedModels < dataCount}
                      />
                    </Table.HeaderCell>
                    )}
                    {columns.filter((col) => !(col.display === false)).map((column) => {
                      const style = { color: '#7E7F8F', whiteSpace: 'normal' };
                      if (!column.sort) style.pointerEvents = 'none';
                      return (
                        <Table.HeaderCell
                          key={column.key}
                          onClick={() => this.handleSort(column)}
                          style={style}
                        >
                          <div className="row" style={{ alignItems: 'center' }}>
                            {column.name}
                            {column.sort && this.getSortIcon(
                              typeof column.key === 'string' ? column.key : column.key[0],
                            )}
                          </div>
                        </Table.HeaderCell>

                      );
                    })}
                  </Table.Row>
                </Table.Header>
                )}
                <Table.Body>
                  {this.renderTableRows()}
                  {/* <Table.Row><Table.Cell colSpan={columns.length + (select ? 1 : 0)} /></Table.Row> */}
                </Table.Body>
              </Table>
            )}
            <div className="row center">
              <div className="row center">
                {!gridLayout && (
                  <div>
                    <Dropdown
                      selection
                      compact
                      value={perPage}
                      options={[defaultPerPage, defaultPerPage * 2, defaultPerPage * 5]
                        .map((n) => ({ key: n, text: n, value: n }))}
                      style={{ marginRight: '5px' }}
                      onChange={(e, { value }) => this.setPerPage(value)}
                    />
                    <span className="grey800">Results Per Page</span>
                  </div>
                )}
                <span className="grey800" style={{ marginLeft: '60px' }}>
                  Showing Results {(((page - 1) * perPage) + 1).toLocaleString()}
                  -
                  {Math.min(page * perPage, dataCount).toLocaleString()} of {dataCount?.toLocaleString()}
                </span>
              </div>
              {totalPages > 1 && (
                <Pagination
                  activePage={page}
                  totalPages={totalPages}
                  onPageChange={this.handlePageChange}
                  size="small"
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Radium(ModelTable);
