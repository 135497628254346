/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import Radium from 'radium';
import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  SelectionState,
  ContentBlock,
  genKey,
  CharacterMetadata,
  Entity,
  BlockMapBuilder,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';
import bind from 'bind-decorator';
import PropTypes from 'prop-types';
import { List, Repeat } from 'immutable';
import { Segment } from 'semantic-ui-react';
import LinkDecorator from '../LessonPlayer/LinkDecorator';
import ComposerMedia from './ComposerMedia';
import ComposerStyleControl from './ComposerStyleControl';
import { styleMap } from '../../constants';

const s = {
  cardTile: {
    width: '600px',
    minHeight: '650px',
  },
  styleContainer: {
    display: 'flex',
  },
  cardWrapper: {
    width: '600px',
    minHeight: '650px',
    display: 'flex',
    flexDirection: 'column',
  },
  cardScrollable: {
    overflow: 'auto',
    height: '100%',
    width: '100%',
    flex: '2 1 auto',
  },
  cardFrame: {
    border: '1px solid #ccc',
    margin: '2rem auto 0 auto',
    color: '#000',
    backgroundColor: '#fff',
    borderRadius: '4px',
    minHeight: '600px',
    overflow: 'scroll',
    cursor: 'text',
  },
};

function insertMediaBlock(editorState, blockType) {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  const afterRemoval = Modifier.removeRange(contentState, selectionState, 'backward');

  const targetSelection = afterRemoval.getSelectionAfter();
  const afterSplit = Modifier.splitBlock(afterRemoval, targetSelection);
  const insertionTarget = afterSplit.getSelectionBefore();

  const asEmbed = Modifier.setBlockType(afterSplit, insertionTarget, 'atomic');

  let data;
  if (blockType === 'image') {
    data = {
      type: 'image',
      id: null,
      url: null,
    };
  } else if (blockType === 'video') {
    data = {
      type: 'video',
      id: null,
      url: null,
    };
  } else if (blockType === 'embed') {
    data = {
      type: 'embed',
      code: null,
    };
  } else if (blockType === 'more-info') {
    data = {
      type: 'more-info',
    };
  }

  const entityKey = Entity.create('atomic', 'IMMUTABLE', data);

  const charData = CharacterMetadata.create({ entity: entityKey });

  const fragmentArray = [
    new ContentBlock({
      key: genKey(),
      type: 'atomic',
      text: ' ',
      characterList: List(Repeat(charData, 1)),
    }),
  ];

  const fragment = BlockMapBuilder.createFromArray(fragmentArray);

  const withMedia = Modifier.replaceWithFragment(asEmbed, insertionTarget, fragment);

  const newContent = withMedia.merge({
    selectionBefore: selectionState,
    selectionAfter: withMedia.getSelectionAfter().set('hasFocus', true),
  });

  return EditorState.push(editorState, newContent, 'insert-fragment');
}

function removeEmbedBlock(editorState, blockKey) {
  const content = editorState.getCurrentContent();
  const block = content.getBlockForKey(blockKey);

  const targetRange = new SelectionState({
    anchorKey: blockKey,
    anchorOffset: 0,
    focusKey: blockKey,
    focusOffset: block.getLength(),
  });

  const withoutEmbed = Modifier.removeRange(content, targetRange, 'backward');
  const resetBlock = Modifier.setBlockType(
    withoutEmbed,
    withoutEmbed.getSelectionAfter(),
    'unstyled',
  );

  const newState = EditorState.push(editorState, resetBlock, 'remove-range');
  return EditorState.forceSelection(newState, resetBlock.getSelectionAfter());
}
class InstructionContent extends Component {
  static defaultProps = {
    lesson: null,
    card: {},
  };

  static propTypes = {
    lesson: PropTypes.object,
    card: PropTypes.object,
    saveCard: PropTypes.func,
    takeSnapshot: PropTypes.func,
    historyPosition: PropTypes.number,
  };

  constructor(props) {
    super(props);

    const rawContent = props.card.content;
    const content = Object.keys(rawContent).length
      ? EditorState.createWithContent(convertFromRaw(rawContent), LinkDecorator)
      : EditorState.createEmpty();

    this.state = {
      // EditorState is the top-level state object for the draftjs-editor.
      // https://facebook.github.io/draft-js/docs/api-reference-editor-state.html
      // Configuring editorState with the decorator allows it to identify links and render a special component for links
      editorState: content,
      readOnly: false,
      saveNow: false,
    };

    this.editor = React.createRef();
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.historyPosition !== prevProps.historyPosition && this.props.historyPosition !== 0) {
      this.setState({
        editorState: EditorState.createWithContent(
          convertFromRaw(this.props.card.content),
          LinkDecorator,
        ),
      });
    }
    return null;
  }

  componentDidMount() {
    // call function to set DOM focus on the Editor
    this.focusEditor();
  }

  @bind
  focusEditor() {
    // Explicitly focus the Editor so spellCheck will display on card without having to click into the card
    this.editor.current.focus();
  }

  @bind
  handleKeyCommand(command) {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    // const rawContent = convertToRaw(contentState);
    const startKey = selectionState.getStartKey();
    const blockBefore = contentState.getBlockBefore(startKey);
    const selectedBlockText = contentState.getBlockForKey(startKey).getText();

    if (
      command === 'backspace'
      && blockBefore
      && selectedBlockText === ''
      && blockBefore.getType() === 'atomic'
    ) {
      this._removeEmbed(blockBefore.getKey());
      return 'handled';
    }
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this._updateState(newState);
      return 'handled';
    }

    return 'not-handled';
  }

  @bind
  handleReturn(e) {
    if (e.shiftKey) {
      const newLine = '\n';
      const currentState = this.state.editorState;
      const newContentState = Modifier.replaceText(
        currentState.getCurrentContent(),
        currentState.getSelection(),
        newLine,
      );
      this._updateState(EditorState.push(currentState, newContentState, 'insert-characters'));
      return 'handled';
    }
    return 'not-handled';
  }

  /*
   * The main updater for state
   */
  @bind
  _updateState(editorState) {
    const newState = convertToRaw(editorState.getCurrentContent());
    const oldState = convertToRaw(this.state.editorState.getCurrentContent());
    this.setState({ editorState });
    if (JSON.stringify(newState) !== JSON.stringify(oldState) || this.state.saveNow) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.props.takeSnapshot();
        this.props.saveCard({ content: newState });
        this.setState({ saveNow: false });
      }, 300);
    }
  }

  @bind
  myBlockRenderer(block) {
    if (block.getType() === 'atomic') {
      console.log(this.props.lesson);
      return {
        component: ComposerMedia,
        props: {
          onSaveEmbed: (blockKey, code) => this._saveEmbed(blockKey, code),
          onSaveVideo: (blockKey, path) => this._saveVideo(blockKey, path),
          onSaveImage: (blockKey, image) => this._saveImage(blockKey, image),
          onRemove: (blockKey) => this._removeEmbed(blockKey),
          lesson: this.props.lesson,
        },
      };
    }
    return null;
  }

  @bind
  toggleBlockType(blockType) {
    if (blockType === 'image' || blockType === 'embed' || blockType === 'video') {
      this.setState({
        readOnly: true,
        editorState: insertMediaBlock(this.state.editorState, blockType),
      });
    } else if (blockType === 'more-info') {
      this.setState({
        editorState: insertMediaBlock(this.state.editorState, blockType),
      });
    } else {
      this._updateState(RichUtils.toggleBlockType(this.state.editorState, blockType));
    }
  }

  @bind
  toggleInlineStyle(inlineStyle) {
    this._updateState(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
  }

  @bind
  toggleCode() {
    this._updateState(RichUtils.toggleCode(this.state.editorState));
  }

  @bind
  onTab(e) {
    const maxDepth = 4;
    this._updateState(RichUtils.onTab(e, this.state.editorState, maxDepth));
  }

  // DraftJS helper function when removing style before reapplying style
  get currentStyleList() {
    const currentStyle = this.state.editorState.getCurrentInlineStyle()._map._map._root;
    if (currentStyle) {
      return currentStyle.entries;
    }
    return null;
  }

  // DRAFTJS CUSTOM STYLE FUNCTIONS
  // modal functions are in the ComposerStyleControl component
  @bind
  toggleCustomStyle(selectedStyle, prefix) {
    // main function to remove/apply custom styles to the span in draftjs.
    // These custom styles need to match a key on our customStyleMap which is an opject stored in the constant styleMap
    // if a custom style does not match those stored in the styleMap,
    // then it will NOT display the style, but will still be stored on the draftjs inline style array
    // @param selectedStyle is string ex '12px', 'Hoefler Text' the chosen from the button dropdown modal
    // @param prefix is a constant that proceeds all items in the syleMap constant.
    // These prefix are used so we can add/remove styles associated with that prefix
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    const prefixStyle = prefix + selectedStyle;
    const currentInlineStyles = editorState.getCurrentInlineStyle();

    // get current styles already applied to the selection for the parameter prefix and remove them
    const currentStyles = [];
    const currentStyleList = this.currentStyleList;
    if (currentStyleList) {
      currentStyleList.forEach((styleItem) => {
        // loop over all styles - each styleItem in the list are also arrays ie. ["SIZE_20px", 5]
        // if the styleItem is prefixed with the passed in prefix then push into array to later
        // remove from currentInlineStyles
        const prefixLength = prefix.length;
        const styleItemPrefix = styleItem[0].slice(0, prefixLength);
        if (styleItemPrefix === prefix) {
          currentStyles.push(styleItem[0]);
        }
      });
    }

    const nextContentState = currentStyles.reduce(
      (contentState, styleItem) => Modifier.removeInlineStyle(contentState, selection, styleItem),
      editorState.getCurrentContent(),
    );

    let nextEditorState = EditorState.push(editorState, nextContentState, 'change-inline-style');

    // Unset style override for the current styles
    // this removes the style from the currentInlineStyles
    if (selection.isCollapsed()) {
      nextEditorState = currentStyles.reduce(
        (state, styleItem) => RichUtils.toggleInlineStyle(state, styleItem),
        nextEditorState,
      );
    }

    // If the custom style is not currently a style in the currentInlineStyle, apply it.
    // If the custom style is already in the the currentInlineStyle or the selectedStyle then do not reapply customStyle
    // apply the prefix style to the editor state that matches key in styleMap
    if (!currentInlineStyles.has(prefixStyle)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, prefixStyle);
    }

    // save the new editor state
    this._updateState(nextEditorState);
  }

  @bind
  addLinkEntity(url) {
    const editorState = this.state.editorState;

    // Example of this: https://facebook.github.io/draft-js/docs/advanced-topics-entities.html#content
    //                  https://github.com/facebook/draft-js/blob/master/examples/draft-0-9-1/link/link.html#L87
    const entityKey = Entity.create('LINK', 'MUTABLE', { url });

    this._updateState(RichUtils.toggleLink(editorState, editorState.getSelection(), entityKey));
  }

  @bind
  removeLinkEntity() {
    const editorState = this.state.editorState;

    // Example of this: https://facebook.github.io/draft-js/docs/advanced-topics-entities.html#content
    //                  https://github.com/facebook/draft-js/blob/master/examples/draft-0-9-1/link/link.html#L110
    this._updateState(RichUtils.toggleLink(editorState, editorState.getSelection(), null));
  }

  _removeEmbed(blockKey) {
    const { editorState } = this.state;
    const newEditorState = removeEmbedBlock(editorState, blockKey);
    this.setState({
      editorState: newEditorState,
      readOnly: false,
    });
    this._updateState(newEditorState);
  }

  _saveEmbed(blockKey, code) {
    const { editorState } = this.state;
    const content = editorState.getCurrentContent();
    const block = content.getBlockForKey(blockKey);
    Entity.mergeData(block.getEntityAt(0), { code });
    const newState = EditorState.createWithContent(content);
    this.setState({
      editorState: newState,
      readOnly: false,
      saveNow: true,
    });
    this._updateState(newState);
  }

  _saveImage(blockKey, image) {
    const { editorState } = this.state;
    const content = editorState.getCurrentContent();
    const block = content.getBlockForKey(blockKey);
    Entity.mergeData(block.getEntityAt(0), { image, type: 'image' });
    const newState = EditorState.createWithContent(content);
    this.setState({
      editorState: newState,
      readOnly: false,
      saveNow: true,
    });
    this._updateState(newState);
  }

  _saveVideo(blockKey, path) {
    const { editorState } = this.state;
    const content = editorState.getCurrentContent();
    const block = content.getBlockForKey(blockKey);
    // eslint-disable-next-line max-len
    const code = `<video controls="controls" controlsList=”nodownload” width="560" height="315"><source src="${path}" type="video/mp4"></video>`;
    Entity.mergeData(block.getEntityAt(0), { code, type: 'embed' });
    const newState = EditorState.createWithContent(content);
    this.setState({
      editorState: newState,
      readOnly: false,
      saveNow: true,
    });
    this._updateState(newState);
  }

  render() {
    const { editorState } = this.state;
    // console.log('====CONTENT=====>', this.props.cardDetail.content);
    // console.log('=====STATE====>', editorState);

    let styleControls;
    // This was uncommented legacy code from Jack, think the intention was to only show the StyleControlBar
    // if there is a `selection` at beginning not sure about the startKey===endKey
    // AND as long as the first block isn't a `media` aka image.  Removed media check, TODO in sorting this out.
    // UPDATE: Removed this condition: `if (startKey === endKey)` -- this prevented the toolbar from showing whem
    // multiple blocks were selected, but styling multiple blocks still works.
    //
    if (this.state.readOnly === false) {
      styleControls = (
        <Segment inverted color="grey" style={{ padding: 0, margin: '-10px' }}>
          <ComposerStyleControl
            editorState={editorState}
            toggleInlineType={this.toggleInlineStyle}
            toggleCustomStyle={this.toggleCustomStyle}
            toggleBlockType={this.toggleBlockType}
            toggleCode={this.toggleCode}
            addLinkEntity={this.addLinkEntity}
            removeLinkEntity={this.removeLinkEntity}
          />
        </Segment>
      );
      // }
    }

    return (
      <div style={s.cardWrapper}>
        {styleControls}
        <div style={s.cardScrollable}>
          <div style={s.cardFrame} onClick={this.focusEditor}>
            <Editor
              editorState={editorState}
              spellCheck
              onTab={this.onTab}
              ref={this.editor}
              readOnly={this.state.readOnly}
              handleKeyCommand={this.handleKeyCommand}
              handleReturn={this.handleReturn}
              blockRendererFn={this.myBlockRenderer}
              customStyleMap={styleMap}
              onChange={this._updateState}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Radium(InstructionContent);
