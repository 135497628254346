/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import _ from 'underscore';
import bind from 'bind-decorator';
import PropTypes from 'prop-types';
import {
  Loader,
  Table,
  Label,
  Dropdown,
  Modal,
  Button,
  Input,
  Ref,
  Progress,
  Checkbox,
} from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SumoLogger from 'sumo-logger';
import Course from '@/models/course';
import Lesson from '@/models/lesson';
import User from '@/models/user';
import Organization from '@/models/organization';
import ModelTable from '../Shared/ModelTable';
import {
  SUMO, CARD, SCORM, ARTICLE, VIDEO, Features,
} from '../../constants';
import CourseLessonRow from './CourseLessonRow';

const sumoLogger = new SumoLogger({ endpoint: SUMO });

class CourseBuilder extends Component {
  static propTypes = {
    course: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showAddLessonModal: false,
      selectedLessons: [],
      editingAvailable: false,
      articles_enabled: false,
      scorm_enabled: false,
      video_lessons_enabled: false,
      uploading_scorm: false,
      showCloneProgress: false,
      showExportModal: false,
      cloning_progress: 0,
      showDeleteLessonConfirmation: false,
      confirmedLessonName: '',
      selectedLesson: { name: '' },
    };
  }

  @bind
  showAddLessonModal() {
    this.setState({
      showAddLessonModal: true,
      selectedLessons: [],
    });
  }

  @bind
  async handleAddLessonsToCourse() {
    const { selectedLessons } = this.state;
    await this.addLessonsToCourse(selectedLessons);
  }

  @bind
  async addLessonsToCourse(selectedLessons) {
    const { lessons } = this.state;
    let { course } = this.state;

    const selectedLessonIds = _.map(selectedLessons, (lesson) => lesson.id);

    const lessonPath = course.lesson_path;
    const existingLessonIds = _.map(lessonPath, (info) => info.lesson_id);
    const lessonIdsToAdd = _.without(selectedLessonIds, existingLessonIds);

    lessonIdsToAdd.forEach((id) => {
      const lesson = _.findWhere(selectedLessons, { id });
      lessonPath.push({ lesson_id: id });
      lessons.push(lesson);
    });

    course = await Course.objects().update(course.id, { lesson_path: lessonPath });
    this.setState({
      course,
      showAddLessonModal: false,
      lessons,
    });
  }

  @bind
  async removeLesson(lesson) {
    let { course, lessons } = this.state;
    const lessonPath = _.reject(course.lesson_path, (info) => info.lesson_id === lesson.id);
    lessons = _.reject(lessons, (l) => l.id === lesson.id);

    course = await Course.objects().update(course.id, { lesson_path: lessonPath });
    this.setState({
      course,
      lessons,
    });
  }

  @bind
  async deleteLesson(lesson) {
    await this.removeLesson(lesson);
    await Lesson.objects().update(lesson.id, { deleted: true });
    this.setState({
      showDeleteLessonConfirmation: false,
    });
  }

  @bind
  handleOnDragEnd(result) {
    const { lessons } = this.state;
    let { course } = this.state;
    if (!result.destination) {
      return;
    }

    const lesson = lessons[result.source.index];
    lessons.splice(result.source.index, 1);
    lessons.splice(result.destination.index, 0, lesson);

    const lessonPath = _.map(lessons, (l) => {
      return _.findWhere(course.lesson_path, { lesson_id: l.id });
    });

    // the first lesson in a path CANNOT have any requirements...
    lessonPath[0].time_delay = 0;
    lessonPath[0].previous_lesson_score = 0;

    this.setState({ lessons }, async () => {
      course = await Course.objects().update(course.id, { lesson_path: lessonPath });
      this.setState({ course });
    });
  }

  @bind
  async createLesson(lessonType) {
    const lessonName = this.state.newLessonName.trim();
    const newLesson = await Lesson.objects().create({ name: lessonName, lesson_type: lessonType });
    if (newLesson.id === undefined) {
      sumoLogger.log(
        `ERROR: missing lesson_id for new Lesson ${this.state.newLessonName} in LessonListContainer.jsx`,
      );
    }
    this.addLessonsToCourse([newLesson]);
    this.props.history.push(`/app/v2/lessons/${newLesson.id}/editor`);
  }

  @bind
  async createSCORMLesson() {
    const lessonName = this.state.newLessonName.trim();
    const newLesson = await Lesson.objects().create({ name: lessonName, lesson_type: 2 });

    const file = this.fileInput.current.files[0];
    this.setState({ uploading_scorm: true }, async () => {
      const response = await newLesson.uploadSCORMContent(file);
      if (response.error) {
        await Lesson.objects().update(newLesson.id, { deleted: true });
        const error = response.error;
        this.setState({
          uploading_scorm: false,
          scorm_error: error,
        });
      } else {
        this.setState({
          uploading_scorm: false,
          showSCORMUploadModal: false,
        });
      }
    });
  }

  @bind
  menuOptionsForLesson(lesson, allowEdits) {
    if (lesson.lesson_type === CARD || lesson.lesson_type === ARTICLE || lesson.lesson_type === VIDEO) {
      return (
        <Dropdown.Menu>
          {allowEdits ? (
            <React.Fragment>
              <Dropdown.Item
                text="Remove"
                onClick={() => {
                  this.removeLesson(lesson);
                }}
              />
              <Dropdown.Item
                text="Edit"
                onClick={() => {
                  const editable = lesson.available_to_edit;
                  if (editable) {
                    this.props.history.push(`/app/v2/lessons/${lesson.id}/editor`);
                  } else {
                    this.setState({ showEditWarningModal: lesson });
                  }
                }}
              />
            </React.Fragment>
          ) : null}
          <Dropdown.Item
            text="Preview"
            onClick={() => {
              this.props.history.push(`/app/v2/lessons/${lesson.id}/player/preview`);
            }}
          />
        </Dropdown.Menu>
      );
    }
    if (!allowEdits) {
      return null;
    }
    return (
      <Dropdown.Menu>
        <Dropdown.Item
          text="Remove"
          onClick={() => {
            this.removeLesson(lesson);
          }}
        />
      </Dropdown.Menu>
    );
  }

  @bind
  menuForLesson(lesson, allowEdits) {
    const options = this.menuOptionsForLesson(lesson, allowEdits);
    if (!options) {
      return null;
    }
    return (
      <Dropdown icon="ellipsis vertical" floating labeled className="icon">
        {options}
      </Dropdown>
    );
  }

  async componentDidMount() {
    const course = await Course.objects()
      .filtered({ id: this.props.course.id })
      .first();
    const lessonIds = course.lesson_path.map((info) => {
      return info.lesson_id;
    });
    const lessons = await Lesson.objects()
      .filtered({ id: lessonIds })
      .all();
    const user = await User.me();
    const organization = await Organization.objects().get(user.organization_id);

    const editingAvailable = user.roles.includes('Owner')
                          || user.roles.includes('Admin')
                          || user.roles.includes('Writer');

    this.setState({
      editingAvailable,
      course,
      loading: false,
      lessons: _.map(lessonIds, (id) => {
        // map the lessonIds to lessons as the former is guaranteed
        // to be in the desired order as specified in course.lesson_path
        return _.findWhere(lessons, { id });
      }),
      scorm_enabled: organization.scorm_enabled,
      articles_enabled: user.waffle_flags.includes(Features.ARTICLES),
      video_lessons_enabled: user.waffle_flags.includes(Features.VIDEO_LESSONS),
    });
  }

  @bind
  waitForClone(dolly) {
    this.setState({ cloning_progress: this.state.cloning_progress + 1 });
    setTimeout(() => {
      if (this.state.cloning_progress >= 100) {
        this.setState({ showCloneProgress: false });
        this.props.history.push(`/app/v2/lessons/${dolly.id}/editor`);
      } else {
        this.waitForClone(dolly);
      }
    }, 300);
  }

  @bind
  doExportLesson() {
    this.setState({
      showExportModal: false,
    });
    const lessonId = this.state.export_lesson_id;
    window.location.href = `/api/v2/cmi5/${lessonId}`; // This is NOT a HACK.
    // Do not change the above line to this.props.history.push. This link generated a download.
    // It's not a link to a react page, so we don't want to push it into the history, the browser
    // will display the native download prompt (not a webpage).
  }

  @bind
  setExportLessonAckCookie(checked) {
    if (checked) {
      localStorage.setItem('export_lesson_ack', true);
    } else {
      localStorage.removeItem('export_lesson_ack');
    }
  }

  @bind
  async updateLessonCriteria(lessonId, criteria) {
    let { course } = this.state;
    const lessonPath = _.map(course.lesson_path, (info) => {
      if (info.lesson_id === lessonId) {
        return {
          lesson_id: lessonId,
          previous_lesson_score: criteria.previous_lesson_score,
          time_delay: criteria.time_delay,
          review_type: criteria.review_type,
          retake_threshold: criteria.retake_threshold,
          review_frequency: criteria.review_frequency,
        };
      }
      return info;
    });
    course = await Course.objects().update(course.id, { lesson_path: lessonPath });
    this.setState({
      course,
    });
  }

  render() {
    const {
      course, loading, lessons, editingAvailable,
    } = this.state;

    if (loading) {
      return (
        <div className="hickory-container">
          <Loader active={this.state.loading} inline="centered" />
        </div>
      );
    }
    const lessonColumns = [
      {
        name: 'Lesson Name',
        search: true,
        key: 'name',
      },
      {
        name: 'Type',
        key: [
          'lesson_type',
          (lessonType) => {
            if (lessonType === CARD) {
              return 'Card';
            }
            if (lessonType === SCORM) {
              return 'Scorm';
            }
            if (lessonType === ARTICLE) {
              return 'Article';
            }
            if (lessonType === VIDEO) {
              return 'Video';
            }
            return 'Other';
          },
        ],
      },
      {
        name: 'Activities',
        key: [
          'lesson_type',
          'card_count',
          (lessonType, cardCount) => {
            return lessonType === CARD ? cardCount : 1;
          },
        ],
      },
    ];

    return (
      <React.Fragment>
        <div className="hickory-sub-header">
          <h2>Lessons in Course</h2>
          {editingAvailable && course.allow_edits ? (
            <Dropdown text="Add..." button>
              <Dropdown.Menu>
                <Dropdown.Item text="Existing Lessons" onClick={this.showAddLessonModal} />
                {this.state.articles_enabled && (
                <Dropdown.Item
                  text="New Article-based Lesson"
                  onClick={() => this.setState({ showCreateLessonModal: true, createLessonType: ARTICLE })}
                />
                )}
                <Dropdown.Item
                  text="New Card-based Lesson"
                  onClick={() => this.setState({ showCreateLessonModal: true, createLessonType: CARD })}
                />
                {this.state.scorm_enabled && (
                <Dropdown.Item
                  text="New SCORM Lesson"
                  onClick={() => this.setState({ showSCORMUploadModal: true })}
                />
                )}
                {this.state.video_lessons_enabled && (
                <Dropdown.Item
                  text="New Video-based Lesson"
                  onClick={() => this.setState({ showCreateLessonModal: true, createLessonType: VIDEO })}
                />
                )}
              </Dropdown.Menu>
            </Dropdown>
          ) : null}
        </div>
        <DragDropContext onDragEnd={this.handleOnDragEnd}>
          <Table>
            <Droppable droppableId="table">
              {(provided) => (
                <Ref innerRef={provided.innerRef}>
                  <Table.Body {...provided.droppableProps}>
                    {_.map(course.lesson_path, (info, i) => {
                      const lesson = _.findWhere(lessons, { id: info.lesson_id });
                      if (!lesson) {
                        return null;
                      }
                      return (
                        <Draggable
                          isDragDisabled={!editingAvailable || !course.allow_edits}
                          key={lesson.id}
                          draggableId={lesson.id}
                          index={i}
                        >
                          {(p) => (
                            <Ref innerRef={p.innerRef}>
                              <Table.Row
                                {...p.draggableProps}
                                {...p.dragHandleProps}
                                key={lesson.id}
                              >
                                <Table.Cell style={{ width: "10px" }}>
                                  <Label>{i + 1}</Label>
                                </Table.Cell>
                                <Table.Cell>
                                  <CourseLessonRow
                                    key={`${lesson.id}`}
                                    lesson={lesson}
                                    index={i}
                                    allowEdits={editingAvailable && course.allow_edits}
                                    criteria={info}
                                    onCriteriaChange={(criteria) => {
                                      this.updateLessonCriteria(lesson.id, criteria);
                                    }}
                                  />
                                </Table.Cell>
                                <Table.Cell>
                                  {this.menuForLesson(lesson, editingAvailable && course.allow_edits)}
                                </Table.Cell>
                              </Table.Row>
                            </Ref>
                          )}
                        </Draggable>
                      );
                    })}
                  </Table.Body>
                </Ref>
              )}
            </Droppable>
          </Table>
        </DragDropContext>

        <Modal
          open={this.state.showCreateLessonModal}
          onClose={() => {
            this.setState({ showCreateLessonModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Create Lesson</Modal.Header>
          <Modal.Content>
            Name: <Input onChange={(e, { value }) => this.setState({ newLessonName: value })} />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showCreateLessonModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                this.createLesson(this.state.createLessonType);
              }}
            >
              Create
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showSCORMUploadModal}
          onClose={() => {
            this.setState({ showSCORMUploadModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Create Lesson</Modal.Header>
          {this.state.uploading_scorm ? (
            <Modal.Content>
              <div>
                Uploading...
                <Loader active inline />
              </div>
            </Modal.Content>
          ) : (
            <Modal.Content>
              {this.state.scorm_error ? <div>{this.state.scorm_error}</div> : null}
              <div>
                Name:{' '}
                <Input
                  error={!this.state.newLessonName}
                  onChange={(e, { value }) => this.setState({ newLessonName: value })}
                />
              </div>
              <div />
              <div>
                Choose a SCORM file to upload:
                <div />
                <input id="file-input" type="file" accept=".zip" ref={this.fileInput} />
              </div>
              <div>
                <br />
                <em>NOTE: SCORM files must be in SCORM 2004 format</em>
              </div>
            </Modal.Content>
          )}
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              disabled={this.state.uploading_scorm}
              onClick={() => {
                this.setState({ showCreateLessonModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              disabled={this.state.uploading_scorm || !this.state.newLessonName}
              onClick={() => {
                this.createSCORMLesson();
              }}
            >
              Create
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showAddLessonModal}
          onClose={() => {
            this.setState({ showAddLessonModal: false });
          }}
          style={{ width: '800px' }}
          closeIcon
        >
          <Modal.Header>Add Lesson</Modal.Header>
          <Modal.Content>
            <ModelTable
              title=""
              model={Lesson}
              select
              columns={lessonColumns}
              defaultSort={['name', 'dsc']}
              baseFilter={{
                deleted: false,
                published: true,
                id__not: this.state.course.lesson_path.map((info) => info.lesson_id),
              }}
              selectionChanged={(selectedLessons) => this.setState({ selectedLessons })}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showAddLessonModal: false });
              }}
            >
              Cancel
            </Button>
            <Button type="submit" className="green" onClick={this.handleAddLessonsToCourse}>
              Add
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={this.state.showCloneProgress} style={{ width: '500px' }}>
          <Modal.Header>Cloning Lesson</Modal.Header>
          <Modal.Content>
            <Progress percent={this.state.cloning_progress} indicating />
          </Modal.Content>
          <Modal.Actions />
        </Modal>

        <Modal
          open={this.state.showPrintModal}
          onClose={() => {
            this.setState({ showPrintModal: false });
          }}
          closeIcon
        >
          <Modal.Header>Print Lesson</Modal.Header>
          <Modal.Content>
            <iframe
              id="printFrame"
              src={`/app/v2/lessons/${this.state.printLessonId}/print?embedded=true`}
              className="hickory-modal-iframe"
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showPrintModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                document.getElementById('printFrame').contentWindow.print();
              }}
            >
              Print
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showExportModal}
          onClose={() => {
            this.setState({ showExportModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Export Lesson</Modal.Header>
          <Modal.Content>
            <p>
              You can export a Hickory lesson to cmi5 format, compatable with xAPI enabled systems.
            </p>
            <p>NOTE: Lessons exported in this way require:</p>
            <ul>
              <li>An external system compatible with xAPI & cmi5</li>
              <li>Actors with mbox ids that match email addresses in Hickory</li>
              <li>An active license of Hickory</li>
            </ul>
            <br />
            <Checkbox
              label="Do not show me this again"
              onClick={(e, { checked }) => {
                this.setExportLessonAckCookie(checked);
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showExportModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                this.doExportLesson();
              }}
            >
              Export
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showDeleteLessonConfirmation}
          onClose={() => {
            this.setState({ showDeleteLessonConfirmation: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Delete Lesson</Modal.Header>
          <Modal.Content>
            <p>
              Deleting this lesson will remove it from <strong>ALL</strong> courses in Hickory.
            </p>
            <p>
              If you wish to proceed, type &quot;<em>{this.state.selectedLesson.name}</em>&quot; in
              the box below.
            </p>
            <br />
            <Input
              style={{ width: '100%' }}
              onChange={(e, { value }) => {
                this.setState({ confirmedLessonName: value });
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showDeleteLessonConfirmation: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="red"
              disabled={this.state.confirmedLessonName !== this.state.selectedLesson.name}
              onClick={() => {
                this.deleteLesson(this.state.selectedLesson);
              }}
            >
              Delete
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showEditWarningModal}
          onClose={() => this.setState({ showEditWarningModal: false })}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>You are editing a scheduled lesson</Modal.Header>
          <Modal.Content>
            While you will still be able to edit the contents of cards, you will be unable to add or
            remove cards.
            {' '}
            <br />
            If you need to do so, please
            {' '}
            <strong>Clone</strong>
            {' '}
            the lesson first and edit the cloned copy.
            {' '}
            <br />
            Otherwise, continue on to
            {' '}
            <strong>Edit</strong>
            {' '}
            the lesson
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="grey"
              onClick={() => {
                this.setState({
                  showEditWarningModal: false,
                });
              }}
            >
              Cancel
            </Button>
            <Button
              positive
              onClick={() => {
                this.props.history.push(`/app/v2/lessons/${this.state.showEditWarningModal.id}/editor`);
              }}
            >
              Edit
            </Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

export default CourseBuilder;
