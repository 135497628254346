import React, { Component } from 'react';
import Radium from 'radium';
import moment from 'moment';
import PropTypes from 'prop-types';
import AssignmentList from '../Shared/AssignmentList';
import LineChartCard from '../Shared/LineChartCard';
import StatusCount from '../../models/status_count';

const s = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '15%',
    paddingRight: '15%',
    paddingTop: '40px',
  },
};

class UserAssignments extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  componentDidMount() {
    const now = moment();
    const startDate = moment()
      .add(-29, 'days')
      .format('YYYY-MM-DD');

    StatusCount.objects()
      .filtered({
        _user: this.props.user.id,
        _start_date: startDate,
        _end_date: now.format('YYYY-MM-DD'),
        _type: 'completed',
      })
      .all()
      .then((completedAssignments) => {
        this.setState({
          completedAssignments,
          startDate,
        });
      });
  }

  render() {
    return (
      <div style={s.container}>
        <LineChartCard
          title="Assignment Activity"
          chartData={[
            // {name: 'New', data: this.state.new_assignments, color: '#4575F1', fillColor: '#4575F10D'},
            {
              name: 'Completed', data: this.state.completedAssignments, color: '#2BB674', fillColor: '#2BB6740D',
            },
          ]}
          startDate={this.state.startDate}
        />
        <AssignmentList baseFilter={{ user_id: this.props.user.id }} />
      </div>
    );
  }
}

export default Radium(UserAssignments);
