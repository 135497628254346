/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  Button, Icon, Image, Segment, Modal,
} from 'semantic-ui-react';
import Dropzone from 'react-dropzone';

const s = {
  segment: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 0,
    height: '150px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  deleteIcon: {
    float: 'right',
    marginTop: '5px',
    marginLeft: '-33px',
    position: 'relative',
    backgroundColor: 'white',
  },
};

class Upload extends Component {
  static propTypes = {
    source: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
    updateFile: PropTypes.func,
    description: PropTypes.string,
    accept: PropTypes.string,
    maxSize: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      showErrorModal: false,
    };

    this.dropZone = React.createRef();
  }

  render() {
    const { source, updateFile, description } = this.props;
    if (source) {
      return (
        <div style={s.container}>
          <div>
            <Image
              src={source.image || source}
              as="a"
              href={source.image || source}
              target="_blank"
              rel="noreferrer"
              rounded
            />
            <Icon name="remove" onClick={() => updateFile(null)} style={s.deleteIcon} circular link />
          </div>
        </div>

      );
    }
    return (
      <React.Fragment>
        <Dropzone
          maxFiles={1}
          ref={this.dropZone}
          onDrop={(acceptedFiles) => {
            let good = true;
            if (acceptedFiles && this.props.maxSize) {
              const maxSizeInBytes = this.props.maxSize * 1024 * 1024;
              good = acceptedFiles[0].size <= maxSizeInBytes;
            }
            if (good) {
              updateFile(acceptedFiles);
            } else {
              this.setState({
                showErrorModal: true,
              });
            }
          }}
          accept={this.props.accept}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => {
            const { ref, ...rootProps } = getRootProps();
            return (
              <Segment style={s.segment} placeholder {...rootProps}>
                <input {...getInputProps()} />
                <span>
                  Drag
                  {' '}
                  {description}
                  {' '}
                  File Here to Upload
                </span>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  or&nbsp;
                  <Button color="blue"> Browse Files</Button>
                </div>
              </Segment>
            );
          }}

        </Dropzone>

        <Modal open={this.state.showErrorModal}>
          <Modal.Header>File Too Large</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <p>
                The file you&apos;re trying to upload exceeds the maximum file size of
                { ' ' }
                { this.props.maxSize }MB. Please select a different file.
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="green"
              onClick={() => {
                this.setState({
                  showErrorModal: false,
                });
              }}
            >
              OK
            </Button>
          </Modal.Actions>
        </Modal>

      </React.Fragment>
    );
  }
}

export default Radium(Upload);
