import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Icon } from 'semantic-ui-react';
import { appLightBackgroundBodyColor, fontMediumColor } from '../../styles/palette';

const s = {
  styleButton: {
    position: 'relative',
    padding: '0.25rem',
    // width: '38px',
    // height: '38px',
    cursor: 'pointer',
    margin: '0.1rem',
    borderRadius: '3px',
    backgroundColor: 'rgb(186, 190, 199)',
    color: 'white',
    transition: 'all 0.3s ease',
    ':hover': {
      color: fontMediumColor,
      backgroundColor: 'white',
    },
  },
  active: {
    backgroundColor: appLightBackgroundBodyColor,
    color: fontMediumColor,
  },
};

class ComposerStyleButton extends Component {
  static defaultProps = {
    iconName: '',
    buttonStyle: '',
    description: '',
    active: false,
    onToggle: () => {},
  };

  static propTypes = {
    iconName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    buttonStyle: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool,
    onToggle: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    custom: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.buttonStyle);
    };
  }

  render() {
    let style = s.styleButton;
    if (this.props.active) {
      style = { ...style, ...s.active };
    }

    return (
      <div style={style} onMouseDown={this.onToggle} title={this.props.description}>
        {this.props.custom ? this.props.iconName : (
          <Icon name={this.props.iconName} size="large" />
        ) }
        {this.props.children}
      </div>
    );
  }
}

export default Radium(ComposerStyleButton);
