import React, { Component } from 'react';
import Radium from 'radium';
import {
  Divider, Loader, Icon,
} from 'semantic-ui-react';
import User from '@/models/user';

let s = {};

class OnboardingContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const user = await User.me();
    this.setState({
      user,
      loading: false,
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <div>
          <div style={s.content}>
            <Loader active={this.state.loading} inline="centered" />
          </div>
        </div>
      );
    }
    const { user } = this.state;
    return (
      <div>
        <div style={s.content}>
          <h1 style={s.h1}>Welcome & Onboarding.</h1>
          <Divider style={s.divider} />
          <h2 style={s.h2}>
            Hi
            {' '}
            {user.first_name}
            , welcome to Hickory.
          </h2>
          <h3 style={s.h3}>Here are a few things to get started:</h3>
          <span style={s.instructionalText}>
            We&apos;re glad to have you onboarding in Hickory. Below are a few steps that should help you get up and
            running faster. If you have any questions, or need any assistance during this process, do not hesitate to
            reach out to us at
            {' '}
            <a href="mailto:support@hickorytraining.com">support@hickorytraining.com</a>
          </span>

          <div style={s.onboardingStepBox}>
            <input
              style={s.checkbox}
              type="checkbox"
              checked
            />
            <p style={s.stepLabel}>
              Step 1:
            </p>
            <p style={s.stepDescription}>
              Create Your First Group
            </p>

            <div>
              Add Lesson&nbsp;
              <Icon name="plus circle" style={s.plus} color="blue" />
            </div>
          </div>

        </div>
      </div>
    );
  }
}

s = {
  content: {
    marginLeft: '20%',
    marginRight: '20%',
    marginTop: '52px',
  },
  divider: {
    marginTop: '40px',
    marginBottom: '40px',
  },
  h1: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '32px',
    lineHeight: '37px',
    color: '#27272B',
  },
  h2: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '125%',
    color: '#27272B',
  },
  h3: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: '24px',
    lineHeight: '125%',
    color: '#27272B',
    marginTop: '-10px',
  },
  instructionalText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '125%',
    color: '#4D4E58',
  },
  onboardingStepBox: {
    background: '#FFFFFF',
    borderRadius: '3px',
    borderColor: '#E2E5EA',
    display: 'flex',
    height: '66px',
    padding: '18px',
    marginTop: '30px',
  },
  checkbox: {
    width: '19px',
    height: '19px',
    marginRight: '5px',
  },
  stepLabel: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '125%',
    color: '#27272B',
  },
  stepDescription: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '125%',
    color: '#4D4E58',
    marginLeft: '10px',
  },
  plus: {
    color: '#4E7BEF !important',
    fontSize: '21px',
  },
};

export default Radium(OnboardingContainer);
