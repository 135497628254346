import React, { Component } from 'react';
import Radium from 'radium';
import { Input, Button, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import bind from 'bind-decorator';
import { hickoryGreen } from '../../styles/palette';

let s = {};

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    const nonSSOdomains = ['localhost', 'staging', 'app', 'beta'];
    const domain = window.location.hostname.split('.')[0];
    const useSSO = nonSSOdomains.indexOf(domain) === -1;

    this.state = {
      username: null,
      password: null,
      working: false,
      invalidUsernameOrPassword: false,
      ssoAllowed: useSSO,
      showFormsBasedAuth: !useSSO,
    };
  }

  @bind
  async doLogin() {
    if (!this.state.username || !this.state.password) {
      this.setState({ invalidUsernameOrPassword: true });
      return;
    }
    this.setState({ working: true });
    const response = await fetch('/api/v2/login/?force=true', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: this.state.username.toLowerCase(),
        password: this.state.password,
      }),
    });

    if (response.status === 200) {
      const tokenContainer = await response.json();
      localStorage.setItem('token.access', tokenContainer.access);
      localStorage.setItem('token.refresh', tokenContainer.refresh);

      window.location.href = '/';
    } else {
      this.setState({ invalidUsernameOrPassword: true });
    }
    this.setState({ working: false });
  }

  render() {
    return (
      <div style={s.contents}>
        <div style={s.loginCard}>
          <div style={s.loginCardTitle}>Login to Hickory</div>

          {this.state.showFormsBasedAuth ? (
            <div style={s.cardContents}>
              {this.state.invalidUsernameOrPassword ? (
                <div style={s.error}>
                  Invalid email address or password, please try again or click &quot;Forgot
                  Password&quot; below.
                </div>
              ) : null}
              <div>Email Address</div>
              <div>
                <Input
                  style={s.input}
                  onChange={(e, { value }) => this.setState({ username: value })}
                />
              </div>
              <div>Password</div>
              <div>
                <Input
                  type="password"
                  style={s.input}
                  onChange={(e, { value }) => this.setState({ password: value })}
                />
              </div>
              <div>
                {this.state.working ? (
                  <Loader active={this.state.working} inline="centered" />
                ) : (
                  <Button style={s.button} onClick={this.doLogin}>
                    Login
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div style={s.cardContents}>
              <p style={s.ssoBody}>
                Authenticate with
                {' '}
                <a href="/saml2/login">SSO Login</a>
              </p>
            </div>
          )}

          {this.state.showFormsBasedAuth ? (
            <div style={s.cardFooter}>
              {this.state.ssoAllowed ? (
                <a
                  style={s.footerAction}
                  onClick={() => this.setState({ showFormsBasedAuth: false, invalidUsernameOrPassword: false })}
                >
                  Using SSO?
                </a>
              ) : null}
              <Link style={s.footerAction} to="/app/v2/reset-password">
                Forgot Password?
              </Link>
            </div>
          ) : (
            <div style={s.cardFooter}>
              <a style={s.footerAction} onClick={() => this.setState({ showFormsBasedAuth: true })}>
                Have a Password?
              </a>
            </div>
          )}
        </div>
        <div style={s.versionNumber}>
          version&nbsp;
          {window.APP_VERSION}
        </div>
      </div>
    );
  }
}

s = {
  contents: {
    backgroundColor: '#f5f5f5',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginCard: {
    backgroundColor: 'white',
    width: '36rem',
  },
  loginCardTitle: {
    fontSize: '2.2em',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '20px',
  },
  cardContents: {
    margin: '20px',
  },
  input: {
    width: '100%',
    marginBottom: '20px',
    height: '4em',
  },
  button: {
    width: '100%',
    height: '4em',
    backgroundColor: hickoryGreen,
    color: 'white',
  },
  cardFooter: {
    backgroundColor: '#f5f5f5',
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '1.5rem',
  },
  footerAction: {
    color: '#918f90',
    marginTop: '10px',
    marginLeft: '40px',
    cursor: 'pointer',
  },
  ssoBody: {
    fontSize: '1.4em',
    marginTop: '50px',
    marginBottom: '50px',
  },
  error: {
    color: '#d24e4e',
    backgroundColor: 'rgba(210,78,78,.1)',
    fontSize: '1.1em',
    border: '1px solid #d24e4e',
    padding: '1.5rem 1.5rem 1.5rem 5rem',
    borderRadius: '4px',
    marginBottom: '20px',
  },
  versionNumber: {
    position: 'fixed',
    bottom: '5px',
    left: '5px',
  },
};

export default Radium(LoginContainer);
