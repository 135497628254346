import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

class LineChartCard extends Component {
  static propTypes = {
    xTitle: PropTypes.string,
    chartData: PropTypes.array,
    startDate: PropTypes.string,
    yTitle: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.chartComponent = React.createRef();
  }

  componentDidMount() {
    const container = this.chartComponent.current.container.current;

    container.style.height = '100%';
    container.style.width = '100%';
    this.chartComponent.current.chart.reflow();
  }

  render() {
    const options = {
      chart: {
        type: 'areaspline',
      },
      credits: {
        enabled: false,
      },
      title: {
        text: '',
      },
      xAxis: {
        type: 'datetime',
        tickInterval: 24 * 3600 * 1000,
        title: {
          text: this.props.xTitle,
        },
      },
      yAxis: {
        title: {
          text: this.props.yTitle,
        },
      },
      tooltip: {
        crosshairs: true,
        shared: true,
      },
      plotOptions: {
        areaspline: {
          pointIntervalUnit: 'day',
          pointStart: +new Date(this.props.startDate),
          lineWidth: 1,
          marker: {
            symbol: 'circle',
            radius: 4,
            lineColor: '#FFFFFF',
            lineWidth: 2,
          },
        },
      },
      series: this.props.chartData,
    };
    return <HighchartsReact ref={this.chartComponent} options={options} highcharts={Highcharts} />;
  }
}

export default Radium(LineChartCard);
