import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import { Header } from 'semantic-ui-react';
import _ from 'underscore';
import Report from '@/models/report';
import Execution from '@/models/execution';
import ReportGenCard from './ReportGenCard';
import ReportList from './ReportList';
import { colorDashboardBlue, colorDashboardDarkGray, colorDashboardLightGray } from '../../styles/palette';

const s = {
  container: {
    margin: '40px 15%',
  },
  mainNav: {
    width: '100%',
    display: 'block',
    // borderBottom: '1px solid #eee',
    padding: '20px 30px 0px 30px',
    boxSizing: 'border-box',
    borderBottom: `1px solid ${colorDashboardLightGray}`,
    position: 'relative',
  },
  subTitle: {
    fontSize: '1.2rem',
    fontWeight: '500',
    cursor: 'pointer',
    height: '100%',
    position: 'relative',
    float: 'left',
    textAlign: 'center',
    marginRight: '30px',
    lineHeight: '2em',
    borderBottom: `4px solid ${colorDashboardLightGray}`,
    boxSizing: 'border-box',
  },
  active: {
    borderBottom: `4px solid ${colorDashboardBlue}`,
  },
  subTitleLink: {
    display: 'block',
    padding: '15px 5px',
    height: '100%',
    color: colorDashboardDarkGray,
  },
};

class ReportContainer extends Component {
  static propTypes = {
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      reportTasks: [],
      reports: [],
    };
  }

  async componentDidMount() {
    // TODO: display loading spinner
    const reports = await Report.objects().all();
    const maxHeight = _.reduce(reports, (memo, report) => Math.max(memo, report.dimensions.length), 0);
    this.setState({
      reports,
      maxHeight,
    });
    this.checkReports();
    this.timer = setInterval(this.checkReports, 10000, true);
  }

  @bind
  async checkNewReport(id) {
    const newReport = await Execution.objects().get(id);
    const temp = this.state.reportTasks;
    const idx = temp.findIndex((item) => item.id === id);
    temp[idx] = newReport;
    if (newReport.status) {
      this.setState({
        reportTasks: temp,
      });
      clearInterval(this.newTimer);
    }
  }

  @bind
  async checkReports() {
    const reportTasks = await Execution.objects().all();
    this.setState({
      reportTasks,
    });
    const runningReports = reportTasks.filter((item) => !item.status);
    if (!runningReports.length) {
      clearInterval(this.timer);
      this.timer = false;
    }
  }

  @bind
  addReport(id) {
    this.checkReports();
    this.newTimer = setInterval(() => {
      this.checkNewReport(id);
    }, 1000);
    setTimeout(() => {
      clearInterval(this.newTimer);
      if (!this.timer) {
        this.timer = setInterval(this.checkReports, 10000, true);
      }
    }, 30000);
  }

  render() {
    return (
      <div>
        <div style={s.container}>
          <Header as="h1">Reports</Header>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            { this.state.reports.filter((r) => !r.disabled).map((report) => (
              <div key={report.name} style={{ width: 'fit-content' }}>
                <ReportGenCard
                  user={this.props.user}
                  report={report}
                  orgID={this.props.user.organization_id}
                  addReport={this.addReport}
                  height={this.state.maxHeight}
                />
              </div>
            ))}
          </div>
          <ReportList reportTasks={this.state.reportTasks} reports={this.state.reports} />
        </div>
      </div>
    );
  }
}

export default Radium(ReportContainer);
