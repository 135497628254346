const colorForGroupNameLength = (nameLength) => {
  if (nameLength <= 50) {
    return 'grey';
  }
  if (nameLength >= 80) {
    return 'red';
  }
  return 'orange';
};

// eslint-disable-next-line import/prefer-default-export
export { colorForGroupNameLength };
