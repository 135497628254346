/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import _ from 'underscore';
import ls from 'local-storage';
import bind from 'bind-decorator';
import PropTypes from 'prop-types';
import {
  Loader,
  Dropdown,
  Modal,
  Button,
  Input,
  TextArea,
  Progress,
  Checkbox,
  Tab,
  Grid,
  Image,
  Dimmer,
} from 'semantic-ui-react';
import Course from '@/models/course';
import Lesson from '@/models/lesson';
import User from '@/models/user';
import Upload from '../Shared/Upload';
import Breadcrumb from '../Shared/Breadcrumb';
import CourseBuilder from './CourseBuilder';
import CourseGroups from './CourseGroups';

class CourseDetailsContainer extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.doSearch = _.debounce(async (term) => {
      const request = await fetch(
        `/api/v2/images/search/?query=${term}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ls('token.access')}`,
          },
        },
      );
      const response = await request.json();

      const { photos } = response;
      this.setState({
        pexelsResults: photos,
      });
    }, 800);
    this.state = {
      course_id: props.match.params.course_id,
      loading: true,
      showDeleteModal: false,
      showRenameModal: false,
      showUpdateHeaderModal: false,
      editingAvailable: false,
      showEditDescriptionModal: false,
      courseDescription: '',
      showCloneProgress: false,
      showExportModal: false,
      cloning_progress: 0,
      showDeleteLessonConfirmation: false,
      confirmedLessonName: '',
      selectedLesson: { name: '' },
      pexelsResults: [],
      pexelsSearchTerm: "",
    };
  }

  @bind
  showRenameModal() {
    this.setState({
      showRenameModal: true,
      courseName: this.state.course.name,
    });
  }

  @bind
  showEditDescriptionModal() {
    this.setState({
      showEditDescriptionModal: true,
      courseDescription: this.state.course.description,
    });
  }

  @bind
  async updateCourseDescription() {
    let { course } = this.state;

    course = await Course.objects().update(course.id, {
      description: this.state.courseDescription,
    });
    this.setState({
      course,
      showEditDescriptionModal: false,
    });
  }

  @bind
  async renameCourse() {
    let { course } = this.state;
    course = await Course.objects().update(course.id, { name: this.state.courseName });
    this.setState({
      course,
      showRenameModal: false,
    });
  }

  @bind
  async deleteCourse() {
    const { course } = this.state;
    await Course.objects().update(course.id, { deleted: true });
    this.props.history.push('/app/v2/courses/');
  }

  @bind
  async removeLesson(lesson) {
    let { course, lessons } = this.state;
    const lessonPath = _.reject(course.lesson_path, (info) => info.lesson_id === lesson.id);
    lessons = _.reject(lessons, (l) => l.id === lesson.id);

    course = await Course.objects().update(course.id, { lesson_path: lessonPath });
    this.setState({
      course,
      lessons,
    });
  }

  @bind
  async deleteLesson(lesson) {
    await this.removeLesson(lesson);
    await Lesson.objects().update(lesson.id, { deleted: true });
    this.setState({
      showDeleteLessonConfirmation: false,
    });
  }

  @bind
  async updateHeaderImage(files) {
    this.setState({ loadingImage: true });
    let { course } = this.state;
    if (!files) {
      // TODO: ???
    } else {
      const file = files[0];
      const url = await course.uploadCoverImage(file);
      course = await Course.objects().update(course.id, { header_image_path: url });
      this.setState({
        loadingImage: false,
        course,
        showUpdateHeaderModal: false,
      });
    }
  }

  async componentDidMount() {
    const course = await Course.objects()
      .filtered({ id: this.state.course_id })
      .first();
    const lessonIds = course.lesson_path.map((info) => {
      return info.lesson_id;
    });
    const lessons = await Lesson.objects()
      .filtered({ id: lessonIds })
      .all();
    const user = await User.me();

    const editingAvailable = user.roles.includes('Owner')
                          || user.roles.includes('Admin')
                          || user.roles.includes('Writer');

    this.setState({
      editingAvailable,
      course,
      loading: false,
      lessons: _.map(lessonIds, (id) => {
        // map the lessonIds to lessons as the former is guaranteed
        // to be in the desired order as specified in course.lesson_path
        return _.findWhere(lessons, { id });
      }),
    });
  }

  @bind
  waitForClone(dolly) {
    this.setState({ cloning_progress: this.state.cloning_progress + 1 });
    setTimeout(() => {
      if (this.state.cloning_progress >= 100) {
        this.setState({ showCloneProgress: false });
        this.props.history.push(`/app/v2/lessons/${dolly.id}/editor`);
      } else {
        this.waitForClone(dolly);
      }
    }, 300);
  }

  @bind
  doExportLesson() {
    this.setState({
      showExportModal: false,
    });
    const lessonId = this.state.export_lesson_id;
    window.location.href = `/api/v2/cmi5/${lessonId}`; // This is NOT a HACK.
    // Do not change the above line to this.props.history.push. This link generated a download.
    // It's not a link to a react page, so we don't want to push it into the history, the browser
    // will display the native download prompt (not a webpage).
  }

  @bind
  setExportLessonAckCookie(checked) {
    if (checked) {
      localStorage.setItem('export_lesson_ack', true);
    } else {
      localStorage.removeItem('export_lesson_ack');
    }
  }

  @bind
  async defaultImageSelected({ target }) {
    this.setState({ loadingImage: true });
    let { course } = this.state;
    const { src } = target;
    course = await Course.objects().update(course.id, { header_image_path: src });
    this.setState({
      loadingImage: false,
      course,
      showUpdateHeaderModal: false,
    });
  }

  @bind
  async searchPexels({ target }) {
    this.setState({
      pexelsSearchTerm: target.value,
      pexelsResults: [],
    });
    this.doSearch(target.value);
  }

  @bind
  async selectPexelsImage(record) {
    this.setState({ loadingImage: true });
    const url = record.src.original;

    let { course } = this.state;
    const imageUrl = await course.uploadPexelsCoverImage(url);
    course = await Course.objects().update(course.id, { header_image_path: imageUrl });
    this.setState({
      loadingImage: false,
      course,
      showUpdateHeaderModal: false,
    });
  }

  render() {
    const {
      course, loading, editingAvailable, loadingImage,
    } = this.state;
    const { history } = this.props;

    if (loading) {
      return (
        <div className="hickory-container">
          <Loader active={this.state.loading} inline="centered" />
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="hickory-course-header">
          {course.header_image_path ? <img src={course.header_image_path} /> : null}
        </div>
        <div className="hickory-container">
          <Breadcrumb parentName="Course" parentUrl="/app/v2/courses" name={course.name}>
            {editingAvailable ? (
              <Dropdown text="Course Actions" button className="green">
                <Dropdown.Menu>
                  <Dropdown.Item
                    text="Delete"
                    onClick={() => this.setState({ showDeleteModal: true })}
                  />
                  { course.allow_edits ? (
                    <React.Fragment>
                      <Dropdown.Item text="Edit Description" onClick={this.showEditDescriptionModal} />
                      <Dropdown.Item text="Rename" onClick={this.showRenameModal} />
                      <Dropdown.Item
                        text="Update Cover Photo"
                        onClick={() => this.setState({
                          showUpdateHeaderModal: true,
                          pexelsResults: [],
                          pexelsSearchTerm: "",
                        })}
                      />
                    </React.Fragment>
                  ) : null }
                </Dropdown.Menu>
              </Dropdown>
            ) : null}
          </Breadcrumb>
          {course.description}
          <Tab
            menu={{ attached: 'top', pointing: true, secondary: true }}
            panes={[
              { menuItem: 'Builder', render: () => <CourseBuilder course={course} history={history} /> },
              { menuItem: 'Groups', render: () => <CourseGroups course={course} /> },
            ]}
          />
        </div>

        <Modal
          open={this.state.showDeleteModal}
          onClose={() => {
            this.setState({ showDeleteModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Delete Course?</Modal.Header>
          <Modal.Content>
            Are you sure you wish to delete the <strong>{this.state.course.name}</strong> course?
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showDeleteModal: false });
              }}
            >
              Cancel
            </Button>
            <Button type="submit" className="red" onClick={this.deleteCourse}>
              Delete
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showRenameModal}
          onClose={() => {
            this.setState({ showRenameModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Rename Course</Modal.Header>
          <Modal.Content>
            Name:{' '}
            <Input
              value={this.state.courseName}
              onChange={(e, { value }) => this.setState({ courseName: value })}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showRenameModal: false });
              }}
            >
              Cancel
            </Button>
            <Button type="submit" className="green" onClick={this.renameCourse}>
              Update
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showEditDescriptionModal}
          onClose={() => {
            this.setState({ showEditDescriptionModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Course Description</Modal.Header>
          <Modal.Content>
            <TextArea
              placeholder="Tell your learners more about this course..."
              value={this.state.courseDescription}
              style={{ width: '454px', height: '184px' }}
              onChange={(e, { value }) => this.setState({ courseDescription: value })}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showEditDescriptionModal: false });
              }}
            >
              Cancel
            </Button>
            <Button type="submit" className="green" onClick={this.updateCourseDescription}>
              Update
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showUpdateHeaderModal}
          onClose={() => {
            this.setState({ showUpdateHeaderModal: loadingImage });
          }}
          style={{ width: '500px' }}
          closeIcon={!loadingImage}
        >
          <Modal.Header>Update Cover Photo</Modal.Header>
          <Modal.Content>
            <Dimmer active={loadingImage} inverted>
              <Loader className="workaround" indeterminate />
            </Dimmer>
            <Tab
              menu={{ secondary: true, pointing: true }}
              panes={[
                {
                  menuItem: 'Gallery',
                  render: () => (
                    <Tab.Pane attached={false}>
                      <Grid columns={2} divided>
                        <Grid.Row>
                          <Grid.Column>
                            <Image
                              style={{ cursor: "pointer" }}
                              onClick={this.defaultImageSelected}
                              src="/static/v2/frontend/blue-wide.png"
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Image
                              style={{ cursor: "pointer" }}
                              onClick={this.defaultImageSelected}
                              src="/static/v2/frontend/green-wide.png"
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column>
                            <Image
                              style={{ cursor: "pointer" }}
                              onClick={this.defaultImageSelected}
                              src="/static/v2/frontend/purple-wide.png"
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Image
                              style={{ cursor: "pointer" }}
                              onClick={this.defaultImageSelected}
                              src="/static/v2/frontend/red-wide.png"
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column>
                            <Image
                              style={{ cursor: "pointer" }}
                              onClick={this.defaultImageSelected}
                              src="/static/v2/frontend/yellow-wide.png"
                            />
                          </Grid.Column>
                          <Grid.Column>
                              &nbsp;
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Tab.Pane>
                  ),
                },
                {
                  menuItem: 'Upload',
                  render: () => (
                    <Tab.Pane attached={false}>
                      <Upload
                        maxSize={3}
                        source=""
                        accept="image/png, image/jpeg"
                        updateFile={this.updateHeaderImage}
                        description="an Image"
                      />
                    </Tab.Pane>
                  ),
                },
                {
                  menuItem: 'Pexels',
                  render: () => (
                    <Tab.Pane attached={false}>
                      <Input
                        value={this.state.pexelsSearchTerm}
                        className="full-width"
                        onChange={this.searchPexels}
                        placeholder="Type here to begin search..."
                      />
                      <Grid columns={2} divided className="hickory-small-modal-results hickory-margin-top">
                        {_.map(this.state.pexelsResults, (record) => (
                          <Grid.Column>
                            <img
                              className="hickory-course-header-preview"
                              src={record.src.medium}
                              alt={record.alt}
                              onClick={() => this.selectPexelsImage(record)}
                            />
                            by <a href={record.photographer_url}>{record.photographer}</a>
                          </Grid.Column>
                        ))}
                      </Grid>
                      { this.state.pexelsResults.length > 0 ? (
                        <div>
                          <br />
                          <a href="https://www.pexels.com">Photos provided by Pexels</a>
                        </div>
                      ) : null}
                    </Tab.Pane>
                  ),
                },
              ]}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showUpdateHeaderModal: false });
              }}
            >
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={this.state.showCloneProgress} style={{ width: '500px' }}>
          <Modal.Header>Cloning Lesson</Modal.Header>
          <Modal.Content>
            <Progress percent={this.state.cloning_progress} indicating />
          </Modal.Content>
          <Modal.Actions />
        </Modal>

        <Modal
          open={this.state.showPrintModal}
          onClose={() => {
            this.setState({ showPrintModal: false });
          }}
          closeIcon
        >
          <Modal.Header>Print Lesson</Modal.Header>
          <Modal.Content>
            <iframe
              id="printFrame"
              src={`/app/v2/lessons/${this.state.printLessonId}/print?embedded=true`}
              className="hickory-modal-iframe"
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showPrintModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                document.getElementById('printFrame').contentWindow.print();
              }}
            >
              Print
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showExportModal}
          onClose={() => {
            this.setState({ showExportModal: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Export Lesson</Modal.Header>
          <Modal.Content>
            <p>
              You can export a Hickory lesson to cmi5 format, compatable with xAPI enabled systems.
            </p>
            <p>NOTE: Lessons exported in this way require:</p>
            <ul>
              <li>An external system compatible with xAPI & cmi5</li>
              <li>Actors with mbox ids that match email addresses in Hickory</li>
              <li>An active license of Hickory</li>
            </ul>
            <br />
            <Checkbox
              label="Do not show me this again"
              onClick={(e, { checked }) => {
                this.setExportLessonAckCookie(checked);
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showExportModal: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="green"
              onClick={() => {
                this.doExportLesson();
              }}
            >
              Export
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showDeleteLessonConfirmation}
          onClose={() => {
            this.setState({ showDeleteLessonConfirmation: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Delete Lesson</Modal.Header>
          <Modal.Content>
            <p>
              Deleting this lesson will remove it from <strong>ALL</strong> courses in Hickory.
            </p>
            <p>
              If you wish to proceed, type &quot;<em>{this.state.selectedLesson.name}</em>&quot; in
              the box below.
            </p>
            <br />
            <Input
              style={{ width: '100%' }}
              onChange={(e, { value }) => {
                this.setState({ confirmedLessonName: value });
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showDeleteLessonConfirmation: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="red"
              disabled={this.state.confirmedLessonName !== this.state.selectedLesson.name}
              onClick={() => {
                this.deleteLesson(this.state.selectedLesson);
              }}
            >
              Delete
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          open={this.state.showEditWarningModal}
          onClose={() => this.setState({ showEditWarningModal: false })}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>You are editing a scheduled lesson</Modal.Header>
          <Modal.Content>
            While you will still be able to edit the contents of cards, you will be unable to add or
            remove cards.
            {' '}
            <br />
            If you need to do so, please
            {' '}
            <strong>Clone</strong>
            {' '}
            the lesson first and edit the cloned copy.
            {' '}
            <br />
            Otherwise, continue on to
            {' '}
            <strong>Edit</strong>
            {' '}
            the lesson
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="grey"
              onClick={() => {
                this.setState({
                  showEditWarningModal: false,
                });
              }}
            >
              Cancel
            </Button>
            <Button
              positive
              onClick={() => {
                this.props.history.push(`/app/v2/lessons/${this.state.showEditWarningModal.id}/editor`);
              }}
            >
              Edit
            </Button>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

export default CourseDetailsContainer;
