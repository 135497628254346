import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import {
  Button, Header, Icon, List, Segment, Table, TextArea,
} from 'semantic-ui-react';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import { v4 as uuid } from 'uuid';
import _ from 'underscore';
import moment from 'moment';
import LinkDecorator from './LinkDecorator';
import CardContainer from '../Shared/CardContainer';
import {
  colorGreyMediumDark,
  colorGreyVeryDark,
  hickoryBlue,
  secondayNavColor,
} from '../../styles/palette';
import MediaComponent from './MediaComponent';
import { FREE_RESPONSE, styleMap } from '../../constants';

const s = {
  container: {
    backgroundColor: '#D6D7DC',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardTile: {
    width: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    fontSize: '1.4rem',
    lineHeight: '1.5',
  },
  footer: {
    display: 'flex',
    color: '#918f90',
    fontSize: '1.5em',
    justifyContent: 'space-between',
    borderTop: '1px solid #d1d1d1',
    marginTop: '20px',
    width: '100%',
  },
  cardTitle: {
    fontWeight: '400',
    lineHeight: '1.3',
    fontSize: '1.6em',
  },
  startButton: {
    display: 'block',
    backgroundColor: '#2185D0',
    textAlign: 'center',
    color: '#ffffff',
    textShadow: 'none',
    padding: '10px',
    borderRadius: '5px',
    fontSize: '1.5em',
  },
  activityContent: {
    overflow: 'auto',
    flexGrow: '2',
    alignSelf: 'flex-start',
    padding: '20px',
    width: 'calc(100% - 20px)',
    marginBottom: '50px',
  },
  activityFooter: {
    padding: '1.8rem',
    height: '3rem',
    width: '100%',
    display: 'flex',
    alignSelf: 'flex-end',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    left: '0%',
    bottom: '0px',
    backgroundColor: secondayNavColor,
    position: 'absolute',
    zIndex: 20,
  },
  dropShadow: {
    transition: 'box-shadow 0.5s',
    WebkitBoxShadow: '0px -8px 27px -10px rgba(0,0,0,0.15)',
    MozBoxShadow: '0px -8px 27px -10px rgba(0,0,0,0.15)',
    boxShadow: '0px -8px 27px -10px rgba(0,0,0,0.15)',
  },
  answer: {
    border: '1px solid #BABEC7',
    borderRadius: '4px',
    width: '100%',
    padding: '20px',
    background: 'white',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  answerContainer: {
    border: '1px solid #BABEC7',
    borderRadius: '4px',
    // eslint-disable-next-line max-len
    boxShadow: '0px 0.7471592426300049px 1.5496636629104614px 0px #00000002, 0px 1.7955275774002075px 3.724057197570801px 0px #00000003, 0px 3.380819082260132px 7.012069225311279px 0px #00000004, 0px 6.030803203582764px 12.508333206176758px 0px #00000005, 0px 11.27995491027832px 23.395462036132812px 0px #00000005',
    background: '#BABEC7',
  },
  answerStatus: {
    border: '1px solid #BABEC7',
    width: '40px',
    minWidth: '40px',
    height: '40px',
    minHeight: '40px',
    borderRadius: '50%',
    display: 'flex',
  },
  freeResponse: {
    height: '300px',
    width: '100%',
  },
  left: {
    textAlign: 'left',
    padding: '0 1rem',
    borderRadius: '18px',
    marginTop: '0.6rem',
    marginBottom: '0.6rem',
    minWidth: '11rem',
    maxWidth: '75%',
    backgroundColor: colorGreyMediumDark,
    color: colorGreyVeryDark,
    alignSelf: 'flex-start',
  },
  right: {
    textAlign: 'right',
    padding: '0 1rem',
    borderRadius: '18px',
    marginTop: '0.6rem',
    marginBottom: '0.6rem',
    minWidth: '11rem',
    maxWidth: '75%',
    backgroundColor: hickoryBlue,
    color: 'white',
    alignSelf: 'flex-end',
  },
  difficultyContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    width: '640px',
    bottom: 0,
    zIndex: 100,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
};

class LessonCard extends Component {
  static propTypes = {
    card: PropTypes.object,
    stack: PropTypes.number,
    nextCard: PropTypes.func,
    response: PropTypes.object,
    submit: PropTypes.func,
    print: PropTypes.bool,
    inFlight: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showingMoreInfo: false,
      answers: [],
      displayAnswers: [],
    };

    this.content = React.createRef();
    this.checkScrollPosition();
  }

  componentDidMount() {
    this.initializeCard();
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (this.props.card.id !== prevProps.card.id) {
      this.initializeCard();
    }
    return null;
  }

  initializeCard() {
    this.checkScrollPosition();
    let answers = null;
    let displayAnswers = [];
    let choices = null;
    let presentationType = 0;
    answers = this.props.card.content.answers;
    choices = this.props.card.content.choices;
    if (answers) {
      presentationType = answers.length ? 1 : 3;
      displayAnswers = _.groupBy(answers, 'correct');
      displayAnswers = _.shuffle(
        [_.sample(displayAnswers.true)].concat(_.sample(displayAnswers.false, choices - 1)),
      );
    }
    this.setState({
      showingMoreInfo: false,
      selected: null,
      freeResponse: '',
      start: moment(),
      buttonText: _.sample(['Got it', 'Next', 'Sounds good', 'Continue']),
      presentationType,
      displayAnswers,
      answers,
    });
    if (this.props.response) {
      const { response } = this.props;
      let selected = null;
      let freeResponse = null;
      if (response.answer_index >= 0) {
        selected = answers[response.answer_index];
      } else {
        freeResponse = response.answer_string;
        selected = response.answer_string;
      }
      this.setState({
        selected,
        freeResponse,
      });
    }
  }

  @bind
  showMoreInfo() {
    this.setState({ showingMoreInfo: true });
    this.checkScrollPosition();
  }

  blockRenderer = (block) => {
    const blockType = block.getType();
    if (blockType === 'atomic' || blockType === 'media') {
      return {
        component: MediaComponent,
        props: {
          showingMoreInfo: this.state.showingMoreInfo,
          showMoreInfo: this.showMoreInfo,
        },
      };
    }
    return null;
  };

  @bind
  buildInstructionCard(rawContent) {
    const displayedInstructionBlocks = [];
    for (let i = 0; i < rawContent.blocks.length; i++) {
      displayedInstructionBlocks.push(rawContent.blocks[i]);
      const blockType = rawContent.blocks[i].type;
      if (blockType === 'media' || blockType === 'atomic') {
        /**
         * convert blockType to atomic for backwards compatibility
         */
        rawContent.blocks[i].type = 'atomic';

        if (
          rawContent.blocks[i].entityRanges.length
          && rawContent.entityMap[rawContent.blocks[i].entityRanges[0].key].data.type === 'more-info'
        ) {
          if (this.state.showingMoreInfo === false) {
            break;
          }
        }
      }
    }
    const content = convertFromRaw({
      blocks: displayedInstructionBlocks,
      entityMap: rawContent.entityMap,
    });
    let editorState = EditorState.createWithContent(content);
    editorState = EditorState.set(editorState, { decorator: LinkDecorator });
    return (
      <Editor
        readOnly
        editorState={editorState}
        customStyleMap={styleMap}
        blockRendererFn={this.blockRenderer}
      />
    );
  }

  selectAnswer(answer) {
    this.setState(
      {
        selected: answer,
      },
      this.submit,
    );
  }

  Answer(props) {
    const { answer, selected } = props;
    if (answer) {
      return (
        <List.Item key={uuid()} style={{ padding: 0 }}>
          <div
            key={uuid()}
            onClick={() => {
              if (!selected) this.selectAnswer(answer);
            }}
            style={s.answer}
          >
            <Header as="h3" color="blue">
              {answer.answer}
            </Header>
            <div style={s.answerStatus}>
              {selected && (
                <Icon
                  name={answer.correct ? 'check' : 'x'}
                  color={answer.correct ? 'green' : 'red'}
                  size="large"
                  style={{ margin: 'auto' }}
                />
              )}
            </div>
          </div>
        </List.Item>
      );
    }
    return null;
  }

  @bind
  answerArea(content) {
    if (!content.answers.length) {
      const expertAnswer = content.explanation[0].string;
      const expertImage = content.explanation[1].image;
      return (
        <div style={{ textAlign: 'center' }}>
          <TextArea
            disabled={this.state.selected || this.props.response}
            value={this.state.freeResponse}
            placeholder="Type your answer here..."
            style={{
              ...s.answerContainer,
              minHeight: 150,
              maxWidth: '100%',
              width: '100%',
              background: 'white',
            }}
            onChange={(e, { value }) => this.setState({ freeResponse: value })}
          />
          {!this.state.selected ? (
            <Button
              disabled={!this.state.freeResponse || this.props.response}
              color="blue"
              onClick={() => this.selectAnswer(this.state.freeResponse)}
            >
              SUBMIT
            </Button>
          ) : (
            (expertAnswer || expertImage) && (
            <Segment inverted color="blue">
              <Header as="h4">Expert Answer</Header>
              {expertAnswer && <span>{expertAnswer}</span>}
              {expertImage && (
                <a href={expertImage} target="_blank" rel="noreferrer">
                  <img src={expertImage} style={{ maxWidth: '100%' }} />
                </a>
              )}
            </Segment>
            )
          )}
        </div>
      );
    }
    let answers = [];
    if (this.state.selected || this.props.print) {
      if (this.props.print) {
        answers = this.state.answers
          .filter((a) => a.correct)
          .map((answer) => this.Answer({ answer, selected: true }));
      } else {
        const correct = _.findWhere(this.state.displayAnswers, { correct: true });
        answers.push(this.Answer({ answer: correct, selected: true }));
      }
      const expertAnswer = content.explanation[0].string;
      const expertImage = content.explanation[1].image;
      if (expertAnswer || expertImage) {
        answers.push(
          <List.Item key={uuid()}>
            <Segment inverted color="blue">
              <Header as="h4">Expert Answer</Header>
              {expertAnswer && <span>{expertAnswer}</span>}
              {expertImage && (
                <a href={expertImage} target="_blank" rel="noreferrer">
                  <img src={expertImage} style={{ maxWidth: '100%' }} />
                </a>
              )}
            </Segment>
          </List.Item>,
        );
      }
      if (this.props.print) {
        for (let i = 0; i !== this.state.answers.length; i++) {
          const answer = this.state.answers[i];
          if (!answer.correct) {
            answers.push(this.Answer({ answer, selected: true }));
          }
        }
      } else if (!this.state.selected.correct) {
        answers.push(this.Answer({ answer: this.state.selected, selected: true }));
      }
    } else {
      answers = this.state.displayAnswers.map((answer) => this.Answer({ answer }));
    }
    return <List style={s.answerContainer}>{answers}</List>;
  }

  @bind
  buildQuestionCard(content) {
    const { image } = this.props.card.content;
    return (
      <div>
        <Header as="h1">{content.question}</Header>
        {!!content.image && (
          <a href={image.image || image} target="_blank" rel="noreferrer">
            <img src={image.image || image} style={{ maxWidth: '100%' }} />
          </a>
        )}
        {this.answerArea(content)}
      </div>
    );
  }

  @bind
  buildScenarioCard(content) {
    const persona = { left: true, right: true };
    const { scenario, question } = content;
    return (
      <div style={s.scenarioContainer}>
        {scenario.map((line) => {
          const label = persona[line.position] && (
            <Header as="h3" style={{ marginBottom: 0 }}>
              {content.persona[line.position]}
            </Header>
          );
          persona[line.position] = false;
          return (
            <div
              key={uuid()}
              style={{ textAlign: line.position, display: 'flex', flexDirection: 'column' }}
            >
              {label}
              <div style={s[line.position]}>{line.content}</div>
            </div>
          );
        })}
        <Header as="h1">{question}</Header>
        {this.answerArea(content)}
      </div>
    );
  }

  @bind
  submit(difficulty = 0) {
    const answerData = {
      type: difficulty ? this.state.presentationType : 0,
      difficulty,
      time_spent: moment().diff(this.state.start),
      score: difficulty && (this.state.selected.correct || this.state.presentationType === FREE_RESPONSE ? 1 : 0),
      submitted_at: moment().format(),
      answer_string: this.state.selected ? this.state.selected.answer || this.state.selected : null,
      answer_index:
        this.state.selected && this.state.answers.length
          ? _.indexOf(this.state.answers, this.state.selected)
          : -1,
    };
    if (this.props.card.card_type === 1) {
      if (!this.props.response) this.props.submit(answerData);
      this.props.nextCard();
    } else if (this.props.response) {
      this.props.submit(answerData);
      this.props.nextCard();
    } else this.props.inFlight(answerData);
  }

  @bind
  difficultyArea() {
    const style = { cursor: 'pointer' };
    return (
      <div style={s.difficultyContainer}>
        <Table celled columns={3} style={{ position: 'relative' }}>
          <Table.Body>
            <Table.Row>
              <Table.Cell textAlign="center" colSpan="3">
                How difficult was it to remember?
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell textAlign="center" style={style} onClick={() => this.submit(1)}>
                EASY
              </Table.Cell>
              <Table.Cell textAlign="center" style={style} onClick={() => this.submit(2)}>
                MEDIUM
              </Table.Cell>
              <Table.Cell textAlign="center" style={style} onClick={() => this.submit(3)}>
                HARD
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    );
  }

  @bind
  checkScrollPosition() {
    const scrollDiv = this.content.current;
    if (scrollDiv) {
      const containerHeight = scrollDiv.offsetHeight;
      const { scrollHeight } = scrollDiv;
      const scrollOffset = scrollDiv.scrollTop;
      const distanceFromBottom = scrollHeight - scrollOffset - containerHeight;
      // TODO: see if there is a better way to keep track of this rather than setting it on state.
      this.setState({
        canScrollDown: distanceFromBottom > 50,
      });
    }
  }

  render() {
    const {
      response, stack, card, print,
    } = this.props;
    const { canScrollDown } = this.state;

    const answered = response && !response.difficulty && !!response.answer_string;
    return (
      <div>
        <CardContainer stack={Math.min(5, stack)} loaded={!!card} compact>
          <div style={[s.cardTile, print ? null : { height: '650px' }]}>
            <div
              style={[s.activityContent, !print ? {
                maxHeight: '100%',
                height: 'calc(100% - 50px)',
              } : null]}
              onScroll={this.checkScrollPosition}
              ref={this.content}
            >
              {{
                1: this.buildInstructionCard,
                2: this.buildQuestionCard,
                3: this.buildScenarioCard,
              }[card.card_type](card.content)}
            </div>
            <div style={{ ...s.activityFooter, ...(canScrollDown ? s.displayShadow : {}) }}>
              {card.card_type === 1 && (
                <Button onClick={() => this.submit()} color="blue">
                  {this.state.buttonText}
                </Button>
              )}
            </div>
          </div>
        </CardContainer>
        {answered && this.difficultyArea()}
      </div>
    );
  }
}

export default Radium(LessonCard);
