import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import moment from 'moment';
import _ from 'underscore';
import { v4 as uuid } from 'uuid';
import {
  Checkbox, Icon, Label, Table,
} from 'semantic-ui-react';
import { CARD } from '../../constants';

const s = {};

class LessonsList extends Component {
  static propTypes = {
    lessons: PropTypes.array,
    // preview: PropTypes.func,
    sort: PropTypes.object,
    setSort: PropTypes.func,
    selectAll: PropTypes.func,
    selectLesson: PropTypes.func,
    selectedLessons: PropTypes.array,
    preview: PropTypes.func,
  };

  static defaultProps = {
    lessons: [],
    selectedLessons: [],
  };

  render() {
    const columns = _.compact([
      { key: 'name', label: 'Name' },
      { key: 'created_at', label: 'Creation Date' },
      { key: 'updated_at', label: 'Last Edited' },
      { key: '', label: 'Card Count' },
    ]);
    const { sort } = this.props;
    return (
      <div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>
                <Checkbox onChange={this.props.selectAll} />
              </Table.HeaderCell>
              {columns.map((column) => {
                if (column.key) {
                  let icon = 'sort down';
                  if (column.key === sort.column) {
                    icon = sort.ascending ? 'sort content ascending' : 'sort content descending';
                  }
                  return (
                    <Table.HeaderCell
                      key={uuid()}
                      onClick={() => {
                        this.props.setSort(column.key);
                      }}
                    >
                      <div style={s.row}>
                        {column.label}
                        <Icon name={icon} disabled={icon === 'sort down'} />
                      </div>
                    </Table.HeaderCell>
                  );
                }
                return (
                  <Table.HeaderCell key={uuid()} style={{ cursor: 'default' }}>
                    {column.label}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.lessons.map((lesson) => {
              const selected = !!this.props.selectedLessons.find((item) => item.id === lesson.id);

              return (
                <Table.Row key={lesson.id}>
                  <Table.Cell>
                    <Label
                      onClick={() => this.props.preview(null, lesson.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      preview
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    <Checkbox
                      onChange={() => {
                        this.props.selectLesson(lesson);
                      }}
                      checked={selected}
                      disabled={lesson.items__count === 0}
                    />
                  </Table.Cell>
                  <Table.Cell>{lesson.name}</Table.Cell>
                  <Table.Cell>{moment(lesson.created_at).format('MM-DD-YYYY')}</Table.Cell>
                  <Table.Cell>{moment(lesson.updated_at).format('MM-DD-YYYY')}</Table.Cell>
                  {lesson.lesson_type === CARD ? (
                    <Table.Cell>{lesson.card_count}</Table.Cell>
                  ) : (
                    <Table.Cell>N/A</Table.Cell>
                  )}
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
}

export default Radium(LessonsList);
