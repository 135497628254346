import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import bind from 'bind-decorator';

class LessonPublishComponent extends Component {
  static propTypes = {
    lesson: PropTypes.object,
    history: PropTypes.object,
    beforePublish: PropTypes.func,
  };

  @bind
  async doPublishLesson() {
    if (this.props.beforePublish && !this.props.beforePublish()) {
      // Give the parent a chance to reject this publish.
      // It is the parent's responsibility to inform the user.
      return;
    }

    const { lesson } = this.props;
    await lesson.publish();

    this.props.history.push('/app/v2/lessons/');
  }

  render() {
    const { lesson } = this.props;

    if (lesson.published) {
      return null;
    }

    return (
      <div className="hickory-lesson-unpublished">
        <div>
          This lesson is currently in draft mode. While in draft
          mode you will be able to edit this lesson, but learners will
          { ' ' }
          <strong>not</strong>
          { ' ' }
          be able to see your changes. When you are done editing, please
          { ' ' }
          <strong>publish</strong>
          { ' ' }
          this lesson.
        </div>
        <div className="hickory-lesson-unpublished-actions">
          <Button
            positive
            onClick={() => {
              this.doPublishLesson();
            }}
          >
            Publish
          </Button>
        </div>
      </div>
    );
  }
}

export default LessonPublishComponent;
