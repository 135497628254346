import ls from 'local-storage';
import { ModelBase } from './model';

class SharedCourse extends ModelBase {
  static namespace = 'shared_courses';

  async addToCourses() {
    const response = await fetch(`/api/v2/shared_courses/${this.id}/add/`, {
      method: 'POST',
      credentials: 'same-origin',
      header: {
        Authorization: `Bearer ${ls('token.access')}`,
        Accept: 'application/json',
      },
    });
    return response.status === 200;
  }
}
export default SharedCourse.register();
