import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  Input, Button, Modal, List,
} from 'semantic-ui-react';
import _ from 'underscore';
import bind from 'bind-decorator';
import { hickoryBlue } from '../../styles/palette';
import Group from '../../models/group';
import { GROUP_TYPE_LEARNING } from '../../constants';

let s = {};

class GroupSelectionModal extends Component {
  static propTypes = {
    handleGroups: PropTypes.func,
    showModal: PropTypes.bool,
    closeModal: PropTypes.func,
    closeAll: PropTypes.func,
    user: PropTypes.object,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.state = {
      groups: [],
      selectedGroups: [],
      groupFilter: '',
    };
  }

  @bind
  async componentDidMount() {
    const groups = await Group.objects()
      .filtered({ deleted: false, group_type: GROUP_TYPE_LEARNING })
      .all();
    this.setState({
      groups,
    });
  }

  @bind
  async componentDidUpdate(prevProps) {
    if (this.props.user?.id !== prevProps.user?.id) {
      if (this.props.user) {
      // this component can also be used by the control bar
      // in more of a bulk way, so user is not a required
      // property.
        let { groups } = this.state;
        const permissionGroups = await this.props.user.permissionGroups();
        const selectedGroups = _.map(permissionGroups, (group) => group.id);
        groups = permissionGroups.concat(groups.filter((group) => !selectedGroups.includes(group.id)));
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          groups,
          selectedGroups,
        });
      }
    }
  }

  @bind
  selectGroup(group) {
    let { selectedGroups } = this.state;
    if (_.contains(selectedGroups, group.id)) {
      selectedGroups = selectedGroups.filter((groupId) => groupId !== group.id);
    } else {
      selectedGroups.push(group.id);
    }
    this.setState({
      selectedGroups,
    });
  }

  @bind
  updateGroupFilter(e, { value }) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.setState({
        groupFilter: value,
      });
    }, 300);
  }

  @bind
  closeAll() {
    this.props.closeModal();
    this.props.closeAll();
  }

  render() {
    return (
      <Modal
        key="groupselectionmodal"
        closeIcon
        open={this.props.showModal}
        onClose={'closeAll' in this.props ? this.closeAll : this.props.closeModal}
      >
        <Modal.Header>Add Users to Groups</Modal.Header>
        <Modal.Content>
          <div
            style={{
              maxWidth: '80%',
              margin: 'auto',
              border: '2px solid gray',
              borderRadius: '5px',
            }}
          >
            <Input onChange={this.updateGroupFilter} fluid placeholder="Search..." />
            <div
              style={{
                maxHeight: '500px',
                overflowY: 'auto',
              }}
            >
              <List divided relaxed>
                {this.state.groups
                  .filter((item) => item.name.toLowerCase().includes(this.state.groupFilter.toLowerCase()))
                  .map((item) => {
                    const selected = _.contains(this.state.selectedGroups, item.id);
                    return (
                      <List.Item
                        key={item.id}
                        style={selected ? s.selection : {}}
                        onClick={() => {
                          this.selectGroup(item);
                        }}
                      >
                        {item.name}
                      </List.Item>
                    );
                  })}
              </List>
            </div>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.props.closeModal}>Back</Button>
          <Button
            primary
            onClick={() => {
              this.props.handleGroups(this.state.selectedGroups);
              this.props.closeModal();
              this.setState({
                groupFilter: '',
              });
            }}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

s = {
  selection: {
    backgroundColor: hickoryBlue,
    color: 'white',
  },
};
export default Radium(GroupSelectionModal);
