import React, { Component } from 'react';
import Radium from 'radium';
import {
  Button, Dropdown, Input, Label, Modal, Tab,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';
import Lesson from '@/models/lesson';
import LessonSummary from './LessonSummary';
import LessonGroups from './LessonGroups';
import Breadcrumb from '../Shared/Breadcrumb';

const FILTER_WIDTH = '250px';
let s = {};

class LessonDetailsContainer extends Component {
  static propTypes = {
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      lesson_id: props.match.params.lesson_id,
      loading: true,
    };
  }

  async componentDidMount() {
    const lesson = await Lesson.objects()
      .filtered({ id: this.state.lesson_id })
      .first();
    this.setState({ lesson, loading: false });
  }

  @bind
  deleteLesson() {
    Lesson.objects()
      .update(this.state.lesson.id, { deleted: true })
      .then(() => {
        const newUrl = '/app/v2/lessons/';
        window.location = newUrl; // HACK
      });
  }

  render() {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    const { lesson } = this.state;
    const panes = [
      {
        menuItem: 'Overview',
        render: () => (
          <Tab.Pane style={s.contentPane} attached="bottom">
            <LessonSummary lesson={lesson} />
          </Tab.Pane>
        ),
      },
    ];
    if (lesson.published) {
      panes.push(
        {
          menuItem: 'Groups',
          render: () => (
            <Tab.Pane style={s.contentPane} attached="bottom">
              <LessonGroups lesson={lesson} />
            </Tab.Pane>
          ),
        },
      );
    }
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={s.menuContainer}>
            <Breadcrumb
              parentName="Lessons"
              parentUrl="/app/v2/lessons/"
              name={lesson.name}
              extra={lesson.published ? null : (
                <Label
                  color="orange"
                  size="large"
                  style={{ height: 'fit-content', marginLeft: '10px' }}
                >Draft
                </Label>
              )}
            >
              <Dropdown text="Actions" button className="green">
                <Dropdown.Menu>
                  <Dropdown.Item
                    text="Preview"
                    onClick={() => {
                      window.location.href = `/app/v2/lessons/${lesson.id}/player/preview`;
                    }}
                  />,
                  <Dropdown.Item
                    text="Editor"
                    onClick={() => {
                      window.location.href = `/app/v2/lessons/${lesson.id}/editor`;
                    }}
                  />,
                  <Dropdown.Item text="Delete" onClick={() => this.setState({ showDeleteLessonConfirmation: true })} />
                </Dropdown.Menu>
              </Dropdown>
            </Breadcrumb>
            <Tab
              menu={{ attached: 'top', pointing: true, secondary: true }}
              renderActiveOnly
              style={s.menu}
              panes={panes}
            />
          </div>
        </div>
        <Modal
          open={this.state.showDeleteLessonConfirmation}
          onClose={() => {
            this.setState({ showDeleteLessonConfirmation: false });
          }}
          style={{ width: '500px' }}
          closeIcon
        >
          <Modal.Header>Delete Lesson</Modal.Header>
          <Modal.Content>
            <p>
              If you wish to proceed, type &quot;<em>{lesson.name}</em>&quot; in
              the box below.
            </p>
            <br />
            <Input
              style={{ width: '100%' }}
              onChange={(e, { value }) => {
                this.setState({ confirmedLessonName: value });
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showDeleteLessonConfirmation: false });
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="red"
              disabled={this.state.confirmedLessonName !== lesson.name}
              onClick={this.deleteLesson}
            >
              Delete
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

s = {
  sidebar: {
    position: 'fixed',
    height: 'calc(100% - 70px)',
    width: FILTER_WIDTH,
    borderRadius: 0,
    border: 'none',
    zIndex: 2,
    backgroundColor: '#1b1c1d',
  },
  menu: {},
  menuContainer: {
    width: '100%',
    paddingLeft: '10%',
    paddingRight: '10%',
    marginTop: '40px',
  },
  contentPane: {
    border: 'none',
    backgroundColor: '#F9FAFD',
  },
};

export default Radium(LessonDetailsContainer);
