import React, { Component } from 'react';
import { render } from 'react-dom';
import Radium from 'radium';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import _ from 'underscore';
import bind from 'bind-decorator';
import moment from 'moment';

import User from '@/models/user';
import Organization from '@/models/organization';
import Header from './Header/Header';

import PeopleContainer from './PeoplePanel/PeopleContainer';
import UserDetailsContainer from './UserDetails/UserDetailsContainer';
import DashboardContainer from './Dashboard/DashboardContainer';
import LessonDetailsContainer from './LessonPanel/LessonDetailsContainer';
import LessonListContainer from './Lessons/LessonListContainer';
import CourseListComponent from './Courses/CourseListComponent';
import CourseDetailsContainer from './Courses/CourseDetailsContainer';
import LessonPlayer from './LessonPlayer/LessonPlayer';
import PrintLesson from './LessonPlayer/PrintLesson';
import LearnerHomeContainer from './LearnerHome/LearnerHomeContainer';
import LoginContainer from './Login/LoginContainer';
import LogoutContainer from './Login/LogoutContainer';
import EnrollmentContainer from './Login/EnrollmentContainer';
import ResetPasswordContainer from './Login/ResetPasswordContainer';
import NewTrialSignupContainer from './Login/NewTrialSignupContainer';
import SlackRegistrationContainer from './Integrations/SlackRegistrationContainer';
import ResetContainer from './Login/ResetContainer';
import ReportContainer from './Reporting/ReportContainer';
import ComplianceContainer from './Compliance/ComplianceContainer';
import OrganizationSettingsContainer from './Settings/OrganizationSettingsContainer';
import OnboardingContainer from './Onboarding/OnboardingContainer';
import GroupListContainer from './Groups/GroupListContainer';

import { checkToken } from '../utilities';

import logo from '../images/HICKORY.png';
import AssignmentsContainer from './Dashboard/AssignmentsContainer';
import LearnerLessonsContainer from './LearnerLessons/LearnerLessonsContainer';
import EditorContainer from './LessonEditor/EditorContainer';
import UploaderContainer from './PeoplePanel/BulkUploader/UploaderContainer';
import GroupContainer from './Groups/GroupContainer';
import CoursePlayer from './LessonPlayer/CoursePlayer';
import CompleteProfile from './Login/CompleteProfile';

const QUOTES = [
  'Repetition is the mother of all learning.',
  'Learning never exhausts the mind.',
  'The only things you learn are the things you tame.',
  'Leadership and learning are indispensable to each other.',
  'That is what learning is. You suddenly understand something you’ve understood all your life, but in a new way.',
  'The beautiful thing about learning is that nobody can take it away from you.',
  'I am always ready to learn although I do not always like being taught.',
  'Live as if you were to die tomorrow. Learn as if you were to live forever.',
  'The more that you read, the more things you will know. The more that you learn, the more places you’ll go.',
  'Tell me and I forget, teach me and I may remember, involve me and I learn.',
  'Wisdom is not a product of schooling but of the lifelong attempt to acquire it.',
  'Change is the end result of all true learning.',
  'A wise man can learn more from a foolish question than a fool can learn from a wise answer.',
  'He who learns but does not think, is lost! He who thinks but does not learn is in great danger.',
  'Keep it secret. Keep it safe.',
  'The more I live, the more I learn. The more I learn, the more I realizes, the less I know.',
  'Anyone who stops learning is old, whether at twenty or eighty. Anyone who keeps learning stays young.',
  'Try to learn something about everything and everything about something.',
  'Education is not the filling of a pail, but the lighting of a fire.',
  'Learning is not attained by chance. It must be sought for with ardor and attended with diligence.',
];

const s = {
  welcomeContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '80%',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  welcomeMessage: {
    fontFamily: 'Helvetica, Arial, Sans-Serif',
    fontSize: '36px',
    fontWeight: '200',
    marginLeft: 'auto',
    marginRight: 'auto',
    order: '1',
    verticalAlign: 'middle',
    textAlign: 'center',
  },
  logo: {
    width: '240px',
  },
  appContainer: {
    flexDirection: 'column',
    display: 'flex',
    minHeight: '55rem',
    height: '100vh',
    justifyContent: 'flex-start',
    overflow: 'auto',
  },
  printContainer: {
    flexDirection: 'column',
    display: 'flex',
    minHeight: '55rem',
    justifyContent: 'flex-start',
    overflow: 'visible',
  },
  header: {
    flex: '0 1 auto',
  },
  content: {
    flex: '1 1 auto',
    overflow: 'auto',
    height: 'calc(100% - 5rem)',
    backgroundColor: '#FAFBFD',
  },
};

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
    };
  }

  async componentDidMount() {
    await checkToken();
    setInterval(() => {
      checkToken(600); // refresh tokens expiring in the next 10 minutes
    }, 10 * 1000); // checked every 10 seconds
    const user = await User.me();
    const organization = await Organization.objects().get(user.organization_id);
    console.log('org->', organization);
    this.setState({
      user,
      organization,
    });

    const hasIntercom = user.roles.includes('Owner') || user.roles.includes('Admin') || user.roles.includes('Manager');

    if (hasIntercom) {
      console.log('Showing Intercom...');
      window.Intercom('boot', {
        app_id: 'gy0v2qpc',
        email: user.email,
        user_id: user.id,
      });
    }
  }

  @bind
  authenticatedPaths() {
    let trialExpired = false;
    const isTrial = this.state.organization.subscription_tier === 1;
    if (isTrial && this.state.user.roles.includes('Owner')) {
      const trialExpires = moment(this.state.organization.trial_expires);
      const now = moment();
      // const days = Math.round(moment.duration(trialExpires - now).asDays());

      trialExpired = trialExpires <= now;
    }
    if (trialExpired) {
      return (
        <div style={s.content}>
          <Route exact path="/app/v2/logout" component={LogoutContainer} />
          <Route path="*" component={OrganizationSettingsContainer} />
        </div>
      );
    }

    if (!this.state.user.first_name && !this.state.user.last_name) {
      return <CompleteProfile userId={this.state.user.id} updateUser={(user) => this.setState({ user })} />;
    }

    return (
      <div style={s.content}>
        <Route exact path="/app/v2/logout" component={LogoutContainer} />
        <Route exact path="/app/v2/settings" component={OrganizationSettingsContainer} />
        <Route exact path="/app/v2/authorize/slack" component={SlackRegistrationContainer} />
        <Route path="/app/v2/reports">
          <ReportContainer user={this.state.user} />
        </Route>
        <Route exact path="/app/v2/groups" component={GroupListContainer} />
        <Route exact path="/app/v2/groups/:group_id" component={GroupContainer} />
        <Route exact path="/app/v2/people" component={PeopleContainer} />
        <Route path="/app/v2/people/:email" component={UserDetailsContainer} />
        <Route exact path="/app/v2/lessons" component={LessonListContainer} />
        <Route exact path="/app/v2/courses" component={CourseListComponent} />
        <Route exact path="/app/v2/courses/:course_id" component={CourseDetailsContainer} />
        <Route exact path="/app/v2/courses/:course_id/player" component={CoursePlayer} />
        <Route exact path="/app/v2/lessons/:lesson_id" component={LessonDetailsContainer} />
        <Route exact path="/app/v2/lessons/:lesson_id/player" component={LessonPlayer} />
        <Route exact path="/app/v2/lessons/:lesson_id/editor" component={EditorContainer} />
        <Route
          exact
          path="/app/v2/lessons/:lesson_id/player/preview"
          render={(props) => <LessonPlayer match={props.match} preview />}
        />
        <Route
          exact
          path="/app/v2/lessons/:lesson_id/print"
          render={(props) => <PrintLesson match={props.match} preview />}
        />
        <Route
          exact
          path="/app/v2/learning/library"
          component={LearnerLessonsContainer}
        />
        <Route
          exact
          path="/app/v2/learning/home"
          component={LearnerHomeContainer}
        />
        <Route exact path="/app/v2" component={DashboardContainer} />
        <Route exact path="/app/v2/assignments" component={AssignmentsContainer} />
        <Route exact path="/app/v2/compliance" component={ComplianceContainer} />
        <Route exact path="/app/v2/onboarding" component={OnboardingContainer} />
        <Route exact path="/app/v2/uploader" component={UploaderContainer} />
      </div>
    );
  }

  render() {
    const learningQuote = _.sample(QUOTES);
    const loginPage = window.location.href.endsWith('/login');
    const enrollmentPage = window.location.href.includes('/enroll/')
                           || window.location.href.includes('/signup');
    const resetPasswordPage = window.location.href.endsWith('/reset-password')
                           || window.location.href.includes('/reset/');
    const printPage = window.location.href.includes('/print');
    const integrationPage = window.location.href.includes('/authorize/');

    if (loginPage || enrollmentPage || resetPasswordPage || this.state.user || integrationPage) {
      return (
        <div style={printPage ? s.printContainer : s.appContainer}>
          <Router>
            <Header
              user={this.state.user}
              organization={this.state.organization}
              style={s.header}
              dataLoaded={this.state.dataLoaded}
            />
            {loginPage || enrollmentPage || resetPasswordPage || integrationPage ? (
              <div style={s.content}>
                <Route exact path="/app/v2/login" component={LoginContainer} />
                <Route exact path="/app/v2/enroll/:verification_code" component={EnrollmentContainer} />
                <Route exact path="/app/v2/reset-password" component={ResetPasswordContainer} />
                <Route exact path="/app/v2/reset/:verification_code" component={ResetContainer} />
                <Route path="/app/v2/signup" component={NewTrialSignupContainer} />
                <Route exact path="/app/v2/authorize/slack" component={SlackRegistrationContainer} />
              </div>
            ) : this.authenticatedPaths() }
          </Router>
        </div>
      );
    }
    if (new URLSearchParams(window.location.search).get('embedded')) {
      // do not show splash screen when in embedded mode
      return null;
    }
    return (
      <div style={s.welcomeContainer}>
        <span style={s.welcomeMessage}>
          <img style={s.logo} src={logo} alt="Hickory Logo" />

          <blockquote>
            <span>{learningQuote}</span>
          </blockquote>
        </span>
      </div>
    );
  }
}

export default Radium(App);

const container = document.getElementById('app');

render(<App />, container);
