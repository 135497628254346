import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import _ from 'underscore';
import {
  Input, Button, Form, Modal,
} from 'semantic-ui-react';
import pluralize from 'pluralize';
import User from '../../models/user';
import PeopleList from './PeopleList';

const s = {
  search: {
    margin: '1em 1em .5em 1em',
  },
};

class PeopleFinder extends Component {
  static propTypes = {
    hideModal: PropTypes.func,
    onSelect: PropTypes.func,
    onCancel: PropTypes.func,
    assignedUsers: PropTypes.array,
  };

  @bind
  async updateUsers() {
    this.setState({ loading: true });

    let users = [];
    if (this.state.search && Object.keys(this.state.search).length >= 1) {
      const allUsers = await User.objects()
        .search(this.state.search)
        .sorted('last_name', true)
        .all();
      const unassignedUsers = allUsers.filter((user) => !this.props.assignedUsers.includes(user.id));
      users = unassignedUsers.slice(0, 100);
    }

    this.setState({
      users,
      loading: false,
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      headerText: 'Find Hickory User',
      sort: { column: 'last_name', ascending: true },
      placeholder: 'Search above to get started',
      selectedUsers: [],
      users: [],
    };
  }

  @bind
  cancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    this.props.hideModal();
  }

  @bind
  select() {
    this.props.onSelect(this.state.selectedUsers);
  }

  @bind
  selectUser(user) {
    let selectedUsers = [];
    const selectedUser = this.state.selectedUsers.find((item) => item.id === user.id);
    if (selectedUser) {
      selectedUsers = this.state.selectedUsers.filter((item) => item.id !== user.id);
    } else {
      selectedUsers = this.state.selectedUsers.concat([user]);
    }
    this.setState({ selectedUsers });
  }

  @bind
  search(e, { value }) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      let tokens = _.map(value.split(' '), (token) => token.trim());
      tokens = _.filter(tokens, (token) => token !== ''); // remove empty tokens

      const query = {
        first_name: tokens,
        last_name: tokens,
        email: tokens,
      };
      this.setState(
        {
          search: tokens.length >= 1 ? query : {},
          placeholder: tokens.length > 0 ? 'No Results Found' : 'Search above to get started',
          selectedUsers: [],
        },
        this.updateUsers,
      );
    }, 300);
    this.setState({
      searchTerm: value,
    });
  }

  @bind
  selectAll() {
    let selectedUsers = [];
    if (this.state.selectedUsers.length !== this.state.users.length) {
      selectedUsers = this.state.users;
    }
    this.setState({
      selectedUsers,
    });
  }

  @bind
  render() {
    return (
      <Modal
        as={Form}
        size="tiny"
        onSubmit={this.select}
        defaultOpen
        closeIcon
        style={{ maxWidth: '900px', minWidth: '900px' }}
        onClose={this.cancel}
      >
        <Modal.Header style={{ textAlign: 'center' }}>{this.state.headerText}</Modal.Header>
        <Modal.Content scrolling>
          <Input
            style={s.search}
            icon="search"
            iconPosition="left"
            placeholder="Search people by name or email..."
            onChange={this.search}
            value={this.state.searchTerm}
            size="huge"
            transparent
            autoFocus
            fluid
          />
          <PeopleList
            users={this.state.users}
            setSort={this.setSort}
            sort={this.state.sort}
            selectUser={this.selectUser}
            selectAll={this.selectAll}
            selectedUsers={this.state.selectedUsers}
            updateUsers={this.updateUsers}
            loading={this.state.loading}
            showActions={false}
            showTags={false}
            showRole={false}
            allowEdit={false}
            placeholder={this.state.placeholder}
          />
        </Modal.Content>
        <Modal.Actions>
          {this.state.selectedUsers.length}
          {' '}
          {pluralize('user', this.state.selectedUsers.length)}
          {' '}
          selected
          <Button type="button" onClick={this.cancel}>
            Cancel
          </Button>
          <Button type="submit" className="green">
            Select
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default Radium(PeopleFinder);
