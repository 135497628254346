import React, { Component } from 'react';
import { Divider, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const s = {
  cardEditorEmbed: {
    width: '60%',
    marginBottom: '2rem',
    display: 'inline-block',
  },
  embedContainer: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
  },
  moreInfo: {
    textAlign: 'center',
    position: 'relative',
    marginBottom: '2rem',
    fontSize: '9px',
  },
  moreInfoText: {
    width: '29%',
    height: '1px',
    top: '8px',
    background: '#dedeea',
  },
};

class MediaComponent extends Component {
  static propTypes = {
    block: PropTypes.object,
    contentState: PropTypes.object,
    blockProps: PropTypes.object,
  };

  render() {
    const { block, contentState } = this.props;
    const { showingMoreInfo, showMoreInfo } = this.props.blockProps;
    if (block.getEntityAt(0) === null) {
      return <div />;
    }
    const entity = contentState.getEntity(block.getEntityAt(0));
    if (entity.data.type === 'embed') {
      return (
        <div style={s.cardEditorEmbed}>
          <div
            style={s.embedContainer}
            dangerouslySetInnerHTML={{ __html: entity.data.code }}
          />
        </div>
      );
    } if (entity.data.type === 'image') {
      return (
        <div style={{ display: 'flex' }}>
          <a href={entity.data.image} target="_blank" rel="noreferrer" style={{ margin: 'auto' }}>
            <img src={entity.data.image} style={{ maxWidth: '100%' }} />
          </a>
        </div>
      );
    } if (entity.data.type === 'more-info') {
      if (showingMoreInfo === true) {
        return <hr />;
      }
      return (
        <Divider horizontal onClick={showMoreInfo}>
          <span style={s.moreInfo}>
            More Info
            <Icon name="chevron down" />
          </span>
        </Divider>
      );
    }
    return null;
  }
}

export default MediaComponent;
