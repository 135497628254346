import React, { Component } from 'react';
import _ from 'underscore';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  Input,
  Select,
  Progress,
  Icon,
} from 'semantic-ui-react';
import bind from 'bind-decorator';
import User from '@/models/user';
import Organization from '@/models/organization';
import Course from '@/models/course';
import CourseSchedule from '@/models/course_schedule';
import Lesson from '@/models/lesson';
import {
  ARTICLE,
  VIDEO,
  CARD,
  SCORM,
  Features,
} from '../../constants';
import ModelTable from './ModelTable';
import Upload from './Upload';

const EXISTING = -1;

class CourseCreationModal extends Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    history: PropTypes.object,
    groupId: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      newCourseName: "",
      selectedLessonType: null,
      selectedLessons: [],
      readyToCreate: false,
      scormFile: null,
      uploading: false,
      uploadingProgress: 0,
      originalFileName: "",
      scormEnabled: false,
      articlesEnabled: false,
      videoLessonsEnabled: false,
    };
  }

  async componentDidMount() {
    const user = await User.me();
    const organization = await Organization.objects().get(user.organization_id);

    this.setState({
      scormEnabled: organization.scorm_enabled,
      articlesEnabled: user.waffle_flags.includes(Features.ARTICLES),
      videoLessonsEnabled: user.waffle_flags.includes(Features.VIDEO_LESSONS),
    });
  }

  @bind
  async createCourse() {
    let path = [];
    let lesson = null;
    const {
      selectedLessonType,
      selectedLessons,
      newCourseName,
      scormFile,
    } = this.state;
    const defaultBackgrounds = [
      "/static/v2/frontend/blue-wide.png",
      "/static/v2/frontend/green-wide.png",
      "/static/v2/frontend/purple-wide.png",
      "/static/v2/frontend/red-wide.png",
      "/static/v2/frontend/yellow-wide.png",
    ];

    if (selectedLessonType === EXISTING) {
      path = _.map(selectedLessons, (l) => {
        return {
          lesson_id: l.id,
        };
      });
    } else {
      // create first lesson based on selected type.
      lesson = await Lesson.objects().create({ name: newCourseName, lesson_type: selectedLessonType });
      if (selectedLessonType === SCORM) {
        this.setState({ uploading: true, uploadingProgress: 0 });

        const fakeProgress = setInterval(() => {
          this.setState({ uploadingProgress: this.state.uploadingProgress + 0.01 });
        }, 1000);
        await lesson.uploadSCORMContent(scormFile);
        clearInterval(fakeProgress);

        this.setState({ uploadingProgress: 1 });
      }
      path = [{ lesson_id: lesson.id }];
    }
    const course = await Course.objects().create({
      name: newCourseName,
      lesson_path: path,
      header_image_path: _.sample(defaultBackgrounds),
    });
    if (this.props.groupId) {
      // do group assignment....
      await CourseSchedule.objects().create({
        course_id: course.id,
        organizational_group_id: this.props.groupId,
      });
    }
    if (selectedLessonType === SCORM || selectedLessonType === EXISTING) {
      this.props.history.push(`/app/v2/courses/${course.id}`);
    } else {
      this.props.history.push(`/app/v2/lessons/${lesson.id}/editor`);
    }
  }

  @bind
  updateReadyToCreate() {
    const {
      selectedLessons,
      selectedLessonType,
      newCourseName,
      scormFile,
    } = this.state;
    let readyToCreate = selectedLessonType !== null && newCourseName.trim().length > 0;

    if (selectedLessonType === EXISTING && selectedLessons.length <= 0) {
      readyToCreate = false;
    } else if (selectedLessonType === SCORM && scormFile === null) {
      readyToCreate = false;
    }
    this.setState({ readyToCreate });
  }

  @bind
  async updateSCORMFile(files) {
    if (!files) {
      this.setState({
        scormFile: null,
      });
    } else {
      const file = files[0];
      this.setState({
        scormFile: file,
        originalFileName: file.name,
      }, this.updateReadyToCreate);
    }
  }

  render() {
    const {
      selectedLessonType,
      readyToCreate,
      uploading,
      scormFile,
      originalFileName,
      scormEnabled,
      articlesEnabled,
      videoLessonsEnabled,
    } = this.state;

    const lessonOptions = [
      { value: EXISTING, text: "Existing lesson" },
      { value: CARD, text: "Card-based lesson" },
    ];
    if (articlesEnabled) {
      lessonOptions.push({ value: ARTICLE, text: "Article-based lesson" });
    }
    if (videoLessonsEnabled) {
      lessonOptions.push({ value: VIDEO, text: "Video-based lesson" });
    }
    if (scormEnabled) {
      lessonOptions.push({ value: SCORM, text: "SCORM-based lesson" });
    }
    const lessonColumns = [
      {
        name: 'Lesson Name',
        search: true,
        key: 'name',
      },
      {
        name: 'Type',
        key: [
          'lesson_type',
          (lessonType) => {
            if (lessonType === CARD) {
              return 'Card';
            }
            if (lessonType === SCORM) {
              return 'Scorm';
            }
            if (lessonType === ARTICLE) {
              return 'Article';
            }
            if (lessonType === VIDEO) {
              return 'Video';
            }
            return 'Other';
          },
        ],
      },
      {
        name: 'Activities',
        key: [
          'lesson_type',
          'card_count',
          (lessonType, cardCount) => {
            return lessonType === CARD ? cardCount : 1;
          },
        ],
      },
    ];
    const uploadPercentComplete = Math.round(this.state.uploadingProgress * 100.0);

    return (
      <Modal
        open={this.props.open}
        onClose={this.props.onClose}
        closeOnDimmerClick={!uploading}
        closeIcon={!uploading}
        style={{ width: '1000px' }}
      >
        <Modal.Header>Create Course</Modal.Header>
        <Modal.Content>
          Name:
          <br />
          <Input
            className="full-width"
            value={this.state.newCourseName}
            disabled={uploading}
            onChange={(e, { value }) => {
              this.setState({
                newCourseName: value,
              }, this.updateReadyToCreate);
            }}
          />
          <h4>
            First Lesson:
          </h4>
          <p>
            Please pick the first lesson for this course. You&apos;ll be able to add and remove lessons after creation.
          </p>
          <Select
            placeholder="Select a lesson type"
            options={lessonOptions}
            disabled={uploading}
            onChange={(e, { value }) => {
              this.setState({
                selectedLessonType: value,
                selectedLessons: [],
              }, this.updateReadyToCreate);
            }}
          />
          { selectedLessonType === EXISTING ? (
            <ModelTable
              title=""
              model={Lesson}
              baseFilter={{
                deleted: false,
                published: true,
              }}
              select
              defaultPerPage={5}
              columns={lessonColumns}
              defaultSort={['name', 'dsc']}
              selectionChanged={(selectedLessons) => {
                this.setState({
                  selectedLessons,
                }, this.updateReadyToCreate);
              }}
            />
          ) : null }
          { selectedLessonType === SCORM && !uploading && !scormFile ? (
            <React.Fragment>
              <h4>
                Choose a SCORM file to upload:
              </h4>
              <Upload
                maxSize={50}
                source=""
                accept="application/zip"
                updateFile={this.updateSCORMFile}
                description="a SCORM 2004"
              />
              <p>
                <em>NOTE: SCORM files must be in SCORM 2004 format</em>
              </p>
            </React.Fragment>
          ) : null }
          { selectedLessonType === SCORM && !uploading && scormFile ? (
            <div className="hickory-margin-top">
              <Icon name="file" size="large" />
              <span>
                {originalFileName}
              </span>
              <Icon
                name="trash alternate outline"
                size="large"
                color="grey"
                onClick={() => {
                  this.setState({
                    scormFile: null,
                  }, this.updateReadyToCreate);
                }}
                link
              />
            </div>
          ) : null }
          { uploading ? (
            <React.Fragment>
              <h4>Upload Progress</h4>
              <Progress
                value={uploadPercentComplete}
                total="100"
                progress="percent"
                style={{ width: '100%' }}
              >
                { uploadPercentComplete > 98 ? 'Finalizing...' : null }
              </Progress>
            </React.Fragment>
          ) : null}
        </Modal.Content>
        <Modal.Actions>
          <Button
            type="submit"
            className="gray"
            disabled={uploading}
            onClick={this.props.onClose}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="green"
            disabled={!readyToCreate || uploading}
            onClick={() => {
              this.createCourse();
            }}
          >
            Create
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default CourseCreationModal;
