import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import {
  Form,
  Icon,
  Label,
  List,
  TextArea,
} from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import ReactTextareaAutosize from 'react-textarea-autosize';
import _ from 'underscore';
import Upload from '../Shared/Upload';
import { FREE_RESPONSE } from '../../constants';

const s = {
  answer: {
    border: '1px solid #BABEC7',
    borderRadius: '4px',
    width: '100%',
    padding: '20px',
    background: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  answerTextArea: {
    width: '100%',
    resize: 'none',
    border: 'none',
    zIndex: 20,
    backgroundColor: 'transparent',
    paddingRight: '20px',
    outline: 'none',
  },
  container: {
    border: '1px solid #BABEC7',
    borderRadius: '4px',
    // eslint-disable-next-line max-len
    boxShadow: '0px 0.7471592426300049px 1.5496636629104614px 0px #00000002, 0px 1.7955275774002075px 3.724057197570801px 0px #00000003, 0px 3.380819082260132px 7.012069225311279px 0px #00000004, 0px 6.030803203582764px 12.508333206176758px 0px #00000005, 0px 11.27995491027832px 23.395462036132812px 0px #00000005',
    background: '#BABEC7',
    marginTop: '5px',
    marginBottom: '25px',
  },
  deleteIcon: {
    marginBottom: '3px',
    marginLeft: '10px',
  },
  addContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    cursor: 'pointer',
  },
};

class AnswerArea extends Component {
  static propTypes = {
    content: PropTypes.object,
    type: PropTypes.number,
    uploadImage: PropTypes.func,
    saveCard: PropTypes.func,
    takeSnapshot: PropTypes.func,
    historyPosition: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      adding: null,
      answers: props.content.answers,
      expert: props.content.explanation ? props.content.explanation[0].string : '',
    };
  }

  @bind
  getSnapshotBeforeUpdate(prevProps) {
    if (prevProps.historyPosition !== this.props.historyPosition) {
      this.setState({
        answers: this.props.content.answers,
        expert: this.props.content.explanation[0].string,
      });
    } return null;
  }

  @bind
  // eslint-disable-next-line react/no-unused-class-component-methods
  Answer(props) {
    const {
      index, correct, showDelete, answer,
    } = props;
    return (
      <List.Item style={{ padding: 0 }}>
        <div
          style={s.answer}
        >
          <ReactTextareaAutosize
            style={s.answerTextArea}
            rows={1}
            value={answer.answer || ''}
            onChange={(e) => (this.updateAnswer(index, e.target.value, !!correct))}
          />
          <Icon name="pencil" color="grey" style={{ marginLeft: '-20px' }} />
          {showDelete && (
            <Icon
              name="trash alternate outline"
              color="grey"
              style={s.deleteIcon}
              onClick={() => (this.updateAnswer(index, null, !!correct))}
              link
            />
          )}
        </div>
      </List.Item>
    );
  }

  @bind
  updateAnswer(index, answer, correct) {
    const data = this.state.answers;
    const newAnswer = { answer, correct };
    if (answer === null) data.splice(index, 1);
    else data[index] = newAnswer;
    this.setState({
      answers: data,
      adding: null,
    });

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      const { content } = this.props;
      this.props.takeSnapshot();

      if (answer === null) {
        content.answers.splice(index, 1);
        content.choices = Math.min(content.choices, content.answers.length);
        if (content.answers.length) this.setState({ adding: null });
      } else if (index >= content.answers.length) {
        content.answers.push(newAnswer);
        this.setState({ adding: null });
      } else {
        content.answers = content.answers.map((a, i) => (i === index ? newAnswer : a));
      }
      this.props.saveCard({ content });
    }, 300);
  }

  @bind
  updateExpertAnswer(answer) {
    this.setState({
      expert: answer,
    });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      const { content } = this.props;
      this.props.takeSnapshot();
      content.explanation[0].string = answer;
      this.props.saveCard({ content });
    }, 300);
  }

  @bind
  async updateImage(files) {
    const { content } = this.props;
    this.props.takeSnapshot();
    if (!files) {
      content.explanation[1].image = null;
    } else {
      const url = await this.props.uploadImage(files);
      content.explanation[1].image = url;
    }
    this.props.saveCard({ content });
  }

  render() {
    const { adding, answers } = this.state;
    const { type, content } = this.props;
    const expertImage = content.explanation ? content.explanation[1].image : '';
    let area = null;
    if (type === FREE_RESPONSE) {
      area = (
        <Form size="massive">
          <Form.Field required>
            <label>Answer:</label>
            <TextArea rows={1} placeholder="Learner will be asked to enter their response." disabled />
          </Form.Field>
        </Form>
      );
    } else {
      answers.forEach((answer, index) => { answer.index = index; });
      const groupedAnswers = _.groupBy(answers, 'correct');
      const correctAnswers = groupedAnswers.true ? groupedAnswers.true.map((answer) => (
        <this.Answer
          key={answer.index}
          showDelete={groupedAnswers.true.length > 1}
          answer={this.state.answers[answer.index] || ''}
          index={answer.index}
          correct
        />
      )) : [];
      const incorrectAnswers = groupedAnswers.false ? groupedAnswers.false.map((answer) => (
        <this.Answer
          key={answer.index}
          showDelete={groupedAnswers.false.length > 1}
          answer={this.state.answers[answer.index] || ''}
          index={answer.index}
        />
      )) : [];
      correctAnswers.push(
        adding === 'correct' ? (
          <this.Answer key={content.answers.length} answer="" first={false} index={content.answers.length} correct />
        ) : (
          <List.Item
            key={uuid()}
            style={s.addContainer}
            onClick={() => this.setState({
              adding: 'correct',
              // answers: [...this.state.answers, ''],
            })}
          >
            <Icon name="circle add" />
          </List.Item>
        ),
      );
      incorrectAnswers.push(
        adding === 'incorrect' ? (
          <this.Answer key={content.answers.length} answer="" first={false} index={content.answers.length} />
        ) : (
          <List.Item
            key={uuid()}
            style={s.addContainer}
            onClick={() => this.setState({
              adding: 'incorrect',
              // answers: [...this.state.answers, ''],
            })}
          >
            <Icon name="circle add" />
          </List.Item>
        ),
      );
      area = (
        <div>
          <Label color="green">Correct Answers</Label>
          <List style={s.container}>{correctAnswers}</List>
          <Label color="red">Incorrect Answers</Label>
          <List style={s.container}>{incorrectAnswers}</List>
        </div>
      );
    }
    return (
      <div>
        {area}
        <Label color="grey">Expert Answer</Label>
        <List style={s.container}>
          <List.Item style={{ padding: 0 }}>
            <div
              style={s.answer}
            >
              <ReactTextareaAutosize
                style={s.answerTextArea}
                rows={1}
                value={this.state.expert}
                onChange={(e) => this.updateExpertAnswer(e.target.value)}
              />
              <Icon name="pencil" color="grey" style={{ marginLeft: '-20px' }} />
              <Icon
                name="trash alternate outline"
                color="grey"
                style={s.deleteIcon}
                onClick={() => (this.updateExpertAnswer(null))}
                link
              />
            </div>
          </List.Item>
          <List.Item>
            <Upload
              maxSize={20}
              source={expertImage}
              updateFile={this.updateImage}
              description="an Image"
              accept="image/png, image/jpeg, image/gif, image/webp"
            />
          </List.Item>
        </List>
      </div>
    );
  }
}

export default Radium(AnswerArea);
