import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import {
  Button,
  Dropdown,
  Modal,
} from 'semantic-ui-react';
import Tag from '../../models/tag';

class TagModal extends Component {
  static propTypes = {
    toggleModal: PropTypes.func,
    showModal: PropTypes.bool,
    tagUsers: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      value: [],
    };
  }

  async componentDidMount() {
    const tags = await Tag.objects().all();
    this.setState({ tags });
  }

  @bind
  handleChange(e, { value }) {
    this.setState({
      value,
    });
  }

  @bind
  handleAddItem(e, { value }) {
    const { tags } = this.state;
    const newTag = new Tag();
    newTag.name = value;
    tags.push(newTag);
    this.setState({ tags });
  }

  render() {
    const tagOptions = this.state.tags.map((tag) => ({ value: tag.name, text: tag.name }));
    return (
      <Modal
        onClose={this.props.toggleModal}
        open={this.props.showModal}
        style={{ maxWidth: '500px' }}
        closeIcon
      >
        <Modal.Header>Tag Users</Modal.Header>
        <Modal.Content>
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Dropdown
              search
              selection
              multiple
              allowAdditions
              additionLabel="Create Tag: "
              onChange={this.handleChange}
              onAddItem={this.handleAddItem}
              options={tagOptions}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            onClick={() => {
              this.props.tagUsers(this.state.value);
            }}
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default Radium(TagModal);
