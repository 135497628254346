import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import {
  Button, Icon, Progress, Segment,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { stringify } from '@vanillaes/csv';
import _ from 'underscore';
import pluralize from 'pluralize';
import User from '../../../models/user';

const s = {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
};

class StatusDisplay extends Component {
  static propTypes = {
    duplicates: PropTypes.array,
    errors: PropTypes.array,
    success: PropTypes.array,
    groups: PropTypes.array,
    fields: PropTypes.object,
    restart: PropTypes.func,
  };

  static defaultProps = {
    duplicates: [],
    errors: [],
    success: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      totalUsers: 0,
      completedUsers: 0,
    };
  }

  @bind
  downloadCSV(name, data) {
    const { fields } = this.props;
    let sheet = [];
    sheet.push(Object.values(fields));
    sheet = sheet.concat(data);
    const filename = `${name}.csv`;
    const csvFile = new Blob([stringify(sheet)], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  @bind
  async addUsersToGroup(dupUsers) {
    this.setState({ adding: true });
    const { fields } = this.props;
    const emailIndex = Object.values(fields).indexOf('email');
    const emails = dupUsers.map((user) => user[emailIndex]);
    const users = await User.objects().filtered({ email: emails }).all();
    this.setState({ totalUsers: users.length });
    const actions = users.map(async (user) => {
      const subActions = this.props.groups.map((groupId) => actions.push(user.assignToGroup(groupId)));
      const data = _.find(dupUsers, (dupUser) => dupUser[emailIndex] === user.email);
      const newUserGroups = [];
      const newUserTags = [];
      Object.entries(fields).forEach(([key, value]) => {
        if (value === 'group' && data[key]) {
          newUserGroups.push(data[key]);
        } else if (value === 'tag' && data[key]) {
          newUserTags.push(data[key]);
        }
      });
      newUserGroups.forEach((group) => subActions.push(user.assignToGroup(null, group)));
      if (newUserTags.length) {
        subActions.push(User.objects().update(user.id, { tags: [...new Set(user.tags.concat(newUserTags))] }));
      }
      await Promise.all(subActions);
      this.setState((prevState) => ({ completedUsers: prevState.completedUsers + 1 }));
    });
    await Promise.all(actions);
    this.setState({ adding: false });
  }

  render() {
    const {
      duplicates, errors, success, restart, groups,
    } = this.props;
    const { adding, totalUsers, completedUsers } = this.state;
    return (
      <div>
        <Segment>
          <Segment style={s.row}>
            <div style={s.column}>
              <div>
                <b>{success.length}</b> rows contained <span style={{ color: 'green' }}>new unique learners</span>
              </div>
              <i>These rows were imported</i>
            </div>
            <Button icon labelPosition="right" onClick={() => this.downloadCSV('success', success)}>
              Download New Learner CSV
              <Icon name="download" />
            </Button>
          </Segment>
          <Segment style={s.row}>
            {!!totalUsers && (<Progress total={totalUsers} value={completedUsers} attached="top" color="green" />)}
            <div style={s.column}>
              <div>
                <b>{duplicates.length}</b> rows contained
                {' '}
                <span style={{ color: 'red' }}>existing learners or duplicate rows</span>
              </div>
              <i>These rows were <b>not</b> imported</i>
            </div>
            {!!duplicates.length && (
              completedUsers > 0 && !adding ? (
                <Button color="white" active>
                  {`${completedUsers} ${completedUsers.length > 1 ? 'Learners' : 'Learner'}
                   Added to Selected ${pluralize('Group', groups.length)} and Tags`}
                </Button>
              ) : (
                <Button
                  icon
                  labelPosition="right"
                  color="green"
                  onClick={() => this.addUsersToGroup(duplicates)}
                  disabled={adding}
                >
                  Add Existing Learners to Selected Groups and Tags
                  <Icon name="plus" />
                </Button>

              )
            )}
            <Button icon labelPosition="right" onClick={() => this.downloadCSV('duplicates', duplicates)}>
              Download Duplicates CSV
              <Icon name="download" />
            </Button>
            {!!totalUsers && (<Progress total={totalUsers} value={completedUsers} attached="bottom" color="green" />)}
          </Segment>
          <Segment style={s.row}>
            <div style={s.column}>
              <div>
                <b>{errors.length}</b> rows contained <span style={{ color: 'red' }}>data errors</span>
              </div>
              <i>These rows were <b>not</b> imported</i>
            </div>
            <Button icon labelPosition="right" onClick={() => this.downloadCSV('errors', errors)}>
              Download Data Errors CSV
              <Icon name="download" />
            </Button>
          </Segment>
        </Segment>
        <Segment>
          <Button icon labelPosition="left" onClick={restart}>Restart<Icon name="undo" /></Button>
          Upload Complete
          <Link to="/app/v2/people">
            <Button primary icon labelPosition="right" style={{ float: 'right' }}>
              Return to Learner List
              <Icon name="angle double right" />
            </Button>
          </Link>
        </Segment>
      </div>
    );
  }
}

export default Radium(StatusDisplay);
