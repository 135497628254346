import React, { Component } from 'react';
import Radium from 'radium';
import { Loader } from 'semantic-ui-react';

const s = {
  container: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },
};

class LogoutContainer extends Component {
  async componentDidMount() {
    localStorage.removeItem('token.access');
    localStorage.removeItem('token.refresh');
    window.location = '/api/v2/logout/';
  }

  render() {
    return (
      <div style={s.container}>
        <Loader active inline="centered" />
      </div>
    );
  }
}

export default Radium(LogoutContainer);
