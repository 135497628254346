import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class Breadcrumb extends Component {
  static propTypes = {
    parentName: PropTypes.string,
    parentUrl: PropTypes.string,
    name: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    extra: PropTypes.object,
  };

  render() {
    return (
      <h1 className="breadcrumb">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Link to={this.props.parentUrl}>{this.props.parentName}</Link>
          <span style={{ color: '#E6E8ED' }}>/</span>
          {this.props.name}
          {this.props.extra}
        </div>
        {this.props.children}
      </h1>
    );
  }
}

export default Breadcrumb;
