import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import {
  List, Segment, Sidebar, Menu, Icon, Divider, Header, Input,
} from 'semantic-ui-react';
import _ from 'underscore';
import bind from 'bind-decorator';
import { v4 as uuid } from 'uuid';
import Group from '../../models/group';
import Tag from '../../models/tag';
import { GROUP_TYPE_LEARNING } from '../../constants';

const s = {
  filterHeader: {
    display: 'flex',
    flexDirection: 'row',
    flex: '0 1 auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '13px',
    alignSelf: 'flex-start',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: 0,
  },
  filterList: {
    flex: '1 1 auto',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    margin: 0,
  },
  search: {
    marginLeft: '13px',
    width: '93%',
  },
};

class PeopleFilter extends Component {
  static propTypes = {
    style: PropTypes.object,
    filters: PropTypes.object,
    setFilter: PropTypes.func,
  };

  static defaultProps = {
    filters: {},
  };

  static noGroupsGroup = {
    name: 'NO GROUPS',
  };

  static untaggedTag = {
    name: 'UNTAGGED',
  };

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      items: [],
      openFilter: '',
      groupFilter: '',
      groupFilterText: '',
      tagFilter: '',
      tagFilterText: '',
      filters: props.filters,
      lookup: {
        roles: [
          { key: 'Owner', label: 'Owners' },
          { key: 'Admin', label: 'Admins' },
          { key: 'Manager', label: 'Managers' },
          { key: 'Writer', label: 'Writers' },
        ],
        status: [
          { key: 'active', label: 'Active' },
          { key: 'inactive', label: 'Inactive' },
          { key: 'invited', label: 'Invited' },
          { key: 'not_invited', label: 'Not Invited' },
          { key: 'invite_expired', label: 'Invite Expired' },
          { key: 'invite_failed', label: 'Invite Failed' },
          { key: 'invalid', label: 'Invalid' },
        ],
      },
    };
  }

  static getDerivedStateFromProps(props) {
    return { filters: props.filters };
  }

  @bind
  shouldComponentUpdate(nextProps, nextState) {
    const render = nextState !== this.state;
    return render;
  }

  @bind
  async componentDidMount() {
    const groups = [PeopleFilter.noGroupsGroup].concat(
      await Group.objects()
        .filtered({ deleted: false, group_type: GROUP_TYPE_LEARNING })
        .sorted('name', true)
        .all(),
    );
    const tags = [PeopleFilter.untaggedTag, ..._.sortBy(await Tag.objects().all(), 'name')];
    const temp = this.state.lookup;
    temp['learning_groups.name'] = groups.map((group) => ({ key: group.name, label: group.name }));
    temp.tags = tags.map((tag) => ({ key: tag.name, label: tag.name }));
    this.setState({
      lookup: temp,
    });
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  async refreshTags() {
    const tags = [PeopleFilter.untaggedTag].concat(await Tag.objects().all());
    const temp = this.state.lookup;
    temp.tags = tags.map((tag) => ({ key: tag.name, label: tag.name }));
    this.setState(
      {
        lookup: temp,
      },
      () => {
        if (this.state.visible) {
          this.showItems(this.state.openFilter);
        }
      },
    );
  }

  @bind
  showItems(filter) {
    this.setState({
      visible: true,
      openFilter: filter,
      items: this.state.lookup[filter.key],
    });
  }

  @bind
  hideItems() {
    this.setState({
      visible: false,
    });
  }

  @bind
  updateGroupFilter(e, { value }) {
    clearTimeout(this.timer);
    // set the groupFilterText immediately so the user can
    // have the feedback in the UI from their typing...
    this.setState({
      groupFilterText: value,
    });
    // but wait to actually execute the search (in case they're still typing).
    this.timer = setTimeout(() => {
      this.setState({
        groupFilter: value,
        groupFilterText: value,
      });
    }, 300);
  }

  @bind
  updateTagFilter(e, { value }) {
    clearTimeout(this.timer);
    // set the tagFilterText immediately so the user can
    // have the feedback in the UI from their typing...
    this.setState({
      tagFilterText: value,
    });
    // but wait to actually execute the search (in case they're still typing).
    this.timer = setTimeout(() => {
      this.setState({
        tagFilter: value,
        tagFilterText: value,
      });
    }, 300);
  }

  render() {
    let items = [];
    switch (this.state.openFilter.key) {
      case 'learning_groups.name':
        items = this.state.items.filter(
          (item) => item.label.toLowerCase().includes(this.state.groupFilter.toLowerCase()),
        );
        break;
      case 'tags':
        items = this.state.items.filter(
          (item) => item.label.toLowerCase().includes(this.state.tagFilter.toLowerCase()),
        );
        break;
      default:
        items = this.state.items;
    }
    return (
      <Sidebar.Pushable as={Segment} style={this.props.style}>
        <Sidebar
          as={Segment}
          animation="overlay"
          icon="labeled"
          inverted
          visible={this.state.visible}
          width="thin"
          style={s.filterContainer}
        >
          <div onClick={this.hideItems} style={s.filterHeader}>
            <Icon name="chevron left" size="large" link />
            <Header inverted as="h3" style={{ margin: 0 }}>
              {this.state.openFilter.label}
            </Header>
          </div>
          {this.state.openFilter.key === 'learning_groups.name' && (
            <Input
              style={s.search}
              icon="search"
              iconPosition="left"
              placeholder="Search groups..."
              onChange={this.updateGroupFilter}
              value={this.state.groupFilterText}
              transparent
              inverted
              fluid
            />
          )}
          {this.state.openFilter.key === 'tags' && (
            <Input
              style={s.search}
              icon="search"
              iconPosition="left"
              placeholder="Search tags..."
              onChange={this.updateTagFilter}
              value={this.state.tagFilterText}
              transparent
              inverted
              fluid
            />
          )}
          <Divider fitted />
          <Menu vertical inverted style={s.filterList}>
            {items.map((item) => (
              <Menu.Item
                key={item.id ? item.id : uuid()}
                active={item.key === this.state.filters[this.state.openFilter.key]}
                onClick={() => {
                  this.props.setFilter(this.state.openFilter.key, item.key);
                }}
              >
                {item.label}
              </Menu.Item>
            ))}
          </Menu>
        </Sidebar>

        <Sidebar.Pusher dimmed={this.state.visible} style={{ height: '100%' }}>
          <Segment
            inverted
            className="menu"
            style={{ height: '100%', borderRadius: 0, padding: 0 }}
          >
            <List divided inverted relaxed style={{ width: '100%' }}>
              <List.Item style={{ padding: '1rem 1rem' }}>
                <List.Icon name="filter" />
                <List.Content>FILTERS</List.Content>
              </List.Item>
              {[
                { key: 'roles', label: 'ROLE' },
                { key: 'status', label: 'STATUS' },
                { key: 'learning_groups.name', label: 'GROUP' },
                { key: 'tags', label: 'TAGS' },
              ].map((filter) => {
                const { key } = filter;
                return (
                  <List.Item
                    key={filter.key}
                    onClick={() => {
                      this.showItems(filter);
                    }}
                    className="link"
                    style={{ padding: '.5rem 1rem' }}
                  >
                    <Header as="h3">{filter.label}</Header>
                    {filter.key in this.state.filters && (
                    <div
                      style={_.defaults({}, s.row, { padding: '.5rem 0' })}
                      hidden={!(filter.key in this.state.filters)}
                    >
                      {
                          this.state.lookup[key].filter((item) => item.key === this.state.filters[key])[0].label
                        }
                      <Icon
                        name="remove circle"
                        inverted
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.setFilter(filter.key);
                        }}
                      />
                    </div>
                    )}
                  </List.Item>
                );
              })}
              <List.Item />
            </List>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

export default Radium(PeopleFilter);
