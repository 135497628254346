import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Icon,
  Dropdown,
  Modal,
} from 'semantic-ui-react';
import PropagateLoader from "react-spinners/PropagateLoader";
import Course from '@/models/course';
import SharedCourse from '@/models/shared_course';
import ModelTable from '../Shared/ModelTable';
import CourseCreationModal from '../Shared/CourseCreationModal';

class CourseListContainer extends Component {
  static propTypes = {
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      modalTableKey: uuid(),
      showCourseCreationModal: false,
      showContentLibrary: false,
      sharedCoursesAdded: {},
      sharedCoursesAdding: {},
      hasSharedCourses: false,
    };
  }

  @bind
  formatTime(secs) {
    const minutes = parseInt(secs / 60);
    let seconds = secs - minutes * 60;
    seconds = `0${seconds}`.slice(-2);
    return `${minutes}:${seconds}`;
  }

  @bind
  async addSharedCourse(share) {
    const { sharedCoursesAdded, sharedCoursesAdding } = this.state;

    sharedCoursesAdding[share.id] = true;
    this.setState({
      sharedCoursesAdding,
    });
    const success = await share.addToCourses();
    if (success) {
      sharedCoursesAdded[share.id] = true;
      sharedCoursesAdding[share.id] = false;
      this.setState({
        sharedCoursesAdded,
        sharedCoursesAdding,
      });
    }
  }

  async componentDidMount() {
    const shares = await SharedCourse.objects().filtered({ deleted: false }).page(1, 1);
    console.log("shares->", shares);
    this.setState({
      hasSharedCourses: (shares && shares.length > 0),
    });
  }

  render() {
    const { sharedCoursesAdded, sharedCoursesAdding, hasSharedCourses } = this.state;

    const courseColumnData = [
      {
        search: ['name'],
        key: [
          'this',
          (course) => {
            return (
              <div className="hickory-course-card">
                <img src={course.header_image_path} />
                { course.by_line ? (
                  <div className="hickory-course-by-line">{course.by_line}</div>
                ) : null}
                <div className="hickory-course-card-body">
                  <div className="hickory-course-card-title">
                    {course.name}
                  </div>
                  <div className="hickory-course-estimate">
                    <Icon name="clock outline" />
                    {course.median_time_spent
                      ? ` ${this.formatTime(course.median_time_spent)}`
                      : " calculating..."}

                    <Icon name="tasks" />
                    {course.lesson_path.length}
                    {course.lesson_path.length === 0 ? " lesson" : " lessons"}
                  </div>
                </div>
                <div className="hickory-course-card-footer">
                  <Link to={`/app/v2/courses/${course.id}`}>View Course</Link>
                </div>
              </div>
            );
          },
        ],
      },
    ];

    const sharedCourseColumnData = [
      {
        search: ['name'],
        key: [
          'this',
          (share) => {
            return (
              <div className="hickory-course-card">
                <img src={share.header_image_path} />
                { share.by_line ? (
                  <div className="hickory-course-by-line">{share.by_line}</div>
                ) : null}
                <div className="hickory-course-card-body">
                  <div className="hickory-course-card-title">
                    {share.name}
                  </div>
                  <div className="hickory-course-estimate">
                    {share.description}
                  </div>
                </div>
                <div className="hickory-course-card-footer">
                  { sharedCoursesAdding[share.id] ? (
                    <div className="hickory-loader-holder">
                      <PropagateLoader />
                    </div>
                  ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <React.Fragment>
                      {share.target_course || sharedCoursesAdded[share.id] ? (
                        <span>Already in Your Courses</span>
                      ) : (
                        <Link onClick={() => { this.addSharedCourse(share); }}>
                          Add to Your Courses
                        </Link>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            );
          },
        ],
      },
    ];

    return (
      <div className="hickory-container">
        <div className="contents-header">
          <h1>Courses</h1>
          { hasSharedCourses ? (
            <Dropdown text="Create New..." button className="green" style={{ height: "40px" }}>
              <Dropdown.Menu>
                <Dropdown.Item
                  text="Course"
                  onClick={() => {
                    this.setState({
                      showCourseCreationModal: true,
                    });
                  }}
                />
                <Dropdown.Item
                  text="From Content Library"
                  onClick={() => {
                    this.setState({
                      showContentLibrary: true,
                      sharedCoursesAdded: {},
                      sharedCoursesAdding: {},
                    });
                  }}
                />
              </Dropdown.Menu>
            </Dropdown>
          ) : (
            <Button
              className="primary-button"
              onClick={() => {
                this.setState({
                  showCourseCreationModal: true,
                });
              }}
            >
              Create Course
            </Button>
          )}

        </div>
        <ModelTable
          gridLayout
          defaultPerPage={12}
          key={this.state.modalTableKey}
          title=""
          model={Course}
          baseFilter={{ deleted: false }}
          columns={courseColumnData}
          defaultSort={['name', 'asc']}
        >
          <ModelTable.EmptyContainer>
            <div className="hickory-empty-state-container">
              <div>
                It does not look like you have any courses right now. Let&apos;s fix that!
                Courses are a great and powerful way to manage lessons, and creating them is simple.
                Click the &quot;Create New...&quot; button above to get started.
              </div>
            </div>
          </ModelTable.EmptyContainer>
        </ModelTable>

        <CourseCreationModal
          open={this.state.showCourseCreationModal}
          onClose={() => {
            this.setState({ showCourseCreationModal: false });
          }}
          history={this.props.history}
        />

        <Modal
          open={this.state.showContentLibrary}
          onClose={() => {
            this.setState({ showContentLibrary: false });
          }}
          size="large"
          closeIcon
        >
          <Modal.Header>Content Library</Modal.Header>
          <Modal.Content>
            <div>
              <ModelTable
                gridLayout
                defaultPerPage={12}
                key={this.state.modalTableKey}
                title=""
                model={SharedCourse}
                baseFilter={{ deleted: false, approved: true }}
                columns={sharedCourseColumnData}
                defaultSort={['name', 'asc']}
              />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              type="submit"
              className="gray"
              onClick={() => {
                this.setState({ showContentLibrary: false });
              }}
            >
              Close
            </Button>
          </Modal.Actions>
        </Modal>

      </div>
    );
  }
}

export default CourseListContainer;
