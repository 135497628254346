import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import { Button, Modal } from 'semantic-ui-react';
import pluralize from 'pluralize';
import Lesson from '@/models/lesson';
import LessonControlBar from '../LessonPanel/LessonControlBar';
import LessonsList from '../LessonPanel/LessonsList';

class LessonModal extends Component {
  static propTypes = {
    toggleModal: PropTypes.func,
    showModal: PropTypes.bool,
    previewLesson: PropTypes.func,
    addLessons: PropTypes.func,
    assignedLessons: PropTypes.array,
    title: PropTypes.string,
  };

  static defaultProps = {
    title: 'Add a Lesson to the Group',
  };

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      perPage: 50,
      lessons: [],
      sort: { column: 'name', ascending: true },
      selectedLessons: [],
    };
  }

  @bind
  componentDidMount() {
    this.updateLessons();
  }

  @bind
  componentDidUpdate(prevProps) {
    if (this.props.assignedLessons !== prevProps.assignedLessons) {
      this.updateLessons();
    }
  }

  @bind
  async updateLessons() {
    this.setState({ loading: true });
    const allLessons = await Lesson.objects()
      .search(this.state.search)
      .sorted(this.state.sort.column, this.state.sort.ascending)
      .all();

    // We _shouldn't_ have to worry about check organizational_group_id here, but since we share
    // Hickory content with our customers, and we don't want those appearing in this list...
    const unassignedLessons = allLessons.filter(
      (lesson) => !this.props.assignedLessons.includes(lesson.id) && lesson.organizational_group_id,
    );
    const page = this.state.page - 1;
    const startOffset = page * this.state.perPage;
    const l = unassignedLessons.slice(startOffset, startOffset + this.state.perPage);

    this.setState({
      lessons: l,
      lessonCount: unassignedLessons.length,
      allLessons: unassignedLessons,
      loading: false,
    });
  }

  @bind
  setPage(page) {
    this.setState(
      {
        page,
      },
      this.updateLessons,
    );
  }

  @bind
  setPerPage(perPage) {
    this.setState(
      {
        perPage,
        page: 1,
      },
      this.updateLessons,
    );
  }

  @bind
  setSearch(value) {
    const query = {
      name: [value],
    };

    this.setState(
      {
        search: value.length >= 1 ? query : {},
        page: 1,
      },
      this.updateLessons,
    );
    this.selectNone();
  }

  @bind
  setSort(key) {
    this.setState(
      {
        sort: {
          column: key,
          ascending: this.state.sort.column === key ? !this.state.sort.ascending : true,
        },
      },
      this.updateLessons,
    );
  }

  @bind
  handleClose() {
    this.selectNone();
    this.setState(
      {
        search: {},
      },
      this.updateLessons,
    );
    this.props.toggleModal();
  }

  @bind
  selectAll() {
    let selectedLessons = [];
    let allSelected = false;
    if (!this.state.allSelected) {
      selectedLessons = this.state.allLessons.filter((lesson) => lesson.items__count > 0);
      allSelected = true;
    }
    this.setState({
      selectedLessons,
      allSelected,
    });
  }

  @bind
  selectNone() {
    this.setState({
      selectedLessons: [],
      allSelected: false,
    });
  }

  @bind
  selectLesson(lesson) {
    console.log('selected lesson', lesson);
    let selectedLessons = [];
    if (this.state.selectedLessons.find((item) => item.id === lesson.id)) {
      selectedLessons = this.state.selectedLessons.filter((item) => item.id !== lesson.id);
    } else {
      selectedLessons = this.state.selectedLessons.concat([lesson]);
    }
    this.setState({
      selectedLessons,
    });
  }

  render() {
    return (
      <Modal closeIcon onClose={this.handleClose} open={this.props.showModal}>
        <Modal.Header>{this.props.title}</Modal.Header>
        <Modal.Content>
          <LessonControlBar
            page={this.state.page}
            setPage={this.setPage}
            perPage={this.state.perPage}
            setPerPage={this.setPerPage}
            lessonCount={this.state.lessonCount}
            setSearch={this.setSearch}
          />
          <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
            <LessonsList
              lessons={this.state.lessons}
              preview={this.props.previewLesson}
              sort={this.state.sort}
              setSort={this.setSort}
              selectLesson={this.selectLesson}
              selectAll={this.selectAll}
              selectedLessons={this.state.selectedLessons}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          {this.state.selectedLessons.length}
          {' '}
          {pluralize('lesson', this.state.selectedLessons.length)}
          {' '}
          selected
          <Button onClick={this.handleClose}>Cancel</Button>
          <Button
            positive
            disabled={this.state.loading}
            onClick={() => {
              this.props.addLessons(this.state.selectedLessons);
              this.handleClose();
              this.setState({
                selectedLessons: [],
              });
            }}
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default Radium(LessonModal);
