import { ModelBase } from './model';

class Report extends ModelBase {
  static namespace = 'report';

  static async create(name, data) {
    const response = await super.objects().create(data, name);
    return response;
  }
}
export default Report.register();
