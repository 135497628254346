/* eslint-disable */
// Lifted from: https://medium.com/@BogdanSoare/how-to-use-reacts-new-context-api-to-easily-manage-modals-2ae45c7def81
























/****************************************************************
* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! *
* !!!!!!!!!!!!!!!!!!DO NOT USE THIS COMPONENT!!!!!!!!!!!!!!!!!! *
* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! *
*****************************************************************/















































import React, { Component, createContext } from 'react'

/****************************************************************
* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! *
* !!!!!!!!!!!!!!!!!!DO NOT USE THIS COMPONENT!!!!!!!!!!!!!!!!!! *
* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! *
*****************************************************************/


const ModalContext = createContext({
  component: null,
  props: {},
  showModal: () => {},
  hideModal: () => {}
})

export class ModalProvider extends Component {
  showModal = (component, props = {}) => {
    this.setState({
      component,
      props
    })
  }

  hideModal = () =>
    this.setState({
      component: null,
      props: {}
    })

  state = {
    component: null,
    props: {},
    showModal: this.showModal,
    hideModal: this.hideModal
  }

  render() {
    return (
      <ModalContext.Provider value={this.state}>
        {this.props.children}
      </ModalContext.Provider>
    )
  }
}

export const ModalConsumer = ModalContext.Consumer

export const ModalRoot = () => (
  <ModalConsumer>
    {({ component: Component, props, hideModal }) =>
      Component ? <Component {...props} hideModal={hideModal} /> : null
    }
  </ModalConsumer>
)

/****************************************************************
* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! *
* !!!!!!!!!!!!!!!!!!DO NOT USE THIS COMPONENT!!!!!!!!!!!!!!!!!! *
* !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! *
*****************************************************************/
