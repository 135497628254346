import { ModelBase } from './model';

class Tag extends ModelBase {
  static namespace = 'tags';

  static path = '/api/v2/users/tags/';

  static cache = false;
}
export default Tag.register();
