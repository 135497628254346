import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import { Checkbox, Button } from 'semantic-ui-react';
import PDFViewer from '../Shared/PDFViewer';
import LessonCompleteCard from './LessonCompleteCard';

const s = {
  content: {
    backgroundColor: '#D6D7DC',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  contentChild: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  lessonName: {
    width: '100%',
    marginLeft: '20px',
  },
  articleHeader: {
    backgroundColor: 'white',
    width: '1000px',
    height: '40px',
    display: 'flex',
    justifyContent: 'space-around',
  },
  articleFooter: {
    backgroundColor: 'white',
    width: '1000px',
    height: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '20px',
    paddingLeft: '20px',
    paddingBottom: '10px',
    paddingTop: '10px',
  },
  acknowledgementCheckbox: {
    paddingTop: '15px',
  },
  completionContainer: {
    backgroundColor: '#D6D7DC',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 auto',
  },
  completionNavigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '640px',
    height: '54px',
  },
};

class ArticlePlayer extends Component {
  static propTypes = {
    lesson: PropTypes.object,
    preview: PropTypes.bool,
    nextLesson: PropTypes.string,
    activityCount: PropTypes.number,
    nextInCourse: PropTypes.array,
    nextIsCourse: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    const { lesson } = this.props;
    this.state = {
      articleRead: false,
      acknowledgement: !lesson.require_acknowledgement,
      complete: false,
      startDate: new Date(),
    };
  }

  @bind
  toggleAcknowledgement() {
    const ack = this.state.acknowledgement;
    this.setState({ acknowledgement: !ack });
  }

  @bind
  async submitLesson() {
    if (this.props.preview) {
      return;
    }
    const { lesson } = this.props;
    const { startDate } = this.state;
    const duration = (new Date()) - startDate;
    const success = await lesson.markAsComplete(duration);
    this.setState({ complete: success });
  }

  render() {
    const { lesson } = this.props;
    if (this.state.complete) {
      return (
        <div style={s.completionContainer}>
          <div style={s.completionNavigation} />
          <LessonCompleteCard
            activityCount={this.props.activityCount}
            next={this.props.nextLesson}
            nextInCourse={this.props.nextInCourse}
            nextIsCourse={this.props.nextIsCourse}
            showScore={false}
            lesson={lesson}
          />
        </div>
      );
    }
    return (
      <div style={s.content}>
        <div style={s.contentChild}>
          <div style={s.articleHeader}>
            <h1 style={s.lessonName}>
              {lesson.name}
            </h1>
          </div>
          <PDFViewer
            url={lesson.content_path}
            height="600px"
            width="1000px"
            onScroll={(e, scrollPercent) => {
              if (scrollPercent >= 0.99) {
                this.setState({ articleRead: true });
              }
            }}
          />
          <div style={s.articleFooter}>
            {lesson.require_acknowledgement ? (
              <Checkbox
                label="I acknowledge that I have read and understand the above document"
                style={s.acknowledgementCheckbox}
                disabled={!this.state.articleRead}
                onChange={this.toggleAcknowledgement}
              />
            ) : (<div> </div>) }
            <Button
              color="green"
              disabled={!(this.state.articleRead && this.state.acknowledgement)}
              onClick={this.submitLesson}
            >
              Complete Lesson
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Radium(ArticlePlayer);
