import React, { Component } from 'react';
import Radium from 'radium';
import bind from 'bind-decorator';
import { Button, Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Lesson from '@/models/lesson';

class LessonSummary extends Component {
  static propTypes = {
    lesson: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      lesson_name: this.props.lesson.name,
    };
  }

  @bind
  saveLesson() {
    Lesson.objects()
      .update(this.props.lesson.id, { name: this.state.lesson_name })
      .then(() => {
        const newUrl = '/app/v2/lessons/';
        window.location = newUrl; // HACK
      });
  }

  render() {
    return (
      <div>
        <div>
          <div>
            Title:
            {' '}
            <Input
              value={this.state.lesson_name}
              onChange={(e, { value }) => {
                this.setState({ lesson_name: value });
              }}
            />
          </div>
        </div>
        <div>
          <br />
          <br />
          <br />
          <Button color="green" onClick={this.saveLesson}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export default Radium(LessonSummary);
