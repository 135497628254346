import _ from 'underscore';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import ScormEvent from '@/models/scorm_event';
import User from '@/models/user';
import 'scorm-again';
import LessonCompleteCard from './LessonCompleteCard';

const s = {
  frame: {
    height: '100%',
    width: '100%',
  },
  completionContainer: {
    backgroundColor: '#D6D7DC',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 auto',
  },
  completionNavigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '640px',
    height: '54px',
  },
};

class ScormPlayer extends Component {
  static propTypes = {
    lesson: PropTypes.object,
    nextLesson: PropTypes.string,
    nextInCourse: PropTypes.array,
    nextIsCourse: PropTypes.bool,
    activityCount: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.state = {
      lesson_id: props.lesson.id,
      loading: true,
      cmi_values: [],
      complete: false,
    };
  }

  async componentDidMount() {
    const settings = {};
    window.API_1484_11 = new window.Scorm2004API(settings);
    const user = await User.me();

    const scormEvents = await ScormEvent.objects().filtered({
      user_id: user.id, lesson_id: this.state.lesson_id,
    }).all();
    const cmiValues = _.map(scormEvents, (event) => ({
      lesson_id: event.lesson_id,
      user_id: event.lesson_id,
      key: event.key,
      value: event.value,
    }));

    window.API_1484_11.on('SetValue.cmi.*', (key, value) => {
      if (key === "cmi.exit") {
        console.log("SCORM file issued cmi.exit", value);
        this.setState({
          complete: true,
        });
        return;
      }
      const values = this.state.cmi_values;
      const v = {
        lesson_id: this.state.lesson_id,
        user_id: user.id,
        key,
        value,
      };
      // Since SCORM does _not_ support async, we can either await the
      // result of object creation, nor can we use the result.
      // We will just fire this off so it can hit the API.
      ScormEvent.objects().create(v);

      // we'll find the _first_ element in this array matching criteria
      // in the GetValue event handler, so we want to ensure that newer
      // values appear first, so we'll using unshift (instead of append)
      values.unshift(v);
    });

    window.API_1484_11.on('GetValue.cmi.*', (key) => {
      const values = this.state.cmi_values;
      return _.findWhere(values, { key });
    });

    this.setState({ loading: false, cmi_values: cmiValues });
  }

  componentWillUnmount() {
    // since SCORM pollutes the global namespace, let's clean this up
    // when we exit.
    if (window.API_1484_11) {
      window.API_1484_11.clear('SetValue.cmi.*');
      window.API_1484_11.clear('GetValue.cmi.*');
      window.API_1484_11 = null;
    }
  }

  render() {
    if (this.state.loading) {
      return <div />;
    }
    if (this.state.complete) {
      return (
        <div style={s.completionContainer}>
          <div style={s.completionNavigation} />
          <LessonCompleteCard
            activityCount={this.props.activityCount}
            next={this.props.nextLesson}
            nextInCourse={this.props.nextInCourse}
            nextIsCourse={this.props.nextIsCourse}
            showScore={false}
            lesson={this.props.lesson}
          />
        </div>
      );
    }
    return (
      <iframe
        style={s.frame}
        frameBorder="0"
        scrolling="no"
        src={`/api/v2/scorm/${this.state.lesson_id}`}
      />
    );
  }
}

export default Radium(ScormPlayer);
