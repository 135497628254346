import React, { Component } from 'react';
import Radium from 'radium';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';
import { Button, Progress, Loader } from 'semantic-ui-react';
import _ from 'underscore';
import moment from 'moment';
import SumoLogger from 'sumo-logger';
import Lesson from '@/models/lesson';
import UserLessonStatus from '@/models/user_lesson_status';
import User from '@/models/user';
import Card from '@/models/card';
import UserCardResponse from '@/models/user_card_response';
import VideoPlayer from './VideoPlayer';
import ArticlePlayer from './ArticlePlayer';
import ScormPlayer from './ScormPlayer';
import {
  SCENARIO,
  SCORM,
  ARTICLE,
  VIDEO,
  SUMO,
  INSTRUCTION,
} from '../../constants';
import LessonTitleCard from './LessonTitleCard';
import LessonCard from './LessonCard';
import LessonCompleteCard from './LessonCompleteCard';
import UserCourseStatus from '../../models/user_course_status';
// eslint-disable-next-line import/no-cycle
import LessonPreviewModal from '../Shared/LessonPreviewModal';

const sumoLogger = new SumoLogger({ endpoint: SUMO });

const s = {
  container: {
    backgroundColor: '#D6D7DC',
    height: '100%',
    minHeight: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '1 1 auto',
  },
  cardTile: {
    width: '600px',
    height: '750px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  footer: {
    display: 'flex',
    color: '#918f90',
    fontSize: '1.5em',
    justifyContent: 'space-between',
    borderTop: '1px solid #d1d1d1',
    marginTop: '20px',
    width: '100%',
  },
  cardTitle: {
    fontWeight: '400',
    lineHeight: '1.3',
    fontSize: '1.6em',
  },
  startButton: {
    display: 'block',
    backgroundColor: '#2185D0',
    textAlign: 'center',
    color: '#ffffff',
    textShadow: 'none',
    padding: '10px',
    borderRadius: '5px',
    fontSize: '1.5em',
  },
  navigation: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '640px',
    height: '54px',
  },
  navButtons: {
    padding: '10px',
    margin: '10px',
  },
  studyButton: {
    position: 'absolute',
    bottom: '90px',
    left: '10px',

  },
};

class LessonPlayer extends Component {
  static propTypes = {
    match: PropTypes.object,
    lessonId: PropTypes.string,
    preview: PropTypes.bool,
    nextInCourse: PropTypes.array,
    courseId: PropTypes.string,
    hideFinalScreen: PropTypes.bool,
    study: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      lesson_id: props.lessonId || props.match.params.lesson_id,
      loading: true,
      cardIndex: -1,
    };
  }

  componentDidMount() {
    this.initializePlayer();
  }

  @bind
  componentDidUpdate(prevProps) {
    if (this.props.match?.params.lesson_id !== prevProps.match?.params.lesson_id
      || this.props.lessonId !== prevProps.lessonId) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        lesson_id: this.props.lessonId || this.props.match.params.lesson_id,
      }, this.initializePlayer);
    }
  }

  @bind
  async initializePlayer() {
    this.setState({ loading: true });
    const { preview, study } = this.props;
    const lessonId = this.state.lesson_id;
    const me = await User.me();
    if (lessonId === undefined) {
      sumoLogger.log(
        `ERROR: missing lesson_id for user ${me.id} in LessonPlayer.jsx`,
      );
    }
    const lesson = await Lesson.objects().get(lessonId);
    const status = await UserLessonStatus.objects().filtered({ user_id: me.id, lesson_id: lesson.id }).first();
    if (me.roles.includes('None')) {
      if (!status) window.location = '/app/v2/learning/home';
      if (preview && !status.completed) window.location = `/app/v2/lessons/${lesson.id}/player`;
    }
    if (!lesson.card_order) {
      this.setState({
        loading: false,
        lesson,
      });
      return;
    }
    const responses = await UserCardResponse
      .objects()
      .filtered({ user_id: me.id, card__lesson_id: lesson.id, _latest: 'true' }).all();
    let assignments = await UserLessonStatus.objects()
      .filtered({ user_id: me.id, available_to_take: true })
      .sorted('localized_start_date', false)
      .all();
    assignments = _.groupBy(assignments, 'completed');
    const incompleteReviews = (assignments.true || []).filter((assignment) => {
      const avail = _.some(assignment.cards_available_to_review, (c) => moment(c.scheduled_review_date)
        .isBefore(moment()));
      return assignment.cards_available_to_review.length && avail;
    });
    const incompleteAssignments = incompleteReviews.concat(assignments.false || []);
    const incompleteCount = incompleteAssignments.length;
    const filter = { lesson_id: lesson.id };
    if (!preview && !status) {
      console.warn(`lesson ${lesson.id} not available.`);
      window.location = '/app/v2/learning/home';
      return;
    }
    if (!preview && status.completed) filter.card_type__not = INSTRUCTION;
    if (preview && study) filter.card_type = INSTRUCTION;
    let cards = await Card.objects().filtered(filter).all();
    if (!preview && status.completed) {
      const reviewIds = _.pluck(
        status.cards_available_to_review.filter((card) => moment(card.scheduled_review_date).isBefore(moment())),
        'card_id',
      );
      cards = cards.filter((card) => reviewIds.includes(card.id));
    }

    const courses = await UserCourseStatus.objects().all();
    cards.map((card) => {
      if (card.card_type === SCENARIO && !card.content.answers) {
        const { content } = card;
        const data = content.scenario.pop();
        content.answers = data.answers;
        content.question = data.question;
        content.explanation = data.explanation;
        content.choices = data.choices;
        card.content = content;
      } return card;
    });
    cards = _.compact(lesson.card_order.map((card) => _.findWhere(cards, { id: card })));
    let cardIndex = this.props.study ? 0 : -1;
    if (!this.props.preview && !(status.completed || status.retake) && responses) {
      cardIndex = responses.length;
    }
    this.setState({
      loading: false,
      responses,
      lesson,
      status,
      cards,
      courses,
      cardIndex,
      incompleteCount,
      incompleteAssignments,
    });
  }

  @bind
  startLesson() {
    this.setState({
      cardIndex: 0,
    });
  }

  @bind
  async nextCard() {
    const { cardIndex, cards } = this.state;
    if (this.props.hideFinalScreen && cardIndex === cards.length - 1) return;
    this.setState({
      cardIndex: cardIndex + 1,
    });
  }

  @bind
  previousCard() {
    this.setState({
      cardIndex: this.state.cardIndex - 1,
    });
  }

  @bind
  async submitResponse(answerData) {
    const {
      status, cards, cardIndex, responses,
    } = this.state;

    if (!this.props.preview) {
      const newResponse = await UserCardResponse.objects().create({
        user_id: status.user_id,
        card_id: cards[cardIndex].id,
        ...answerData,
      });
      this.setState({
        responses: [...responses, newResponse],
      });
    }
  }

  @bind
  async submitInFlight(answerData) {
    if (!this.props.preview) {
      const {
        status, cards, cardIndex,
      } = this.state;
      const newStatus = await UserLessonStatus.objects().update(status.id, {
        in_flight: {
          card_id: cards[cardIndex].id,
          answer_string: answerData.answer_string,
          answer_index: answerData.answer_index,
          time_spent: answerData.time_spent,
        },
      });
      this.setState({ status: newStatus });
    }
  }

  render() {
    const {
      lesson, status, cards, cardIndex, responses, courses,
    } = this.state;
    const {
      preview, nextInCourse, courseId, hideFinalScreen,
    } = this.props;
    const otherCourses = courses ? courses.filter((c) => c.id !== courseId) : [];
    let nextIsCourse = false;
    let nextId = null;
    const showScore = _.some(cards, (c) => c.card_type !== INSTRUCTION);
    if (this.state.loading) {
      return (
        <div style={s.container}>
          <Loader active inline="centered" />
        </div>
      );
    }
    if (otherCourses.length) {
      nextIsCourse = true;
      nextId = otherCourses[0].id;
    } else {
      const nextLessons = this.state.incompleteAssignments.filter((assignment) => assignment.lesson_id !== lesson.id);
      nextId = nextLessons.length > 0 ? nextLessons[0].lesson_id : null;
    }
    if (lesson.lesson_type === SCORM) {
      return (
        <ScormPlayer
          lesson={this.state.lesson}
          nextLesson={preview ? '' : nextId}
          nextInCourse={nextInCourse}
          nextIsCourse={nextIsCourse}
          activityCount={this.state.incompleteCount}
        />
      );
    }
    if (lesson.lesson_type === ARTICLE || lesson.lesson_type === VIDEO) {
      if (lesson.lesson_type === ARTICLE) {
        return (
          <ArticlePlayer
            lesson={this.state.lesson}
            preview={this.props.preview}
            nextLesson={preview ? '' : nextId}
            nextInCourse={nextInCourse}
            nextIsCourse={nextIsCourse}
            activityCount={this.state.incompleteCount}
          />
        );
      }
      return (
        <VideoPlayer
          lesson={this.state.lesson}
          preview={this.props.preview}
          nextLesson={preview ? '' : nextId}
          nextInCourse={nextInCourse}
          nextIsCourse={nextIsCourse}
          activityCount={this.state.incompleteCount}
        />
      );
    }
    const style = preview ? { ...s.container, paddingBottom: '20px' } : s.container;
    if (cardIndex < 0) {
      return (
        <div style={style}>
          <div style={s.navigation} />
          <LessonTitleCard
            lesson={lesson}
            startLesson={this.startLesson}
            preview={preview}
            status={status}
            empty={!cards}
          />
        </div>
      );
    }
    if (cardIndex === cards.length) {
      return (
        <div style={style}>
          <div style={s.navigation} />
          <LessonCompleteCard
            activityCount={this.state.incompleteCount}
            next={preview ? '' : nextId}
            nextInCourse={nextInCourse}
            nextIsCourse={nextIsCourse}
            lesson={lesson}
            status={status}
            showScore={showScore}
            preview={preview}
            initializePlayer={this.initializePlayer}
          />
        </div>
      );
    }
    const stack = cards.length - cardIndex;
    let responseData = null;
    if (!preview) {
      responseData = _.findWhere(responses, { card_id: cards[cardIndex].id });
      if (status && (status.completed || status.retake)) {
        const { scheduled_review_date: scheduledReviewDate } = _.findWhere(
          status.cards_available_to_review,
          { card_id: cards[cardIndex].id },
        );
        if (responseData && moment(scheduledReviewDate).isAfter(responseData.submitted_at)) responseData = null;
      }
      if (!responseData && status.in_flight && status.in_flight.card_id === cards[cardIndex].id) {
        responseData = status.in_flight;
      }
    }
    return (
      <div style={style}>
        <div style={s.navigation}>
          <Button
            color="blue"
            disabled={cardIndex < 1}
            onClick={this.previousCard}
            style={s.navButtons}
            compact
          >
            Back
          </Button>
          <Progress
            value={cardIndex + 1}
            total={cards.length}
            progress="ratio"
            color="green"
            style={{ width: '100%', margin: 'auto' }}
          />
          <Button
            color="blue"
            disabled={(!(responseData && (!responseData.difficulty === !responseData.answer_string))
              && !this.props.preview) || (hideFinalScreen && cardIndex === cards.length - 1)}
            onClick={this.nextCard}
            style={s.navButtons}
            compact
          >
            Next
          </Button>
        </div>
        <LessonCard
          card={cards[cardIndex]}
          stack={stack}
          nextCard={this.nextCard}
          response={responseData}
          submit={this.submitResponse}
          inFlight={this.submitInFlight}
        />
        {status?.completed && !preview
        && (
        <Button style={s.studyButton} color="blue" onClick={() => this.setState({ showStudyModal: true })}>
          Study
        </Button>
        )}
        <LessonPreviewModal
          lessonId={lesson.id}
          show={this.state.showStudyModal}
          close={() => this.setState({ showStudyModal: false })}
          study
        />
      </div>
    );
  }
}

export default Radium(LessonPlayer);
