/* eslint-disable react/jsx-props-no-spreading */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import _ from 'underscore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import moment from 'moment';
import {
  Button,
  Dropdown,
  Header,
  Icon,
  Input,
  Label,
  Loader,
  Segment,
  Modal,
} from 'semantic-ui-react';
import Card from '@/models/card';
import Lesson from '@/models/lesson';
import {
  DEFAULT_QUESTION_CARD_CONTENT,
  DEFAULT_SCENARIO_CARD_CONTENT,
  INSTRUCTION,
  QUESTION,
  SCENARIO,
  CARD,
  VIDEO,
  ARTICLE,
} from '../../constants';
import EditorCard from './EditorCard';
import LessonPublishComponent from './LessonPublishComponent';
import { clone } from '../../utilities';
import ArticleEditorContainer from './ArticleEditorContainer';
import VideoEditorContainer from './VideoEditorContainer';
import { hickoryBlue } from '../../styles/palette';
import LessonPreviewModal from '../Shared/LessonPreviewModal';

const s = {
  container: {
    display: 'flex',
    height: '100%',
  },
  sidebar: {
    display: 'flex',
    flexDirection: 'column',
    width: '440px',
    background: 'white',
    // eslint-disable-next-line max-len
    boxShadow: '0px 1.1899203062057495px 1.743371605873108px 0px #03060F03, 0px 2.859543800354004px 4.189564228057861px 0px #03060F04, 0px 5.384267330169678px 7.888577461242676px 0px #03060F05, 0px 9.604613304138184px 14.071874618530273px 0px #03060F06, 0px 17.964372634887695px 26.319894790649414px 0px #03060F07, 0px 43px 63px 0px #03060F0A',
    height: '100%',
    padding: '40px',
  },
  lesson: {
    background: '#E6E8ED',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'calc(100% - 440px)',
    overflow: 'auto',
  },
  buttonContainer: {
    color: 'blue',
    fontSize: '15px',
    marginBottom: '20px',
    height: '80px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardsRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  cardTypes: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '90px',
    background: '#F2F3F4',
    alignItems: 'center',
    padding: '20px 40px',
    whiteSpace: 'nowrap',
  },
  titleEdit: {
    width: '340px',
  },
  grabber: {
    backgroundImage: 'radial-gradient(#D8DBE2 30%, transparent 20%)',
    backgroundSize: '10px 10px',
    padding: '10px',
    width: '30px',
    marginRight: '10px',
  },
  titleCard: {
    display: 'flex',
    flexDirection: 'row',
    height: '130px',
    marginBottom: '10px',
  },
  titleCardContainer: {
    overflow: 'auto',
    marginTop: '10px',
  },
  deleteIcon: {
    marginLeft: 'auto',
    color: 'red',
    cursor: 'pointer',
  },
  lessonHeader: {
    marginTop: '5px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 3,
  },
};

const CARD_TYPES = ['Instruction', 'Question', 'Scenario'];

class EditorContainer extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      lesson_id: props.match.params.lesson_id,
      loading: true,
      editingName: false,
      newLessonName: null,
      deleting: null,
      selected: null,
      history: [],
      historyPosition: 0,
    };
  }

  async componentDidMount() {
    const lesson = await Lesson.objects().get(this.state.lesson_id);
    let cards = await Card.objects()
      .filtered({ lesson_id: lesson.id, deleted: false })
      .all();
    let refresh = false;
    const actions = [];
    cards.forEach((card) => {
      if (card.card_type === SCENARIO && !card.content.answers) {
        console.log(`Rewriting scenario content for card ${card.id}`);
        const { content } = card;
        const data = content.scenario.pop();
        content.answers = data.answers;
        content.question = data.question;
        content.explanation = data.explanation;
        content.choices = data.choices;
        console.log(content);
        actions.push(Card.objects().update(card.id, { content }));
        refresh = true;
      }
    });
    if (refresh) {
      await Promise.all(actions);
      cards = await Card.objects()
        .filtered({ lesson_id: lesson.id, deleted: false })
        .all();
    }
    cards = _.compact(lesson.card_order.map((card) => _.findWhere(cards, { id: card })));
    this.setState({
      lesson,
      cards,
      loading: false,
      canEdit: lesson.content_head && !lesson.published,
    });
  }

  @bind
  async doCreateNewDraft() {
    const { lesson } = this.state;
    this.setState({ draftCreating: true });
    const newRevision = await lesson.new_revision();

    window.location = `/app/v2/lessons/${newRevision.id}/editor`;
  }

  @bind
  setSelected(selected) {
    this.setState({ selected });
  }

  @bind
  async saveLessonName() {
    const { newLessonName, lesson } = this.state;
    if (newLessonName !== lesson.name) {
      this.takeSnapshot();
      this.saveLesson({ name: newLessonName });
    }
    this.setState({ editingName: false });
  }

  @bind
  async deleteCard() {
    const { lesson, deleting } = this.state;
    const order = _.without(lesson.card_order, deleting);
    this.takeSnapshot();
    this.saveCard(deleting, { deleted: true });
    lesson.card_order = order;
    this.setState({ deleting: null, lesson });
  }

  @bind
  async handleOnDragEnd(result) {
    const { lesson, cards } = this.state;
    const initialOrder = lesson.card_order.slice();
    const order = lesson.card_order;
    const id = order[result.source.index];
    const card = cards[result.source.index];
    order.splice(result.source.index, 1);
    order.splice(result.destination.index, 0, id);
    cards.splice(result.source.index, 1);
    cards.splice(result.destination.index, 0, card);
    if (order !== initialOrder) {
      this.takeSnapshot();
      this.setState({ cards });
      this.saveLesson({ card_order: order });
    }
  }

  @bind
  scrollToCard(id) {
    document.getElementById(id).scrollIntoView({ block: 'center', behavior: 'smooth' });
  }

  @bind
  async uploadImage(files) {
    const url = await this.state.lesson.uploadImage(files[0]);
    return url;
  }

  @bind
  async saveCard(id, data) {
    const card = await Card.objects().update(id, data);
    const cards = this.state.cards.map((c) => (c.id === id ? card : c));
    this.setState({ cards });
  }

  @bind
  async saveLesson(data) {
    const lesson = await Lesson.objects().update(this.state.lesson.id, data);
    this.setState({ lesson });
  }

  @bind
  takeSnapshot() {
    const { cards, lesson, historyPosition } = this.state;
    let { history } = this.state;
    const snapshot = { lesson: clone(lesson), cards: clone(cards) };
    if (historyPosition) {
      history = history.slice(0, history.length - historyPosition + 1);
    }
    history.push(snapshot);
    this.setState({ history, historyPosition: 0 });
  }

  @bind
  async applySnapshot(pos) {
    const {
      lesson, cards, history, historyPosition,
    } = this.state;
    let position = pos;
    if (!historyPosition) {
      this.takeSnapshot();
      position += 1;
    }
    const snapshot = history[history.length - position];
    if (JSON.stringify(lesson) !== JSON.stringify(snapshot.lesson)) {
      await this.saveLesson(snapshot.lesson);
    }
    const actions = cards.map((card) => {
      const snapshotCard = _.findWhere(snapshot.cards, { id: card.id });
      if (JSON.stringify(card) !== JSON.stringify(snapshotCard)) {
        return this.saveCard(snapshotCard.id, snapshotCard);
      }
      return null;
    });
    await Promise.all(_.compact(actions));
    this.setState({ historyPosition: position });
  }

  @bind
  async undo() {
    const { historyPosition } = this.state;
    this.applySnapshot(historyPosition + 1);
  }

  @bind
  async redo() {
    const { historyPosition } = this.state;
    this.applySnapshot(historyPosition - 1);
  }

  @bind
  async addNewCard(type) {
    const card = await Card.objects().create({
      lesson_id: this.state.lesson.id,
      card_type: type,
      presentation_type: type === INSTRUCTION ? null : 1,
      content: {
        [INSTRUCTION]: {},
        [QUESTION]: DEFAULT_QUESTION_CARD_CONTENT,
        [SCENARIO]: DEFAULT_SCENARIO_CARD_CONTENT,
      }[type],
    });
    if (!card.id) return;
    this.saveLesson({ card_order: [...this.state.lesson.card_order, card.id] });
    this.setState(
      {
        cards: [...this.state.cards, card],
      },
      () => setTimeout(() => this.scrollToCard(card.id), 300),
    );
  }

  render() {
    const {
      lesson,
      cards,
      editingName,
      deleting,
      historyPosition,
      history,
      canEdit,
      draftCreating,
      showPreview,
    } = this.state;
    if (this.state.loading) return <Loader active />;
    if (!canEdit) {
      return (
        <Modal
          open={!this.state.canEdit}
          style={{ width: '500px' }}
        >
          <Modal.Header>Lesson already published</Modal.Header>
          <Modal.Content>
            You are attempting to edit an already published lesson. To ensure the best experience possible for
            your learners we instead recommend that you create a
            { ' ' }
            <strong>new draft</strong>
            { ' ' }
            (unpublished) version of this lesson.
            You will be able to edit this draft version as much as you need, and then
            { ' ' }
            <strong>publish</strong>
            { ' ' }
            a replacement of this lesson for your learners.
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="grey"
              onClick={this.props.history.goBack}
            >
              Cancel
            </Button>
            <Button
              positive
              onClick={() => {
                this.doCreateNewDraft();
              }}
              disabled={draftCreating}
              loading={draftCreating}
            >
              New Draft
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }

    const orderedCards = _.compact(
      lesson.card_order.map((card) => _.findWhere(cards, { id: card })),
    );
    const counts = _.countBy(orderedCards, (card) => card.card_type);
    if (lesson.lesson_type === ARTICLE) {
      return <ArticleEditorContainer lesson={lesson} history={this.props.history} />;
    }
    if (lesson.lesson_type === VIDEO) {
      return <VideoEditorContainer lesson={lesson} history={this.props.history} />;
    }
    if (lesson.lesson_type !== CARD) {
      return <div>Lesson type not supported</div>;
    }
    const displayCards = lesson.card_order.map((id, index) => {
      const card = _.findWhere(orderedCards, { id });
      let header = '';
      switch (card.card_type) {
        case INSTRUCTION:
          header = card.content.blocks ? card.content.blocks[0].text : '';
          break;
        case QUESTION:
          header = card.content.question;
          break;
        case SCENARIO:
          header = card.content.scenario && card.content.scenario.length > 0
            ? card.content.scenario[0].content
            : '';
          break;
        default:
      }
      return (
        <Draggable key={card.id} draggableId={card.id} index={index}>
          {(provided) => (
            <div ref={provided.innerRef} {...provided.draggableProps} id={`${card.id}-thumbnail`}>
              <Segment style={s.titleCard} color={card.id === this.state.selected ? 'blue' : null}>
                <div style={s.grabber} {...provided.dragHandleProps} />
                <div style={s.column} onClick={() => this.scrollToCard(card.id)}>
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Label color="grey" basic>
                      {index + 1}
                    </Label>
                    <Label color={card.card_type === 1 ? 'grey' : 'blue'}>
                      {CARD_TYPES[card.card_type - 1]}
                    </Label>
                    {deleting === card.id ? (
                      <a
                        style={s.deleteIcon}
                        onClick={this.deleteCard}
                        onMouseOut={() => this.setState({ deleting: null })}
                      >
                        Delete?
                      </a>
                    ) : (
                      lesson.available_to_edit && (
                        <Icon
                          name="trash alternate outline"
                          color="grey"
                          style={s.deleteIcon}
                          onClick={() => this.setState({ deleting: card.id })}
                        />
                      )
                    )}
                  </div>
                  <Header as="h4" style={s.lessonHeader}>
                    {header}
                  </Header>
                </div>
              </Segment>
            </div>
          )}
        </Draggable>
      );
    });
    return (
      <div style={s.container}>
        <div style={s.sidebar}>
          <Button.Group style={{ marginTop: '-40px', marginLeft: '400px', Iindex: 0 }}>
            <Button icon onClick={this.undo} disabled={historyPosition === history.length}>
              <Icon name="undo" />
            </Button>
            <Button icon onClick={this.redo} disabled={historyPosition <= 1}>
              <Icon name="redo" />
            </Button>
          </Button.Group>
          <div style={s.buttonContainer}>
            <Button onClick={this.props.history.goBack} style={{ color: hickoryBlue }}>
              Back
            </Button>
            <Button onClick={() => this.setState({ showPreview: true })} style={{ color: hickoryBlue }}>
              Preview
            </Button>
          </div>
          {editingName ? (
            <div style={s.titleRow}>
              <Input
                defaultValue={lesson.name}
                size="big"
                style={s.titleEdit}
                onChange={(e, { value }) => this.setState({ newLessonName: value })}
              />
              <div style={s.column}>
                <Icon name="check" size="large" color="green" onClick={this.saveLessonName} link />
                <Icon
                  name="close"
                  size="large"
                  color="red"
                  onClick={() => this.setState({ editingName: false, newLessonName: null })}
                  link
                />
              </div>
            </div>
          ) : (
            <div style={s.titleRow}>
              <Header as="h1">{lesson.name}</Header>
              <Icon
                name="edit"
                size="large"
                color="grey"
                onClick={() => this.setState({ editingName: true })}
                link
              />
            </div>
          )}
          <LessonPublishComponent
            lesson={lesson}
            history={this.props.history}
          />
          <span style={{ color: '#7E7F8F' }}>
            Time Estimate: {moment.duration(lesson.card_count * 15, 'seconds').humanize()}
          </span>
          <Segment style={s.cardTypes}>
            <div style={s.column}>
              <Icon name="file alternate outline" color="grey" size="large" />
              {counts['1'] || 0} Instructions
            </div>
            <div style={s.column}>
              <Icon name="question" color="grey" size="large" />
              {counts['2'] || 0} Questions
            </div>
            <div style={s.column}>
              <Icon name="comments outline" color="grey" size="large" />
              {counts['3'] || 0} Scenario
            </div>
          </Segment>
          <div style={s.cardsRow}>
            <Header as="h2" color="grey" style={{ margin: 0 }}>
              Cards:
            </Header>
            {lesson.available_to_edit && (
              <Dropdown text="Add New Card" button className="green">
                <Dropdown.Menu>
                  <Dropdown.Item
                    text="Instruction Card"
                    onClick={() => this.addNewCard(INSTRUCTION)}
                  />
                  <Dropdown.Item text="Question Card" onClick={() => this.addNewCard(QUESTION)} />
                  <Dropdown.Item text="Scenario Card" onClick={() => this.addNewCard(SCENARIO)} />
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
          <DragDropContext onDragEnd={this.handleOnDragEnd}>
            <Droppable droppableId="cards">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={s.titleCardContainer}
                >
                  {displayCards}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        <div style={s.lesson}>
          <Header as="h2" color="grey" style={{ margin: '20px' }}>
            Learner Preview:
          </Header>
          {orderedCards.map((card, index) => (
            <EditorCard
              key={card.id}
              card={card}
              lesson={lesson}
              setSelected={this.setSelected}
              index={index + 1}
              uploadImage={this.uploadImage}
              saveCard={this.saveCard}
              takeSnapshot={this.takeSnapshot}
              historyPosition={historyPosition}
            />
          ))}
        </div>
        <LessonPreviewModal
          lessonId={lesson.id}
          close={() => this.setState({ showPreview: false })}
          show={showPreview}
        />
      </div>
    );
  }
}

export default Radium(EditorContainer);
