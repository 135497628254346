import _ from 'underscore';
import moment from 'moment';
import React, { Component } from 'react';
import Radium from 'radium';
import { Label } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import UserLessonStatus from '@/models/user_lesson_status';
import RoleAssignment from '@/models/role_assignment';
import User from '@/models/user';
import ModelTable from './ModelTable';

class AssignmentList extends Component {
  static propTypes = {
    baseFilter: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    limit: PropTypes.number,
  };

  static defaultProps = {
    baseFilter: {},
    limit: null,
  };

  async componentDidMount() {
    const me = await User.me();
    let roles = await RoleAssignment.objects().filtered({ user_id: me.id }).all();
    roles = _.unique(
      _.map(roles, (assignment) => assignment.role_name),
    );
    me.roles = roles;

    this.setState({
      me,
    });
  }

  render() {
    const { me } = this.state;

    const columnData = [
      {
        name: 'Assigned',
        sort: true,
        key: ['localized_start_date', (date) => moment(date).fromNow()],
      },
      {
        name: 'Status',
        sort: true,
        filter: true,
        key: [
          'completed',
          (comp) => (comp ? (
            <Label color="green">Complete</Label>
          ) : (
            <Label color="yellow">In Progress</Label>
          )),
        ],
      },

      {
        name: 'Lesson',
        sort: true,
        search: true,
        key: [
          'lesson_id',
          'lesson__name',
          (id, name) => (me && (me.roles.includes('Admin') || me.roles.includes('Owner')) ? (
            <Link to={`/app/v2/lessons/${id}`}>
              { name }
            </Link>
          ) : (name)
          ),
        ],
      },
      {
        name: 'User',
        sort: true,
        search: true,
        key: [
          'user__first_name',
          'user__last_name',
          'user__email',
          (first, last, email) => {
            const name = `${first} ${last}`;
            return (!this.state.me.roles.includes('Admin') ? (name) : (
              <Link to={`/app/v2/people/${email}`}>
                { name }
              </Link>
            )
            );
          },
        ],
      },
      {
        name: 'Trigger',
        filter: true,
        key: [
          'trigger_info',
          (info) => {
            if (info) {
              return info.action_taken === 6 ? 'Completed Preceding Lesson' : 'Drip Schedule';
            }
            return 'Added to Group';
          },
        ],
      },
      {
        name: 'Date',
        sort: true,
        key: ['localized_start_date', (date) => moment(date).format('M/D/YY [at] h:mm A')],
      },
    ];
    const dateLimiter = moment()
      .add(-3, 'months')
      .format('MM/DD/YYYY');

    return (
      <ModelTable
        title="Assignments"
        model={UserLessonStatus}
        join={['lesson__name', 'user__first_name', 'user__last_name', 'user__email']}
        columns={columnData}
        defaultSort={['localized_start_date', 'dsc']}
        baseFilter={_.extend(
          {},
          {
            completed: false,
            localized_start_date__gte: dateLimiter,
            available_to_take: true,
            user__deleted: false,
            user__is_active: true,
          },
          this.props.baseFilter,
        )}
        limit={this.props.limit}
      />
    );
  }
}

export default Radium(AssignmentList);
