/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import {
  Table, Icon, Button, Modal, Radio,
} from 'semantic-ui-react';
import User from '@/models/user';
import GroupSelectionModal from './GroupSelectionModal';
/* eslint-disable react/self-closing-comp */

const ROLE_DESCRIPTIONS = {
  None:
    'Learners have no managerial permissions. They can only see lessons which have been scheduled for them and insights on themselves. By default, all new users are learners.',
  Writer: 'Writers have full access to lessons. They can create, edit, tag or delete lessons.',
  Manager:
    'Managers have insights and data reporting access over specific groups. To configure which groups a Manager has permission over, click Next.',
  Admin:
    'Admins have the highest level of access. They can create, edit or delete groups, users, and lessons and view data for all users.',
  Owner:
'Owners have the highest level of access, and can do everything edit and review billing and security settings for the whole organization.',
};

class PeoplePermissionModal extends Component {
  static propTypes = {
    onSave: PropTypes.func,
    canShowUserRoleModal: PropTypes.bool,
    onCancel: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showGroupModal: false,
      me: { roles: [] },
    };
  }

  async componentDidMount() {
    const user = await User.me();

    this.setState({
      me: user,
    });
  }

  @bind
  // eslint-disable-next-line react/no-unused-class-component-methods
  setUser(user) {
    let role = user.roles[0];
    if (role === 'None' && user.roles.length > 1) {
      role = user.roles[1]; // HACK
    }
    this.setState({
      originalRole: role,
      pendingRole: role,
      pendingRoleDescription: ROLE_DESCRIPTIONS[role],
      user,
    });
  }

  @bind
  handleChangedRole(e, radio) {
    this.setState({
      pendingRole: radio.value,
      pendingRoleDescription: ROLE_DESCRIPTIONS[radio.value],
    });
  }

  @bind
  toggleGroupModal() {
    this.setState({
      showGroupModal: !this.state.showGroupModal,
    });
  }

  @bind
  handleSave(groups = []) {
    this.setState({
      showGroupModal: false,
    });
    this.props.onSave(this.state.user, this.state.pendingRole, groups);
  }

  render() {
    let canEdit = true;
    const isOwner = this.state.me.roles.indexOf('Owner') >= 0;

    if (!isOwner) {
      canEdit = this.state.user?.roles?.indexOf('Owner') === -1;
    }

    return (
      <div>
        <Modal closeIcon key={this.state.user} open={this.props.canShowUserRoleModal} onClose={this.props.onCancel}>
          <Modal.Header>Update Permissions</Modal.Header>
          <Modal.Content>
            <Table style={{ borderRadius: 0 }}>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>Learning</Table.HeaderCell>
                  <Table.HeaderCell>Groups</Table.HeaderCell>
                  <Table.HeaderCell>Dashboard</Table.HeaderCell>
                  <Table.HeaderCell>Reports</Table.HeaderCell>
                  <Table.HeaderCell>Lessons</Table.HeaderCell>
                  <Table.HeaderCell>People</Table.HeaderCell>
                  <Table.HeaderCell>Security</Table.HeaderCell>
                  <Table.HeaderCell>Billing</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <Radio
                      name="userRole"
                      checked={this.state.pendingRole === 'None'}
                      value="None"
                      onChange={this.handleChangedRole}
                      disabled={!canEdit}
                    />
                  </Table.Cell>
                  <Table.Cell>Learner (default)</Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Radio
                      name="userRole"
                      checked={this.state.pendingRole === 'Writer'}
                      value="Writer"
                      onChange={this.handleChangedRole}
                      disabled={!canEdit}
                    />
                  </Table.Cell>
                  <Table.Cell>Writer</Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Radio
                      name="userRole"
                      checked={this.state.pendingRole === 'Manager'}
                      value="Manager"
                      onChange={this.handleChangedRole}
                      disabled={!canEdit}
                    />
                  </Table.Cell>
                  <Table.Cell>Manager</Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Radio
                      name="userRole"
                      checked={this.state.pendingRole === 'Admin'}
                      value="Admin"
                      onChange={this.handleChangedRole}
                      disabled={!canEdit}
                    />
                  </Table.Cell>
                  <Table.Cell>Admin</Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>
                    <Radio
                      name="userRole"
                      checked={this.state.pendingRole === 'Owner'}
                      value="Owner"
                      onChange={this.handleChangedRole}
                      disabled={!isOwner}
                    />
                  </Table.Cell>
                  <Table.Cell>Owner</Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="check" />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            <p>{this.state.pendingRoleDescription}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.props.onCancel}>Cancel</Button>
            {this.state.pendingRole === 'Manager' ? (
              <Button primary onClick={this.toggleGroupModal}>
                Next
              </Button>
            ) : (
              <Button primary onClick={this.handleSave} disabled={this.state.pendingRole === this.state.originalRole}>
                Save
              </Button>
            )}
          </Modal.Actions>
        </Modal>
        <GroupSelectionModal
          closeModal={() => {
            this.toggleGroupModal();
            this.props.onCancel();
          }}
          showModal={this.state.showGroupModal}
          handleGroups={this.handleSave}
          closeAll={this.props.onCancel}
          user={this.state.user}
        />
      </div>
    );
  }
}

export default Radium(PeoplePermissionModal);
