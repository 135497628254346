import _ from 'underscore';

function paginate(list, page, numPerPage, perRecordCallback = function (i) { return i; }) {
  const offset = (page - 1) * numPerPage;

  const subset = list.slice(offset, offset + page * numPerPage);

  const result = _.map(subset, perRecordCallback);

  return result;
}

export default paginate;
