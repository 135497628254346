import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import _ from 'underscore';
import {
  Button, Divider, Form, Header, Message, Modal,
} from 'semantic-ui-react';
import User from '../../models/user';

class UserModal extends Component {
  static propTypes = {
    hideModal: PropTypes.func,
    create: PropTypes.bool,
    user: PropTypes.object,
    userId: PropTypes.string,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    updateUsers: PropTypes.func,
    open: PropTypes.bool,
    selectedGroup: PropTypes.object,
  };

  static defaultProps = {
    create: false,
    user: {},
    userId: null,
    onSave: () => {},
    onCancel: () => {},
    open: true,
  };

  constructor(props) {
    super(props);
    const STRING_FIELDS = ['id', 'first_name', 'last_name', 'email', 'preferred_timezone', 'status'];
    const BOOLEAN_FIELDS = ['is_active'];
    this.FIELDS = STRING_FIELDS.concat(BOOLEAN_FIELDS);

    const state = {
      errors: {},
      headerText: this.props.create ? 'Create New User' : 'Edit User',
    };
    STRING_FIELDS.forEach((stringField) => {
      state[stringField] = '';
    });
    BOOLEAN_FIELDS.forEach((booleanField) => {
      state[booleanField] = false;
    });
    this.state = state;
  }

  @bind
  getUserProps(obj) {
    return _.mapObject(_.pick(obj, this.FIELDS), (val) => (typeof val === 'string' ? val.trim() : val));
  }

  @bind
  handleDataError(error) {
    this.setState({ errors: error.data || { non_field_errors: ['Unexpected error. Contact support.'] } });
  }

  @bind
  isMe() {
    return this.props.userId === 'me' || this.props.userId === User.getCurrentUserId();
  }

  @bind
  async componentDidMount() {
    if (this.props.create) {
      // This fixes the input-loses-focus-on-first-character bug.
      // Not sure why. :(
      this.setState(this.state);
    } else if (_.isEmpty(this.props.user)) {
      if (this.props.userId) {
        if (this.isMe()) {
          this.setState({ headerText: 'User Profile' });
        }
        try {
          let user;
          if (this.props.userId === 'me') {
            user = await User.me();
          } else {
            user = await User.objects().get(this.props.userId);
          }
          this.setState(this.getUserProps(user));
        } catch (error) {
          this.handleDataError(error);
        }
      } else {
        throw new Error("The properties 'user' or 'userId' are required for User edit.");
      }
    } else {
      this.setState(this.getUserProps(this.props.user));
    }
  }

  @bind
  async save(event) {
    event.preventDefault();

    const userProps = this.getUserProps(this.state);

    // TODO: Better phone number handling.
    // NOTE: The backend currently only allows US phone numbers in
    //       +1NNNNNNNNNN format, which is not user friendly. We should
    //       consider relaxing the backend validator rules and using
    //       something like the following on the frontend:
    //         https://www.npmjs.com/package/react-phone-number-input
    //         https://www.npmjs.com/package/react-phone-input-2
    if (userProps.phone_number) {
      let phoneNumber = userProps.phone_number;
      if (phoneNumber.startsWith('+1')) {
        phoneNumber = phoneNumber.slice(2);
      }
      userProps.phone_number = `+1${phoneNumber.replace(/\D/g, '')}`;
    }

    try {
      let user;
      if (this.props.create) {
        user = await User.objects().create(userProps, null);
        if (this.props.selectedGroup) {
          await user.assignToGroup(this.props.selectedGroup.id);
        }
      } else {
        if (this.state.new_password) {
          if (this.state.new_password !== this.state.confirm_password) {
            this.handleDataError({ data: { confirm_password: ['Does not match New Password.'] } });
            return;
          }
          userProps.password = this.state.new_password;
        }
        user = await User.objects().update(userProps.id, userProps);
      }
      this.props.onSave(user);
      this.props.hideModal();
      if ('updateUsers' in this.props) {
        this.props.updateUsers();
      }
    } catch (error) {
      this.handleDataError(error);
    }
  }

  @bind
  async cancel() {
    if (!_.isEmpty(this.props.user)) {
      this.setState(this.getUserProps(this.props.user));
    }
    this.props.onCancel();
    this.props.hideModal();
  }

  @bind
  handleInputChange(e, comp) {
    this.setState({ [comp.name]: comp.value });
  }

  @bind
  handleCheckboxChange(e, comp) {
    this.setState((prevState) => ({ [comp.name]: !prevState[comp.name] }));
  }

  @bind
  render() {
    let isActiveSection = null;
    if (!this.props.create && !this.isMe()) {
      isActiveSection = (
        <Form.Checkbox
          label="Active"
          name="is_active"
          checked={this.state.is_active}
          onChange={this.handleCheckboxChange}
        />
      );
    }

    let changePasswordSection = null;
    if (!this.props.create && this.isMe()) {
      changePasswordSection = (
        <div>
          <Divider />
          <Header size="medium">Change Password</Header>
          <Form.Input
            fluid
            label="New Password"
            placeholder="New Password"
            id="user-new-password"
            type="password"
            name="new_password"
            value={this.state.new_password || ''}
            error={this.state.errors.new_password}
            onChange={this.handleInputChange}
          />
          <Form.Input
            fluid
            label="Confirm New Password"
            placeholder="Confirm New Password"
            id="user-confirm-password"
            type="password"
            name="confirm_password"
            value={this.state.confirm_password || ''}
            error={this.state.errors.confirm_password}
            onChange={this.handleInputChange}
          />
        </div>
      );
    }

    let nonFieldErrorsSection = null;
    if (this.state.errors.non_field_errors) {
      nonFieldErrorsSection = (
        <div>
          <Divider />
          <Message negative header="Operation generated error(s)." list={this.state.errors.non_field_errors} />
        </div>
      );
    }

    return (
      <Modal as={Form} size="tiny" onSubmit={this.save} open={this.props.open} closeIcon onClose={this.cancel}>
        <Modal.Header style={{ textAlign: 'center' }}>{this.state.headerText}</Modal.Header>
        <Modal.Content>
          <Form.Input
            fluid
            label="First Name"
            placeholder="First Name"
            name="first_name"
            value={this.state.first_name}
            required
            onChange={this.handleInputChange}
          />
          <Form.Input
            fluid
            label="Last Name"
            placeholder="Last Name"
            name="last_name"
            value={this.state.last_name}
            required
            onChange={this.handleInputChange}
          />
          <Form.Input
            fluid
            label="Email Address"
            placeholder="Email Address"
            id="user-email"
            type="email"
            name="email"
            value={this.state.email}
            error={this.state.errors.email}
            disabled={this.state.status === 'active'}
            required
            onChange={this.handleInputChange}
          />
          <Form.Select
            fluid
            label="Timezone"
            placeholder="Select Timezone..."
            name="preferred_timezone"
            value={this.state.preferred_timezone}
            required
            options={UserModal.preferred_timezone_options}
            search
            onChange={this.handleInputChange}
          />
          {isActiveSection}
          {changePasswordSection}
          {nonFieldErrorsSection}
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={this.cancel}>
            Cancel
          </Button>
          <Button type="submit" className="green">
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

UserModal.preferred_timezone_options = User.SUPPORTED_TIMEZONES.map((tz) => ({ key: tz, value: tz, text: tz }));

export default Radium(UserModal);
