import _ from 'underscore';
import moment from 'moment';
import React, { Component } from 'react';
import Radium from 'radium';
import bind from 'bind-decorator';
import {
  Button, Loader, Table, Dropdown, Modal, Header, Checkbox, Input,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import SumoLogger from 'sumo-logger';
import pluralize from 'pluralize';
import Group from '@/models/group';
import LessonSchedule from '@/models/lesson_schedule';
import Lesson from '@/models/lesson';
import { GROUP_TYPE_COMPLIANCE, CARD, SUMO } from '../../constants';
import LessonModal from '../Shared/LessonModal';

const FREQUENCY = ['days', 'months'];

const sumoLogger = new SumoLogger({ endpoint: SUMO });
let s = {};

class ComplianceContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      group: null,
      lessons: [],
      reviewFrequencyType: 2,
    };
  }

  async componentDidMount() {
    await this.reloadData();
  }

  @bind
  async reloadData() {
    this.setState({ loading: true });
    const group = await Group.objects()
      .filtered({ deleted: false, group_type: GROUP_TYPE_COMPLIANCE })
      .first();
    const filter = {
      deleted: false,
      organizational_group_id: group.id,
    };
    const schedules = await LessonSchedule.objects()
      .filtered(filter)
      .all();
    const lessonIds = _.map(schedules, (schedule) => schedule.lesson_id);
    const lessons = await Lesson.objects()
      .filtered({ deleted: false, id: lessonIds })
      .sorted('name', true)
      .all();

    const records = _.map(lessons, (lesson) => {
      const schedule = _.findWhere(schedules, { lesson_id: lesson.id });
      let description = 'Available to Schedule';
      let scheduledDateString = 'N/A';
      let scheduled = false;
      if (schedule && schedule.start_date) {
        description = 'Live';
        scheduled = true;
        if (schedule.start_date_tz === 'UTC') {
          scheduledDateString = moment(schedule.start_date).format('MMMM Do YYYY, hh:mm a');
        } else {
          scheduledDateString = moment(schedule.start_date)
            .tz(schedule.start_date_tz)
            .format('MMMM Do YYYY, hh:mm a');
        }
      }

      return {
        lesson_id: lesson.id,
        lesson,
        localized_start_date: null,
        title: lesson.name,
        status: description,
        available_to_edit: lesson.available_to_edit,
        scheduled,
        scheduled_date_string: scheduledDateString,
        reviews_enabled: schedule.reviews_enabled,
        review_schedule: schedule.continuous_reviews
          ? 'Continuous'
          : `Every ${schedule.review_frequency} ${pluralize(
            FREQUENCY[schedule.review_frequency_type - 1],
          )}`,
        completion_criteria:
          schedule.retake_threshold > 0
            ? `${parseInt(schedule.retake_threshold * 100.0)}% or higher`
            : 'Any score',
        schedule,
      };
    });

    this.setState({
      loading: false,
      group,
      records,
      lessons,
    });
  }

  @bind
  toggleLessonModal() {
    this.setState({ showLessonModal: false });
  }

  @bind
  async addLessons(lessons) {
    this.setState({ loading: true });
    const actions = lessons.map((lesson) => {
      const newLessonData = {
        lesson_id: lesson.id,
        organizational_group_id: this.state.group.id,
      };
      return LessonSchedule.objects().create(newLessonData);
    });
    await Promise.all(actions)
      .then(() => {
        this.reloadData();
      })
      .catch(() => {
        // TODO: error
      });
  }

  @bind
  handleRemoveFromGroup(record, doConfirm) {
    const { schedule } = record;
    if (doConfirm) {
      this.setState({
        selectedName: record.title,
        selectedRecord: record,
        showRemovalConfirmation: true,
      });
    } else {
      LessonSchedule.objects()
        .update(schedule.id, { deleted: true })
        .then(() => {
          this.setState({
            selectedRecord: null,
            showRemovalConfirmation: false,
          });
          this.reloadData();
        })
        .catch(() => {
          // TODO
        });
    }
  }

  @bind
  handleRemovalConfirmationCancel() {
    this.setState({
      showRemovalConfirmation: false,
    });
  }

  @bind
  setStartDate(record) {
    const { lesson } = record;
    this.setState({
      showSetStartTimeModal: true,
      selected_record: record,
      scheduled_review: 12,
      scheduled_score: 60,
      allowRetakes: false,
      scheduled_continuous_reviews: false,
      continuous_reviews_enabled: lesson.lesson_type === CARD,
    });
  }

  @bind
  async scheduleLesson() {
    const { schedule } = this.state.selected_record;

    let retakeThreshold = parseInt(this.state.scheduled_score) / 100.0;
    if (retakeThreshold > 1) {
      retakeThreshold = 1;
    } else if (retakeThreshold < 0) {
      retakeThreshold = 0;
    }

    await LessonSchedule.objects().update(schedule.id, {
      start_date: moment()
        .startOf('day')
        .toISOString(),
      continuous_reviews: this.state.scheduled_continuous_reviews,
      review_frequency: this.state.scheduled_review,
      review_frequency_type: this.state.reviewFrequencyType,
      retake_threshold: this.state.allowRetakes ? retakeThreshold : 0,
    });
    this.setState({
      showSetStartTimeModal: false,
    });
    this.reloadData();
  }

  @bind
  toggleAllowRetakes() {
    this.setState({
      allowRetakes: !this.state.allowRetakes,
    });
  }

  @bind
  toggleContinuousReviews() {
    this.setState({
      scheduled_continuous_reviews: !this.state.scheduled_continuous_reviews,
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader active={this.state.loading} inline="centered" />;
    }
    const { records, reviewFrequencyType } = this.state;

    return (
      <div style={s.body}>
        <div style={s.container}>
          <div style={{ width: '100%' }}>
            <div style={s.header}>
              <h2>Compliance Training</h2>
              <Button positive onClick={() => this.setState({ showLessonModal: true })}>
                Assign
              </Button>
            </div>
            {records && records.length > 0 ? (
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Lesson Name</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Start Date</Table.HeaderCell>
                    <Table.HeaderCell>Review Schedule</Table.HeaderCell>
                    <Table.HeaderCell>Completion Criteria</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {records.map((record) => {
                    if (record.lesson_id === undefined) {
                      sumoLogger.log(
                        `ERROR: missing lesson_id for UserLessonStatus ${record.id} in ComplianceContainer.jsx`,
                      );
                    }
                    return (
                      <Table.Row>
                        <Table.Cell>
                          <Link to={`/app/v2/lessons/${record.lesson_id}`}>{record.title}</Link>
                        </Table.Cell>
                        <Table.Cell>{record.status}</Table.Cell>
                        <Table.Cell>{record.scheduled_date_string}</Table.Cell>
                        <Table.Cell>{record.review_schedule}</Table.Cell>
                        <Table.Cell>{record.completion_criteria}</Table.Cell>
                        <Table.Cell>
                          <Dropdown icon="ellipsis vertical" floating labeled className="icon">
                            <Dropdown.Menu>
                              <Dropdown.Item
                                text="Preview"
                                onClick={() => {
                                  window.location.href = `/app/v2/lessons/${record.lesson_id}/player/preview`;
                                }}
                              />
                              <Dropdown.Item
                                text="Edit"
                                onClick={() => {
                                  window.open(`/app/v2/lessons/${record.lesson_id}/editor`, '_blank');
                                }}
                              />
                              <Dropdown.Divider />
                              {record.scheduled ? (
                                <Dropdown.Item
                                  text={
                                  record.reviews_enabled ? 'Turn Off Reviews' : 'Turn On Reviews'
                                }
                                  onClick={() => {
                                  // TODO
                                  }}
                                />
                              ) : (
                                <Dropdown.Item
                                  text="Schedule"
                                  onClick={() => {
                                    this.setStartDate(record);
                                  }}
                                />
                              )}
                              <Dropdown.Item
                                text="Remove"
                                onClick={() => {
                                  this.handleRemoveFromGroup(record, true);
                                }}
                              />
                            </Dropdown.Menu>
                          </Dropdown>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            ) : (
              <p>
                No lessons are currently assigned for compliance training. Click &quot;Assign&quot;
                above to get started.
              </p>
            )}
          </div>
        </div>

        <LessonModal
          title="Assign lesson for compliance training"
          showModal={this.state.showLessonModal}
          toggleModal={this.toggleLessonModal}
          previewLesson={this.handlePreviewLesson}
          addLessons={this.addLessons}
          assignedLessons={this.state.lessons.map((lesson) => lesson.id)}
        />

        <Modal open={this.state.showRemovalConfirmation}>
          <Modal.Header>Confirm Action</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <p>
                Are you sure you wish to remove the lesson
                {' '}
                <b>{this.state.selectedName || ''}</b>
                {' '}
                from compliance training?
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleRemovalConfirmationCancel}>Cancel</Button>
            <Button
              content="Remove"
              color="red"
              onClick={() => {
                this.handleRemoveFromGroup(this.state.selectedRecord, false);
              }}
            />
          </Modal.Actions>
        </Modal>

        <Modal
          onClose={() => {
            this.setState({
              showSetStartTimeModal: false,
            });
          }}
          open={this.state.showSetStartTimeModal}
          style={{ maxWidth: 'fit-content' }}
          closeIcon
        >
          <Modal.Header>Schedule Lesson</Modal.Header>
          <Modal.Content>
            <Header as="h3">
              <p>
                Please configure the completion criteria and review schedule for &quot;
                {this.state.selected_record ? this.state.selected_record.title : ''}
                &quot;.
              </p>
            </Header>
            <div>
              <table>
                <tbody>
                  {this.state.continuous_reviews_enabled ? (
                    <tr>
                      <td>
                        <Checkbox
                          label="Continuous Learning"
                          onChange={this.toggleContinuousReviews}
                          checked={this.state.scheduled_continuous_reviews}
                          toggle
                        />
                      </td>
                      <td />
                    </tr>
                  ) : null}
                  {this.state.scheduled_continuous_reviews ? null : (
                    <tr>
                      <td>Review entire lesson every:</td>
                      <td>
                        <Input
                          style={{ maxWidth: '60px' }}
                          value={this.state.scheduled_review}
                          onChange={(e, { value }) => {
                            this.setState(
                              { scheduled_review: value.match(/\d*/)[0] },
                              this.updateParent,
                            );
                          }}
                        />
                        <Dropdown
                          compact
                          selection
                          onChange={(e, { value }) => { this.setState({ reviewFrequencyType: value }); }}
                          value={reviewFrequencyType}
                          options={[
                            {
                              key: 1,
                              text: 'Days',
                              value: 1,
                            },
                            {
                              key: 2,
                              text: 'Months',
                              value: 2,
                            },
                          ]}
                        />
                        {' '}
                        after completion
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <Checkbox label="Allow retakes" onChange={this.toggleAllowRetakes} toggle />
                    </td>
                    <td />
                  </tr>
                  {this.state.allowRetakes ? (
                    <tr>
                      <td>Require a score of:</td>
                      <td>
                        <Input
                          value={this.state.scheduled_score}
                          onChange={(e, { value }) => {
                            this.setState({ scheduled_score: value.match(/\d*/)[0] });
                          }}
                        />
                        {' '}
                        percent or higher to mark as completed
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                this.setState({
                  showSetStartTimeModal: false,
                });
              }}
            >
              Cancel
            </Button>
            <Button onClick={this.scheduleLesson} positive>
              Schedule
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

s = {
  body: {},
  container: {
    marginLeft: '20%',
    marginRight: '20%',
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

export default Radium(ComplianceContainer);
