import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import bind from 'bind-decorator';
import moment from 'moment';
import CardContainer from './CardContainer';
import LineChart from './LineChart';

class LineChartCard extends Component {
  static propTypes = {
    title: PropTypes.string,
    xTitle: PropTypes.string,
    chartData: PropTypes.array,
    startDate: PropTypes.string,
    yTitle: PropTypes.string,
    width: PropTypes.string,
  };

  @bind
  exportCSV() {
    const { chartData } = this.props.chartData;
    // let csvContent = 'data:text/csv;charset=utf-8,\n"",'
    if (chartData.length) {
      let csvContent = `"",  ${chartData[0].data
        .map((dat) => `"${dat[0]}"`)
        .join(', ')}\n`;
      csvContent += chartData
        .map((series) => `"${series.name}",  +
            ${series.data
    .map((dat) => `"${dat[1]}"`)
    .join(', ')}`)
        .join('\n');

      const startDate = moment(chartData[0].data[0][0]).format('YYYY-MM-DD');
      const endDate = moment(chartData[0].data[chartData[0].data.length - 1][0]).format('YYYY-MM-DD');
      const filename = `${this.props.title}: ${startDate}-${endDate}.csv`;
      const csvFile = new Blob([csvContent], { type: 'text/csv' });
      const downloadLink = document.createElement('a');
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = 'none';
      document.body.appendChild(downloadLink);
      downloadLink.click();
    }
  }

  render() {
    return (
      <CardContainer
        title={this.props.title}
        options={{ 'Export CSV': this.exportCSV }}
        loaded={!!this.props.chartData[0].data}
        width={this.props.width}
      >
        <LineChart
          xTitle={this.props.xTitle}
          chartData={this.props.chartData}
          startDate={this.props.startDate}
          yTitle={this.props.yTitle}
        />
      </CardContainer>
    );
  }
}

export default Radium(LineChartCard);
