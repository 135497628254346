import moment from 'moment';
import { ModelBase } from '@/models/model';

class UserLessonStatus extends ModelBase {
  static namespace = 'user_lesson_status';

  isLate() {
    const late = moment().subtract(3, 'days');
    let isLate = false;
    if (!this.completed && late.isAfter(this.localized_start_date)) {
      isLate = true;
    }
    isLate = this.cards_available_to_review.some((review) => {
      if (late.isAfter(review.scheduled_review_date)) return true;
      return false;
    });
    return isLate;
  }
}

export default UserLessonStatus.register();
