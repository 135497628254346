/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Link } from 'react-router-dom';
import bind from 'bind-decorator';
import moment from 'moment';
import _ from 'underscore';
import {
  Table, Loader, List, Header, Icon, Button, Checkbox, Label,
} from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import User from '@/models/user';
import PeoplePermissionModal from './PeoplePermissionModal';

const s = {
  groupCell: {
    cursor: 'pointer',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
  },
};

class PeoplePanel extends Component {
  static propTypes = {
    users: PropTypes.array,
    style: PropTypes.object,
    setSort: PropTypes.func,
    sort: PropTypes.object,
    selectAll: PropTypes.func,
    selectUser: PropTypes.func,
    selectedUsers: PropTypes.array,
    updateUsers: PropTypes.func,
    loading: PropTypes.bool,
    showActions: PropTypes.bool,
    showTags: PropTypes.bool,
    showRole: PropTypes.bool,
    allowEdit: PropTypes.bool,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    users: [],
    selectedUsers: [],
    showActions: true,
    showTags: true,
    showRole: true,
    allowEdit: true,
    placeholder: 'No Results Found',
  };

  constructor(props) {
    super(props);

    this.state = {};
    this.roleComp = React.createRef();
  }

  @bind
  showUserRoleModal(user) {
    this.roleComp.current.setUser(user);
    this.setState({ canShowUserRoleModal: true, user });
  }

  @bind
  hideUserRoleModal() {
    this.setState({ canShowUserRoleModal: false });
  }

  @bind
  async saveUserRoleChange(user, newRole, groups = []) {
    let actions = [];
    // 1. update user
    if (groups.length) {
      actions = groups.map((group) => this.state.user.updateRole(newRole, group));
    } else {
      actions.push(this.state.user.updateRole(newRole));
    }

    // 2. close modal
    await Promise.all(actions);
    this.setState({ canShowUserRoleModal: false });
    this.props.updateUsers();
  }

  render() {
    const columns = _.compact([
      { key: 'first_name', label: 'First Name' },
      { key: 'last_name', label: 'Last Name' },
      { key: 'email', label: 'Email' },
      this.props.showRole ? { key: '', label: 'Role' } : null,
      { key: 'status', label: 'Status' },
      this.props.showActions ? { key: '', label: 'Actions' } : null,
      { key: '', label: 'Learning Groups' },
      { key: 'last_login', label: 'Last Login' },
      this.props.showTags ? { key: '', label: 'Tags' } : null,
    ]);
    const { sort } = this.props;
    const users = this.props.loading ? [] : this.props.users;
    return (
      <div style={[this.props.style, { height: 'calc(100% - 150px)', overflow: 'auto' }]}>
        <Table style={{ borderRadius: 0, cursor: 'default' }}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Checkbox onChange={this.props.selectAll} />
              </Table.HeaderCell>
              {columns.map((item) => {
                if (item.key) {
                  let icon = 'sort down';
                  if (sort.column.key === sort.column) {
                    icon = sort.ascending ? 'sort content ascending' : 'sort content descending';
                  }
                  return (
                    <Table.HeaderCell
                      key={uuid()}
                      onClick={() => {
                        this.props.setSort(item.key);
                      }}
                    >
                      <div style={s.row}>
                        {item.label}
                        <Icon name={icon} disabled={icon === 'sort down'} />
                      </div>
                    </Table.HeaderCell>
                  );
                }
                return (
                  <Table.HeaderCell key={uuid()} style={{ cursor: 'default' }}>
                    {item.label}
                  </Table.HeaderCell>
                );
              })}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {users.map((user) => (
              <ExpandableRow
                key={user.email}
                user={user}
                selectUser={this.props.selectUser}
                updateUsers={this.props.updateUsers}
                showActions={this.props.showActions}
                showRole={this.props.showRole}
                showTags={this.props.showTags}
                allowEdit={this.props.allowEdit}
                showUserRoleModal={this.showUserRoleModal}
                selected={
                    !!this.props.selectedUsers.find((item) => item.id === user.id)
                  }
              />
            ))}
          </Table.Body>
        </Table>
        {this.props.loading ? (
          <Loader active={!users.length} inline="centered" />
        ) : (
          _.isEmpty(this.props.users) && (
            <Header as="h3" textAlign="center">
              {this.props.placeholder}
            </Header>
          )
        )}
        <PeoplePermissionModal
          canShowUserRoleModal={this.state.canShowUserRoleModal}
          onCancel={this.hideUserRoleModal}
          onSave={this.saveUserRoleChange}
          ref={this.roleComp}
        />
      </div>
    );
  }
}

class ExpandableRow extends Component {
  static propTypes = {
    user: PropTypes.object,
    selectUser: PropTypes.func,
    selected: PropTypes.bool,
    updateUsers: PropTypes.func,
    showUserRoleModal: PropTypes.func,
    allowEdit: PropTypes.bool,
    showRole: PropTypes.bool,
    showActions: PropTypes.bool,
    showTags: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
    this.statusMap = {
      not_invited: 'Not Invited',
      invited: 'Invited',
      invite_expired: 'Invite Expired',
      inactive: 'Inactive',
      active: 'Active',
      invite_failed: 'Invite Failed',
      invalid: 'Invalid',
    };
  }

  @bind
  toggleExpansion() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  @bind
  async sendInvite(user) {
    await user.sendInvite();
    this.props.updateUsers();
  }

  @bind
  async removeTag(tag) {
    const { user } = this.props;
    const newTags = user.tags.filter((item) => item !== tag);
    await User.objects().update(user.id, { tags: newTags });
    this.props.updateUsers();
  }

  render() {
    const { statusMap } = this;
    const { user } = this.props;
    const roles = _.compact(_.map(user.roles, (role) => {
      if (role === 'None') {
        return null; // remove for now so that we can add learner to the end (below)
      }
      return role;
    }));
    if (!roles.includes('Learner')) {
      roles.push('Learner'); // everybody is a learner
    }
    return (
      <Table.Row key={`user${user.id}`}>
        <Table.Cell>
          <Checkbox
            onChange={() => {
              this.props.selectUser(user);
            }}
            checked={this.props.selected}
          />
        </Table.Cell>
        <Table.Cell>
          {this.props.allowEdit ? (
            <Link to={`/app/v2/people/${user.email}`}>{user.first_name}</Link>
          ) : (
            <p>{user.first_name}</p>
          )}
        </Table.Cell>
        <Table.Cell>
          {this.props.allowEdit ? (
            <Link to={`/app/v2/people/${user.email}`}>{user.last_name}</Link>
          ) : (
            <p>{user.last_name}</p>
          )}
        </Table.Cell>
        <Table.Cell>{user.email}</Table.Cell>
        {this.props.showRole && (
          <Table.Cell>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                this.props.showUserRoleModal(user);
              }}
            >
              {roles.join(', ')}
            </a>
          </Table.Cell>
        )}
        <Table.Cell>{statusMap[user.status]}</Table.Cell>
        {this.props.showActions && (
          <Table.Cell>
            {(() => {
              switch (user.status) {
                case 'not_invited':
                  return (
                    <Button
                      color="blue"
                      onClick={() => {
                        this.sendInvite(user);
                      }}
                    >
                      Send Invite
                    </Button>
                  );
                case 'invited':
                case 'invite_expired':
                  return (
                    <Button
                      color="grey"
                      onClick={() => {
                        this.sendInvite(user);
                      }}
                    >
                      Resend Invite
                    </Button>
                  );
                default:
                  return null;
              }
            })()}
          </Table.Cell>
        )}
        <Table.Cell>
          <ExpandableCell
            content={user.learning_groups}
            expanded={this.state.expanded}
            toggleExpansion={this.toggleExpansion}
          />
        </Table.Cell>
        <Table.Cell>{user.last_login ? moment(user.last_login).format('MM-DD-YYYY') : ''}</Table.Cell>
        {this.props.showTags && (
          <Table.Cell>
            <ExpandableCell
              content={user.tags}
              expanded={this.state.expanded}
              toggleExpansion={this.toggleExpansion}
              editable
              removeTag={this.removeTag}
            />
          </Table.Cell>
        )}
      </Table.Row>
    );
  }
}

class ExpandableCell extends Component {
  static propTypes = {
    content: PropTypes.array,
    expanded: PropTypes.bool,
    toggleExpansion: PropTypes.func,
    editable: PropTypes.bool,
    removeTag: PropTypes.func,
  };

  render() {
    const content = this.props.expanded ? this.props.content : this.props.content.slice(0, 2);
    return (
      <List onClick={this.props.toggleExpansion} style={this.props.content.length > 2 ? s.groupCell : null}>
        {content.map((item, index) => {
          let expandable = false;
          if (!this.props.expanded && index === 1 && this.props.content.length > 2) {
            expandable = true;
          }
          if (this.props.editable) {
            return (
              <List.Item style={s.row} key={uuid()}>
                <Label style={{ whiteSpace: 'nowrap' }}>
                  {item}
                  <Icon
                    style={{ marginLeft: '10px', marginRight: '0' }}
                    name="remove circle"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.props.removeTag(item);
                    }}
                  />
                </Label>
                {expandable ? ' ...' : ''}
              </List.Item>
            );
          }
          return <List.Item key={uuid()}>{expandable ? `${item} ...` : item}</List.Item>;
        })}
      </List>
    );
  }
}

export default Radium(PeoplePanel);
