import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'semantic-ui-react';
import moment from 'moment';
import bind from 'bind-decorator';
import User from '@/models/user';
import ModelTable from './ModelTable';

const STATUS = {
  not_invited: 'Not Invited',
  invited: 'Invited',
  invite_expired: 'Invite Expired',
  inactive: 'Inactive',
  active: 'Active',
  invite_failed: 'Invite Failed',
  invalid: 'Invalid',
};

class ExistingUserModal extends Component {
  static propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    action: PropTypes.func,
    actionText: PropTypes.string,
    filter: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.table = React.createRef();
  }

  @bind
  action() {
    this.props.action(this.table.current.state.selectedModels);
    this.table.current.selectNone();
  }

  render() {
    const {
      show, close, actionText, filter,
    } = this.props;
    return (
      <Modal open={show}>
        <Modal.Header>Find Hickory Users</Modal.Header>
        <Modal.Content>
          <ModelTable
            ref={this.table}
            model={User}
            columns={[
              {
                name: 'Active',
                key: [
                  'last_login',
                  (lastLogin) => (lastLogin ? moment(lastLogin).fromNow() : ''),
                ],
              },
              {
                name: 'Learner',
                key: [
                  'first_name',
                  'last_name',
                  (first, last) => `${first} ${last}`,
                ],
                search: true,
              },
              {
                name: 'Email',
                key: 'email',
                search: true,
              },
              {
                name: 'Status',
                key: [
                  'status',
                  (status) => STATUS[status],
                ],
              },
            ]}
            baseFilter={filter}
            defaultSort={['name', 'asc']}
            select
          />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={close}>Cancel</Button>
          <Button onClick={this.action} positive>{actionText}</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ExistingUserModal;
